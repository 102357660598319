import { Row, Col, Card, Progress } from "antd";
import React from "react";
import { ReactComponent as Logo } from "../../../../media/icons/books.svg";

const SuccessMeter = (props) => {
  const { progress } = props;

  return (
    <Row
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#755FE2",
        display: "flex",
      }}
    >
      <Col
        span={12}
        style={{
          paddingLeft: "4%",
          paddingTop: "6%",
          paddingBottom: "6%",
          display: "flex",
          gap: "32px",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <span style={{ fontSize: "20px", fontWeight: "bold", color: "white" }}>
          {progress > 50
            ? "Так держать, скоро вы доберетесь до нового уровня"
            : "Еще только начало!"}
        </span>
        <span style={{ color: "white" }}>
          {progress > 50
            ? "Прошло уже больше половины курса"
            : "Курс только начинается!"}
        </span>
      </Col>
      <Col
        span={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="ad-div"
      >
        <Progress
          type="circle"
          percent={progress ?? 0}
          success={{ percent: progress ?? 0 }}
        />
      </Col>
    </Row>
  );
};

export default SuccessMeter;
