import { Row, Col, Card, Button, Space } from "antd";
import React from "react";
import { ReactComponent as Logo } from "./ad-second.svg";
import context from "../../../../context";

const AdDivSecond = (props) => {
  const { navigate } = props;
  const { window } = React.useContext(context);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "white",
      }}
    >
      <Row
        style={{
          maxHeight: "361px",
        }}
      >
        <Col span={12} xs={24} xl={12} md={24} xxl={12}>
          <Card
            style={{ backgroundColor: "transparent", border: "0" }}
            bodyStyle={{ textAlign: "left" }}
          >
            <Space
              direction="vertical"
              style={{ width: "100%", padding: "5%" }}
              size={32}
            >
              <span style={{ fontSize: "34px", lineHeight: "34px" }}>
                Правильная история начинается с хорошего преподавателя
              </span>
              <span>
                Мы предоставляем всю точную информацию об учителях без прикрас,
                чтобы Вы точно нашли своего учителя.
              </span>
              <Button
                type="primary"
                shape="round"
                onClick={() => navigate("/teachers")}
              >
                Смотреть преподавателей
              </Button>
            </Space>
          </Card>
        </Col>
        {!(window.width <= 1200) && (
          <Col
            span={12}
            xs={24}
            xl={12}
            md={24}
            xxl={12}
            style={
              {
                // display: "flex",
                // justifyContent: "flex-end",
              }
            }
          >
            <Logo
              style={
                window.width <= 1200
                  ? {
                      width: "100%",
                      height: "100%",
                    }
                  : {}
              }
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AdDivSecond;
