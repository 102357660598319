import { Button, Space } from "antd";
import React, { useContext, useState } from "react";
import styles from "./index.module.css";
import { ReactComponent as Logo } from "../../../../../media/icons/header-logo.svg";
import { useNavigate } from "react-router-dom";
import ls from "localstorage-slim";
import { getTimePackages } from "../../../../../apis";
import context from "../../../../../context";

const HomePageRibbon = (props) => {
  const navigate = useNavigate();

  const { windowDimensions, isCurator = true } = props;
  const small = windowDimensions.width < 1000;
  const button = !small && (
    <div></div>
    // <Button type="text" onClick={() => navigate("/my-courses")}>
    //   Мои преподаватели
    // </Button>
  );

  const { hasCourses, profileData } = useContext(context);

  return hasCourses ||
    (ls.get("profile")?.role !== "student" &&
      profileData?.role !== "student") ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
      }}
      className={styles["wrapper-ribbon"]}
    >
      <div>
        <Logo onClick={() => navigate("/bought-main-page")} />
      </div>
      {/* {isCurator && !small && (
        <Button type="text" onClick={() => navigate("/library")}>
          Ученики
        </Button>
      )} */}
      {isCurator && !small && (
        <div>
          {ls.get("profile")?.role === "student" ||
          profileData?.role === "student" ? (
            <Button type="text" onClick={() => navigate("/my-courses")}>
              Мои курсы
            </Button>
          ) : (
            <Button
              type="text"
              onClick={() => navigate("/teacher-students-page")}
            >
              Студенты
            </Button>
          )}
        </div>
      )}
      {(ls.get("profile")?.role === "student" ||
        profileData?.role === "student") &&
        isCurator &&
        !small && (
          <div>
            <Button type="text" onClick={() => navigate("/teachers")}>
              Учителя
            </Button>
          </div>
        )}
      {(ls.get("profile")?.role === "supervisor" ||
        profileData?.role === "supervisor" ||
        ls.get("profile")?.role === "headteacher" ||
        profileData?.role === "headteacher") &&
        isCurator &&
        !small && (
          <div>
            <Button
              type="text"
              onClick={() => navigate("/teacher-teachers-page")}
            >
              Учителя
            </Button>
          </div>
        )}
      {(ls.get("profile")?.role !== "student" ||
        profileData?.role !== "student") &&
        isCurator &&
        !small && (
          <div>
            {(ls.get("profile")?.role === "supervisor" ||
              profileData?.role === "supervisor") && (
              <Button
                type="text"
                onClick={() => navigate("/teacher-curators-page")}
              >
                Кураторы
              </Button>
            )}
          </div>
        )}
      {!small &&
        (ls.get("profile")?.role === "teacher" ||
        profileData?.role === "teacher" ? (
          <Button type="text" onClick={() => navigate("/hws")}>
            Домашние задания
          </Button>
        ) : (
          <Button type="text" onClick={() => navigate("/library")}>
            Библиотека
          </Button>
        ))}
      {(ls.get("profile")?.role === "student" ||
        ls.get("profile")?.role === "headteacher") &&
        isCurator &&
        !small && (
          <Button type="text" onClick={() => navigate("/news-list")}>
            Новости
          </Button>
        )}
      {ls.get("profile")?.role !== "student" &&
        profileData?.role !== "student" &&
        isCurator &&
        !small && (
          <Button type="text" onClick={() => navigate("/news-internal-list")}>
            Нововведения
          </Button>
        )}
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
      }}
      className={styles["wrapper-ribbon"]}
    >
      <div>
        <Logo onClick={() => navigate("/")} />
      </div>
      {!small && (
        <div>
          <Button type="text" onClick={() => navigate("/library")}>
            Библиотека
          </Button>
        </div>
      )}
      {!small && (
        <div>
          <Button type="text" onClick={() => navigate("/first-look")}>
            Первый взгляд на уроки
          </Button>
        </div>
      )}
      {!small && (
        <div>
          <Button type="text" onClick={() => navigate("/teachers")}>
            Преподаватели
          </Button>
        </div>
      )}
    </div>
  );
};

export default HomePageRibbon;
