import React from "react";
import { Col, Card, Row, Input, Button, Space } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router";
import {
  getHeadTeachers,
  getStudents,
  getTeachers,
  getTimePackages,
  login,
} from "../../../../../apis";
import context from "../../../../../context";
import ls from "localstorage-slim";
const LoginCard = (props) => {
  const { setLogin, setLoading } = props;

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loginButt, setLoginButt] = React.useState(false);
  const [error, setError] = React.useState(false);

  const navigate = useNavigate();
  const { setProfileData, setHasCourses } = React.useContext(context);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  React.useEffect(() => {
    const cond = validateEmail(email) && password.length >= 8;
    if (cond && !loginButt) {
      setLoginButt(true);
    } else if (!cond && loginButt) setLoginButt(false);
  }, [email, password]);

  return (
    <Card
      headStyle={{ flex: 0 }}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
      }}
      title="Вход"
    >
      <Space direction="vertical" style={{ width: "100%" }} size={10}>
        <Row>
          <Col span={24}>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Электронная почта"
              size="large"
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input.Password
              onChange={(e) => setPassword(e.target.value)}
              value={[password]}
              placeholder="Пароль"
              size="large"
              style={{ width: "100%" }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={12} style={{ flex: "inherit" }}>
            <Button
              type="link"
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 500);
              }}
            >
              Забыли пароль?
            </Button>
          </Col>
        </Row>
        <Row style={{ paddingTop: "5%" }}>
          <Col span={24}>
            <Button
              onClick={() => {
                setLoading(true);
                login(email, password)
                  .then((r) => r.json())
                  .then((res) => {
                    if (!res.token) {
                      throw new Error(res?.status);
                    }
                    if (res?.grants?.[0] === "ROLE_TEACHER") {
                      getTeachers(res?.token)
                        .then((r) => r.json())
                        .then((r) => {
                          console.log(r, email);
                          // const user = r.find(
                          //   (element) =>
                          //     element.email?.toLowerCase() ===
                          //     email?.toLowerCase()
                          // );
                          const user = r.find(
                            (element) => element.userId === res?.id
                          );
                          if (!user) {
                            throw new Error(res?.status);
                          }
                          ls.set("token", res?.token, {
                            ttl: 60 * 60 * 24,
                          });
                          ls.set(
                            "profile",
                            {
                              ...user,
                              role: "teacher",
                              userId: res?.id,
                            },
                            {
                              ttl: 60 * 60 * 24,
                            }
                          );

                          setProfileData({
                            ...user,
                            userId: res?.id,
                          });
                          setTimeout(() => {
                            setLoading(false);
                            navigate("/teacher-main-page");
                          }, 300);
                        })
                        .catch((e) => console.log(e));
                    } else if (
                      res?.grants?.[0] === "ROLE_HEAD_TEACHER" ||
                      res?.grants?.[0] === "ROLE_SUPERVISOR"
                    ) {
                      getHeadTeachers(res?.token)
                        .then((r) => r.json())
                        .then((r) => {
                          console.log(r, email);

                          // const user = r.find(
                          //   (element) =>
                          //     element.email?.toLowerCase() ===
                          //     email?.toLowerCase()
                          // );
                          const user = r.find(
                            (element) => element.userId === res?.id
                          );
                          if (!user) {
                            throw new Error(res?.status);
                          }
                          ls.set("token", res?.token, {
                            ttl: 60 * 60 * 24,
                          });
                          ls.set(
                            "profile",
                            {
                              ...user,
                              role:
                                res?.grants?.[0] === "ROLE_HEAD_TEACHER"
                                  ? "headteacher"
                                  : "supervisor",
                              userId: res?.id,
                            },
                            {
                              ttl: 60 * 60 * 24,
                            }
                          );

                          setProfileData({
                            ...user,
                            userId: res?.id,
                          });
                          setTimeout(() => {
                            setLoading(false);
                            navigate("/teacher-main-page");
                          }, 300);
                        })
                        .catch((e) => console.log(e));
                    } else if (res?.grants?.[0] === "ROLE_STUDENT") {
                      getStudents(res?.token)
                        .then((r) => r.json())
                        .then((r) => {
                          const user = r.find(
                            (element) => element.userId === res?.id
                          );
                          if (!user) {
                            throw new Error(res?.status);
                          }
                          ls.set("token", res?.token, {
                            ttl: 60 * 60 * 24,
                          });
                          ls.set(
                            "profile",
                            {
                              ...user,
                              role: "student",
                              userId: res?.id,
                            },
                            {
                              ttl: 60 * 60 * 24,
                            }
                          );
                          getTimePackages(user?.id)
                            .then((r) => r.json())
                            .then((rCourses) => {
                              rCourses?.length > 0 && setHasCourses(true);
                            })
                            .finally(() => {
                              setProfileData({
                                ...user,
                                userId: res?.id,
                              });
                              setTimeout(() => {
                                setLoading(false);
                                navigate("/");
                              }, 300);
                            });
                        })
                        .catch((e) => console.log(e));
                    }
                    // setProfileData(user);
                    // getStudents(res?.token)
                    //   .then((r) => r.json())
                    //   .then((r) => {
                    //     console.log(r, email);
                    //     ls.set("token", res?.token, {
                    //       ttl: 60 * 60 * 60,
                    //     });
                    //     const user = r.find(
                    //       (element) =>
                    //         element.email?.toLowerCase() ===
                    //         email?.toLowerCase()
                    //     );
                    //     if (!user) {
                    //       throw new Error(res?.status);
                    //     }
                    //     ls.set(
                    //       "profile",
                    //       {
                    //         ...user,
                    //         role: "student",
                    //       },
                    //       {
                    //         ttl: 60 * 60 * 60,
                    //       }
                    //     );

                    //     setProfileData(user);
                    //     setTimeout(() => {
                    //       setLoading(false);
                    //       navigate("/");
                    //     }, 300);
                    //   })
                    // .catch(() => {
                    //   ls.set("token", res?.token, {
                    //     ttl: 60 * 60 * 60,
                    //   });
                    // getTeachers(res?.token)
                    //   .then((r) => r.json())
                    //   .then((r) => {
                    //     console.log(r, email);
                    //     const user = r.find(
                    //       (element) =>
                    //         element.email?.toLowerCase() ===
                    //         email?.toLowerCase()
                    //     );
                    //     if (!user) {
                    //       throw new Error(res?.status);
                    //     }
                    //     ls.set(
                    //       "profile",
                    //       {
                    //         ...user,
                    //         role: "teacher",
                    //       },
                    //       {
                    //         ttl: 60 * 60 * 60,
                    //       }
                    //     );

                    //     setProfileData(user);
                    //     setTimeout(() => {
                    //       setLoading(false);
                    //       navigate("/");
                    //     }, 300);
                    //   });
                  })
                  // })
                  .catch((e) => {
                    setLoading(false);
                    setError(true);
                  });
                // setLoading(true);
                // setTimeout(() => {
                //   navigate("/bought-main-page");
                //   setLoading(false);
                // }, 500);
              }}
              type="primary"
              size="large"
              disabled={!loginButt}
              block
            >
              Войти
            </Button>
          </Col>
        </Row>
        {error && (
          <Row>
            <Col span={24}>
              <span style={{ color: "red" }}>
                Вы ввели неверный логин или пароль!
              </span>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <span>
              Нет аккаунта?
              <Button
                type="link"
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    setLogin({
                      isLogin: false,
                      isRegister: true,
                      isRestore: false,
                      isRegEmployee: false,
                    });
                    setLoading(false);
                  }, 500);
                }}
              >
                Зарегистрироваться
              </Button>
            </span>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default LoginCard;
