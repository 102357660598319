import React from "react";
import RegisterPage from "./forms";

import WithLayout from "../../../withLayout";
import context from "../../../context";
import { useNavigate } from "react-router";
import ls from "localstorage-slim";
const RegistedLayoutPageTemp = (props) => {
  const [login, setLogin] = React.useState({
    isLogin: true,
    isRegister: false,
    isRestore: false,
    isRegEmployee: false,
  });

  const { isAuthed, setIsAuthed } = React.useContext(context);
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log(isAuthed);
    if (!isAuthed.auth) {
      const token = ls.get("token");
      if (token) {
        setIsAuthed({
          auth: true,
          token,
          role: "student",
        });
      }
    } else {
      navigate("/");
    }
  }, [props]);

  return <RegisterPage login={login} setLogin={setLogin} />;
};

const RegistedLayoutPage = WithLayout(RegistedLayoutPageTemp);
export default RegistedLayoutPage;
