import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "./ad-logo.svg";

const UpperAd = (props) => {
  const {
    tags = [
      "Goethe Zertifikat",
      "TestDaF",
      "DSD-Prüfung",
      "Sprachtest",
      "Немецкий с носителем",
      "Бизнес-немецкий",
      "Немецкий для детей",
      "Немецкий через игры",
      "Медицинский немецкий",
      "Математика на немецком",
    ],
    navigate,
  } = props;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        gap: "16px",
        flexDirection: "row",
      }}
    >
      <Row gutter={[16, 8]}>
        <Col
          span={14}
          xs={24}
          xl={14}
          xxl={14}
          md={24}
          style={{ textAlign: "left" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
              alignContent: windowDimensions.width < 1000 ? "center" : "start",
            }}
          >
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                lineHeight: "32px",
              }}
            >
              Освойте немецкий за несколько месяцев с лучшими преподавателями
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "8px",
              }}
            >
              {tags.map((tag) => {
                return <Button shape="round">{tag}</Button>;
              })}
            </div>
            <Button
              size="large"
              type="primary"
              style={{ backgroundColor: "#B40A1B", width: "100%" }}
              shape="round"
              onClick={() => navigate("/leave-a-request")}
            >
              Оставить заявку
            </Button>
          </div>
        </Col>
        <Col
          span={10}
          xs={24}
          xl={10}
          xxl={10}
          md={24}
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Logo
            style={{
              alignSelf: "center",
              justifySelf: "flex-end",
              width: "100%",
              height: "100%",
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UpperAd;
