import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./utils/serviceWorkerRegistration";
import "./index.css";
import App from "./App";
import dayjs from "dayjs";
var isBetween = require("dayjs/plugin/isBetween");
var duration = require("dayjs/plugin/duration");

dayjs.extend(isBetween);
dayjs.extend(duration);

var utc = require("dayjs/plugin/utc");

// import utc from 'dayjs/plugin/utc' // ES 2015

var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
// import timezone from 'dayjs/plugin/timezone' // ES 2015

dayjs.extend(utc);
dayjs.extend(timezone);
// teacher-my-teachers
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
