import React, { useEffect, useState } from "react";
import { HomePageRibbon, AuthRibbon } from "./ribbons";
import { Row, Col } from "antd";

import styles from "./index.module.css";
import { ReactComponent as Logo } from "../../../media/icons/header-logo.svg";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const {
    isHomePage = false,
    isAuthorizedClient = false,
    isAuthorizedCurator = false,
  } = props;

  const navigate = useNavigate();
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Row
      className={styles["wrapper"]}
      style={{
        height: "100%",
        alignItems: "center",
      }}
    >
      <Col span={16}>
        {isHomePage ? (
          <HomePageRibbon windowDimensions={windowDimensions} />
        ) : (
          <Logo />
        )}
      </Col>
      <Col
        span={8}
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        }}
      >
        {isHomePage && <AuthRibbon windowDimensions={windowDimensions} />}
      </Col>
    </Row>
  );
};

export default Header;
