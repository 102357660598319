import { Row, Col, Card, Progress, Button } from "antd";
import React from "react";
import { ReactComponent as Logo } from "../../../../media/icons/books.svg";

const AttendanceStatus = (props) => {
  const { attendance = 0 } = props;

  return (
    <Row
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#0062FF",
        display: "flex",
      }}
    >
      <Col
        span={24}
        style={{
          paddingLeft: "25px",
          paddingTop: "25px",
          paddingBottom: "15px",
          display: "flex",
          gap: "32px",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <span style={{ fontSize: "20px", fontWeight: "bold", color: "white" }}>
          Посещаемость занятий
        </span>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          padding: "20px",
          paddingTop: "15px",
          justifyContent: "left",
        }}
      >
        <span style={{ fontSize: "64px", fontWeight: "bold", color: "white" }}>
          {`${parseInt(attendance)}%`}
        </span>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          padding: "20px",
          paddingTop: "15px",
          justifyContent: "left",
        }}
      >
        <span style={{ fontSize: "20px", color: "white" }}>
          {attendance < 50
            ? "У вас плохая посещяемость"
            : "У вас хорошая посещяемость"}
        </span>
      </Col>
    </Row>
  );
};

export default AttendanceStatus;
