import { Row, Col, Card, Avatar, Button, Space } from "antd";
import React from "react";
import { MessageOutlined, UserOutlined } from "@ant-design/icons";

const StudentComp = (props) => {
    const {
        name,
        onClick
    } = props;
    return <Card hoverable onClick={onClick}>
        <Row>
            <Col span={18}>
                <Space direction="vertical" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "16px",
                            // padding: "5%",
                            // paddingBottom: '1%',
                            // paddingLeft: "7%",
                            justifyContent: "left",
                        }}
                    >
                        <Avatar
                            size={48}
                            style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
                            icon={<UserOutlined style={{ fontSize: "24px" }} />}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                                textAlign: "left",
                            }}
                        >
                            <span style={{ textDecoration: 'underline' }}>{name}                             <Button style={{marginLeft: '15px'}} icon={<MessageOutlined />}>Связаться</Button>
</span>
                        </div>
                    </span>
                    <span style={{ color: "#818199", fontSize: '14px',
                            float: 'left' }}>{`Состоит в группах: `}<p style={{fontWeight: 'bold', color: 'black', paddingLeft: '5px'}}>{' Группа 1, Группа 2'}</p></span>

                </Space>

            </Col>
            <Col span={6} style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
               <div style={{
                padding: '5%'
               }}>
               <Button size="large" danger>Удалить преподавателя</Button>
               </div>
            </Col>
        </Row>
    </Card>
}

export default StudentComp