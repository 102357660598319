import React, { useContext, useState } from "react";

import { Button, Col, Row } from "antd";
import { ArrowRightOutlined, ArrowUpOutlined } from "@ant-design/icons";

import WithLayout from "../../../withLayout";
import UpperAvatar from "./upper-avatar";
import PrivateInfo from "./private-info";
import ContactInfo from "./contact-info";
import PasswordChange from "./password-change";
import styles from "./index.module.css";
import ls from "localstorage-slim";
import { useNavigate, useParams } from "react-router";
import {
  getCourses,
  getHeadTeacherById,
  getStudentById,
  getTeacherById,
  getTeachers,
  getTimePackages,
} from "../../../apis";
import context from "../../../context";
import CurrentCourse from "../current-course-profile";
import PaymentInfo from "./payment-info";
const ProfilePageTemp = (props) => {
  const { openNotification, student, teacher, curator } = props;

  const { profileData, window: window2, setProfileData } = useContext(context);
  const { name, surname, email } = profileData;
  const fullname = `${name} ${surname}`;

  const [isBot, setIsBot] = React.useState(false);
  const [currUser, setCurrUser] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const update = (notif) => {
    if (view) {
      if (student)
        getStudentById(id)
          .then((r) => r.json())
          .then((rInner) =>
            setCurrUser({
              ...rInner,
              roleProfile: "student",
            })
          );
      if (teacher)
        getTeacherById(id)
          .then((r) => r.json())
          .then((rInner) =>
            setCurrUser({
              ...rInner,
              roleProfile: "teacher",
            })
          );
      if (curator)
        getHeadTeacherById(id)
          .then((r) => r.json())
          .then((rInner) =>
            setCurrUser({
              ...rInner,
              roleProfile: "curator",
            })
          );
    }
    notif &&
      openNotification("bottomLeft", "Информация была успешно обновлена!");
  };
  const dataUpdated = () => {
    update(true);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 200) {
      setIsBot(true);
    } else {
      setIsBot(false);
    }
  };

  const [courses, setCourses] = React.useState();
  React.useEffect(() => {
    id &&
      currUser?.id &&
      getCourses()
        .then((r) => r.json())
        .then((r) => {
          const rtemp = r?.filter((element) => {
            if (element.teacherList?.find((el) => el?.userId === parseInt(id)))
              return true;
            if (element.studentList?.find((el) => el?.userId === parseInt(id)))
              return true;
          });
          getTimePackages(currUser?.id)
            .then((rr) => rr.json())
            .then((rrr) => {
              const tt = (rtemp ?? []).map((el) => {
                const temp = rrr.find((el2) => el2.courseId === el.id);
                return {
                  ...temp,
                  ...el,
                };
              });
              console.log(tt);
              setCourses(tt);
            });
        });
  }, [currUser]);

  const view = (student || teacher || curator) && id;

  React.useEffect(() => {
    update();
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [selected, setSelected] = React.useState(0);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10%",
        paddingRight: "10%",
        paddingBottom: "1%",
        width: "100%",
        height: "100%",
        alignItems: "center",
        gap: "32px",
      }}
    >
      <UpperAvatar
        email={view ? currUser?.email : email}
        fullname={view ? `${currUser?.name} ${currUser?.surname}` : fullname}
      />
      {id && (
        <Row gutter={[8, 8]} style={{ width: "100%", float: "left" }}>
          <Col>
            <Button
              type={selected === 0 ? "primary" : "default"}
              onClick={() => setSelected(0)}
            >
              Личные данные
            </Button>
          </Col>
          {currUser?.role !== "headteacher" && (
            <Col>
              <Button
                type={selected === 1 ? "primary" : "default"}
                onClick={() => setSelected(1)}
              >
                Курсы
              </Button>
            </Col>
          )}
        </Row>
      )}
      {selected === 0 && (
        <>
          <PrivateInfo
            student={
              profileData?.role === "student" ||
              ls.get("profile")?.role === "student"
            }
            teacher={
              profileData?.role === "teacher" ||
              ls.get("profile")?.role === "teacher"
            }
            headteacher={
              profileData?.role === "headteacher" ||
              ls.get("profile")?.role === "headteacher" ||
              profileData?.role === "supervisor" ||
              ls.get("profile")?.role === "supervisor"
            }
            dataUpdated={dataUpdated}
            view={view}
            currUser={currUser}
          />
          <ContactInfo
            student={
              profileData?.role === "student" ||
              ls.get("profile")?.role === "student"
            }
            teacher={
              profileData?.role === "teacher" ||
              ls.get("profile")?.role === "teacher"
            }
            headteacher={
              profileData?.role === "headteacher" ||
              ls.get("profile")?.role === "headteacher" ||
              profileData?.role === "supervisor" ||
              ls.get("profile")?.role === "supervisor"
            }
            dataUpdated={dataUpdated}
            view={view}
            currUser={currUser}
          />
          <PaymentInfo
            setCurrUser={setCurrUser}
            student={
              profileData?.role === "student" ||
              ls.get("profile")?.role === "student"
            }
            teacher={
              profileData?.role === "teacher" ||
              ls.get("profile")?.role === "teacher"
            }
            headteacher={
              profileData?.role === "headteacher" ||
              ls.get("profile")?.role === "headteacher" ||
              profileData?.role === "supervisor" ||
              ls.get("profile")?.role === "supervisor"
            }
            dataUpdated={dataUpdated}
            currUser={profileData ?? ls.get("profile")}
          />
          {!view && <PasswordChange dataUpdated={dataUpdated} />}
          <Button
            shape="circle"
            icon={<ArrowUpOutlined />}
            size="large"
            style={{ position: "fixed", bottom: "20px", right: "20px" }}
            className={isBot ? styles["buttonANim"] : styles["buttonANim2"]}
            onClick={() => window.scrollTo(0, 0)}
          />
        </>
      )}
      {selected === 1 && (
        <>
          {courses && courses?.length > 0 ? (
            courses.map((x) => {
              return (
                <Col span={24} style={{ width: "100%" }}>
                  <CurrentCourse
                    {...x}
                    navigate={navigate}
                    name={x?.title}
                    delete={
                      ls.get("profile")?.role !== "teacher"
                        ? () => {}
                        : () => {}
                    }
                    teacher={{
                      name: `${x?.teacherList?.[0]?.name} ${x?.teacherList?.[0]?.surname}`,
                    }}
                    topics={x?.courseSubjects}
                    type={x?.type}
                    total={
                      +(x?.initialAmount ? x?.initialAmount / 60 : 0).toFixed(0)
                    }
                    remaining={+(x?.amount ? x?.amount / 60 : 0).toFixed(0)}
                    remainingHoursCount={
                      +(x?.amount ? x?.amount / 60 : 0).toFixed(0)
                    }
                    totalHoursCount={
                      +(x?.initialAmount ? x?.initialAmount / 60 : 0).toFixed(0)
                    }
                    currUser={profileData}
                    extra={[
                      <Button
                        type="primary"
                        size="large"
                        style={{
                          margin: "5px",
                        }}
                        onClick={() =>
                          navigate("/teacher-course-page/" + x?.id, {
                            state: {
                              id: x?.id,
                            },
                          })
                        }
                        icon={<ArrowRightOutlined />}
                        iconPosition="end"
                      >
                        Перейти к курсу
                      </Button>,
                    ]}
                    window={window}
                  />
                </Col>
              );
            })
          ) : courses ? (
            <Col span={24}>
              <span
                style={{
                  fontSize: "24px",
                  lineHeight: "26px",
                  fontWeight: "bold",
                  float: "left",
                }}
              >
                Нет активных курсов
              </span>
            </Col>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

const ProfilePage = WithLayout(ProfilePageTemp);
export default ProfilePage;
