import React from "react";
import { Row, Col, Button } from "antd";

const AttendanceStatus = (props) => {
  const { openAttendance = () => {} } = props;

  return (
    <Row
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#FFFFFF",
        display: "flex",
        padding: "15px",
      }}
      gutter={[8, 8]}
    >
      <Col
        span={24}
        style={{
          // padding: "3%",
          // paddingLeft: "7%",
          // paddingBottom: "5px",
          padding: "15px",
          paddingTop: "15px",
          paddingLeft: "5px",
          paddingBottom: "5px",
          display: "flex",
          gap: "32px",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          Посещаемость занятий
        </span>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          padding: "15px",
          paddingTop: "5px",
          paddingLeft: "5px",
          justifyContent: "left",
          paddingBottom: "15px",
        }}
      >
        <Button type="primary" onClick={() => openAttendance()}>
          Посмотреть
        </Button>
      </Col>
    </Row>
  );
};

export default AttendanceStatus;
