import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Progress, Row, Space } from "antd";
import React from "react";

const AllStudents = (props) => {
  const { small = false, cardless, name = 'Павел Козлов', teacher, timeout, delayed = false, isGroup = false, navigate } = props;
  return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px",
            padding: "3%",
            paddingBottom: '1%',
            paddingRight: '1%',
            paddingLeft: "5%",
            justifyContent: "left",
          }}
        >
          <Avatar
            size={36}
            style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
            icon={<UserOutlined style={{ fontSize: "18px" }} />}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              textAlign: "left",
            }}
          >
            <span style={{ textDecoration: 'underline', fontSize: '16px' }}>{name}
            </span>
          </div>
        </span>
      </Space>
  );
};

export default AllStudents;
