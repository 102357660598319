import { Button, Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import WithLayout from "../../../withLayout";
import AdDiv from "./ad-div";
import buttons from "./buttons";
import AdDiv2 from "./ad-div-2";
import BooksDrawer from "./books-drawer";
import context from "../../../context";
import topics from "./books";

const MainLayuotPageTemp = (props) => {
  const { isAuth = true } = props;
  const navigate = useNavigate();
  const { window } = React.useContext(context);
  console.log(window);

  const [buttonState, setButtonState] = React.useState(buttons);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [chosenTopic, setChosenTopic] = React.useState();

  const handleClick = (x) => {
    if (x?.title === "Все") setChosenTopic();
    else setChosenTopic(x);
    // setButtonState((prev) =>
    //   prev.map((item) => {
    //     return {
    //       ...item,
    //       clicked: item.key === x.key,
    //     };
    //   })
    // );
  };

  const handleBuyRequest = () => {
    setLoading(true);
    setTimeout(() => {
      setSuccess(true);
      setLoading(false);
    }, 300);
  };

  const buyBook = () => {
    setOpenDrawer(false);
    setTimeout(() => {
      setSuccess(false);
    }, 300);
  };

  React.useEffect(() => {
    !isAuth && navigate("/");
  });

  return (
    <Space
      direction="vertical"
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
      }}
    >
      <AdDiv window={window} />
      <Space
        direction="horizontal"
        style={{
          width: "100%",
          padding: "3%",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {[{ title: "Все" }, ...topics].map((x) => {
          return (
            <Button
              type={
                chosenTopic
                  ? chosenTopic.title === x.title
                    ? "primary"
                    : "default"
                  : x.title === "Все"
                  ? "primary"
                  : "default"
              }
              shape="round"
              size="large"
              onClick={() => handleClick(x)}
            >
              {x.title}
            </Button>
          );
        })}
      </Space>
      <AdDiv2 />
      <BooksDrawer
        chosenTopic={chosenTopic}
        buttonState={buttonState}
        handleBuyRequest={handleBuyRequest}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        loading={loading}
        success={success}
        setSuccess={setSuccess}
        buyBook={buyBook}
      />
    </Space>
  );
};

const MainLayuotPage = WithLayout(MainLayuotPageTemp);
export default MainLayuotPage;
