import {Avatar, Button, DatePicker, Input} from "antd";

import "./styles.css"

export const StudentInfoPage = () => {
    return (
        <div className="student-info__body">
            <div className="student-info__title">
                <div className="student-info__name">
                    <Avatar size={100}/>
                    <div style={{fontSize: '24px', fontWeight: '600'}}>Артурианна Запрудская</div>
                    <div style={{color: '#818199'}}>arturiana_zaprudskaya@gmail.com</div>
                </div>
            </div>

            <div className="student-info__card">
                <div className="student-info__name">
                    <div style={{fontSize: '24px', fontWeight: '600'}}>Личная информация</div>
                    <div style={{color: '#818199'}}>Введите актуальную информацию</div>
                </div>
                <div className="student-info__form">
                    <Input placeholder="Имя" style={{marginTop: '20px'}}/>
                    <Input placeholder="Фамилия" style={{marginTop: '20px'}}/>
                    <Input placeholder="Отчество" style={{marginTop: '20px'}}/>
                    <DatePicker placeholder="Дата рождения" style={{width: "100%", marginTop: '20px'}} />
                    <Input.TextArea placeholder="Цель обучения" style={{marginTop: '20px'}}/>
                    <Input.TextArea placeholder="Пожелания на счет преподавателя" style={{marginTop: '20px'}}/>
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <Button style={{backgroundColor: '#DADAEE', border: 'none', marginTop: '20px'}}>Сохранить</Button>
                    </div>
                </div>
            </div>

            <div className="student-info__card">
                <div className="student-info__name">
                    <div style={{fontSize: '24px', fontWeight: '600'}}>Информация для связи</div>
                    <div style={{color: '#818199'}}>Введите актуальную информацию</div>
                </div>
                <div className="student-info__form">
                    <Input placeholder="Номер телефона" style={{marginTop: '20px'}}/>
                    <Input placeholder="Электронная почта" style={{marginTop: '20px'}}/>
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <Button style={{backgroundColor: '#DADAEE', border: 'none', marginTop: '20px'}}>Сохранить</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}