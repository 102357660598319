import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Drawer,
  Row,
  Space,
  Input,
  Spin,
  Checkbox,
  Slider,
  Result,
} from "antd";
import context from "../../../../context";
import { pushNotificationApi } from "../../../../apis";
import topics from "../books";

const BooksDrawer = (props) => {
  const { chosenTopic, openDrawer, setOpenDrawer, loading, buyBook } = props;
  const { window: window2 } = React.useContext(context);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [books, setBooks] = useState([]);
  const [booksNumber, setBooksNumber] = useState();
  const [success, setSuccess] = useState(false);

  const handleBuyRequest = () => {
    console.log({
      books,
      phoneNumber,
      city,
      books,
      booksNumber,
    });
    pushNotificationApi(
      "Получен запрос на печать!",
      `${name}, ${phoneNumber}, из ${city} заказал печать книг: ${books?.join(
        ", "
      )}. Количество штук: ${booksNumber}`,
      [],
      ["ROLE_HEAD_TEACHER", "ROLE_SUPERVISOR"]
    ).then(() => setSuccess(true));
  };

  return (
    <Space
      direction="vertical"
      style={{
        justifyContent: "center",
        maxWidth: "100%",
        width: "100%",
      }}
    >
      {(chosenTopic ? [chosenTopic] : topics).map((x) => {
        return (
          <Card
            style={{
              backgroundColor: "transparent",
              border: "0",
              boxShadow: "none",
              width: "100%",
            }}
            extra={
              window2.width > 1000 && (
                <Button
                  onClick={() =>
                    setOpenDrawer({
                      open: true,
                      ...x,
                    })
                  }
                >
                  Приобрести печатную версию
                </Button>
              )
            }
            key={x.id}
            title={x.title}
            bordered={false}
          >
            {window2.width <= 1000 && (
              <Button
                style={{ width: "100%", marginBottom: "10px" }}
                type="primary"
                onClick={() =>
                  setOpenDrawer({
                    open: true,
                    ...x,
                  })
                }
              >
                Приобрести печатную версию
              </Button>
            )}

            {/* <div
              direction="horizontal"
              style={{
                width: "100%",
                // overflowX: "scroll",
                maxWidth: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            > */}
            <Row gutter={[8, 8]}>
              {x?.books?.map((book) => {
                return (
                  <Col xs={24} md={24} lg={8}>
                    <Card
                      hoverable
                      onClick={() =>
                        book.download && window.open(book.download)
                      }
                      key={book.key}
                      style={{
                        width: "100%",
                        height: "100%",
                        minWidth: "250px",
                      }}
                      bodyStyle={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                      }}
                      // cover={<img src={book?.img} />}
                    >
                      {/* <Card.Meta
                      title={
                        <span style={{ width: "100%" }}>{book.title} </span>
                      }
                    /> */}
                      <img src={book?.img} />
                      <span style={{ width: "100%", fontWeight: "bold" }}>
                        {book.title}{" "}
                      </span>
                    </Card>
                  </Col>
                );
              })}
            </Row>

            {/* </div> */}
          </Card>
        );
      })}
      <Drawer
        title={
          <span style={{ fontSize: "34px", lineHeight: "36px" }}>
            Купить печатную версию
          </span>
        }
        closeIcon={true}
        // style={{ padding: "5%", overflow: "scroll", maxHeight: "100%" }}
        width={window2.width < 1000 ? "100%" : "50%"}
        onClose={() => {
          setOpenDrawer({
            open: false,
          });
          setName("");
          setCity("");
          setPhoneNumber("");
          setBooks([]);
          setBooksNumber();
        }}
        open={openDrawer?.open}
      >
        <Spin
          spinning={loading}
          // style={{
          //   overflowY: "scroll",
          //   maxHeight: `${window2.height}px`,
          // }}
        >
          {!success && (
            <Space direction="vertical" style={{ width: "100%" }} size={25}>
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Стоимость всех наших учебников составляет 6.000 ₸,что в среднем
                на 20% ниже рыночной цены!
              </span>
              <Row>
                <Col span={24}>
                  <Input
                    size="large"
                    placeholder="Имя"
                    value={name}
                    onChange={(e) => setName(e?.target?.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Input
                  placeholder="Номер телефона"
                  size="large"
                  style={{ width: "100%" }}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e?.target?.value)}
                />
              </Row>
              <Row>
                <Input
                  size="large"
                  placeholder="Город"
                  value={city}
                  onChange={(e) => setCity(e?.target?.value)}
                />
              </Row>
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Выберите учебник
              </span>
              <Checkbox.Group
                options={openDrawer?.books?.map((check) => check.title)}
                style={{ flexDirection: "column", flexWrap: "nowrap" }}
                value={books}
                onChange={(e) => setBooks(e)}
              />
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                Количество экземпляров
              </span>
              <Row>
                <Col span={24}>
                  <Slider
                    min={1}
                    max={100}
                    onChange={(e) => setBooksNumber(e)}
                    marks={{
                      0: 0,
                      50: 50,
                      100: 100,
                    }}
                  />
                </Col>
              </Row>
              <Button
                type="primary"
                size="large"
                block
                onClick={handleBuyRequest}
                disabled={
                  !(
                    name &&
                    city &&
                    phoneNumber &&
                    books?.length >= 1 &&
                    booksNumber >= 1
                  )
                }
              >
                Отправить на проверку
              </Button>
            </Space>
          )}
          {success && (
            <Result
              status="success"
              title="Наш менеджер скоро свяжется с вами!"
              extra={[
                <Button
                  type="primary"
                  key="console"
                  block
                  size="large"
                  onClick={() => setSuccess(false)}
                >
                  Закрыть
                </Button>,
              ]}
            />
          )}
        </Spin>
      </Drawer>
    </Space>
  );
};

export default BooksDrawer;
