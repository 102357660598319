import { Avatar } from "antd";
import React from "react";
import { UserOutlined } from "@ant-design/icons";

const TeacherDiv = (props) => {
  const {
    name = "Павел Козлов",
    swap = false,
    litup = false,
    pos,
    chat,
    routeGo,
  } = props;

  return (
    <div
      style={
        litup
          ? {
              height: "100%",
              width: "100%",
              borderRadius: "25px",
              backgroundColor: "#FFFFFF",
              border: "1px solid grey",
              padding: chat ? "0px" : "15px",
            }
          : {
              height: "100%",
              width: "100%",
              borderRadius: "25px",
              padding: chat ? "0px" : "15px",
              backgroundColor: "#FFFFFF",
            }
      }
    >
      <span
        style={
          chat
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
                padding: "5px",
                paddingLeft: "5px",
                justifyContent: !pos ? "left" : "center",
              }
            : {
                padding: "5px",
                display: "flex",
                gap: "8px",
              }
        }
      >
        {!pos && (
          <Avatar
            size={48}
            style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
            icon={<UserOutlined style={{ fontSize: "24px" }} />}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            textAlign: !pos ? "left" : "center",
          }}
          onClick={routeGo ? routeGo : () => {}}
        >
          {!swap && (
            <spam style={{ color: "#818199", fontSize: "14px" }}>Учитель</spam>
          )}
          <span style={{ textDecoration: "underline" }}>{name}</span>
          {!pos && swap && (
            <spam style={{ color: "#818199", fontSize: "14px" }}>Учитель</spam>
          )}
        </div>
      </span>
    </div>
  );
};

export default TeacherDiv;
