import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Space } from "antd";
import React, { useContext } from "react";
import MainAdDiv from "../../widgets/main-ad-div";
import ls from "localstorage-slim";
import context from "../../../../../context";

const CollapsibleCourseList = (props) => {
  const {
    courses,
    openDrawer,
    window,
    upd,
    loading,
    setFile,
    setLoading,
    setOpenDrawer,
    course,
    title,
    subTitle,
    isCollapsed,
  } = props;

  const { profileData } = useContext(context);

  return (
    <Collapse
      defaultActiveKey={isCollapsed && ["1"]}
      className="mainers"
      style={{
        backgroundColor: "transparent",
        border: "none",
      }}
      items={[
        {
          key: "1",
          label: (
            <div
              style={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "baseline",
                gap: "8px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                {title}
              </span>
              <span
                style={{
                  fontSize: "14px",
                  color: "gray",
                }}
              >
                {subTitle}
              </span>
            </div>
          ),
          children: (
            <>
              {courses?.length >= 1 ? (
                courses?.map((element) => {
                  return (
                    <Collapse
                      bordered={false}
                      ecoursepandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "0px",
                        // borderTop: "1px solid #d9d9d9",
                      }}
                      items={[
                        {
                          label: (
                            <Space
                              style={{ width: "100%" }}
                              direction="vertical"
                            >
                              <span
                                style={{
                                  tecoursetAlign: "left",
                                  color: "#818199",
                                }}
                              >
                                {`Тема: ${element?.title}`}
                                <span
                                  style={{
                                    color: element?.completed
                                      ? "#5ED973"
                                      : "red",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {element?.completed
                                    ? `Пройден`
                                    : "Не пройден"}
                                </span>
                              </span>
                              <span style={{ tecoursetAlign: "left" }}>
                                {element?.description?.length > 0
                                  ? element.description
                                  : "Описание отсутствует"}
                              </span>
                            </Space>
                          ),
                          children: (
                            <MainAdDiv
                              onClick={setOpenDrawer}
                              lessonLink={course?.lessonLink}
                              open={openDrawer}
                              window={window}
                              upd={upd}
                              loading={loading}
                              setFile={setFile}
                              setLoading={setLoading}
                              addHw={() => {}}
                              course={course}
                              addFile={() => {}}
                              hwSubmitted={element?.homeworkList?.find(
                                (el) =>
                                  el.studentId ===
                                  (ls.get("profile")?.id ?? profileData?.id)
                              )}
                              element={element}
                              material={element?.files}
                              join={false}
                            />
                          ),
                          style: {
                            borderRadius: "25px",
                            border: "none",
                          },
                        },
                      ]}
                    />
                  );
                })
              ) : (
                <span>Уроков нет</span>
              )}
            </>
          ),
        },
      ]}
    />
  );
};

export default CollapsibleCourseList;
