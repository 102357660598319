import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  RegistedLayoutPage,
  MainLayuotPage,
  FirstLookPage,
  HeadPage,
  FormRequestPage,
  TeachersPage,
  ProfilePage,
  CourseBoughtMainPage,
  NewsListPage,
  MainPage,
  StudentsPage,
  StudentDetailPage,
  ModTeachersPage,
  TeacherDetailPage,
  ModChatPage,
  // SuperVisorStudentsPage,
  Homeworks,
} from "./pages";
import "./index.css";
import {
  ChangeSchedulePage,
  ChatPage,
  CoursePage,
  MyCoursesPage,
  NewsPage,
} from "./pages/course-bought-page";
import {
  CoursePageTeacher,
  CoursePageTeacherEdit,
  CoursePageTeacherManager,
  TeacherMainPage,
  TeacherStudentsManagerPage,
  TeacherStudentsPage,
} from "./pages/teacher-page";
import { SuperVisorMainPage } from "./pages/supervisors-page";
import context from "./context";
import { default as HandleRouting } from "./handleRouting";
import { ChatPageTemp } from "./pages/course-bought-page/chat-page";
import { notification, ConfigProvider } from "antd";
import ls from "localstorage-slim";
import { getTimePackages } from "./apis";
import { ProfilePageSomeone } from "./pages/main-home-page";

const App = (props) => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isAuthed, setIsAuthed] = useState({
    auth: false,
    role: "",
  });

  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (status, message, description) => {
    api[status]({
      message,
      description,
      placement: "bottomLeft",
    });
  };

  const [hasCourses, setHasCourses] = useState(false);

  React.useLayoutEffect(() => {
    (profileData?.role === "student" ||
      ls.get("profile")?.role === "student") &&
      getTimePackages(profileData?.id)
        .then((r) => r.json())
        .then((r) => {
          console.log(r, "time");
          r?.length > 0 && setHasCourses(true);
        });
  }, [profileData]);

  useEffect(() => {
    profileData?.role &&
      ls.set("profile", profileData, {
        ttl: 60 * 60 * 24,
      });
  }, [profileData]);
  console.log(ls.get("token"));
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            // defaultActiveBorderColor: "#2B2A2A",
            // defaultActiveColor: "#2B2A2A",
            colorPrimary: "#2B2A2A",
            colorPrimaryBorder: "#2B2A2A",
            colorPrimaryHover: "#535252",
            colorPrimaryActive: "#676666",
            primaryColor: "#FFFFFF",
          },
        },
        // token: {
        //   defaultActiveBorderColor: "#2B2A2A",
        //   defaultActiveColor: "#2B2A2A",
        //   defaultColor: "#FFFFFF",

        //   // // Seed Token
        //   // colorPrimary: "#00b96b",
        //   // borderRadius: 2,

        //   // // Alias Token
        //   // colorBgContainer: "#f6ffed",
        // },
      }}
    >
      <context.Provider
        value={{
          window: windowDimensions,
          isAuthed,
          setIsAuthed,
          profileData,
          setProfileData,
          notificationApi: api,
          openNotification,
          hasCourses,
          setHasCourses,
        }}
      >
        <BrowserRouter>
          {contextHolder}
          <Routes>
            <Route
              exact
              path="/"
              element={<HandleRouting hasCourses={hasCourses} />}
            >
              <Route path="/authorization" element={<RegistedLayoutPage />} />
              <Route
                exact
                path="/library"
                element={<MainLayuotPage isHomePage />}
              />
              <Route
                exact
                path="/profile"
                element={<ProfilePage isHomePage />}
              />
              <Route
                exact
                path="/student/:id"
                element={<ProfilePageSomeone isHomePage student />}
              />
              <Route
                exact
                path="/teacher/:id"
                element={<ProfilePageSomeone isHomePage teacher />}
              />
              <Route
                exact
                path="/curator/:id"
                element={<ProfilePageSomeone isHomePage curator />}
              />
              <Route
                exact
                path="/first-look"
                element={<FirstLookPage isHomePage />}
              />
              <Route
                exact
                path="/teachers"
                element={<TeachersPage isHomePage />}
              />
              <Route
                exact
                path="/moderator"
                element={<MainPage isHomePage />}
              />
              <Route
                exact
                path="/moderator-students"
                element={<StudentsPage isHomePage />}
              />
              <Route
                exact
                path="/moderator-student-detail"
                element={<StudentDetailPage isHomePage />}
              />
              <Route
                exact
                path="/moderator-teachers"
                element={<ModTeachersPage isHomePage />}
              />
              <Route
                exact
                path="/moderator-teacher-detail"
                element={<TeacherDetailPage isHomePage />}
              />
              <Route
                exact
                path="/moderator-chat"
                element={<ModChatPage isHomePage />}
              />
              <Route
                exact
                path="/supervisor-main"
                element={<SuperVisorMainPage isHomePage />}
              />
              {/* <Route
                exact
                path="/supervisor-students"
                element={<SuperVisorStudentsPage isHomePage />}
              /> */}
              <Route
                exact
                path="/bought-main-page"
                element={<CourseBoughtMainPage isHomePage />}
              />
              <Route
                exact
                path="/teacher-main-page"
                element={<TeacherMainPage isHomePage />}
              />
              <Route
                exact
                path="/teacher-students-page"
                element={<TeacherStudentsPage isHomePage students />}
              />
              <Route
                exact
                path="/teacher-teachers-page"
                element={<TeacherStudentsPage isHomePage teachers />}
              />
              <Route
                exact
                path="/teacher-curators-page"
                element={<TeacherStudentsPage isHomePage curators />}
              />
              <Route exact path="/hws" element={<Homeworks isHomePage />} />
              <Route
                exact
                path="/teacher-course-page/:id"
                element={<CoursePageTeacher isHomePage />}
              />
              <Route
                exact
                path="/teacher-course-page/:id/edit"
                element={<CoursePageTeacherEdit isHomePage />}
              />
              <Route
                exact
                path="/teacher-my-teachers"
                element={<TeacherStudentsManagerPage isHomePage />}
              />
              <Route
                exact
                path="/teacher-course-page-manager"
                element={<CoursePageTeacherManager isHomePage />}
              />
              <Route
                exact
                path="/news-list"
                element={<NewsListPage isHomePage />}
              />
              <Route
                exact
                path="/news-internal-list"
                element={<NewsListPage isHomePage internal />}
              />
              <Route exact path="/news/:id" element={<NewsPage isHomePage />} />
              <Route
                exact
                path="/my-courses"
                element={<MyCoursesPage isHomePage />}
              />
              <Route
                exact
                path="/change-schedule"
                element={<ChangeSchedulePage isHomePage />}
              />
              <Route exact path="/chat" element={<ChatPage isHomePage />}>
                <Route path=":id" element={<ChatPageTemp isHomePage />} />
              </Route>
              <Route
                exact
                path="/course/:id"
                element={<CoursePage isHomePage />}
              />
              <Route
                exact
                path="/leave-a-request"
                element={<FormRequestPage isHomePage />}
              />
              <Route
                exact
                path="/"
                element={
                  typeof hasCourses === "undefined" ? null : hasCourses ? (
                    <CourseBoughtMainPage isHomePage />
                  ) : (
                    <HeadPage isHomePage />
                  )
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </context.Provider>
    </ConfigProvider>
  );
};

export default App;
