import React, { useEffect, useState } from "react";
import { Button, Spin, Input, Space, Card, DatePicker, Select } from "antd";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import context from "../../../../context";
import ls from "localstorage-slim";
import {
  getStudentById,
  getUser,
  putHeadTeacherById,
  putStudentById,
  putTeacherById,
} from "../../../../apis";
const PrivateInfo = (props) => {
  const { dataUpdated, student, view, currUser, teacher, headteacher } = props;
  const { window, profileData, setProfileData } = React.useContext(context);
  const {
    name,
    surname,
    email: email2 = "Загрузка...",
    middleName,
    city,
    studyGoal,
    phone,
    wishes,
  } = { ...profileData };
  console.log(student);
  const [loading, setLoading] = React.useState(false);

  const saveCard = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      getData();
    }, 300);
  };

  const getData = () => {
    currUser?.roleProfile === "student"
      ? putStudentById(currUser?.id, {
          city: cityInput,
          name: nameInput,
          surname: surnameInput,
          middleName: middlenameInput,
          email: view ? currUser?.email : email2,
          phone: view ? currUser?.phone : phone,
          studyGoal: studyGoalInput,
          wishes: wishesInput,
        })
          .then((r) => r.json())
          .then((r) => {
            console.log(r);
            !view &&
              setProfileData({
                ...profileData,
                ...r,
              });
            dataUpdated();
          })
      : currUser?.roleProfile === "teacher"
      ? putTeacherById(currUser?.id ?? profileData?.userId, {
          city: cityInput,
          name: nameInput,
          surname: surnameInput,
          email: view ? currUser?.email : email2,
          phone: view ? currUser?.phone : phone,
        })
          .then((r) => r.json())
          .then((r) => {
            console.log(r);
            !view &&
              setProfileData({
                ...profileData,
                ...r,
              });
            dataUpdated();
          })
      : putHeadTeacherById(currUser?.id ?? profileData?.userId, {
          city: cityInput,
          name: nameInput,
          surname: surnameInput,
          email: view ? currUser?.email : email2,
          phone: view ? currUser?.phone : phone,
        })
          .then((r) => r.json())
          .then((r) => {
            console.log(r);
            !view &&
              setProfileData({
                ...profileData,
                ...r,
              });
            dataUpdated();
          });
  };

  const [nameInput, setNameInput] = useState(view ? currUser?.name : name);
  const [middlenameInput, setMiddlenameInput] = useState(
    view ? currUser?.middleName : middleName
  );
  const [cityInput, setCityInput] = useState(view ? currUser?.city : city);
  const [surnameInput, setSurnameInput] = useState(
    view ? currUser?.name : name
  );

  const [wishesInput, setWishesInput] = useState(
    view ? currUser?.wishes : wishes
  );
  const [studyGoalInput, setStudyGoalInput] = useState(
    view ? currUser?.studyGoal : studyGoal
  );
  console.log(
    student,
    ls.get("profile"),
    ls.get("profile")?.role === "student"
  );
  useEffect(() => {
    setNameInput(view ? currUser?.name : name);
    setSurnameInput(view ? currUser?.surname : surname);
    setMiddlenameInput(view ? currUser?.middleName : middleName);
    setCityInput(view ? currUser?.city : city);
    setWishesInput(view ? currUser?.wishes : wishes);
    setStudyGoalInput(view ? currUser?.studyGoal : studyGoal);
  }, [view, currUser, name, surname, middleName, city, studyGoal, wishes]);
  return (
    <Card style={{ width: "100%" }}>
      <Spin spinning={loading}>
        <div
          style={{
            width: "100%",
            alignItems: "start",
            display: "flex",
            flexDirection: window.width <= 1200 ? "column" : "row",
            gap: "16px",
          }}
          size="large"
        >
          <Space
            direction="vertical"
            style={{ width: "100%", alignItems: "start" }}
          >
            <span style={{ fontWeight: "bold", fontSize: "24px" }}>
              Личная информация
            </span>
            <span style={{ color: "#818199", fontSize: "16px" }}>
              {!view && "Введите актуальную информацию"}
            </span>
          </Space>
          <div
            style={{
              width: "100%",
              alignItems: "start",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Input
              placeholder="Имя"
              readOnly={teacher}
              value={nameInput}
              onChange={(e) => setNameInput(e?.target?.value)}
              style={{ width: "100%" }}
            />
            <Input
              placeholder="Фамилия"
              readOnly={teacher}
              value={surnameInput}
              onChange={(e) => setSurnameInput(e?.target?.value)}
              // value={view ? currUser?.surname : surname}
              style={{ width: "100%" }}
            />
            {(student || currUser?.roleProfile === "student") && (
              <Input
                placeholder="Отчество"
                readOnly={teacher}
                value={middlenameInput}
                onChange={(e) => setMiddlenameInput(e?.target?.value)}
                style={{ width: "100%" }}
              />
            )}
            {/* <DatePicker
              locale={locale}
              disabled={!student || view}
              placeholder="Дата рождения"
              style={{ width: "100%" }}
            /> */}
            {/* {!student || view ? (
              <Input
                placeholder="Отчество"
                readOnly
                style={{ width: "100%" }}
              />
            ) : (
              <Select
                placeholder={
                  <span style={{ fontSize: "14px", textAlign: "left" }}>
                    Город проживания
                  </span>
                }
                options={[{ label: "Шымкент", value: "1" }]}
                style={{ width: "100%", textAlign: "left" }}
              />
            )} */}
            {/* <Select
              placeholder={
                <span style={{ fontSize: "14px", textAlign: "left" }}>
                  Город проживания
                </span>
              }
              disabled={!student || view}
              options={[{ label: "Алмата", value: "1" }]}
              style={{ width: "100%", textAlign: "left" }}
            /> */}
            {(student || currUser?.roleProfile === "student") && (
              <Input
                placeholder="Город проживания"
                readOnly={teacher}
                value={cityInput}
                onChange={(e) => setCityInput(e?.target?.value)}
                style={{ width: "100%" }}
              />
            )}
            {(student || currUser?.roleProfile === "student") && (
              <Input.TextArea
                rows={6}
                placeholder="Цель обучения"
                value={studyGoalInput}
                onChange={(e) => setStudyGoalInput(e?.target?.value)}
                style={{ width: "100%" }}
              />
            )}
            {(student || currUser?.roleProfile === "student") && (
              <Input.TextArea
                rows={6}
                placeholder="Пожелания на счет преподавателя"
                value={wishesInput}
                onChange={(e) => setWishesInput(e?.target?.value)}
                style={{ width: "100%" }}
              />
            )}
            {(!teacher || currUser?.roleProfile !== "teacher") && (
              <Button type="primary" size="large" onClick={saveCard}>
                Сохранить
              </Button>
            )}
          </div>
        </div>
      </Spin>
    </Card>
  );
};

export default PrivateInfo;
