import { Button, Input, Col, Row, Space, Spin, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import WithLayout from "../../../withLayout";
import AdDivSecond from "../head-page/ad-div-second";
import AdDivTeachers from "./ad-div-teachers";
import TeacherCard from "./teacher-card";

const TeachersPageTemp = (props) => {
  const {
    isAuth = true,
    teachers = [
      {
        name: "Айдар Аманжулов",
        level: "C2 (в совершенстве)",
        experience: "11 лет",
        location: "г. Алматы, Казахстан",
        photo:
          "https://static.tildacdn.pro/tild6136-3333-4137-b439-633566663530/image1.png",
        about: (
          <div>
            О себе: <br />
            В обучении делаю ставку не только на проверенные отечественные
            методики, но и на современные, разработанные зарубежными
            лингвистами, которые существенно отличаются от разработок
            отечественных коллег и направлены на овладение языком на разговорном
            и бытовом уровне. Более ориентируюсь на языковую практику. Для
            желающих, у которых есть высокий уровень владения английским языком,
            у меня есть опыт преподавания английского на немецком языке и
            наоборот.
            <br />
            <br />
            Квалификация: <br />
            Около пяти лет обучался в Германии на английском и немецком языках
            <br />– из них три года в государственном вузе Rheinische
            Friedrich-Wilhelms-Universität zu Bonn
            <br />– Владение английским языком на уровне CEFR C2* (* что
            аналогично 8 баллам по IELTS и 95 баллам TOEFL),
            <br />– Владение немецким языком на уровне С2, высшие баллы TestDAF
            не ниже 5/5/4/5 Учёные степени, звания, дипломы и т.п. (с годом
            получения):
            <br />– Диплом бакалавра международных отношений КИМЭП 2004 года
            <br />- Диплом магистра экономических наук КИМЭП 2006 года
            <br />- Сертификат TestDaF 2015 года
            <br />
            <br /> Опыт репетиторской деятельности:
            <br />– Программа обмена языками общественная студенческая
            организация при университете г. Бонн
            <br />- Allgemeiner Studentenausschuss
            <br />– Опыт преподавания в программе Sprachtandem Bonn
            <br />– Практикум преподавание в частной языковой школе в городе
            Бонн, Нордрайн-Вестфалия
            <br />– 120 часов преподавания, по программе обмена опытом
          </div>
        ),
      },
      {
        name: "Наталья Панина",
        level: "C2 (уровень носителя языка)",
        experience: "20 лет",
        location: "г. Фюссен, Бавария",
        photo:
          "https://static.tildacdn.pro/tild6234-3736-4364-b735-383661323834/1.svg",
        about: (
          <div>
            О себе: <br />
            Огромный опыт работы, внедряю новейшие методики, в том числе из
            области психологии в изучении языков. Использую стратегию полиглотов
            для быстрого усвоения грамматики и развития памяти. Так сама
            постоянно учу иностранные языки,могу легко сделать доступным сложный
            материал. Особенный упор на фонетику и произношение, так как это
            залог уверенности и быстрого развития языковых навыков.
            <br />
            <br />
            Квалификация: <br />
            Магистр языкознания и литературоведения, филологический факультет
            Университета Людовика Максимилиана Мюнхен. Магистр языкознания и
            литературоведения Университет Людовика Максимилиана Мюнхен.
            <br />
            <br />
            Гете Институт - сертификат С2 (уровень носителя языка)
            Преподавательский стаж 20 лет в Германии / земля Бавария, в том
            числе подготовка иностранных учеников к сдачи экзамена по немецкому
            языку в гимназии Баварии. Руководитель и организатор интеграционных
            курсов для беженцев с 2014 года. 2023 - сертификат по внедрению
            новейших методик из области психологии в обучение иностранным
            языкам: „Training program Polyglot Strategies".
          </div>
        ),
      },
      {
        name: "Марина Ний",
        level: "C2 (в совершенстве)",
        experience: "27 лет",
        photo:
          "https://static.tildacdn.pro/tild3235-3636-4161-b539-656635363332/noroot.png",
        location: "г. Алматы, Казахстан",
        about: (
          <div>
            О себе: <br />
            Меня зовут Марина Ний, я имею 25 лет стажа.
            <br />
            <br />
            Обучение проходила в Германии с носителями языка – Mannheimer Abend
            Akademie, Berlitz Sprachschule Mannheim.
            <br />
            <br />
            Нравится изучать иностранные языки с детства. <br />
            <br />
            Доступно и доходчиво объясняю материал, стараюсь увлечь и
            заинтересовать ребенка. <br />
            <br />
            Нацеленность на результат. <br />
            <br />
            Благодаря новым методикам обучения, ученики осваивают основные
            базовые знания за короткий срок, например А-1 А-2 за 3 месяца.
            Ученики поступили в вузы за границей, успешно прошли собеседование в
            посольстве, успешно сдали экзамены и получили Гёте- сертификаты.
          </div>
        ),
      },
      {
        name: "Жадра Мукушева",
        level: "C2 (в совершенстве)",
        experience: "6 лет",
        location: "г. Астана, Казахстан",
        photo:
          "https://static.tildacdn.pro/tild3936-3465-4838-b961-303633313934/IMG_9173.png",
        about: (
          <div>
            О себе: <br />
            Всем Hallöchen! <br /> <br />
            Меня зовут Жадра и я влюблена в немецкий язык с одиннадцати лет.
            Закончила бакалавр по специальности "Два иностранных языка: немецкий
            и английский" и магистратуру по специальности "Преподаватель
            немецкого языка". <br /> <br />
            Уже более семи лет помогаю моим учащимся в достижении их целей и
            переезда в Германию. Моя отличительная черта как преподавателя
            немецкого в том, что все мои учащиеся с первого раза сдают экзамены
            Goethe-Zertifikat. Именно результаты моих учащихся дают мне силы и
            мотивацию работать в этой сфере. <br /> <br /> Жду вас у себя на
            занятиях, чтобы сопроводить вас к вашей заветной мечте.
          </div>
        ),
      },
      {
        name: "Лю Лин",
        level: "C2 (носитель языка)",
        experience: "7,5 лет",
        url: "https://www.youtube.com/watch?v=FQKhuB8inic&t=726s",
        photo:
          "https://static.tildacdn.pro/tild3463-3131-4664-b963-313363306235/IMG_9114.JPG",
        location: "г. Алматы, Казахстан",
        about: (
          <div>
            О себе: <br />– Опыт работы: 7,5 лет в китайском университете -
            немецкий как второй иностранный + сдача экзаменов в Гёте-институте и
            для поступления на магистратуру (до этого - частные уроки
            параллельно с учебой в университете, а также частные уроки в Алматы
            с сентября 2021 и до начала работы в DeutschKZ) <br /> <br />– О
            себе: гражданка ФРГ, немецкий на уровне родного (считаюсь
            носителем); полное обучение до диплома магистра в LMU Мюнхена;
            проживание в Германии (а именно в Баварии) с 1999 по 2013, до
            получения работы в Китае <br /> <br />– Чем отличаются мои занятия:
            использую собственную методику, выходящую за пределы учебников;
            нахожу индивидуальный подход к каждому ученику и его нуждам; делаю
            упор на грамматику и словарный запас на начальных уровнях и на
            разговорную речь на различные актуальные темы на более продвинутых;
            включаю культуру Германии и Австрии в программу изучения языка; по
            желанию проведу экскурс в составление письменных работ (для желающих
            учиться в Германии или Австрии); занимаюсь в основном в
            оффлайн-режиме. <br /> <br />– Так как я являюсь гражданкой ФРГ,
            подтверждения уровня языка для нас не проводятся.
          </div>
        ),
      },
      {
        name: "Межед Вайсерт",
        level: "C2 (носитель языка)",
        photo:
          "https://static.tildacdn.pro/tild3163-3562-4231-b635-313962353135/__2022-10-12__71324_.png",
        experience: "6 лет",
        location: "г. Берлин, Германия",
        about: (
          <div>
            О себе: <br />
            Меня зовут Межед Вайсерт и я являюсь дипломированным преподавателем
            немецкого языка с опытом работы в Германии и с сертификатами
            международного образца С1, Zulassung zur Lehrtätigkeit in
            Integrationskursen von BAMF. <br />
            <br />
            15 лет жил в Германии. Бакалавриат (im Bereich: Deutsch als
            Fremdspache und Deutsch als Zweitsprache) и Магистратуру (im
            Bereich: Deutsch als Fremd- und Fachsprache) окончил в Германии и с
            2015 по 2021 работал преподавателем в разных образовательных центрах
            как: GLS German Language School in Berlin, ZeBuS e.v. Berlin und
            German Language Academy Münster. <br />
            <br />Я обучал немецкому языку иностранных обучающихся
            (Erasmus-Studenten, ausländische Studenten an der Uni, Flüchtlinge -
            Integrationskursen etc. готовил их к международным экзаменам A1, A2,
            B1, B2, C1, С2. (формат экзаменов: Start Deutsch, TELC, DTZ, etc.)
            <br />
            Так же я преподавал немецкий язык тем, кто учился в колледже по
            разным специальностям в том числе НОСИТЕЛЯМ ЯЗЫКА, у которых имелись
            проблемы с немецкой грамматикой (орфографией и правописанием).
            <br />
            <br /> Преподаю по лично выработанной методике, используя
            аутентичные современные методические материалы и стараюсь
            максимально увлекательно и не занудно преподносить материал.
          </div>
        ),
      },
      {
        name: "Аружан Тнашева",
        level: "C1 (проф. владение)",
        experience: "4 лет",
        location: "г. Алматы, Казахстан",
        photo:
          "https://static.tildacdn.pro/tild3234-6333-4539-a230-626338303138/noroot.png",
        about: (
          <div>
            О себе: <br />
            Начала изучение немецкого языка в 2005 году в немецкой школе, имею
            профильное высшее образование и продолжаю совершенствовать свои
            знания уже в роли преподавателя.
            <br />
            <br /> В процессе обучения я: <br />* обеспечиваю комфортную
            обстановку; <br />* объясняю трудные темы простым языком; <br />*
            уделяю внимание на пожелания и запросы ученика, а также предоставляю
            большое количество дополнительных материалов по грамматическим и
            лексическим темам; <br />* подготавливаю учеников к языковым
            экзаменам Goethe и DSD. <br />
            <br />
            Самое большое удовольствие для преподавателя – наблюдать за успехами
            его учеников: мои ученики сейчас обучаются и работают в Германии;
            успешно сдают языковые экзамены; и - что немаловажно – просто
            общаются на немецком!
          </div>
        ),
      },
      {
        name: "Сабина Далова",
        level: "C2 (в совершенстве)",
        experience: "5 лет",
        location: "г. Ташкент, Узбекистан",
        url: "https://www.youtube.com/watch?v=TvFtYY-3blk&t=231s",
        photo:
          "https://static.tildacdn.pro/tild3237-6134-4135-a162-353730356263/IMG_0245.JPG",
        about: (
          <div>
            О себе: <br />
            Меня зовут Сабина. Я уже более четырех лет преподаю немецкий язык
            онлайн. Большинство моих учеников показывают отличные результаты на
            дистанционном обучении. Помимо обучения общему немецкому языку,
            подготавливаю к сдаче экзамена DSD(I,II) по всем необходимым
            направлениям: •Hören •Schreiben •Lesen •Sprechen Параллельно
            обучаюсь в медицинском институте, где также открыла группу по
            изучению немецкого языка. В будущем планирую продолжить своё
            обучение в Германии. Второй год занимаюсь консультациями по
            поступлению в Германию, так как у меня был опыт поступления в два
            колледжа.
            <br />
            <br />
            Квалификация:
            <br />
            Получила два сертификата о знании немецкого языка. Участвовала в
            различных семинарах, проводившихся в Гёте институте на территории
            Ташкента, в 2018 году участвовала в программе PASCH: Schule -
            Partner der Zukunft, которая проходила в Бишкеке 3 недели.
            <br />
            <br />
            Чем ваши занятия отличаются от других?
            <br />
            - Программа обмена языками общественная студенческая организация при
            университете г. Бонн- Allgemeiner Studentenausschuss - Опыт
            преподавания в программе Sprachtandem Bonn - Практикум преподавание
            в частной языковой школе в городе Бонн, Нордрайн-Вестфалия – 120
            часов преподавания, по программе обмена опытом
            <br />
            <br />
            Как проходит урок?
            <br />
            «А как проходит урок?» – знакомьтесь, самый частый вопрос тех, кто
            только начинает учить немецкий в DeutschKZ. А мы просто берем и
            отвечаем на него! Ознакомьтесь с форматом преподавания Сабины
            Даловой, не посещая занятия лично и не записываясь на пробный урок.
          </div>
        ),
      },
      // {
      //   name: "Лусине Чалян",
      //   level: "C1 (проф. владение)",
      //   experience: "12 лет",
      //   location: "г. Алматы, Казахстан",
      //   about: (
      //     <div>
      //       – Опыт работы: 7,5 лет в китайском университете - немецкий как
      //       второй иностранный + сдача экзаменов в Гёте-институте и для
      //       поступления на магистратуру (до этого - частные уроки параллельно с
      //       учебой в университете, а также частные уроки в Алматы с сентября
      //       2021 и до начала работы в DeutschKZ)
      //       <br />
      //       <br />
      //       – О себе: гражданка ФРГ, немецкий на уровне родного (считаюсь
      //       носителем); полное обучение до диплома магистра в LMU Мюнхена;
      //       проживание в Германии (а именно в Баварии) с 1999 по 2013, до
      //       получения работы в Китае
      //       <br />
      //       <br />
      //       – Чем отличаются мои занятия: использую собственную методику,
      //       выходящую за пределы учебников; нахожу индивидуальный подход к
      //       каждому ученику и его нуждам; делаю упор на грамматику и словарный
      //       запас на начальных уровнях и на разговорную речь на различные
      //       актуальные темы на более продвинутых; включаю культуру Германии и
      //       Австрии в программу изучения языка; по желанию проведу экскурс в
      //       составление письменных работ (для желающих учиться в Германии или
      //       Австрии); занимаюсь в основном в оффлайн-режиме.
      //       <br />
      //       <br />– Так как я являюсь гражданкой ФРГ, подтверждения уровня языка
      //       для нас не проводятся.
      //     </div>
      //   ),
      // },
    ],
  } = props;
  const navigate = useNavigate();

  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        gap: "32px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AdDivTeachers navigate={navigate} />
      {teachers &&
        teachers.map((teacher) => {
          return <TeacherCard teacher={teacher} />;
        })}
    </div>
  );
};

const TeachersPage = WithLayout(TeachersPageTemp);
export default TeachersPage;
