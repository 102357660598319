import { Progress } from "antd";
import React from "react";
const RemainingTimeDiv = (props) => {
  const { total, remaining, remainingHoursCount, totalHoursCount } = props;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#FFFFFF",
        padding: "15px",
      }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          // padding: "5%",
          // paddingLeft: "7%",
          justifyContent: "left",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            textAlign: "left",
            padding: "5px",
            paddingLeft: "5px",
          }}
        >
          <span style={{ fontWeight: "bold", fontSize: "15px" }}>
            У тебя осталось часов по тарифу
          </span>
          <Progress
            percent={+((total / remaining) * 100).toFixed(0)}
            format={(f) => (
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  paddingLeft: "10px",
                }}
              >
                {`${+(
                  remainingHoursCount ? remainingHoursCount / 60 : 0
                ).toFixed(0)}/${+(
                  totalHoursCount ? totalHoursCount / 60 : 0
                ).toFixed(0)}`}
              </span>
            )}
            style={{
              width: "90%",
            }}
            showInfo
          />
        </div> */}
        <div
          style={{
            // display: "flex",
            // flexDirection: "column",
            // gap: "4px",
            // backgroundColor: "#F6F6FA",
            // padding: "15px",
            // justifyContent: "start",
            // borderRadius: "10px",
            // alignItems: "start",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            textAlign: "left",
            padding: "5px",
            paddingLeft: "5px",
          }}
        >
          <span style={{ fontWeight: "bold", fontSize: "15px" }}>
            {`Осталось часов по вашему тарифу: ${remaining}/${total}`}
          </span>
          <Progress
            percent={(remaining / total) * 100}
            style={{
              margin: "0px",
            }}
            showInfo={false}
          />
        </div>
      </span>
    </div>
  );
};

export default RemainingTimeDiv;
