import { Progress } from "antd";
import React from "react";

const type = {
  individual: "Индивидуальный",
  group: "Групповой",
  pair: "Парный",
};

const RemainingTimeDiv = (props) => {
  const { courses } = props;

  return (
    <div
      style={{
        height: "fit-content",
        width: "100%",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        padding: "15px",
        textAlign: "left",
      }}
    >
      <span
        style={{ paddingLeft: "5px", fontWeight: "bold", fontSize: "16px" }}
      >
        Тарифы
      </span>
      {courses?.map((course) => {
        const total = +(
          course?.initialAmount ? course?.initialAmount / 60 : 0
        ).toFixed(0);
        const remaining = +(course?.amount ? course?.amount / 60 : 0).toFixed(
          0
        );
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
              justifyContent: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                textAlign: "left",
                padding: "5px",
              }}
            >
              <span style={{ fontSize: "15px" }}>{`${
                type[course.type]
              }: ${remaining}/${total}`}</span>
              <Progress
                percent={(remaining / total) * 100}
                style={{
                  margin: "0px",
                }}
                showInfo={false}
              />
            </div>
          </span>
        );
      })}
    </div>
  );
};

export default RemainingTimeDiv;
