import React from "react";
import { Button, Avatar, Dropdown, Divider, Space, Card } from "antd";
import { UserOutlined, MenuOutlined, ExportOutlined } from "@ant-design/icons";
import context from "../../../../context";

const UpperAvatar = (props) => {
  const { fullname, email } = props;
  const { window, profileData } = React.useContext(context);
  const { name, surname, email: email2 = "Загрузка..." } = { ...profileData };
  return (
    <Space
      direction="horizontal"
      style={{ width: "100%", justifyContent: "center" }}
    >
      <Avatar
        size={96}
        style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
        icon={<UserOutlined style={{ fontSize: "48px" }} />}
      />
      <Space
        direction="vertical"
        style={{ width: "100%", alignItems: "start" }}
      >
        <span
          style={{ fontWeight: "bold", fontSize: "24px", lineHeight: "26px" }}
        >
          {fullname}
        </span>
        <span
          style={{ color: "#818199", fontSize: "16px", lineHeight: "18px" }}
        >
          {email}
        </span>
      </Space>
    </Space>
  );
};

export default UpperAvatar;
