import { Row, Col, Card, Avatar } from "antd";
import React from "react";
import { ReactComponent as Logo } from "../../../../media/icons/books.svg";
import { UserOutlined } from "@ant-design/icons";

const TeacherDiv = (props) => {
  const {
    name = "Павел Козлов",
    swap = false,
    litup = false,
    pos,
    chat,
    routeGo,
    role,
  } = props;

  return (
    <div
      style={
        litup
          ? {
              height: "100%",
              width: "100%",
              borderRadius: "25px",
              backgroundColor: "#FFFFFF",
              border: "1px solid grey",
            }
          : {
              height: "100%",
              width: "100%",
              borderRadius: "25px",
              backgroundColor: "#FFFFFF",
            }
      }
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          padding: chat ? "5px" : "5%",
          paddingLeft: chat ? "5px" : "7%",
          justifyContent: "left",
        }}
      >
        <Avatar
          size={40}
          style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
          icon={<UserOutlined style={{ fontSize: "20px" }} />}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // gap: "4px",
            textAlign: !pos ? "left" : "center",
          }}
          onClick={routeGo ? routeGo : () => {}}
        >
          <span
            style={{
              fontSize: "15px",
            }}
          >
            {name}
          </span>
          <span
            style={{
              fontSize: "13px",
            }}
          >
            {role}
          </span>
        </div>
      </span>
    </div>
  );
};

export default TeacherDiv;
