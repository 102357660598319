import React, { useMemo } from "react";
import WithLayout from "../../../withLayout";
import {
  Button,
  Col,
  Collapse,
  Drawer,
  Input,
  Result,
  Row,
  Space,
  Spin,
} from "antd";
import MainAdDiv from "./widgets/main-ad-div";
import TeacherDiv from "./widgets/teacher-div";
import NextLessonDiv from "./widgets/next-lesson-div";
import RemainingTimeDiv from "./widgets/remaining-time-div";
import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import context from "../../../context";
import {
  getCourses,
  getTeachers,
  getTimePackages,
  postHomework,
} from "../../../apis";
import { useNavigate, useParams } from "react-router";
import ls from "localstorage-slim";
import dayjs from "dayjs";
import { Calendar } from "../../../components";
import { CollapsibleCourseList } from "./components";
const getCol = (x, del) => {
  return (
    <Col span={8} xs={24} md={24} lg={8}>
      <Button
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#F6F6FA",
        }}
        danger={del}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            // backgroundColor: "#F6F6FA",
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              paddingLeft: "4%",
              paddingTop: "6%",
              paddingBottom: "6%",
              display: "flex",
              gap: "4px",
              flexDirection: "column",
              justifyContent: "center",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <span>{del ? <DeleteOutlined /> : <FileTextOutlined />}</span>
            <span>{x.name}</span>
          </div>
        </div>
      </Button>
    </Col>
  );
};

const CoursePageTemp = () => {
  const [files, setFiles] = React.useState([{ name: "Сочинение.docx" }]);
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = React.useState({
    open: false,
  });
  const [success, setSuccess] = React.useState(false);
  const [buttons, setButtons] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { window, profileData } = React.useContext(context);
  const [course, setCourse] = React.useState();
  const [nextCourse, setNextCourse] = React.useState();

  const { id } = useParams();

  const isSmall = useMemo(() => window.width < 992, [window]);

  const upd = () => {
    setLoading(true);
    getCourses(undefined, false)
      .then((r) => r.json())
      .then((r) => {
        console.log(r, profileData);
        getTimePackages(profileData?.id ?? ls.get("profile")?.id)
          .then((rr) => rr.json())
          .then((rrr) => {
            let rtemp = r.filter((el) => {
              return el?.studentList?.find((y) => {
                console.log(el, profileData?.id, y.id === profileData?.id);
                return y.id === (profileData?.id ?? ls.get("profile")?.id);
              });
            });
            rtemp = rtemp.find((el) => {
              console.log(el, id);
              return id == el.id;
            });
            const temp = rrr.find((el2) => el2.courseId === rtemp?.id);
            setCourse({
              teacher: rtemp?.teacherList?.[0],
              ...temp,
              ...rtemp,
            });
            const allDurationsUnion = [];
            rtemp.courseSubjects.forEach((el) => {
              allDurationsUnion.push({
                title: el.title,
                homework: el.homeworkTitle,
                time: el.startAt,
              });
            });
            setEvents(
              allDurationsUnion.map((el, id) => {
                const currDate = dayjs(
                  el.time,
                  "YYYY-MM-DDTHH:mm:ss.sss+05:00"
                );
                const newDate = currDate.add(dayjs.duration({ hour: 1 }));
                return {
                  id,
                  color: "#fd3153",
                  from: currDate.format("YYYY-MM-DDTHH:mm:ss+05:00"),
                  to: newDate.format("YYYY-MM-DDTHH:mm:ss+05:00"),
                  title: el.title,
                };
              })
            );
            setNextCourse(rtemp.courseSubjects.find((el) => !el.completed));
          });
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (
      ls.get("profile")?.role !== "student" &&
      profileData?.role !== "student"
    )
      navigate("/teacher-main-page");
    upd();
  }, []);

  const [file, setFile] = React.useState("");
  function fileToByteArray(file) {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        let fileByteArray = [];
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
          if (evt.target.readyState == FileReader.DONE) {
            let arrayBuffer = evt.target.result,
              array = new Uint8Array(arrayBuffer);
            for (let byte of array) {
              fileByteArray.push(byte);
            }
          }
          resolve(fileByteArray);
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  const handleUpload = (id, body, type) => {
    setLoading(true);
    postHomework(id, body, type)
      .then(() => {
        getCourses(undefined, false)
          .then((r) => r.json())
          .then((r) => {
            console.log(r);
            getTeachers()
              .then((rteach) => rteach.json())
              .then((rteach) => {
                const rtemp = r?.map((el) => {
                  return {
                    ...el,
                    teacher: rteach.find((element) => element?.id === el?.id),
                  };
                });
                console.log(rtemp);
                setCourse(
                  rtemp?.find((el) => {
                    return el?.id.toString() === id;
                  })
                );
                setLoading(false);
                setSuccess(true);
              })
              .catch(() => {
                setLoading(false);
              });
          });
      })
      .catch(() => setLoading(false));
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    fileToByteArray(fileUploaded).then((r) => {
      setFile({
        file: fileUploaded,
        byte: `${fileUploaded?.name}|${fileUploaded?.type}|${r.join(".")}`,
      });
    });
  };
  const ref2 = React.useRef();

  const coursesFuture = useMemo(
    () =>
      course?.courseSubjects?.filter(
        (el) => !el.completed || dayjs(el?.startAt) >= dayjs() // .sort((el, el2) => dayjs(el.startAt) - dayjs(el2.startAt))
      ),
    [course]
  );

  const coursesOld = useMemo(
    () =>
      course?.courseSubjects?.filter(
        (el) => el.completed && dayjs(el?.startAt) < dayjs() // .sort((el, el2) => dayjs(el.startAt) - dayjs(el2.startAt))
      ),
    [course]
  );

  const listOfItems = (
    <>
      <CollapsibleCourseList
        courses={coursesFuture}
        openDrawer={openDrawer}
        window={window}
        upd={upd}
        loading={loading}
        setFile={setFile}
        setLoading={setLoading}
        setOpenDrawer={setOpenDrawer}
        course={course}
        title={"Активные уроки"}
        subTitle="Не завершенные и будущие уроки"
        isCollapsed
      />
      <CollapsibleCourseList
        courses={coursesOld}
        openDrawer={openDrawer}
        window={window}
        upd={upd}
        loading={loading}
        setFile={setFile}
        setLoading={setLoading}
        setOpenDrawer={setOpenDrawer}
        course={course}
        title={"Прошедшие уроки"}
        subTitle="Завершенные и прошедшие уроки"
      />
    </>
  );

  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        gap: window.width < 1000 ? "24px" : "96px",
        flexDirection: "column",
        alignContent: "center",
      }}
    >
      {!course?.title ? (
        <Spin
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <Spin spinning={loading}>
          <div
            style={{
              display: "flex",
              gap: window.width < 1000 ? "24px" : "96px",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={
                window.width < 1000
                  ? {
                      fontSize: "36px",
                      fontWeight: "bold",
                      textAlign: "left",
                      lineHeight: "44px",
                    }
                  : {
                      fontSize: "48px",
                      fontWeight: "bold",
                      textAlign: "left",
                      lineHeight: "76px",
                    }
              }
            >
              {course?.title}
            </span>
            <Row gutter={[16, 16]}>
              <Col span={24} xs={24} md={24} lg={16}>
                <Space style={{ width: "100%" }} direction="vertical" size={16}>
                  <div
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "#E8FE9D",
                      padding: "25px",
                    }}
                  >
                    <Space
                      style={{
                        width: "100%",
                        textAlign: "left",
                        // padding: "25px",
                        paddingBottom: "10px",
                      }}
                      direction="vertical"
                    >
                      <span
                        style={{
                          tecoursetAlign: "left",
                          color: "#818199",
                        }}
                      >
                        Тема:
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {nextCourse?.title}
                        </span>
                        <span
                          style={{
                            color: course?.homework?.completed
                              ? "#5ED973"
                              : "red",
                            marginLeft: "5px",
                          }}
                        >
                          {course?.homework?.completed
                            ? `Пройден`
                            : "Не пройден"}
                        </span>
                      </span>
                      <span
                        style={{
                          tecoursetAlign: "left",
                          color: "#818199",
                        }}
                      >
                        Описание:
                        <p
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {nextCourse?.description?.length > 0
                            ? nextCourse.description
                            : "Описания урока нет"}
                        </p>
                      </span>
                    </Space>
                    <MainAdDiv
                      onClick={setOpenDrawer}
                      lessonLink={course?.lessonLink}
                      open={openDrawer}
                      window={window}
                      upd={upd}
                      loading={loading}
                      setFile={setFile}
                      setLoading={setLoading}
                      addHw={() => {}}
                      course={course}
                      addFile={() => {}}
                      hwSubmitted={nextCourse?.homeworkList?.find(
                        (el) =>
                          el.studentId ===
                          (ls.get("profile")?.id ?? profileData?.id)
                      )}
                      element={nextCourse}
                      material={nextCourse?.files}
                      join={false}
                      current
                    />
                  </div>
                </Space>
              </Col>
              {!isSmall && (
                <>
                  <Col span={16} xs={24} md={24} lg={16}>
                    {listOfItems}
                  </Col>
                  <Col span={8} xs={24} md={24} lg={8}>
                    <Space
                      style={{ width: "100%" }}
                      direction="vertical"
                      size={16}
                    >
                      <TeacherDiv
                        name={`${course?.teacher?.name} ${course?.teacher?.surname}`}
                        routeGo={() => {
                          navigate(`/teacher/${course?.teacher?.userId}`);
                        }}
                      />
                      <NextLessonDiv
                        time={
                          course?.durationList?.[
                            course?.durationList?.length - 1
                          ]
                            ? dayjs(
                                course?.durationList?.[
                                  course?.durationList?.length - 1
                                ]
                              ).format("DD.MM HH:mm")
                            : null
                        }
                      />
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "25px",
                          backgroundColor: "#FFFFFF",
                          padding: "15px",
                        }}
                      >
                        <Calendar
                          // header={(el) => {
                          //   console.log(el);
                          //   return <div>{el}</div>;
                          // }}
                          // header={NewHeader}
                          events={events}
                          widget
                        />
                      </div>

                      <RemainingTimeDiv
                        total={
                          course?.initialAmount ? course?.initialAmount / 60 : 0
                        }
                        remaining={course?.amount ? course?.amount / 60 : 0}
                        remainingHoursCount={course?.amount}
                        totalHoursCount={course?.initialAmount}
                      />
                      {/* <GroupLessonsDiv />
                  <SuccessMeter />
                  <AttendanceStatus /> */}
                    </Space>
                  </Col>
                </>
              )}

              {isSmall && (
                <>
                  <Col span={8} xs={24} md={24} lg={8}>
                    <Space
                      style={{ width: "100%" }}
                      direction="vertical"
                      size={16}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "25px",
                          backgroundColor: "#FFFFFF",
                          padding: "15px",
                        }}
                      >
                        <Calendar events={events} widget />
                      </div>
                      <Collapse
                        className="mainers"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        items={[
                          {
                            key: "1",
                            label: (
                              <div
                                style={{
                                  display: "inline-flex",
                                  flexDirection: "row",
                                  alignItems: "baseline",
                                  gap: "8px",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                  }}
                                >
                                  Информация о курсе
                                </span>
                              </div>
                            ),
                            children: (
                              <Space
                                style={{ width: "100%" }}
                                direction="vertical"
                                size={16}
                              >
                                <RemainingTimeDiv
                                  total={
                                    course?.initialAmount
                                      ? course?.initialAmount / 60
                                      : 0
                                  }
                                  remaining={
                                    course?.amount ? course?.amount / 60 : 0
                                  }
                                  remainingHoursCount={course?.amount}
                                  totalHoursCount={course?.initialAmount}
                                />
                                <TeacherDiv
                                  name={`${course?.teacher?.name} ${course?.teacher?.surname}`}
                                  routeGo={() => {
                                    navigate(
                                      `/teacher/${course?.teacher?.userId}`
                                    );
                                  }}
                                />
                                <NextLessonDiv
                                  time={
                                    course?.durationList?.[
                                      course?.durationList?.length - 1
                                    ]
                                      ? dayjs(
                                          course?.durationList?.[
                                            course?.durationList?.length - 1
                                          ]
                                        ).format("DD.MM HH:mm")
                                      : null
                                  }
                                />
                              </Space>
                            ),
                          },
                        ]}
                      />
                      {/* <GroupLessonsDiv />
                  <SuccessMeter />
                  <AttendanceStatus /> */}
                    </Space>
                  </Col>
                  <Col span={24} xs={24} md={24} lg={24}>
                    {listOfItems}
                  </Col>
                </>
              )}
            </Row>
            <Drawer
              open={openDrawer?.open}
              onClose={() =>
                setOpenDrawer({
                  ...openDrawer,
                  open: false,
                })
              }
              title={openDrawer?.course?.title}
              width={window.width < 1000 ? "100%" : "50%"}
            >
              <Space direction="vertical" style={{ width: "100%" }} size={24}>
                <Spin spinning={loading}>
                  {!success && (
                    <Space
                      direction="vertical"
                      style={{ width: "100%" }}
                      size={24}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <span style={{ fontSize: "14px", color: "#818199" }}>
                          Описание
                        </span>
                        <span>{openDrawer?.course?.description}</span>
                      </div>
                      {/* <Row gutter={[16, 8]}>{files.map((x) => getCol(x))}</Row> */}
                      <Input.TextArea rows={6} placeholder="Ваш комментарии" />
                      <input
                        type="file"
                        onChange={handleChange}
                        ref={ref2}
                        style={{ display: "none" }} // Make the file input element invisible
                      />
                      {file && (
                        <Row gutter={[16, 8]}>
                          <Col span={8} xs={24} md={24} lg={8}>
                            <Button
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#F6F6FA",
                              }}
                              onClick={() => setFile(null)}
                              danger
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "10px",
                                  // backgroundColor: "#F6F6FA",
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "8px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: "4%",
                                    paddingTop: "6%",
                                    paddingBottom: "6%",
                                    display: "flex",
                                    gap: "4px",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  <span>
                                    <DeleteOutlined />
                                  </span>
                                  <span>{file?.file?.name}</span>
                                </div>
                              </div>
                            </Button>
                          </Col>
                        </Row>
                      )}
                      <Button
                        size="large"
                        onClick={() => {
                          ref2?.current?.click();
                        }}
                      >
                        Добавить файл
                      </Button>
                      <Row gutter={[16, 8]}>
                        {buttons.map(() => {
                          return getCol(files[0], true);
                        })}
                      </Row>
                      <Button
                        onClick={() => {
                          setLoading(true);
                          handleUpload(
                            openDrawer?.course?.homework?.id,
                            {
                              ...(openDrawer?.course?.homework ?? {
                                title: file?.byte,
                              }),
                              file: file?.byte,
                            },
                            openDrawer?.course?.homework ? "PUT" : "POST"
                          );
                        }}
                        type="primary"
                        size="large"
                        style={{ width: "100%" }}
                      >
                        Отправить на проверку
                      </Button>
                    </Space>
                  )}
                  {success && (
                    <Result
                      status="success"
                      title="Ваше задание было отправлено!"
                      extra={[
                        <Button
                          type="primary"
                          key="console"
                          block
                          size="large"
                          onClick={() => {
                            setOpenDrawer({
                              ...openDrawer,
                              open: false,
                            });
                            setSuccess(false);
                          }}
                        >
                          Закрыть
                        </Button>,
                      ]}
                    />
                  )}
                </Spin>
              </Space>
            </Drawer>
          </div>
        </Spin>
      )}
    </div>
  );
};

const CoursePage = WithLayout(CoursePageTemp);
export default CoursePage;
