import { Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WithLayout from "../../../withLayout";
import VideosRows from "./videos-rows";
import { fetchVideoClassList } from "../../../apis";

const FirstLookPageTemp = (props) => {
  const { isAuth = true } = props;
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    fetchVideoClassList().then((data) => setVideoList(data));
  }, []);

  const handleBuyRequest = () => {
    setLoading(true);
    setTimeout(() => {
      setSuccess(true);
      setLoading(false);
    }, 300);
  };

  const buyBook = () => {
    setOpenDrawer(false);
    setTimeout(() => {
      setSuccess(false);
    }, 300);
  };

  React.useEffect(() => {
    !isAuth && navigate("/");
  });

  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "48px",
        flexDirection: "column",
      }}
    >
      <span
        style={{
          fontWeight: "bold",
          fontSize: "34px",
          textAlign: "left",
          lineHeight: "38px",
        }}
      >
        Первый взгляд на уроки
      </span>
      <span style={{ fontWeight: "bold", fontSize: "22px", textAlign: "left" }}>
        Для начинающих
      </span>
      <VideosRows videos={videoList} />
    </div>
  );
};

const FirstLookPage = WithLayout(FirstLookPageTemp);
export default FirstLookPage;
