import React from "react";
import WithLayout from "../../../withLayout";
import { Button, Col, Image, Input, Row, Space } from "antd";
import StudentComp from "./students-comp";
import { useNavigate } from "react-router";

const TeacherStudentsManagerPageTemp = (props) => {
  const navigate = useNavigate();
  const { students = [
    {
      name: 'Павел Козлов',
    },
    {
      name: 'Даурен Карасаев',
    },
    {
      name: 'Даурен Сарсембин',
    },
  ] } = props;
  return <Space direction="vertical" size={24} style={{
    width: '100%',
    paddingLeft: '10%',
    paddingRight: '10%'
  }}>
    <span style={{
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '36px',
      textAlign: 'left',
      float: 'left'
    }}>Преподаватели</span>
    <Row style={{width: '100%'}}>
      <Col span={12}>
        <Input placeholder="Поиск" size="large" />
      </Col>
      <Col span={12} style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
      }}>
        {/* <Button type="primary" size="large">
        Пригласить ученика
        </Button> */}
      </Col>
    </Row>
    {
      students.map((element) => {
        return <StudentComp {...element} onClick={() => navigate('/teacher-course-page-manager')} />
      })
    }
  </Space>
};

const TeacherStudentsManagerPage = WithLayout(TeacherStudentsManagerPageTemp);
export default TeacherStudentsManagerPage;
