import {useState} from "react";
import {Avatar, Button, Drawer, Input, Progress, Select, TimePicker} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

import "./styles.css"



export const EditDrawer = () => {

    const [isVisible, setIsVisible] = useState(false)

    const onOpen = () => {
        setIsVisible(true)
    }

    const onClose = () => {
        setIsVisible(false)
    }

    return (
        <div style={{width: '100%'}}>
            <Button className="group-schedule__card__edit-btn" onClick={onOpen}><EditOutlined/></Button>
            <Drawer open={isVisible} onClose={onClose} title="Редактировать группу" width={window.innerWidth < 750 ? "100%" : "30%"}>
               <Input placeholder="Название" />
                <div>
                    <div style={{marginTop: '20px'}}>
                        <Select
                            placeholder="День недели"
                            options={[
                                { value: 'jack', label: 'Понедельник' },
                                { value: 'lucy', label: 'Вторник' },
                                { value: 'Yiminghe', label: 'Среда' },
                                { value: 'Yiminghe', label: 'Четверг' },
                                { value: 'Yiminghe', label: 'Пятница' },
                                { value: 'Yiminghe', label: 'Суббота' },
                                { value: 'Yiminghe', label: 'Воскресенье' },
                            ]}
                            style={{width: '100%'}}
                        />
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <TimePicker style={{width: '100%'}}/>
                    </div>
                </div>

                <div>
                    <div style={{marginTop: '20px'}}>
                        <Select
                            placeholder="День недели"
                            options={[
                                { value: 'jack', label: 'Понедельник' },
                                { value: 'lucy', label: 'Вторник' },
                                { value: 'Yiminghe', label: 'Среда' },
                                { value: 'Yiminghe', label: 'Четверг' },
                                { value: 'Yiminghe', label: 'Пятница' },
                                { value: 'Yiminghe', label: 'Суббота' },
                                { value: 'Yiminghe', label: 'Воскресенье' },
                            ]}
                            style={{width: '100%'}}
                        />
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <TimePicker style={{width: '100%'}}/>
                    </div>
                </div>

                <div>
                    <div style={{marginTop: '20px'}}>
                        <Select
                            placeholder="День недели"
                            options={[
                                { value: 'jack', label: 'Понедельник' },
                                { value: 'lucy', label: 'Вторник' },
                                { value: 'Yiminghe', label: 'Среда' },
                                { value: 'Yiminghe', label: 'Четверг' },
                                { value: 'Yiminghe', label: 'Пятница' },
                                { value: 'Yiminghe', label: 'Суббота' },
                                { value: 'Yiminghe', label: 'Воскресенье' },
                            ]}
                            style={{width: '100%'}}
                        />
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <TimePicker style={{width: '100%'}}/>
                    </div>
                </div>

                <div className="group-schedule__edit__tag">
                    + Добавить урок
                </div>
                <Select
                    placeholder="Учитель"
                    options={[
                        { value: 'jack', label: 'Понедельник' },
                        { value: 'lucy', label: 'Вторник' },
                        { value: 'Yiminghe', label: 'Среда' },
                        { value: 'Yiminghe', label: 'Четверг' },
                        { value: 'Yiminghe', label: 'Пятница' },
                        { value: 'Yiminghe', label: 'Суббота' },
                        { value: 'Yiminghe', label: 'Воскресенье' },
                    ]}
                    style={{width: '100%', marginTop: '10px'}}
                />
                <div style={{fontWeight: '700', marginTop: '20px'}}>Ученики</div>
                <div style={{display: 'flex', gap: '20px'}}>
                    <div className="group-schedule__edit__tag">
                        <Avatar />
                        <div style={{fontWeight: '400', marginTop: '5px'}}>Айзере Нуржанкызы</div>
                    </div>
                    <div className="group-schedule__edit__tag">
                        <div style={{fontWeight: '400', marginTop: '5px'}}>+ Добавить ученикв</div>
                    </div>
                </div>
                <Button style={{backgroundColor: '#2B2A2A', width: '100%', color: 'white', marginTop: '20px'}}>Сохранить</Button>
            </Drawer>
        </div>
    )
}