import {Avatar, Button, DatePicker, Input, Select} from "antd";

import "./styles.css"

export const TeacherInfoPage = () => {
    return (
        <div className="teacher-info__body">
            <div className="teacher-info__title">
                <div className="teacher-info__name">
                    <Avatar size={100}/>
                    <div style={{fontSize: '24px', fontWeight: '600'}}>Артурианна Запрудская</div>
                    <div style={{color: '#818199'}}>arturiana_zaprudskaya@gmail.com</div>
                </div>
            </div>

            <div className="teacher-info__card">
                <div className="teacher-info__name">
                    <div style={{fontSize: '24px', fontWeight: '600'}}>Личная информация</div>
                    <div style={{color: '#818199'}}>Введите актуальную информацию</div>
                </div>
                <div className="teacher-info__form">
                    <Input placeholder="Имя" style={{marginTop: '20px'}}/>
                    <Input placeholder="Фамилия" style={{marginTop: '20px'}}/>
                    <Input placeholder="Отчество" style={{marginTop: '20px'}}/>
                    <DatePicker placeholder="Дата рождения" style={{width: "100%", marginTop: '20px'}} />
                    <Input.TextArea placeholder="Цель обучения" style={{marginTop: '20px'}}/>
                    <Input.TextArea placeholder="Пожелания на счет преподавателя" style={{marginTop: '20px'}}/>
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <Button style={{backgroundColor: '#DADAEE', border: 'none', marginTop: '20px'}}>Сохранить</Button>
                    </div>
                </div>
            </div>

            <div className="teacher-info__card">
                <div className="teacher-info__name">
                    <div style={{fontSize: '24px', fontWeight: '600'}}>Оплата за уроки</div>
                    <div style={{color: '#818199'}}>Введите актуальную информацию</div>
                </div>
                <div className="teacher-info__form">
                    <Select
                        options={[
                            { value: 'jack', label: '₸' },
                        ]}
                        style={{marginTop: '20px', width: '100%'}}
                        placeholder="Валюта зарплаты"
                    />
                    <div className="teacher__salaries">
                        <Input placeholder="Инд. занятия (60м)"/>
                        <Input placeholder="Парные занятия (60м)"/>
                    </div>
                    <div style={{marginTop: '20px'}}>
                        <Input placeholder="Групповые занятия (60м)"/>
                    </div>
                    <div className="teacher__salaries">
                        <Input placeholder="Инд. занятия (90м)"/>
                        <Input placeholder="Парные занятия (90м)"/>
                    </div>
                    <div style={{marginTop: '20px'}}>
                        <Input placeholder="Групповые занятия (90м)"/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <Button style={{backgroundColor: '#DADAEE', border: 'none', marginTop: '20px'}}>Сохранить</Button>
                    </div>
                </div>
            </div>

            <div className="teacher-info__card">
                <div className="teacher-info__name">
                    <div style={{fontSize: '24px', fontWeight: '600'}}>Информация для связи</div>
                    <div style={{color: '#818199'}}>Введите актуальную информацию</div>
                </div>
                <div className="teacher-info__form">
                    <Input placeholder="Номер телефона" style={{marginTop: '20px'}}/>
                    <Input placeholder="Электронная почта" style={{marginTop: '20px'}}/>
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <Button style={{backgroundColor: '#DADAEE', border: 'none', marginTop: '20px'}}>Сохранить</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}