import React, { useEffect, useLayoutEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import context from "./context";
import ls from "localstorage-slim";
const HandleRouting = (props) => {
  const navigate = useNavigate();
  const { isAuthed, setIsAuthed, setProfileData, profileData, hasCourses } =
    React.useContext(context);
  const loc = useLocation();
  useEffect(() => {
    console.log(
      "nav",
      loc.pathname === "/" && ls.get("profile")?.role !== "student"
    );
    if (loc.pathname === "/" && ls.get("profile")?.role !== "student")
      navigate("/teacher-main-page");
    if (
      loc.pathname === "/" &&
      ls.get("profile")?.role === "student" &&
      hasCourses
    )
      navigate("/bought-main-page");
  }, [hasCourses, loc.pathname]);

  useEffect(() => {
    if (!profileData?.role) setProfileData(ls.get("profile"));
  }, [profileData]);

  React.useEffect(() => {
    console.log(isAuthed, loc);
    const token = ls.get("token");
    if (!token) {
      setIsAuthed({
        auth: false,
        token: "",
        role: "",
      });
      navigate("/authorization");
    }
    if (!isAuthed.auth || loc?.state?.check) {
      if (token) {
        setIsAuthed({
          auth: true,
          token,
          role: "student",
        });
        setProfileData(ls.get("profile"));
      } else {
        setIsAuthed({
          auth: false,
          token: "",
          role: "",
        });
        navigate("/authorization");
      }
    }
  }, [loc?.state?.check]);
  return <Outlet />;
};

export default HandleRouting;
