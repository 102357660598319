import {Tabs} from "antd";
import WithLayout from "../../../withLayout";
import {GroupSchedulePage} from "./group-schedule";
import {TeacherInfoPage} from "./teacher-info";

import "./styles.css"



const items= [
    {
        key: '1',
        label: 'Группы и расписание',
        children: <GroupSchedulePage />
    },
    {
        key: '2',
        label: 'Личная информация',
        children: <TeacherInfoPage /> ,
    },
];
const TeacherDetailPageTemp = () => {
    return (
        <div className="student-detail__body">
            <div className="student-detail__title">
                <div style={{fontSize: "48px"}}>
                    Айзере Нуржанкызы
                </div>
                <div style={{fontSize: "20px", color: "#818199", marginTop: "30px"}} id="student__tag">
                    Учитель
                </div>
            </div>
            <Tabs defaultActiveKey="1" items={items} style={{marginTop: "20px"}}/>
        </div>
    )
}

const TeacherDetailPage = WithLayout(TeacherDetailPageTemp);
export default TeacherDetailPage;