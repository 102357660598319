import React from "react";
import styles from "./event.module.css";

export default class Event extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.myRef = props.newRef;
  }

  onClick(e) {
    e.stopPropagation();
    this.props.onClick();
  }

  // componentDidMount() {
  //   this.myRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
  // }

  render() {
    return (
      <div
        style={{ backgroundColor: this.props.color }}
        onClick={this.onClick}
        className={styles.dailyEventWrapper}
      >
        <span className={styles.dailyEventTitle} >
          {this.props.title}
        </span>
      </div>
    );
  }
}
