import React from "react";
import { ReactComponent as Logo } from "../../../../../media/icons/clock.svg";
import { Col, Row } from "antd";

const NextLessonDiv = (props) => {
  const { time } = props;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#FFFFFF",
        padding: "15px",
      }}
    >
      <Row
        style={{
          padding: "5px",
        }}
      >
        <Col
          span={12}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            textAlign: "left",
            justifyContent: "center",
          }}
        >
          {/* <div
            style={{
              // display: "flex",
              // flexDirection: "column",
              // gap: "16px",
              // textAlign: "left",
            }}
          > */}
          <span style={{ fontWeight: "bold", fontSize: "15px" }}>
            Следующий урок
          </span>
          <spam style={{ color: "#292929", fontSize: "16px" }}>{time}</spam>
          {/* </div> */}
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Logo />
        </Col>
      </Row>

      {/* </span> */}
    </div>
  );
};

export default NextLessonDiv;
