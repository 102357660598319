import {Avatar, Button, Card, Input, Row} from "antd";
import {DeleteOutlined, MessageOutlined} from "@ant-design/icons";
import WithLayout from "../../../withLayout";

import "./style.css"
import InviteDrawer from "./invite-drawer";


const StudentsPageTemp = () => {
    return (
        <div className="students__body">
            <div className="students__title">
                Ученики
            </div>
            <Row>
                <div className="students__tools">
                    <Input.Search placeholder="Поиск" />
                    <InviteDrawer/>
                </div>
            </Row>
            <div className="students__cards">
                <div className="stundents__card">
                    <Card className="card__body">
                        <div className="students__card__info">
                                <div className="students__card__info__body">
                                    <div className="students__card__info__name">
                                        <div style={{display: "flex", gap: "20px"}}>
                                            <Avatar />
                                            <div className="student__name">Айзере Нуржанкызы</div>
                                        </div>
                                        <Button className="students__card__communicate-btn">
                                            <MessageOutlined /> Связаться
                                        </Button>
                                    </div>

                                </div>
                            <div className="students__card__info__groups">
                                <div>
                                    <div style={{display: "flex", justifyContent: "left", fontSize: "14px", color: "#818199"}}>Состоит в группах</div>
                                    <div style={{display: "flex", gap: "10px", fontSize: "16px", fontWeight: "500"}}>
                                        <div>Группа 1</div>
                                        <div>Группа 2</div>
                                        <div>Анна Алексеевна</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card__delete-btn">
                            <Button className="students__card__delete-btn">Удалить ученика <DeleteOutlined /></Button>
                        </div>
                    </Card>
                </div>
                <div className="stundents__card">
                    <Card className="card__body">
                        <div className="students__card__info">
                            <div className="students__card__info__body">
                                <div className="students__card__info__name">
                                    <div style={{display: "flex", gap: "20px"}}>
                                        <Avatar />
                                        <div className="student__name">Айзере Нуржанкызы</div>
                                    </div>
                                    <Button className="students__card__communicate-btn">
                                        <MessageOutlined /> Связаться
                                    </Button>
                                </div>

                            </div>
                            <div className="students__card__info__groups">
                                <div>
                                    <div style={{display: "flex", justifyContent: "left", fontSize: "14px", color: "#818199"}}>Состоит в группах</div>
                                    <div style={{display: "flex", gap: "10px", fontSize: "16px", fontWeight: "500"}}>
                                        <div>Группа 1</div>
                                        <div>Группа 2</div>
                                        <div>Анна Алексеевна</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card__delete-btn">
                            <Button className="students__card__delete-btn">Удалить ученика <DeleteOutlined /></Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

const StudentsPage = WithLayout(StudentsPageTemp);
export default StudentsPage;
