import { Button, Card, Col, Modal, Row, Space } from "antd";

import React from "react";
import ReactPlayer from "react-player";
import "./index.css";
const VideosRows = (props) => {
  const { small = false, videos = [] } = props;
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [currentVideo, setCurrentVideo] = React.useState("");

  // const tempVideos = React.useMemo(() => {
  //   return small ? videos.slice(0, 5) : videos;
  // }, [small, videos]);

  return (
    <span
      // style={{
      //   display: "flex",
      //   flexDirection: "row",
      //   flexWrap: "wrap",
      //   gap: "16px",
      // }}
      style={{
        overflowX: "hidden",
      }}
    >
      {small &&
        videos.slice(0, 3).map((vid) => {
          return (
            <Card
              onClick={() => {
                setCurrentVideo(vid.url);
                setOpenDrawer(true);
              }}
              hoverable
              style={{
                height: small ? "100%" : "100%",
                maxWidth: "250px",
                margin: "15px",
              }}
              cover={
                <img
                  src={`https://img.youtube.com/vi/${vid.id}/mqdefault.jpg`}
                />
              }
            >
              <Card.Meta title={vid.title} />
            </Card>
          );
        })}
      <Row gutter={[16, 8]} style={{ overflow: "hidden", overflowX: "scroll" }}>
        {!small &&
          videos.map((vid) => {
            return (
              <Col
                span={small ? 8 : 8}
                xs={24}
                md={24}
                lg={8}
                // style={{
                //   minWidth: "250px",
                // }}
              >
                <Card
                  onClick={() => {
                    setCurrentVideo(vid.url);
                    setOpenDrawer(true);
                  }}
                  hoverable
                  style={{
                    height: small ? "100%" : "100%",
                    minWidth: "250px",
                  }}
                  cover={
                    <img
                      src={`https://img.youtube.com/vi/${vid.id}/mqdefault.jpg`}
                    />
                  }
                >
                  <Card.Meta title={vid.title} />
                </Card>
              </Col>
            );
          })}
        {/* {small && (
          <Col span={4}>
            <Card
              onClick={() => {
                // setCurrentVideo(vid.url);
                // setOpenDrawer(true);
              }}
              style={{
                height: "90%",
              }}
            >
              <Space
                direction="vertical"
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                  paddingTop: "15%",
                }}
                size={32}
              >
                <Button
                  type="primary"
                  icon={<RightOutlined />}
                  onClick={() => {
                    navigate && navigate("/first-look");
                  }}
                  size={"large"}
                />
                <span>Смотреть другие уроки</span>
              </Space>
            </Card>
          </Col>
        )} */}
      </Row>
      <Modal
        closeIcon={false}
        open={openDrawer}
        width={small ? "90%" : "50%"}
        he
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
        }}
        onCancel={() => {
          setOpenDrawer(false);
        }}
        footer={[]}
      >
        <ReactPlayer
          controls
          width={"100%"}
          height={"480px"}
          url={currentVideo}
        />
      </Modal>
    </span>
  );
};

export default VideosRows;
