const topics = [
  {
    id: "1",
    title: "A1 Niveau",
    books: [
      {
        key: 1,
        title: "Schritte international NEU A1.1 (1)",
        download:
          "https://drive.google.com/drive/folders/1u5K0LNSl7nltLQEmiFggeMS_RnAHPQ09?usp=sharing",
        img: "https://optim.tildacdn.pro/tild6565-6366-4539-b166-653431326638/-/resize/122x/-/format/webp/__2021-09-05__63824_.png",
      },
      {
        key: 2,
        title: "Schritte international NEU A1.2 (2)",
        download:
          "https://drive.google.com/drive/folders/1n7YEYoQy1Yrmn_5Rh6B_xh5WsrisHAQE",
        img: "https://optim.tildacdn.pro/tild3838-3562-4234-b933-363136316133/-/resize/122x/-/format/webp/__2021-09-05__63833_.png",
      },
      {
        key: 3,
        title: "DEUTSCH INTENSIV Schreiben A1",
        download:
          "https://drive.google.com/file/d/1gxE0RmjzeJ5iaAlKhGBanyvSnHc4LY2_/view",
        img: "https://optim.tildacdn.pro/tild6166-3565-4332-a139-313465666531/-/resize/122x/-/format/webp/9783126750479.jpg",
      },
    ],
  },
  {
    id: "2",
    title: "A2 Niveau",
    books: [
      {
        key: 1,
        title: "Schritte international NEU A2.1 (3)",
        download:
          "https://drive.google.com/file/d/1rpSLVoUrkcwQNoi0KJpw38qnm_PcphVP/view",
        img: "https://optim.tildacdn.pro/tild6134-3233-4266-a336-326537633939/-/resize/122x/-/format/webp/__2021-09-05__63842_.png",
      },
      {
        key: 2,
        title: "Schritte international NEU A2.2 (4)",
        download:
          "https://drive.google.com/file/d/1RzuQfzatBWRFlr98LWKi3TCqRHcDryxt/view",
        img: "https://optim.tildacdn.pro/tild3736-3531-4831-b866-316565333637/-/resize/122x/-/format/webp/__2021-09-05__63859_.png",
      },
      {
        key: 3,
        title: "Langenscheidt Grammatik Intensivtrainer A2",
        download:
          "https://drive.google.com/file/d/1rpSLVoUrkcwQNoi0KJpw38qnm_PcphVP/view",
        img: "https://optim.tildacdn.pro/tild3132-6439-4435-b564-303832393361/-/resize/122x/-/format/webp/71mBijhrPSL.jpg",
      },
      {
        key: 4,
        title: "Deutsch üben A2",
        download:
          "https://drive.google.com/file/d/1ox64x8ANMsLgclnaIAclhC796fBzAksv/view",
        img: "https://optim.tildacdn.pro/tild6538-3764-4534-b161-646134663837/-/resize/122x/-/format/webp/81HFkwcKOIL.jpg",
      },
    ],
  },
  {
    id: "3",
    title: "B1 Niveau",
    books: [
      {
        key: 1,
        title: "Menschen B1.1",
        download:
          "https://drive.google.com/file/d/1UkzGIDgdehePBSdmJKrDA_QuxOLoCzkI/view",
        img: "https://optim.tildacdn.pro/tild3838-3661-4366-a335-323532343361/-/resize/122x/-/format/webp/__2021-09-05__63907_.png",
      },
      {
        key: 2,
        title: "Begegnungen Deutsch als Fremdsprache B1+",
        download: "https://disk.yandex.kz/i/Ddus3h2ayzEAFQ",
        img: "https://optim.tildacdn.pro/tild3464-3932-4533-b836-623961653833/-/resize/122x/-/format/webp/51-EtJECLRL.jpg",
      },
      {
        key: 3,
        title:
          "Mit Erfolg zum Goethe- | ÖSD-Zertifikat B1 (Übungs- und Testbuch)",
        download: "https://disk.yandex.kz/d/-SV315RQ2hxFCA",
        img: "https://optim.tildacdn.pro/tild3536-6534-4363-a134-643632333435/-/resize/122x/-/format/webp/9783126758505jpg.png",
      },
      {
        key: 4,
        title: "Mit Erfolg zu telc Deutsch B1+Beruf",
        download: "https://disk.yandex.kz/i/YT2nbQs-5RcORg",
        img: "https://optim.tildacdn.pro/tild6134-3233-4266-a336-326537633939/-/resize/122x/-/format/webp/__2021-09-05__63842_.png",
      },
      {
        key: 5,
        title: "Schritte international NEU B1.1 (5)",
        download:
          "https://drive.google.com/file/d/1RJeXvr4d5ouud_CCyD1BdTpGYDAEKzfG/view",
        img: "https://optim.tildacdn.pro/tild3438-6466-4636-b432-313536346164/-/resize/122x/-/format/webp/__2021-12-10__52718_.png",
      },
      {
        key: 6,
        title: "Schritte international NEU B1.2 (6)",
        download:
          "https://drive.google.com/file/d/1HNnHYUTA8Nr1qXwLLHYXf5O2azw54tke/view",
        img: "https://optim.tildacdn.pro/tild6666-3736-4933-b839-366332656634/-/resize/122x/-/format/webp/41b0f91b65ac883a999b.jpg",
      },
      {
        key: 7,
        title: "DEUTSCH ÜBEN. Wortschatz & Grammatik B1 (2018)",
        download:
          "https://drive.google.com/file/d/1-Rsx2HWeKJ6hdkDi8f1SJO9VIDOU5mbF/view",
        img: "https://optim.tildacdn.pro/tild6436-3730-4765-b566-336163393135/-/resize/122x/-/format/webp/054uplff12qns4v6rjcc.jpg",
      },
      {
        key: 8,
        title: "DEUTSCH ÜBEN. Wortschatz & Grammatik B1 (2014)",
        download:
          "https://drive.google.com/file/d/1zNZa5ytuuL6aNIXpOKX1D5S6BtlYMv6N/view?usp=sharing",
        img: "https://optim.tildacdn.pro/tild3163-6436-4463-a331-386439663061/-/resize/122x/-/format/webp/51CKXv1Q31L.jpg",
      },
    ],
  },
  {
    id: "4",
    title: "B2 Niveau",
    books: [
      {
        key: 1,
        title: "Mittelpunkt NEU B2 (Arbeitsbuch B2.1)",
        download: "https://disk.yandex.kz/i/M7DOkS-euFiMiA",
        img: "https://optim.tildacdn.pro/tild6662-3033-4163-b766-633564303336/-/resize/122x/-/format/webp/index_4_2.png",
      },
      {
        key: 2,
        title: "Mittelpunkt NEU B2 (Hinweise zum Goethe Zertifikat b2)",
        download: "https://disk.yandex.kz/i/ctchaIexdWiFzQ",
        img: "https://optim.tildacdn.pro/tild3935-6235-4762-a239-623732653165/-/resize/122x/-/format/webp/r-1.jpg",
      },
      {
        key: 3,
        title: "DEUTSCH INTENSIV Grammatik B2 (Probekapitel)",
        download: "https://disk.yandex.kz/i/CKcSL1WHQS54Bg",
        img: "https://optim.tildacdn.pro/tild6562-3639-4238-a165-323339636636/-/resize/122x/-/format/webp/9783126750370.jpg",
      },
      {
        key: 4,
        title: "Mit Erfolg zur DSH B2-C2",
        download: "https://disk.yandex.kz/i/qeLRJqoIU1noQQ",
        img: "https://optim.tildacdn.pro/tild3464-6430-4364-b364-633166626561/-/resize/122x/-/format/webp/81oa2GC29jL.jpg",
      },
      {
        key: 5,
        title: "DEUTSCH INTENSIV Schreiben B2",
        download: "https://disk.yandex.kz/i/j7mt-8LnlvOVVQ",
        img: "https://optim.tildacdn.pro/tild3937-6338-4836-a133-623134373965/-/resize/122x/-/format/webp/9783126750448.jpg",
      },
    ],
  },
  {
    id: "5",
    title: "С1 Niveau",
    books: [
      {
        key: 1,
        title: "Mit Erfolg zum Gothe-Zertifikat C1 (Übungs- und Testbuch)",
        download: "https://disk.yandex.kz/d/EwD40rc3JwyYKA",
        img: "https://optim.tildacdn.pro/tild3835-3830-4936-b065-313232366430/-/resize/122x/-/format/webp/9783126758352.jpg",
      },
      {
        key: 2,
        title: "Mit Erfolg zum digitalen TestDaF (mit Online-Simulation)",
        download: "https://disk.yandex.kz/i/Eha4l7xAROHeAg",
        img: "https://optim.tildacdn.pro/tild3166-6530-4238-b735-343865363061/-/resize/122x/-/format/webp/9783126768276.jpg",
      },
      {
        key: 3,
        title: "Mit Erfolg zum TestDaF (Übungs- und Testbuch)",
        download: "https://disk.yandex.kz/i/SeUQ1BFLbQGP4g",
        img: "https://optim.tildacdn.pro/tild6561-3564-4964-a362-346630303535/-/resize/122x/-/format/webp/make_nimagephp.jpeg",
      },
      {
        key: 4,
        title: "Mit Erfolg zur DSH B2-C2",
        download: "https://disk.yandex.kz/i/qeLRJqoIU1noQQ",
        img: "https://optim.tildacdn.pro/tild3464-6430-4364-b364-633166626561/-/resize/122x/-/format/webp/81oa2GC29jL.jpg",
      },
      {
        key: 5,
        title: "Deutsch üben C1",
        download:
          "https://drive.google.com/file/d/1Drbr8lAt6n_hYPyYXeCN0ChVfB3Pptl3/view?usp=sharing",
        img: "https://optim.tildacdn.pro/tild6138-3534-4530-a137-343264613539/-/resize/122x/-/format/webp/2215509292_221550929.jpg",
      },
    ],
  },
  {
    id: "6",
    title: "С2 Niveau",
    books: [
      {
        key: 1,
        title: "Endstation C2 (Arbeits+Kursbuch)",
        download: "https://disk.yandex.kz/i/3IeQQt3q8jdmhQ",
        img: "https://optim.tildacdn.pro/tild3531-3137-4162-b934-613736383432/-/resize/122x/-/format/webp/1641341352.jpeg",
      },
      {
        key: 2,
        title: "Erkundungen C2 (Integriertes Arbeits+Kursbuch)",
        download: "https://disk.yandex.kz/i/8Y5HjkaU1V5rLg",
        img: "https://optim.tildacdn.pro/tild3263-6235-4436-a334-333433346164/-/resize/122x/-/format/webp/erkundungen-deutsch-.jpg",
      },
      {
        key: 3,
        title: "...zur DSH B2-C2",
        download: "https://disk.yandex.kz/i/qeLRJqoIU1noQQ",
        img: "https://optim.tildacdn.pro/tild3464-6430-4364-b364-633166626561/-/resize/122x/-/format/webp/81oa2GC29jL.jpg",
      },
    ],
  },
  {
    id: "7",
    title: "DEUTSCHKZ KINDER",
    books: [
      {
        key: 1,
        title: "Planetino 1. Deutsch für Kinder (Kursbuch)",
        download:
          "https://drive.google.com/file/d/19FVYFg6Io11EiQ0EecVw8zCpLhZPwxkp/view?usp=sharing",
        img: "https://optim.tildacdn.pro/tild6165-3831-4931-b166-626435346233/-/resize/122x/-/format/webp/__2021-09-05__72623_.png",
      },
      {
        key: 2,
        title: "Planetino 1. Deutsch für Kinder (Arbeitsbuch)",
        download:
          "https://drive.google.com/file/d/1bbUmS0V8HArWzSEoA40oWERTjBKtPfIM/view?usp=sharing",
        img: "https://optim.tildacdn.pro/tild6166-3135-4163-b433-366537626432/-/resize/122x/-/format/webp/1631357654.jpeg",
      },
    ],
  },
  // {
  //   id: "8",
  //   title: "Медицинский немецкий",
  // },
  // {
  //   id: "9",
  //   title: "SCHRITTE INTERNATIONAL NEU",
  // },
  // {
  //   id: "10",
  //   title: "MIT ERFOLG ...",
  // },
];

export default topics;
