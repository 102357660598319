import React from "react";
import './index.css'
const TextMessage = (props) => {
    const {
        text,
        time,
        align = 'right'
    } = props

    const borderRight = {
        borderTopRightRadius: '0px',
    }
    const borderLeft = {
        borderTopLeftRadius: '0px',
    }
    const border = align === 'right' ? borderRight : borderLeft;
    return <div className="text" style={{
        display: 'flex',
        float: 'right',
        alignSelf: align === 'right' ? 'flex-end' : 'flex-start',
        flexDirection: 'column',
        gap: '4px',
        maxWidth: '80%',
        textAlign: align
    }}>
        <div
            style={{
                backgroundColor: align === 'right' ? '#B40A1B' : '#FFE1E1',
                color: align === 'right' ? 'white' : 'black',
                padding: '10px',
                borderRadius: '15px',
                ...border
            }}>
            <span style={{ fontSize: '16px', whiteSpace: 'initial' }}>{text}</span>

        </div>
        <span style={{ fontSize: '12px', whiteSpace: 'initial', color: 'grey', textAlign: 'right', alignSelf: align === 'right' ?  'flex-end' : 'flex-start'}}>{time}</span>
    </div>
}

export default TextMessage