import {useState} from "react";
import {Button, Drawer, Input} from "antd";

const InviteDrawer = () => {
    const [isVisible, setIsVisible] = useState(false)

    const onOpen = () => {
        setIsVisible(true)
    }

    const onClose = () => {
        setIsVisible(false)
    }

    return (
        <div style={{width: "100%"}}>
            <Button className="students__invite-btn" onClick={onOpen}>+ Пригласить участника</Button>
            <Drawer open={isVisible} title="Пригласить ученика" onClose={onClose} width={window.innerWidth < 750 ? "100%" : "30%"}>
                <Input placeholder="Электронная почта"/>
                <div style={{fontWeight: "700", marginTop: "30px"}}>Выберете тариф ученика</div>
                <div className="tariffs">
                    <div className="tariff">
                        <div style={{fontWeight: "700"}}>Групповой</div>
                        <div>64 часа</div>
                    </div>
                    <div className="tariff">
                        <div style={{fontWeight: "700"}}>Групповой</div>
                        <div>64 часа</div>
                    </div>
                    <div className="tariff">
                        <div style={{fontWeight: "700"}}>Групповой</div>
                        <div>64 часа</div>
                    </div>
                    <div className="tariff">
                        <div style={{fontWeight: "700"}}>Групповой</div>
                        <div>64 часа</div>
                    </div>
                </div>
                <Button className="students__invite-btn"> Пригласить</Button>
            </Drawer>
        </div>
    )
}

export default InviteDrawer;