import React from "react";
import WithLayout from "../../../withLayout";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Input,
  Modal,
  Radio,
  Result,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import CurrentCourse from "./current-course";
import { ReactComponent as Calendar2 } from "../../../media/icons/calendar.svg";
import AllStudents from "./all-students";

const CoursePageTeacherManagerTemp = (props) => {
  const [files, setFiles] = React.useState([{ name: "Сочинение.docx" }]);

  const {
    topics = [
      {
        key: "1",
        label: "Fälle und Geschlecht",
        files: files,
      },
    ],
  } = props;

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [buttons, setButtons] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [lessons, setLessons] = React.useState([1]);
  const [choose, setChoose] = React.useState(false);
  const [addedStudent, setAddedStudent] = React.useState(false);

  const addLesson = () => {
    setLessons((prev) => [...prev, 1]);
  };
  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "78px",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          gap: "12px",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            fontSize: "48px",
            fontWeight: "bold",
            textAlign: "left",
            lineHeight: "76px",
          }}
        >
          Павел Козлов
        </span>
        <span
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "left",
            lineHeight: "16px",
          }}
        >
          Преподаватель
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            gap: "8px",
            paddingTop: "15px",
          }}
        >
          <Button type="primary">Группы и расписания</Button>
          <Button type="dashed">Личная информация</Button>
        </div>
      </div>
      <Row gutter={[16, 8]}>
        <Col span={16}>
          <Space style={{ width: "100%" }} direction="vertical" size={16}>
            {/* <MainAdDiv
              onClick={(open) => setOpenDrawer(open)}
              open={openDrawer}
            /> */}
            <Button
              onClick={() => setOpenDrawer(true)}
              type="primary"
              shape="large"
              style={{
                width: "100%",
              }}
            >
              Создать новую группу
            </Button>
            {topics.map((x) => {
              return (
                // <TopicCard
                //   topics={[x]}
                //   files={files}
                //   onClick={(open) => setOpenDrawer(open)}
                //   open={openDrawer}
                // />
                <CurrentCourse click={() => setOpenDrawer(true)} />
              );
            })}
          </Space>
        </Col>
        <Col span={8}>
          <Space style={{ width: "100%" }} direction="vertical" size={16}>
            {/* <TeacherDiv /> */}
            <Calendar2 />
            {/* <AllStudents /> */}
          </Space>
        </Col>
      </Row>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        width={"50%"}
      >
        <Space direction="vertical" style={{ width: "100%" }} size={24}>
          <Spin spinning={loading}>
            {!success && (
              <Space direction="vertical" style={{ width: "100%" }} size={24}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <span style={{ fontSize: "28px", color: "#818199" }}>
                    Создать группу
                  </span>
                  <Input placeholder="Название группы" />
                  {lessons.map((el, i) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          gap: "16px",
                        }}
                      >
                        <span>{`Урок ${i + 1}`}</span>
                        <DatePicker showTime placeholder="Время" />
                      </div>
                    );
                  })}
                  <Button onClick={addLesson} type="dashed">
                    Добавить урок
                  </Button>
                  <Select placeholder="Учитель">
                    <Select.Option>Павел Козлов</Select.Option>
                  </Select>
                  <span>Ученики</span>
                  {addedStudent && <AllStudents cardless />}
                  <Button onClick={() => setChoose(true)} type="dashed">
                    Добавить учеников
                  </Button>
                </div>

                <Space direction="vertical" style={{ width: "100%" }} size={4}>
                  <Button
                    onClick={() => {
                      setLoading(true);
                      setTimeout(() => {
                        setSuccess(true);
                        setLoading(false);
                      }, 500);
                    }}
                    type="primary"
                    size="large"
                    style={{ width: "100%" }}
                  >
                    Создать
                  </Button>
                </Space>
              </Space>
            )}
            {success && (
              <Result
                status="success"
                title="Группа была создана!"
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    block
                    size="large"
                    onClick={() => {}}
                  >
                    Закрыть
                  </Button>,
                ]}
              />
            )}
          </Spin>
        </Space>
        <Modal
          open={choose}
          centered
          onCancel={() => setChoose(false)}
          closeIcon={false}
          footer={null}
        >
          <Space
            direction="vertical"
            size={24}
            style={{
              padding: "15px",
              width: "100%",
            }}
          >
            <Radio.Group
              name="radiogroup"
              defaultValue={1}
              style={{
                width: "100%",
              }}
            >
              <Radio
                style={{
                  width: "100%",
                }}
                value={1}
              >
                <AllStudents cardless />
              </Radio>
            </Radio.Group>
            <Button
              onClick={() => setAddedStudent(true)}
              type="primary"
              style={{
                width: "95%",
              }}
            >
              Добавить студентов
            </Button>
          </Space>
        </Modal>
      </Drawer>
    </div>
  );
};

const CoursePageTeacherManager = WithLayout(CoursePageTeacherManagerTemp);
export default CoursePageTeacherManager;
