import { Card, Col, Row, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../../../media/icons/earth-globe.svg";
import LoginCard from "./login";
import RegistrationCard from "./registration";
import styles from "./index.module.css";
import RestoreCard from "./restore-password";
import RegistrationEmployeeCard from "./registration-employee";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const RegisterPage = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { login, setLogin } = props;
  const {
    isLogin = false,
    isRegister = false,
    isRestore = false,
    isRegEmployee = true,
  } = { ...login };
  const [steps, setSteps] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  console.log(window.screen.width);
  return (
    <Row gutter={[8, 16]}>
      <Col
        span={12}
        sm={24}
        xl={12}
        xs={24}
        xxl={12}
        lg={24}
        md={24}
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
          padding: "10%",
        }}
      >
        <Spin spinning={loading} wrapperClassName={styles["spinner"]}>
          {isLogin && (
            <LoginCard
              login={login}
              setLogin={setLogin}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {isRegister && (
            <RegistrationCard
              steps={steps}
              setSteps={setSteps}
              login={login}
              setLogin={setLogin}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {isRestore && (
            <RestoreCard
              steps={steps}
              setSteps={setSteps}
              login={login}
              setLogin={setLogin}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {isRegEmployee && (
            <RegistrationEmployeeCard
              steps={steps}
              setSteps={setSteps}
              login={login}
              setLogin={setLogin}
              loading={loading}
              setLoading={setLoading}
              isCurator={false}
            />
          )}
        </Spin>
      </Col>
      {windowDimensions?.width > 992 && (
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Logo />
        </Col>
      )}
    </Row>
  );
};

export default RegisterPage;
