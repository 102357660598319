import React from "react";
const context = React.createContext({
  window: {},
  isAuthed: {
    role: "",
    auth: false,
    token: "",
  },
  setIsAuthed: () => {},
  profileData: {},
  setProfileData: () => {},
  notificationApi: {},
  openNotification: () => {},
  hasCourses: undefined,
  setHasCourses: () => {},
});

export default context;
