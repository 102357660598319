import React, { useMemo, useState } from "react";
import WithLayout from "../../../withLayout";
import {
  Row,
  Col,
  Badge,
  Calendar,
  Skeleton,
  Card,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Spin,
  Space,
  Empty,
  Pagination,
} from "antd";
import AdDiv from "./ad-div";
import HomeworkStatus from "./homework-status";
import CurrentCourse from "./current-course";
import NewsCard from "./news-card";
import { useNavigate } from "react-router-dom";
import { getCourses, getTeachers, postCourse2 } from "../../../apis";
import context from "../../../context";
import ls from "localstorage-slim";

import { ReactComponent as CuratorLogo } from "./curators.svg";
import { ReactComponent as StudentLogo } from "./students.svg";
import { ReactComponent as TeacherLogo } from "./teachers.svg";
import { ReactComponent as HomeworkLogo } from "./homeworks.svg";
import dayjs from "dayjs";
import { ArrowRightOutlined } from "@ant-design/icons";

const getListData = (value) => {
  let listData;
  switch (value.date()) {
    case 8:
      listData = [
        {
          type: "warning",
          content: "This is warning event.",
        },
        {
          type: "success",
          content: "This is usual event.",
        },
      ];
      break;
    case 10:
      listData = [
        {
          type: "warning",
          content: "This is warning event.",
        },
        {
          type: "success",
          content: "This is usual event.",
        },
        {
          type: "error",
          content: "This is error event.",
        },
      ];
      break;
    case 15:
      listData = [
        {
          type: "warning",
          content: "This is warning event",
        },
        {
          type: "success",
          content: "This is very long usual event......",
        },
        {
          type: "error",
          content: "This is error event 1.",
        },
        {
          type: "error",
          content: "This is error event 2.",
        },
        {
          type: "error",
          content: "This is error event 3.",
        },
        {
          type: "error",
          content: "This is error event 4.",
        },
      ];
      break;
    default:
  }
  return listData || [];
};
const getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
};

const TeacherMainPageTemp = (props) => {
  const {} = props;

  const navigate = useNavigate();
  const { profileData, window, openNotification, notificationApi } =
    React.useContext(context);
  const { name } = { ...profileData };
  const [courses, setCourses] = React.useState();
  const [searchCourses, setSearchCourses] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [teachers, setTeachers] = React.useState([]);
  const [selectedTeacher, setSelectedTeacher] = React.useState();
  const [title, setTitle] = React.useState("");
  const [type, setType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [time, setTime] = useState();
  const [current, setCurrent] = useState(1);

  const upd = () => {
    setLoading(true);
    getCourses()
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        getTeachers()
          .then((rteach) => rteach.json())
          .then((rteach) => {
            const rtemp = r
              ?.map((el) => {
                return {
                  ...el,
                  teacher: rteach.find((element) => element?.id === el?.id),
                };
              })
              .filter((x) => {
                if (ls.get("profile")?.role !== "teacher") return x;
                return x?.teacherList?.find((y) => y.id === profileData?.id);
              });

            setCourses(rtemp ?? []);
            setSearchCourses(rtemp ?? []);
            const t = rtemp?.sort(
              (el, el2) =>
                dayjs(el?.durationList?.[el?.durationList?.length - 1]) -
                dayjs(el2?.durationList?.[el2?.durationList?.length - 1])
            );
            setTime(t?.[0]?.durationList?.[0]);
            setLoading(false);
          });
      })
      .catch(() => {
        notificationApi && openNotification("error", "Произошла ошибка!");
      });
  };

  React.useLayoutEffect(() => {
    if (ls.get("profile")?.role === "student") navigate("/");
  }, []);

  React.useEffect(() => {
    upd();
  }, [profileData]);

  function slice2(array = [], chunk, offset) {
    let subarray = [];
    let lastInd;
    for (let i = 0; i < chunk; i++) {
      let ind = (offset + i) % array?.length;
      if (lastInd === ind) break;
      lastInd = ind;
      subarray.push(array[ind]);
    }

    return subarray;
  }

  const newSlicedCourses = useMemo(
    () =>
      slice2(
        searchCourses?.length > 0 ? searchCourses : courses,
        5,
        (current - 1) * 5
      ),
    [searchCourses, courses, current]
  );

  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "48px",
        flexDirection: "column",
      }}
    >
      <Row
        gutter={[16, 8]}
        // style={{ display: "flex", flexDirection: "row", gap: "12px" }}
      >
        <Col
          xs={24}
          md={24}
          lg={
            ls.get("profile")?.role === "teacher" ||
            profileData?.role === "teacher"
              ? 12
              : 24
          }
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "18px",
                textAlign: "left",
                paddingTop: "35px",
                paddingBottom: "35px",
                paddingLeft: "15px",
              }}
            >
              <span
                style={{
                  fontSize: "38px",
                  lineHeight: "42px",
                  fontWeight: "bold",
                }}
              >{`${
                ls.get("profile")?.role === "student" ||
                profileData?.role === "student"
                  ? "Привет"
                  : "Здравствуйте"
              }, ${name} 👋`}</span>
              <span style={{ fontSize: "16px" }}>
                {ls.get("profile")?.role === "teacher" ||
                profileData?.role === "teacher"
                  ? "Готовы давать ученикам новые знания сегодня?"
                  : ls.get("profile")?.role === "supervisor" ||
                    profileData?.role === "supervisor"
                  ? "Готовы улучшать работу кураторов и покорять новые вершины?"
                  : "Готовы вносить порядок между учениками и преподавателями?"}
              </span>
            </div>
          </div>
        </Col>
        {(ls.get("profile")?.role === "teacher" ||
          profileData?.role === "teacher") && (
          <Col span={12} xs={24} md={24} lg={12}>
            <AdDiv
              time={time ? dayjs(time)?.format("DD/MM/YYYY HH:mm") : null}
            />
          </Col>
        )}

        {/* <Col span={9}>
          <div
            style={{
              height: "408px",
              width: "360px",
              position: "absolute",
              bottom: "0px",
              right: "0px",
              borderRadius: "25px",
            }}
          >
            <Calendar2 />
          </div>
        </Col> */}
      </Row>
      <Row
        gutter={[16, 8]}
        // style={{ display: "flex", flexDirection: "row", gap: "12px" }}
      >
        <Col
          xs={24}
          md={24}
          lg={ls.get("profile")?.role !== "supervisor" ? 12 : 8}
        >
          <HomeworkStatus
            name="Ученики"
            buttonName="Смотреть"
            onClick={() => {
              navigate("/teacher-students-page");
            }}
            svg={StudentLogo}
          />
        </Col>
        {ls.get("profile")?.role === "teacher" && (
          <Col span={12} xs={24} md={24} lg={12}>
            <HomeworkStatus
              name="Домашние задания"
              buttonName="Смотреть"
              onClick={() => {
                navigate("/hws");
              }}
              svg={HomeworkLogo}
            />
          </Col>
        )}
        {ls.get("profile")?.role !== "teacher" && (
          <Col
            xs={24}
            md={24}
            lg={ls.get("profile")?.role !== "supervisor" ? 12 : 8}
          >
            <HomeworkStatus
              name="Преподаватели"
              buttonName="Смотреть"
              onClick={() => {
                navigate("/teacher-teachers-page");
              }}
              svg={TeacherLogo}
            />
          </Col>
        )}
        {(ls.get("profile")?.role === "supervisor" ||
          profileData?.role === "supervisor") && (
          <Col
            span={8}
            xs={24}
            md={24}
            lg={ls.get("profile")?.role !== "supervisor" ? 12 : 8}
          >
            <HomeworkStatus
              name="Кураторы"
              buttonName="Смотреть"
              onClick={() => {
                navigate("/teacher-curators-page");
              }}
              svg={CuratorLogo}
            />
          </Col>
        )}

        {/* <Col span={12} xs={24} md={12} lg={12}>
          <HomeworkStatus
            name="Мои домашние задания"
            buttonName="Смотреть"
            courses={courses}
            load
            onClick={() => {
              navigate("/teacher-my-teachers");
            }}
          />
        </Col> */}
      </Row>
      <Col span={24}>
        <Row gutter={[4, 4]} style={{ width: "100%" }}>
          <Col span={12}>
            <span
              style={{
                fontSize: "26px",
                lineHeight: "28px",
                fontWeight: "bold",
                float: "left",
              }}
            >
              Курсы
            </span>
          </Col>
          <Col span={12}>
            {ls.get("profile")?.role !== "teacher" && (
              <Button
                type="primary"
                disabled={loading}
                style={{ float: "right" }}
                onClick={() => {
                  getTeachers(ls.get("token"))
                    .then((r) => r.json())
                    .then((r) => {
                      setTeachers(r);
                      setOpen(true);
                    });
                }}
              >
                Создать новый курс
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Row
        gutter={[16, 16]}
        // style={{ display: "flex", flexDirection: "row", gap: "12px" }}
      >
        {courses?.length > 0 && !loading ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "4px",
                width: "100%",
              }}
            >
              <div>
                <Pagination
                  style={{ display: "flex" }}
                  current={current}
                  onChange={(e) => setCurrent(e)}
                  pageSize={5}
                  total={courses.length}
                />
              </div>
              <Input.Search
                onChange={(e) => {
                  console.log(e.target.value);
                  e.target.value.length <= 1 && setSearchCourses([]);
                }}
                onSearch={(e) => {
                  console.log(e);
                  setSearchCourses([
                    ...courses.filter((x) => x.title.includes(e)),
                  ]);
                }}
                allowClear
              />
            </div>
            {newSlicedCourses
              // .slice((current - 1) * 5, (current - 1) * 5 + 5)
              .map((x) => {
                return (
                  <Col span={24}>
                    <CurrentCourse
                      // delayed={x.delayed}
                      // isGroup={x.isGroup}
                      {...x}
                      navigate={navigate}
                      name={x?.title}
                      upd={upd}
                      delete={
                        ls.get("profile")?.role !== "teacher"
                          ? () => {}
                          : () => {}
                      }
                      total={
                        +(x?.initialAmount ? x?.initialAmount / 60 : 0).toFixed(
                          0
                        )
                      }
                      remaining={+(x?.amount ? x?.amount / 60 : 0).toFixed(0)}
                      remainingHoursCount={
                        +(x?.amount ? x?.amount / 60 : 0).toFixed(0)
                      }
                      totalHoursCount={
                        +(x?.initialAmount ? x?.initialAmount / 60 : 0).toFixed(
                          0
                        )
                      }
                      teacher={
                        x?.teacherList?.[0] && {
                          name: x.teacherList[0]
                            ? `${x.teacherList[0].name} ${x.teacherList[0].surname}`
                            : "Учитель отсутствует",
                          id: x.teacherList[0].userId,
                        }
                      }
                      topics={x?.courseSubjects}
                      type={x?.type}
                      extra={[
                        <Button
                          type="primary"
                          size="large"
                          style={{
                            margin: "5px",
                          }}
                          onClick={() =>
                            navigate("/teacher-course-page/" + x?.id, {
                              state: {
                                id: x?.id,
                              },
                            })
                          }
                          icon={<ArrowRightOutlined />}
                          iconPosition="end"
                        >
                          Перейти к курсу
                        </Button>,
                      ]}
                      window={window}
                    />
                  </Col>
                );
              })}
          </>
        ) : loading ? (
          <>
            <Col span={24}>
              <Card>
                <Skeleton active rows />
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Skeleton active rows />
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Skeleton active rows />
              </Card>
            </Col>
          </>
        ) : (
          <Col span={24}>
            <span
              style={{
                fontSize: "18px",
                lineHeight: "20px",
                float: "left",
              }}
            >
              Нет курсов
            </span>
          </Col>
        )}
        {/* {[
          { delayed: false, isGroup: false },
          { delayed: true, isGroup: true },
        ].map((x) => {
          return (
            <Col span={24}>
              <CurrentCourse
                delayed={x.delayed}
                isGroup={x.isGroup}
                navigate={navigate}
              />
            </Col>
          );
        })} */}
        <Space
          direction="vertical"
          style={{ width: "100%", paddingTop: "24px" }}
          size={48}
        >
          {ls.get("profile")?.role !== "teacher" && (
            <Col span={24}>
              <span
                style={{
                  fontSize: "26px",
                  lineHeight: "28px",
                  fontWeight: "bold",
                  float: "left",
                }}
              >
                Новости
              </span>
            </Col>
          )}
          {ls.get("profile")?.role !== "teacher" && (
            <Col span={24}>
              <NewsCard />
            </Col>
          )}
          <Col span={24}>
            <span
              style={{
                fontSize: "26px",
                lineHeight: "28px",
                fontWeight: "bold",
                float: "left",
              }}
            >
              Нововведения
            </span>
          </Col>
          <Col span={24}>
            <NewsCard internal />
          </Col>
        </Space>
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          width={window.width < 1000 ? "100%" : "50%"}
        >
          <Spin spinning={loading}>
            <Form.Item label="Название курса" labelCol={{ span: 24 }}>
              <Input
                placeholder="Введите название курса"
                value={title}
                onChange={(e) => setTitle(e?.target?.value)}
              />
            </Form.Item>
            <Form.Item label="Тип курса" labelCol={{ span: 24 }}>
              <Select
                placeholder="Выберите тип курса"
                onSelect={(e) => {
                  console.log(e);
                  setType(e);
                }}
                value={type}
                notFoundContent={<Empty description="Нет данных" />}
                options={[
                  {
                    value: "individual",
                    label: "Индивидульное занятие",
                  },
                  {
                    value: "group",
                    label: "Групповое занятие",
                  },
                  {
                    value: "pair",
                    label: "Парные занятия",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item label="Учитель" labelCol={{ span: 24 }}>
              <Select
                style={{ width: "100%" }}
                showSearch
                value={selectedTeacher}
                // onSearch={() => {}}
                placeholder="Выберите учителя"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                optionFilterProp="children"
                onSelect={(e) => {
                  console.log(e);
                  setSelectedTeacher(e);
                }}
                notFoundContent={<Empty description="Нет данных" />}
                options={teachers?.map((e) => ({
                  value: e?.id,
                  label: `${e?.name} ${e?.surname}`,
                }))}
              />
            </Form.Item>
            <Button
              type="primary"
              size="large"
              disabled={
                !(selectedTeacher && title && typeof type !== "undefined")
              }
              style={{ width: "100%" }}
              onClick={() => {
                setLoading(true);
                postCourse2({
                  title: title,
                  courseSubjects: [],
                  studentList: [],
                  type,
                  teacherList: [
                    {
                      id: selectedTeacher,
                    },
                  ],
                })
                  .then((e) => {
                    setOpen(false);
                    return upd();
                  })
                  .then(() => {
                    openNotification(
                      "success",
                      "Курс успешно создан!",
                      "Новый курс был успешно создан"
                    );
                  });
              }}
            >
              Создать
            </Button>
          </Spin>
        </Drawer>
      </Row>
    </div>
  );
};

const TeacherMainPage = WithLayout(TeacherMainPageTemp);
export default TeacherMainPage;
