import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Progress, Row, Space } from "antd";
import React from "react";

const CurrentCourse = (props) => {
  const { small = false, click, name, teacher, timeout, delayed = false, isGroup = false, navigate } = props;
  return (
    <Card>
      <Row gutter={[16, 8]}>
        <Col span={16}>
          <Space
            style={{ width: "100%", textAlign: "left" }}
            direction="vertical"
          >
            {!small && <span style={{ fontSize: '14px', color: "#757575" }}>Курс</span>}
            <span style={{ fontSize: small ? '16px' : "24px", lineHeight: '28px', fontWeight: "bold" }}>
              {name ?? 'Gruppenunterricht Deutsch A1 13:00 – 13:40'}
            </span>
            {isGroup ? (
              <span style={{ fontSize: "16px", color: "#757575" }}>
                В группе{" "}
                <span style={{ fontSize: "16px", color: "black" }}>
                  12 людей
                </span>
              </span>
            ) : (
              <span style={{ fontSize: "16px", color: "#757575" }}>
                Индивидуальные занятия
              </span>
            )}

            {!small && <span style={{ fontSize: "14px", color: "#757575" }}>
              {delayed && (
                <span style={{ color: "red" }}>Урок был перенесен!{" "}</span>
              )}
              Время
            </span>}
            {!small && <span style={{ fontSize: "16px", fontWeight: "bold" }}>{timeout ?? '13:00 – 13:40 (Пн, Вт, Пт)'}</span>
            }
          </Space>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Space style={{ width: "100%" }} direction="vertical">
            <span style={{ fontSize: "14px", color: "#757575" }}>Учитель</span>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {teacher ?? 'Павел Козлов'}
            </span>
          </Space>
        </Col>
      </Row>
      {!small && <Button
        type="primary"
        danger
        size="large"
        style={{ position: "absolute", right: "24px", bottom: "24px" }}
      >
        Удалить из группы
      </Button>}
    </Card>
  );
};

export default CurrentCourse;
