import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Progress, Row, Space } from "antd";
import React, { useContext, useMemo } from "react";
import { deleteCourse } from "../../../../apis";
import context from "../../../../context";
import ls from "localstorage-slim";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

const CurrentCourse = (props) => {
  const {
    small = false,
    name,
    teacher,
    id,
    delayed = false,
    isGroup = false,
    topics = [],
    type,
    window,
    upd,
    extra,
    total,
    remaining,
    totalHoursCount,
    remainingHoursCount,
  } = props;
  const { profileData } = useContext(context);
  const filteredTopics = useMemo(
    () =>
      topics
        ?.filter((a) => a.completed === false || a.completed === null)
        ?.sort((a, b) => new Date(a.startAt) - new Date(b.startAt)),
    [topics]
  );

  const smallWindow = window.width < 1000;
  const topicLastTitle = filteredTopics?.[filteredTopics?.length - 1];
  const canDelete = useMemo(
    () =>
      profileData?.role === "headteacher" ||
      ls?.get("profile")?.role === "headteacher" ||
      profileData?.role === "supervisor" ||
      ls?.get("profile")?.role === "supervisor",
    [profileData]
  );

  const navigate = useNavigate();

  return (
    <Card
      extra={
        remainingHoursCount > 0 || profileData?.role !== "student" ? extra : []
      }
      title={
        <Space
          style={{ width: "100%", textAlign: "left" }}
          direction="vertical"
        >
          {!small && (
            <span
              style={{
                fontSize: "14px",
                lineHeight: "16px",
                color: "#757575",
              }}
            >
              Курс
            </span>
          )}
          <span
            style={{
              fontSize: small ? "16px" : "24px",
              lineHeight: small ? "18px" : "26px",
              fontWeight: "bold",
            }}
          >
            {name}
            {canDelete && (
              <Button
                danger
                type="text"
                onClick={() => {
                  deleteCourse(id).then(() => upd());
                }}
              >
                <DeleteOutlined />
              </Button>
            )}
          </span>
        </Space>
      }
      className="currentcourse"
      bodyStyle={{
        paddingTop: "4px",
        paddingBottom: "16px",
      }}
    >
      <Row gutter={[16, 8]}>
        <Col span={16}>
          <Space
            style={{ width: "100%", textAlign: "left" }}
            direction="vertical"
          >
            {isGroup ? (
              <span
                style={{
                  fontSize: "16px",
                  lineHeight: "18px",
                  color: "#757575",
                }}
              >
                {`В группе: `}
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    color: "black",
                  }}
                >
                  {` 12 людей`}
                </span>
              </span>
            ) : (
              <span
                style={{
                  fontSize: "16px",
                  lineHeight: "18px",
                  color: "#757575",
                  width: "100%",
                }}
              >
                {type === "group"
                  ? "Групповой курс"
                  : type !== "pair"
                  ? "Индвидуальный курс"
                  : "Парный курс"}
              </span>
            )}
            {!small && (
              <span
                style={{
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#757575",
                }}
              >
                Следующая тема
              </span>
            )}
            {!small && (
              <span
                style={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: "bold",
                }}
              >
                {topicLastTitle?.title ?? "Уроков нет"}
              </span>
            )}
            {totalHoursCount && remainingHoursCount > 0 ? (
              <span
                // style={{ width: smallWindow ? "100%" : "50%" }}
                style={{ width: "100%" }}
                className="current-course"
              >
                <Progress
                  percent={+((total / remaining) * 100).toFixed(0)}
                  format={(f) => (
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        paddingLeft: "10px",
                      }}
                    >
                      {`${remainingHoursCount}/${totalHoursCount}`}
                    </span>
                  )}
                  style={{
                    width: "90%",
                  }}
                  showInfo
                />
              </span>
            ) : (
              profileData?.role === "student" && (
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                  }}
                >
                  У вас нет часов по тарифу
                </span>
              )
            )}
          </Space>
        </Col>
        <Col span={8} style={{ textAlign: smallWindow ? "right" : "left" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              gap: "4px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                backgroundColor: "#F6F6FA",
                padding: "15px",
                justifyContent: "end",
                borderRadius: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    color: "#757575",
                    textAlign: "end",
                  }}
                >
                  Учитель
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/teacher/${teacher.id}`);
                  }}
                >
                  {teacher?.name ?? "Не найден"}
                </span>
              </div>
              <Avatar
                size={38}
                style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
                icon={<UserOutlined style={{ fontSize: "19px" }} />}
              />
            </div>
            {!small && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  backgroundColor: "#F6F6FA",
                  padding: "15px",
                  justifyContent: "end",
                  borderRadius: "10px",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "14px", color: "#757575" }}>
                  {delayed && (
                    <span style={{ color: "red" }}>Урок был перенесен! </span>
                  )}
                  Следующий урок
                </span>

                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {topicLastTitle?.startAt
                    ? dayjs(topicLastTitle?.startAt)
                        .tz("Asia/Almaty")
                        .format("DD/MM/YYYY HH:mm")
                    : "Уроков нет"}
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CurrentCourse;
