import { Row, Col, Card, Progress, Button } from "antd";
import React from "react";
import logo from "../../../../media/icons/red-back.svg";

const HomeworkStatus = (props) => {
  const { name, buttonName, courses, onClick, load, svg: SvgLogo } = props;

  return (
    <Row
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        padding: "10px",
        backgroundColor: "white",
        display: "flex",
      }}
    >
      <Col span={12} style={{ zIndex: "999" }}>
        <Row
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
          }}
        >
          <Col
            span={24}
            style={{
              paddingLeft: "4%",
              paddingTop: "6%",
              paddingBottom: "6%",
              padding: "20px",
              display: "flex",
              gap: "32px",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                lineHeight: "18px",
                fontWeight: "bold",
              }}
            >
              {name ?? "У вас есть 1 не выполненное домашние задания"}
            </span>
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              padding: "20px",
              paddingTop: "15px",
              justifyContent: "left",
            }}
          >
            <Button
              {...(!load
                ? {}
                : {
                    loading: !courses?.length,
                  })}
              onClick={onClick}
              type="primary"
            >
              {buttonName ?? "Смотреть задания"}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <SvgLogo />
      </Col>
    </Row>
  );
};

export default HomeworkStatus;
