import React from "react";
import { Col, Card, Row, Input, Button, Space } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import PhoneInput from "antd-phone-input";
import { useNavigate } from "react-router";
import { getStudents, login, registerStudent } from "../../../../../apis";
import context from "../../../../../context";
import ls from "localstorage-slim";
const RegistrationCard = (props) => {
  const { steps, setSteps, setLogin, setLoading } = props;

  const navigate = useNavigate();

  const [name, setName] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState({
    areaCode: null,
    countryCode: 7,
    isoCode: "kz",
    phoneNumber: null,
    valid: false,
    val: "",
  });

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [regButt, setRegButt] = React.useState(false);
  const { profileData, setProfileData } = React.useContext(context);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  React.useEffect(() => {
    const cond =
      validateEmail(email) &&
      password.length >= 8 &&
      passwordConfirm.length >= 8 &&
      password === passwordConfirm;
    if (cond && !regButt) {
      setRegButt(true);
    } else if (!cond && regButt) setRegButt(false);
  }, [email, passwordConfirm, password]);

  const nextStep = () => {
    setLoading(true);
    setTimeout(() => {
      setSteps(2);
      setLoading(false);
    }, 250);
  };

  const prevStep = () => {
    setLoading(true);
    setTimeout(() => {
      setSteps(1);
      setLoading(false);
    }, 300);
  };

  return (
    <Card
      headStyle={{ flex: 0 }}
      extra={`${steps}/2`}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
      }}
      title="Регистрация"
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        {steps === 1 && (
          <>
            <Row>
              <Col span={24}>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Имя"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastname}
                  placeholder="Фамилия"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <PhoneInput
                  enableSearch={true}
                  searchPlaceholder="Введите страну..."
                  preferredCountries={["kz"]}
                  excludeCountries={["ru"]}
                  value={phone}
                  onChange={(e) => setPhone(e)}
                  placeholder="Номер телефона"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: "5%" }}>
              <Col span={24}>
                <Button
                  type="primary"
                  size="large"
                  disabled={
                    !(phone.valid && name.length >= 1 && lastname.length >= 1)
                  }
                  onClick={() => nextStep()}
                  block
                >
                  Далее
                </Button>
              </Col>
            </Row>
          </>
        )}
        {steps === 2 && (
          <>
            <Row>
              <Col span={24}>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Электронная почта"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input.Password
                  onChange={(e) => setPassword(e.target.value)}
                  value={[password]}
                  placeholder="Пароль"
                  size="large"
                  style={{ width: "100%" }}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input.Password
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  value={[passwordConfirm]}
                  placeholder="Повторите пароль"
                  size="large"
                  style={{ width: "100%" }}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: "5%" }} gutter={[16, 8]}>
              <Col span={8}>
                <Button size="large" block onClick={prevStep}>
                  Назад
                </Button>
              </Col>
              <Col span={16}>
                <Button
                  onClick={() => {
                    setLoading(true);
                    registerStudent(
                      email,
                      password,
                      name,
                      lastname,
                      email,
                      `${phone.countryCode}${phone.areaCode}${phone.phoneNumber}`
                    ).then(() => {
                      login(email.toLowerCase(), password)
                        .then((r) => r.json())
                        .then((r) => {
                          if (!r.token) {
                            throw new Error(r.status);
                          }
                          ls.set("token", r?.token, {
                            ttl: 60 * 60 * 60,
                          });
                          getStudents(r?.token)
                            .then((r) => r.json())
                            .then((r) => {
                              console.log(
                                r,
                                email,
                                "Bermagambet.d4@gmail.com".toLowerCase()
                              );
                              const user = r.find(
                                (element) =>
                                  element.email?.toLowerCase() ===
                                  email?.toLowerCase()
                              );
                              if (!user) {
                                throw new Error(r?.status);
                              }
                              ls.set(
                                "profile",
                                {
                                  ...user,
                                  role: "student",
                                },
                                {
                                  ttl: 60 * 60 * 60,
                                }
                              );
                              setProfileData(user);
                              setTimeout(() => {
                                setLoading(false);
                                navigate("/");
                              }, 300);
                            });
                        })
                        .catch((e) => {
                          setLoading(false);
                          // setError(true);
                        });
                      // setTimeout(() => {
                      //   setLoading(false);
                      //   navigate("/");
                      // }, 300);
                    });
                    // setTimeout(() => {
                    //   navigate('/');
                    //   setLoading(false)
                    // }, 500)
                  }}
                  type="primary"
                  size="large"
                  disabled={!regButt}
                  block
                >
                  Зарегистрироваться
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col span={24}>
            <span>
              Есть аккаунт?
              <Button
                type="link"
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    setLogin({
                      isLogin: true,
                      isRegister: false,
                      isRestore: false,
                      isRegEmployee: false,
                    });
                    setLoading(false);
                  }, 500);
                }}
              >
                Войти
              </Button>
            </span>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default RegistrationCard;
