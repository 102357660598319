import { Row, Col, Card, Avatar } from "antd";
import React from "react";
import { ReactComponent as Logo } from "../../../../media/icons/books.svg";
import { UserOutlined } from "@ant-design/icons";

const TeacherDiv = (props) => {
  const { name = "Павел Козлов", swap = false, litup = false} = props;

  return (
    <div
      style={litup ? {
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#FFFFFF",
        border: '1px solid grey'
      }
      : {
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          padding: "5%",
          paddingLeft: "7%",
          justifyContent: "left",
        }}
      >
        <Avatar
          size={48}
          style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
          icon={<UserOutlined style={{ fontSize: "24px" }} />}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            textAlign: "left",
          }}
        >
         {!swap && <spam style={{ color: "#818199", fontSize: '14px'}}>Ученик</spam>}
         {!swap &&<span style={{textDecoration: 'underline'}}>{name}</span>}
         {swap &&<span style={{textDecoration: 'underline'}}>{name}</span>}
         {swap && <spam style={{ color: "#818199", fontSize: '14px'}}>Ученик</spam>}
        </div>
      </span>
    </div>
  );
};

export default TeacherDiv;
