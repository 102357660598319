import React, { useState } from "react";
import WithLayout from "../../../withLayout";
import { Row, Col, Skeleton, Card } from "antd";
// import Calendar from "react-awesome-calendar";

import AdDiv from "./ad-div";
import SuccessMeter from "./success-meter";
import AttendanceStatus from "./attendance-status";
import CurrentCourse from "./current-course";
import NewsCard from "./news-card";
import { useNavigate } from "react-router-dom";
import context from "../../../context";
import { getCourses, getTimePackages } from "../../../apis";
import ls from "localstorage-slim";
import dayjs from "dayjs";
import HomeworkStatus from "./homework-status";
import { Calendar } from "../../../components";

const CourseBoughtMainPageTemp = (props) => {
  // const { name = "Паша" } = props;
  const { profileData, window } = React.useContext(context);
  const { name } = { ...profileData };
  const navigate = useNavigate();
  const [courses, setCourses] = React.useState();
  const [progress, setProgress] = React.useState();
  const [attendance, setAttendance] = React.useState();
  const [time, setTime] = useState();
  const [events, setEvents] = useState([]);

  React.useEffect(() => {
    console.log(
      "nav",
      ls.get("profile")?.role !== "student" || profileData?.role !== "student"
    );
    if (
      ls.get("profile")?.role !== "student" &&
      profileData?.role !== "student"
    )
      navigate("/teacher-main-page");

    getCourses()
      .then((r) => r.json())
      .then((r) => {
        getTimePackages(profileData?.id ?? ls.get("profile")?.id)
          .then((rr) => rr.json())
          .then((rrr) => {
            const rtemp = r
              .filter((el) => {
                return el?.studentList?.find((y) => y.id === profileData?.id);
              })
              .map((el) => {
                const temp = rrr.find((el2) => el2.courseId === el.id);
                return { teacher: el.teacherList?.[0], ...temp, ...el };
              });
            console.log(rtemp);
            const t = rtemp?.sort(
              (el, el2) =>
                dayjs(el?.durationList?.[el?.durationList?.length - 1]) -
                dayjs(el2?.durationList?.[el2?.durationList?.length - 1])
            );
            console.log(t);
            setTime(t?.[0]?.durationList?.[0]);
            const allDurations = rtemp.map((el) => [...el.courseSubjects]);
            const allDurationsUnion = [];

            allDurations.forEach((el) => {
              el.forEach((innerEl) => {
                allDurationsUnion.push({
                  title: innerEl.title,
                  homework: innerEl.homeworkTitle,
                  time: innerEl.startAt,
                });
              });
            });
            setEvents(
              allDurationsUnion.map((el, id) => {
                const currDate = dayjs(
                  el.time,
                  "YYYY-MM-DDTHH:mm:ss.sss+05:00"
                );
                const newDate = currDate.add(dayjs.duration({ hour: 1 }));
                return {
                  id,
                  color: "#fd3153",
                  from: currDate.format("YYYY-MM-DDTHH:mm:ss+05:00"),
                  to: newDate.format("YYYY-MM-DDTHH:mm:ss+05:00"),
                  title: el.title,
                };
              })
            );
            setCourses(rtemp ?? []);
          });
      });
  }, [profileData]);

  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "48px",
        flexDirection: "column",
      }}
    >
      <Row
        gutter={[16, 8]}
        // style={{ display: "flex", flexDirection: "row", gap: "12px" }}
      >
        <Col span={14} xs={24} md={24} lg={14}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "18px",
                textAlign: "left",
                paddingTop: "15%",
                paddingBottom: "15%",
                paddingLeft: "5%",
              }}
            >
              <span
                style={{
                  fontSize: "38px",
                  lineHeight: "40px",
                  fontWeight: "bold",
                }}
              >{`Привет, ${
                (name && name[0].toUpperCase()) + name?.substring(1)
              }`}</span>
              <span style={{ fontSize: "16px" }}>
                Готов покорять новые вершины вместе с нами?
              </span>
            </div>
          </div>
        </Col>
        <Col span={10} xs={24} md={24} lg={10}>
          <Calendar
            // header={(el) => {
            //   console.log(el);
            //   return <div>{el}</div>;
            // }}
            // header={NewHeader}
            events={events}
          />
        </Col>
        {/* {courses?.length > 0 && (
          <Col span={12} xs={24} md={24} lg={12}>
            <AdDiv
              time={time ? dayjs(time)?.format("DD/MM/YYYY HH:mm") : null}
            />
          </Col>
        )} */}
      </Row>
      <Row
        gutter={[16, 8]}
        // style={{ display: "flex", flexDirection: "row", gap: "12px" }}
      >
        {progress ? (
          <Col span={8} xs={24} md={24} lg={8}>
            <SuccessMeter progress={progress} />
          </Col>
        ) : null}
        {courses?.length > 0 && (
          <Col span={12} xs={24} md={24} lg={12}>
            <AdDiv
              time={time ? dayjs(time)?.format("DD/MM/YYYY HH:mm") : null}
            />
          </Col>
        )}
        {courses?.length > 0 && (
          <Col span={12} xs={24} md={24} lg={12}>
            <HomeworkStatus courses={courses} />
          </Col>
        )}
        {attendance ? (
          <Col span={8} xs={24} md={24} lg={8}>
            <AttendanceStatus attendance={attendance} />
          </Col>
        ) : null}
      </Row>
      <Row
        gutter={[16, 16]}
        // style={{ display: "flex", flexDirection: "row", gap: "12px" }}
      >
        <Col span={24}>
          <span
            style={{
              fontSize: "26px",
              lineHeight: "28px",
              fontWeight: "bold",
              float: "left",
              paddingBottom: "25px",
              paddingTop: "5px",
            }}
          >
            Курсы
          </span>
        </Col>
        {courses?.length > 0 ? (
          courses.map((x) => {
            return (
              <Col span={24}>
                <CurrentCourse
                  // delayed={x.delayed}
                  // isGroup={x.isGroup}
                  {...x}
                  navigate={navigate}
                  name={x?.title}
                  teacher={
                    // x?.teacherList?.[0]
                    //   ? `${x?.teacherList?.[0].name} ${x?.teacherList?.[0].surname}`
                    //   : "Учителя нет"
                    x?.teacherList?.[0] && {
                      name: x.teacherList[0]
                        ? `${x.teacherList[0].name} ${x.teacherList[0].surname}`
                        : "Учитель отсутствует",
                      id: x.teacherList[0].userId,
                    }
                  }
                  topics={x?.courseSubjects}
                  type={x?.type}
                  window={window}
                  remainingHours={+(x?.amount ? x?.amount / 60 : 0).toFixed(0)}
                  totalHours={
                    +(x?.initialAmount ? x?.initialAmount / 60 : 0).toFixed(0)
                  }
                  remainingHoursCount={
                    +(x?.amount ? x?.amount / 60 : 0).toFixed(0)
                  }
                  totalHoursCount={
                    +(x?.initialAmount ? x?.initialAmount / 60 : 0).toFixed(0)
                  }
                  // mins={mins}
                />
              </Col>
            );
          })
        ) : courses ? (
          <Col span={24}>
            <span
              style={{
                fontSize: "24px",
                lineHeight: "26px",
                fontWeight: "bold",
                float: "left",
              }}
            >
              Нет активных курсов
            </span>
          </Col>
        ) : (
          <Col span={24}>
            <Card>
              <Skeleton active rows />
            </Card>
          </Col>
        )}
        {/* {courses
          .filter((x) => {
            console.log(x, profileData);
            return x?.studentList?.find((y) => y.id === profileData?.id);
          })
          .map((x) => {
            return (
              <Col span={24}>
                <CurrentCourse
                  // delayed={x.delayed}
                  // isGroup={x.isGroup}
                  {...x}
                  navigate={navigate}
                  name={x?.title}
                  teacher={`${x.teacher.name} ${x.teacher.surname}`}
                  topics={x?.courseSubjects}
                  type={x?.description}
                  window={window}
                />
              </Col>
            );
          })} */}
        <Col span={24}>
          <span
            style={{
              fontSize: "26px",
              lineHeight: "28px",
              fontWeight: "bold",
              float: "left",
              paddingBottom: "25px",
              paddingTop: "5px",
            }}
          >
            Новости
          </span>
        </Col>
        <Col span={24}>
          <NewsCard />
        </Col>
      </Row>
    </div>
  );
};

const CourseBoughtMainPage = WithLayout(CourseBoughtMainPageTemp);
export default CourseBoughtMainPage;
