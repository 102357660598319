import React from "react";
import { Row, Col, Button, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

const StudentList = (props) => {
  const { students } = props;

  return (
    <Row
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#FFFFFF",
        display: "flex",
        padding: "15px",
      }}
      gutter={[8, 8]}
    >
      <Col
        span={24}
        style={{
          // padding: "3%",
          // paddingLeft: "7%",
          // paddingBottom: "5px",
          padding: "15px",
          paddingTop: "15px",
          paddingLeft: "5px",
          paddingBottom: "5px",
          display: "flex",
          gap: "32px",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Студенты</span>
      </Col>
      {students?.length === 0 && (
        <Col
          span={24}
          style={{
            // padding: "3%",
            // paddingLeft: "7%",
            // paddingBottom: "5px",
            padding: "15px",
            paddingTop: "15px",
            paddingLeft: "5px",
            paddingBottom: "5px",
            display: "flex",
            gap: "32px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <span style={{ fontSize: "16px" }}>Студентов нет</span>
        </Col>
      )}
      {students?.map((el) => (
        <Col
          span={24}
          style={{
            display: "flex",
            padding: "15px",
            paddingTop: "5px",
            paddingLeft: "5px",
            justifyContent: "left",
            paddingBottom: "15px",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
              // padding: "3%",
              // paddingBottom: "1%",
              // paddingRight: "1%",
              // paddingLeft: "5%",
              justifyContent: "left",
            }}
          >
            <Avatar
              size={36}
              style={{
                marginRight: "5px",
                backgroundColor: "#FFD600",
              }}
              icon={<UserOutlined style={{ fontSize: "18px" }} />}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  textDecoration: "underline",
                  fontSize: "16px",
                }}
              >
                {el.name + " " + el.surname}
              </span>
            </div>
          </span>
        </Col>
      ))}
    </Row>
  );
};

export default StudentList;
