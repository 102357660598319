import {
  Row,
  Col,
  Card,
  Button,
  Switch,
  Drawer,
  Space,
  Input,
  Form,
  Result,
  Spin,
  Modal,
  Table,
  DatePicker,
  Popconfirm,
} from "antd";
import React, { useContext } from "react";
import { ReactComponent as Logo } from "../../../../media/icons/books.svg";
import {
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { fileToByteArray } from "..";
import {
  getStudents,
  postCourse,
  postHomework,
  updateSubjectCanceled,
} from "../../../../apis";
import {
  base64ToArrayBuffer,
  download,
  saveByteArray,
} from "../../../../utils/files";
import moment from "moment";
import context from "../../../../context";
import ls from "localstorage-slim";
import dayjs from "dayjs";

const MainAdDiv = (props) => {
  const {
    time = "1 час 42 минуты",
    onClick,
    addHw,
    setFile,
    element,
    course,
    openLesson,
    loading,
    lessonLink,
    main,
    setLoading,
    upd,
    material,
  } = props;

  const ref3 = React.useRef();
  const { window: window2, profileData } = useContext(context);
  const addFile = () => {
    ref2?.current?.click();
  };

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [classUrl, setClassUrl] = React.useState(lessonLink);

  const [status, setStatus] = React.useState(false);
  const [canceled, setCanceled] = React.useState(false);

  const [openModal, setOpenModal] = React.useState(false);

  const [elementDate, setElementDate] = React.useState(element?.startAt);
  const [success, setSuccess] = React.useState(false);
  console.log(element, course);
  React.useEffect(() => {
    setClassUrl(lessonLink);
  }, [lessonLink]);

  const handleChange = (event) => {
    setLoading(true);
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    fileToByteArray(fileUploaded).then((r) => {
      console.log(r.join("."));
      const tempSubj = course?.courseSubjects?.map((el) => {
        if (
          el?.id ===
          (element
            ? element?.id
            : course?.courseSubjects[course?.courseSubjects?.length - 1]?.id)
        ) {
          return {
            ...el,
            files: [
              ...el?.files,
              `${fileUploaded?.name}|${fileUploaded?.type}|${r.join(".")}`,
            ],
          };
        }
        return el;
      });
      postCourse(course?.id, {
        ...course,
        courseSubjects: tempSubj,
      }).then(() => {
        upd();
      });
      // setFiles([
      //   ...files,
      //   {
      //     file: fileUploaded,
      //     byte: r.join("."),
      //   },
      // ]);
    });
  };

  const handleFileDelete = (el) => {
    setLoading(true);
    const tempSubj = course?.courseSubjects?.map((el2) => {
      if (
        el2?.id ===
        (element
          ? element?.id
          : course?.courseSubjects[course?.courseSubjects?.length - 1]?.id)
      ) {
        const files = [...el2?.files];
        var index = files.indexOf(el);
        if (index !== -1) {
          files.splice(index, 1);
        }
        return {
          ...el2,
          files,
        };
      }
      return el2;
    });
    console.log(tempSubj, el);
    postCourse(course?.id, {
      ...course,
      courseSubjects: tempSubj,
    }).then(() => {
      upd();
    });
  };

  const [modalFile, setModalFile] = React.useState();

  const handleFileClick = (el) => {
    setModalFile(el);
    // const d = el.split("|");
    // saveByteArray(d[0] ?? "Материал №", d[2]?.split("."), d[1]);
  };

  const save = () => {
    if (modalFile) {
      const d = modalFile.split("|");
      saveByteArray(d[0] ?? "Материал №", d[2]?.split("."), d[1]);
      setModalFile(false);
    }
  };

  const handleChange2 = (event) => {
    setLoading(true);
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    fileToByteArray(fileUploaded).then((r) => {
      console.log(r.join("."));
      setFiles([
        ...files,
        `${fileUploaded?.name}|${fileUploaded?.type}|${r.join(".")}`,
      ]);
      setLoading(false);

      // postCourse(course?.id, {
      //   ...course,
      //   courseSubjects: tempSubj,
      // }).then(() => {
      //   upd();
      // });
      // // setFiles([
      // //   ...files,
      // //   {
      // //     file: fileUploaded,
      // //     byte: r.join("."),
      // //   },
      // // ]);
    });
  };

  const [hwList, setHwList] = React.useState(element?.homeworkList);

  const handleHwAddition = () => {
    setOpen(true);
  };

  const handleSuccess = () => {
    setLoading(true);
    postCourse(course?.id, {
      ...course,
      courseSubjects: course?.courseSubjects?.map((el) => {
        if (
          el.id ===
          (element
            ? element.id
            : course?.courseSubjects[course?.courseSubjects?.length - 1]?.id)
        ) {
          return {
            ...el,
            homeworkDescription: description,
            homeworkTitle: name,
            homeworkFiles: files,
          };
        }
        return el;
      }),
    })
      // postHomework("", {
      //     ...last
      // })
      .then((r) => {
        console.log(r);
        if (r?.status !== 200) {
          throw new Error(r?.response);
        }
        return r.json();
      })
      .then((r) => {
        console.log(r);

        setLoading(false);
        setSuccess(true);
        upd();
      })
      .catch(() => {
        setOpen(false);
        setLoading(false);
      });
  };

  const handlePostHw = () => {
    setLoading(false);
    setSuccess(false);
    setOpen(false);
  };

  React.useEffect(() => {
    const lastTemp = element
      ? element
      : course?.courseSubjects[course?.courseSubjects?.length - 1];
    setName(lastTemp?.homeworkTitle);
    setDescription(lastTemp?.homeworkDescription);
    setFiles(lastTemp?.homeworkFiles ?? []);
    setStatus(lastTemp?.completed);
    setCanceled(lastTemp?.isCanceled);
  }, [course]);

  const [openHws, setOpenHws] = React.useState(false);
  const [students, setStudents] = React.useState({});

  const checkHws = () => {
    getStudents(ls.get("token"))
      .then((r) => r.json())
      .then((r) => {
        let dictionary = Object.assign(
          {},
          ...r.map((x) => ({ [x.id]: `${x.name} ${x.surname}` }))
        );
        setStudents({
          ...dictionary,
        });
      });
    setOpenHws(true);
  };

  const saveHws = () => {
    console.log(hwList);
    setLoading(true);
    postCourse(course?.id, {
      ...course,
      courseSubjects: course?.courseSubjects?.map((el) => {
        if (
          el.id ===
          (element
            ? element?.id
            : course?.courseSubjects[course?.courseSubjects?.length - 1]?.id)
        ) {
          return {
            ...el,
            homeworkList: hwList,
          };
        }
        return el;
      }),
    }).then(() => {
      setOpenHws(false);
      upd();
    });
  };

  const ref2 = React.useRef();
  const last = element
    ? element
    : course?.courseSubjects[course?.courseSubjects?.length - 1];
  return (
    <div>
      <Row gutter={[16, 8]} style={{ paddingBottom: "10px" }}>
        {material?.map((el, i) => (
          <Col span={8} xs={12} md={12} lg={12}>
            {/* <Popconfirm
                  title={
                    <span style={{ fontSize: "22px" }}>
                      Скачать или удалить?
                    </span>
                  }
                  icon={false}
                  // description="Are you sure to delete this task?"
                  onConfirm={() => handleFileClick(el)}
                  onCancel={() => handleFileDelete(el)}
                  okText={<span style={{ fontSize: "18px" }}>Скачать</span>}
                  cancelText={<span style={{ fontSize: "18px" }}>Удалить</span>}
                > */}
            <Button
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#F6F6FA",
                maxWidth: "100%",
                overflow: "hidden",
                borderRadius: "15px",
              }}
              onClick={() => {
                // var sampleArr = base64ToArrayBuffer(el.split("."));

                handleFileClick(el);
                // const d = el.split("|");
                // saveByteArray(d[0] ?? "Материал №", d[2]?.split("."), d[1]);

                // saveByteArray("Материал №", el.split("."));
                // download(el.split("."));
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "10px",
                  // backgroundColor: "#F6F6FA",
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  justifyContent: "center",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    display: "flex",
                    gap: "4px",
                    flexDirection: "column",
                    justifyContent: "center",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    maxWidth: "100%",
                    wordBreak: "break-all",
                    whiteSpace: "normal",
                  }}
                >
                  <FileTextOutlined />
                  <span style={{ overflow: "hidden" }}>
                    {el?.split("|")[0] ?? "Материал №" + (i + 1)}
                  </span>
                </div>
              </div>
            </Button>
            {/* <Col span={4}>
                <Button
                  danger
                  onClick={() => handleFileDelete(el)}
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F6F6FA",
                    borderRadius: "15px",
                  }}
                  // onClick={() => {
                  //   // var sampleArr = base64ToArrayBuffer(el.split("."));

                  //   handleFileClick(el);
                  //   // const d = el.split("|");
                  //   // saveByteArray(d[0] ?? "Материал №", d[2]?.split("."), d[1]);

                  //   // saveByteArray("Материал №", el.split("."));
                  //   // download(el.split("."));
                  // }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "10px",
                      // backgroundColor: "#F6F6FA",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                    >
                      <DeleteOutlined />
                    </div>
                  </div>
                </Button>
              </Col> */}
            {/* </Popconfirm> */}
          </Col>
        ))}
        <Col span={8} xs={12} md={12} lg={12}>
          <Button
            size="large"
            type="primary"
            onClick={addFile}
            style={{
              width: "100%",
              height: "100%",
              // backgroundColor: "#F6F6FA",
              maxWidth: "100%",
              overflow: "hidden",
              borderRadius: "15px",
            }}
          >
            <PlusOutlined />
          </Button>
        </Col>
      </Row>
      <div
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "15px",
          backgroundColor: "#E8FE9D",
        }}
      >
        <div
          style={{
            padding: "25px",
            display: "flex",
            gap: "16px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <span style={{ fontSize: "16px", color: "#292929" }}>Тема</span>
          <span style={{ fontSize: "20px", fontWeight: "bold" }}>
            {element
              ? element?.title
              : course?.courseSubjects[course?.courseSubjects?.length - 1]
                  ?.title}
          </span>
        </div> */}
          {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              span={12}
              xs={24}
              md={24}
              lg={12}
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {element ? (
                <span style={{ fontSize: "16px", color: "#292929" }}>
                  {element?.homeworkTitle}
                </span>
              ) : (
                last?.homeworkTitle && (
                  <span style={{ fontSize: "16px", color: "#292929" }}>
                    {last?.homeworkTitle}
                  </span>
                )
              )}
              {element ? (
                <span style={{ fontSize: "16px", color: "#292929" }}>
                  {element?.homeworkDescription}
                </span>
              ) : (
                last?.homeworkDescription && (
                  <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {last?.homeworkDescription}
                  </span>
                )
              )}
            </Col>
          </Row>
        </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {/* <Button
            // type="primary"
            size="large"
            onClick={() => {
              if (
                course?.courseSubjects[course?.courseSubjects?.length - 1]
                  ?.homework?.file
              ) {
                setFile({
                  file: {
                    name:
                      "ДЗ №" +
                      course?.courseSubjects[course?.courseSubjects?.length - 1]
                        ?.homework?.id,
                  },
                  byte: course?.courseSubjects[
                    course?.courseSubjects?.length - 1
                  ]?.homework?.file,
                });
              }
              onClick({
                ...open,
                open: !open?.open,
                course:
                  course?.courseSubjects[course?.courseSubjects?.length - 1],
              });
            }}
          >
            Cдать задание
          </Button> */}

            {/* <Button size="large" onClick={addFile}>
              Добавить материал
            </Button> */}
            <spam>Домашнее задание</spam>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col
                  span={12}
                  xs={24}
                  md={24}
                  lg={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {element ? (
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#292929",
                        fontWeight: "bold",
                      }}
                    >
                      {element?.homeworkTitle}
                    </span>
                  ) : (
                    last?.homeworkTitle && (
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#292929",
                          fontWeight: "bold",
                        }}
                      >
                        {last?.homeworkTitle}
                      </span>
                    )
                  )}
                  {element ? (
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#292929",
                      }}
                    >
                      {element?.homeworkDescription}
                    </span>
                  ) : (
                    last?.homeworkDescription && (
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {last?.homeworkDescription}
                      </span>
                    )
                  )}
                </Col>
              </Row>
            </div>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  onClick={handleHwAddition}
                >
                  {`${
                    (element ? element?.homeworkTitle : last?.homeworkTitle)
                      ? "Изменить"
                      : "Добавить"
                  }`}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={checkHws}
                >
                  Проверить
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          width={window2.width < 1000 ? "100%" : "50%"}
        >
          {success ? (
            <Result
              status="success"
              title={`Ваше задание было ${
                !last?.homeworkTitle ? "добавлено" : "обновлено"
              }!`}
              extra={[
                <Button
                  type="primary"
                  key="console"
                  block
                  size="large"
                  onClick={handlePostHw}
                >
                  Закрыть
                </Button>,
              ]}
            />
          ) : (
            <Spin
              spinning={loading}
              style={{
                width: "100%",
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }} size={8}>
                <Form.Item label="Название задания" labelCol={{ span: 24 }}>
                  <Input
                    placeholder="Введите название задания"
                    value={name}
                    onChange={(e) => setName(e?.target?.value)}
                  ></Input>
                </Form.Item>
                <Form.Item label="Описание задания" labelCol={{ span: 24 }}>
                  <Input
                    placeholder="Введите название задания"
                    value={description}
                    onChange={(e) => setDescription(e?.target?.value)}
                  ></Input>
                </Form.Item>
                <Row gutter={[8, 8]}>
                  {files ? (
                    files.map((el, i) => (
                      <Col span={12} xs={24} md={24} lg={12}>
                        <Row gutter={[4, 4]}>
                          <Col span={20}>
                            <Button
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#F6F6FA",
                              }}
                              onClick={() => {
                                // var sampleArr = base64ToArrayBuffer(el.split("."));
                                const d = el.split("|");
                                saveByteArray(
                                  d[0] ?? "ДЗ №",
                                  d[2]?.split("."),
                                  d[1]
                                );
                                // saveByteArray("Домашнее задание №", el.split("."));
                                // download(el.split("."));
                              }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "10px",
                                  // backgroundColor: "#F6F6FA",
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "8px",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: "10px",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                    display: "flex",
                                    gap: "4px",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                  }}
                                >
                                  <FileTextOutlined />
                                  <span style={{ overflow: "hidden" }}>
                                    {el?.split("|")[0] ?? "ДЗ №" + (i + 1)}
                                  </span>
                                </div>
                              </div>
                            </Button>
                          </Col>

                          <Col span={4}>
                            <Button
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#F6F6FA",
                              }}
                              danger
                              onClick={() => {
                                // var sampleArr = base64ToArrayBuffer(el.split("."));
                                // saveByteArray("Домашнее задание №", el.split("."));
                                // download(el.split("."));
                                console.log(files);
                                const tempFiles = files?.find(
                                  (ele) => ele.id === el.id
                                );
                                setFiles([
                                  ...files.filter(
                                    (ele) => ele.id !== tempFiles?.id
                                  ),
                                ]);
                              }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "10px",
                                  // backgroundColor: "#F6F6FA",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                  }}
                                >
                                  <DeleteOutlined />
                                </div>
                              </div>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    ))
                  ) : (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      Файлы не вложены
                    </span>
                  )}
                  {/* {files &&
                  files?.map((el, i) => (
                    <Col span={12} xs={24} md={24} lg={12}>
                      <Button
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#F6F6FA",
                        }}
                        danger
                        onClick={() => {
                          // var sampleArr = base64ToArrayBuffer(el.split("."));
                          // saveByteArray("Домашнее задание №", el.split("."));
                          // download(el.split("."));
                          console.log(files);
                          const tempFiles = files?.find(
                            (ele) => ele.id === el.id
                          );
                          setFiles([
                            ...files.filter((ele) => ele.id !== tempFiles?.id),
                          ]);
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "10px",
                            // backgroundColor: "#F6F6FA",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              paddingTop: "15px",
                              paddingBottom: "15px",
                              display: "flex",
                              gap: "4px",
                              flexDirection: "column",
                              justifyContent: "center",
                              flexWrap: "nowrap",
                              alignItems: "center",
                            }}
                          >
                            <DeleteOutlined />
                            <span>{"Удалить №" + (i + 1)}</span>
                          </div>
                        </div>
                      </Button>
                    </Col>
                  ))} */}
                </Row>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  onClick={() => ref3?.current?.click()}
                >
                  Добавить материал
                </Button>
                <Button
                  type="primary"
                  size="large"
                  style={{ width: "100%" }}
                  onClick={handleSuccess}
                >
                  Обновить домашнее задание
                </Button>
                <input
                  type="file"
                  onChange={handleChange2}
                  ref={ref3}
                  style={{ display: "none" }} // Make the file input element invisible
                />
              </Space>
            </Spin>
          )}
        </Drawer>
        <Drawer
          open={openHws}
          onClose={() => setOpenHws(false)}
          width={window2.width < 1000 ? "100%" : "50%"}
        >
          <Spin spinning={loading}>
            <Table
              locale={{
                emptyText: "Нет данных",
              }}
              columns={[
                {
                  title: "Студент",
                  dataIndex: "student",
                  key: "student",
                },
                {
                  title: "Статус",
                  dataIndex: "status",
                  key: "status",
                  render: (_, { status, id }) => {
                    return (
                      <Button
                        danger={!status}
                        type="primary"
                        onClick={() =>
                          setHwList((prev) =>
                            prev.map((el) => {
                              if (el.id === id)
                                return {
                                  ...el,
                                  completed: !el?.completed,
                                };
                              return el;
                            })
                          )
                        }
                      >
                        {status ? "Принят" : "Не принят"}
                      </Button>
                    );
                  },
                },
                {
                  title: "Файл",
                  dataIndex: "file",
                  key: "file",
                  render: (_, { file2 }) => (
                    <Button
                      onClick={() => {
                        // saveByteArray("ДЗ №", file2.split("."));
                        const d = file2.split("|");
                        saveByteArray(d[0] ?? "ДЗ №", d[2]?.split("."), d[1]);
                      }}
                    >
                      Скачать
                    </Button>
                  ),
                },
              ]}
              dataSource={[
                ...(hwList?.map((el) => {
                  return {
                    id: el?.id,
                    status: el?.completed,
                    file2: el?.file,
                    student: students[el?.studentId],
                  };
                }) ?? []),
              ]}
            />
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Button
                  danger
                  style={{ width: "100%" }}
                  onClick={() => setOpenHws(false)}
                >
                  Отменить
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={saveHws}
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          </Spin>
        </Drawer>
        <Modal
          open={openModal}
          onCancel={() => setOpenModal(false)}
          footer={
            <Button
              disabled={loading}
              type="primary"
              style={{ margin: "15px" }}
              onClick={() => {
                setLoading(true);
                postCourse(course?.id, {
                  ...course,
                  lessonLink: classUrl,
                })
                  // postHomework("", {
                  //     ...last
                  // })
                  .then((r) => {
                    console.log(r);
                    if (r?.status !== 200) {
                      throw new Error(r?.response);
                    }
                    return r.json();
                  })
                  .then((r) => {
                    console.log(r);

                    setLoading(false);
                    upd();
                  })
                  .catch(() => {
                    setLoading(false);
                  });
              }}
            >
              Сохранить
            </Button>
          }
        >
          <Spin spinning={loading}>
            <Form.Item
              label="Ссылка на Zoom"
              labelCol={{ span: 24 }}
              style={{
                padding: "25px",
                paddingBottom: "10px",
              }}
            >
              <Input
                placeholder="Введите ссылку Zoom"
                value={classUrl}
                onChange={(e) => setClassUrl(e?.target?.value)}
              ></Input>
            </Form.Item>
          </Spin>
        </Modal>
        <Modal
          open={modalFile}
          onCancel={() => setModalFile(false)}
          footer={
            <div>
              <Button
                type="primary"
                danger
                style={{ margin: "15px", marginRight: "5px" }}
                onClick={() => {
                  // save();
                }}
              >
                Удалить
              </Button>
              ,
              <Button
                type="primary"
                style={{ margin: "15px", marginLeft: "5px" }}
                onClick={() => {
                  save();
                }}
              >
                Сохранить
              </Button>
            </div>
          }
        >
          <span style={{ padding: "25px", paddingBottom: "0px" }}>
            Выберите действие с файлом:
          </span>
        </Modal>
      </div>
      <Row gutter={[8, 8]} style={{ paddingTop: "10px" }}>
        {/* <Col span={24}>
          <span style={{ fontSize: "14px" }}>Ссылка на урок</span>
        </Col> */}
        <input
          type="file"
          onChange={handleChange}
          ref={ref2}
          style={{ display: "none" }} // Make the file input element invisible
        />
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Switch
            checked={canceled}
            disabled={
              profileData?.role === "teacher" ||
              ls.get("profile")?.role === "teacher"
            }
            onChange={(e) => {
              setLoading(true);
              updateSubjectCanceled(element?.id, e)
                // postCourse(course?.id, {
                //   ...course,
                //   courseSubjects: course?.courseSubjects?.map((el) => {
                //     if (
                //       el.id ===
                //       (element
                //         ? element?.id
                //         : course?.courseSubjects[
                //             course?.courseSubjects?.length - 1
                //           ]?.id)
                //     ) {
                //       return {
                //         ...el,
                //         homeworkDescription: description,
                //         homeworkTitle: name,
                //         homeworkFiles: files,
                //         isCanceled: e,
                //       };
                //     }
                //     return el;
                //   }),
                // })
                .then((r) => {
                  console.log(r);
                  if (r?.status !== 200) {
                    throw new Error(r?.response);
                  }
                  return r.json();
                })
                .then(() => {
                  setLoading(false);
                  upd();
                })
                .catch(() => {
                  setLoading(false);
                })
                .finally(() => setCanceled(e));
            }}
          />
          <span
            style={{
              fontSize: window2.width > 1000 ? "16px" : "14px",
              paddingLeft: "8px",
            }}
          >{`${canceled ? "Отменен" : "Не отменен"}`}</span>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Switch
            checked={status}
            disabled={
              profileData?.role === "teacher" ||
              ls.get("profile")?.role === "teacher"
            }
            onChange={(e) => {
              setLoading(true);
              postCourse(course?.id, {
                ...course,
                courseSubjects: course?.courseSubjects?.map((el) => {
                  if (
                    el.id ===
                    (element
                      ? element?.id
                      : course?.courseSubjects[
                          course?.courseSubjects?.length - 1
                        ]?.id)
                  ) {
                    return {
                      ...el,
                      homeworkDescription: description,
                      homeworkTitle: name,
                      homeworkFiles: files,
                      completed: e,
                    };
                  }
                  return el;
                }),
              })
                .then((r) => {
                  console.log(r);
                  if (r?.status !== 200) {
                    throw new Error(r?.response);
                  }
                  return r.json();
                })
                .then(() => {
                  setLoading(false);
                  upd();
                })
                .catch(() => {
                  setLoading(false);
                })
                .finally(() => setStatus(e));
            }}
          />
          <span
            style={{
              fontSize: window2.width > 1000 ? "16px" : "14px",
              paddingLeft: "8px",
            }}
          >{`${status ? "Пройден" : "Не пройден"}`}</span>
        </Col>
        <Col span={8}>
          <DatePicker
            disabled={
              profileData?.role === "teacher" ||
              ls.get("profile")?.role === "teacher"
            }
            format="DD/MM HH:mm"
            showTime={{
              format: "HH:mm",
            }}
            onChange={(e) => {
              if (profileData?.role === "teacher") return;
              setElementDate(e);
              setLoading(true);
              const courseTemp = course?.courseSubjects?.map((el) => {
                if (el?.id == element?.id)
                  return {
                    ...el,
                    startAt: dayjs(e).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
                  };
                return el;
              });
              postCourse(course?.id, {
                ...course,
                courseSubjects: courseTemp,
              }).then(() => {
                upd();
              });
            }}
            value={dayjs(elementDate)}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default MainAdDiv;
