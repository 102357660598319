import { Button, Card, Carousel, Col, Row, Skeleton, Space } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import context from "../../../../context";
import { getLocalNews, getNews } from "../../../../apis";

const NewsCard = (props) => {
  const {
    title,
    internal,
    description,
    singlePhoto,
    edit,
    editAction,
    onClick,
    photo,
  } = props;
  const navigate = useNavigate();
  const { window } = useContext(context);
  console.log(window.width);
  const [news, setNews] = React.useState();
  const [currIndex, setCurrIndex] = React.useState(1);

  React.useEffect(() => {
    if (!title) {
      internal
        ? getLocalNews()
            .then((r) => r.json())
            .then((r2) => {
              console.log(r2);
              setNews(
                r2?.sort && r2.sort((a, b) => b.id - a.id).filter((e) => e.show)
              );
            })
        : getNews()
            .then((r) => r.json())
            .then((r2) => {
              console.log(r2);
              setNews(
                r2?.sort && r2.sort((a, b) => b.id - a.id).filter((e) => e.show)
              );
            });
    }
  }, []);

  const sttr = description ?? (news && news[currIndex]?.shortDescription);
  // const sttrLng = window?.width < 1000 ? 100 : 350;
  const sttrLng = window?.width / 5;
  return (news && news.length > 0) || title ? (
    <div
      onClick={onClick}
      style={{
        border: "1px solid #f0f0f0",
        borderRadius: "8px",
        background: "#ffffff",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Row>
        <Col span={8} xs={24} md={24} lg={8} xl={8}>
          {!singlePhoto ? (
            <Carousel
              autoplay={!singlePhoto}
              afterChange={(curr) => {
                console.log(curr);
                setCurrIndex(curr);
              }}
              effect="fade"
              style={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: window?.width < 1000 ? "0px" : "8px",
                borderTopRightRadius: window?.width < 1000 ? "8px" : "0px",
                height: "250px",
              }}
            >
              {news?.map((el, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius:
                        window?.width < 1000 ? "0px" : "8px",
                      borderTopRightRadius:
                        window?.width < 1000 ? "8px" : "0px",
                      height: "250px",
                    }}
                  >
                    <img
                      src={
                        el.picture && el.picture !== ""
                          ? el.picture
                          : "https://static.tildacdn.com/tild3732-3131-4332-b334-666534633433/11.jpg"
                      }
                      style={{
                        objectFit: "cover",
                      }}
                      height="250px"
                      width="100%"
                    />
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <div
              style={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: window?.width < 1000 ? "0px" : "8px",
                borderTopRightRadius: window?.width < 1000 ? "8px" : "0px",
                height: "250px",
              }}
            >
              <img
                src={
                  photo && photo !== ""
                    ? photo
                    : "https://static.tildacdn.com/tild3732-3131-4332-b334-666534633433/11.jpg"
                }
                style={{
                  objectFit: "cover",
                }}
                height="250px"
                width="100%"
              />
            </div>
          )}
        </Col>
        <Col
          span={16}
          xs={24}
          md={24}
          lg={16}
          xl={16}
          style={{
            textAlign: "left",
            padding: "15px",
            paddingLeft: "5px",
            paddingTop: "10px",
            height:
              window?.width < 1000 && !edit && singlePhoto ? "150px" : "250px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Space
            direction="vertical"
            style={{
              width: "100%",
              height: "100%",
              padding: "15px",
              paddingBottom: "0px",
              // justifyContent: "center",
              ...(edit || window?.width > 1000
                ? {}
                : { justifyContent: "center" }),
            }}
            size={16}
          >
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              {title ?? news[currIndex]?.title}
            </span>
            <span>
              {sttr?.length > sttrLng
                ? `${sttr?.substring(0, sttrLng)}...`
                : sttr}
            </span>
            {!singlePhoto && (
              <Button
                type="primary"
                shape="round"
                style={{ position: "absolute", bottom: "15px", left: "15px" }}
                onClick={() =>
                  internal
                    ? navigate("/news-internal-list")
                    : navigate("/news-list")
                }
              >
                Узнать подробнее
              </Button>
            )}
            {edit && (
              <Button
                type="primary"
                shape="round"
                style={{ position: "absolute", bottom: "15px", left: "15px" }}
                onClick={editAction}
              >
                Редактировать
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </div>
  ) : news?.length !== 0 ? (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Card>
          <Skeleton active rows />
        </Card>
      </Col>{" "}
    </Row>
  ) : (
    <span
      style={{
        fontSize: "18px",
        lineHeight: "20px",
        float: "left",
      }}
    >
      {`Нет ${internal ? "нововведении" : "новостей"}`}
    </span>
  );
};

export default NewsCard;
