import React from "react";
import { Button, Spin, Input, Space, Card } from "antd";
import context from "../../../../context";
import {
  putHeadTeacherById,
  putStudentById,
  putTeacherById,
} from "../../../../apis";
const ContactInfo = (props) => {
  const {
    dataUpdated,
    student,
    view,
    currUser,
    teacher,
    headteacher,
    supervisor,
  } = props;

  const { window, profileData } = React.useContext(context);
  const { email: email2 = "Загрузка...", phone: phone2 } = { ...profileData };
  console.log(profileData);
  const [email, setEmail] = React.useState(view ? currUser?.email : email2);
  const [phone, setPhone] = React.useState(view ? currUser?.phone : phone2);
  console.log(phone);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setEmail(view ? currUser?.email : email2);
  }, [email2, currUser]);

  React.useEffect(() => {
    setPhone(view ? currUser?.phone : phone2);
  }, [phone2, currUser]);

  const getData = () => {
    currUser?.roleProfile === "student"
      ? putStudentById(currUser?.id, {
          ...(currUser ?? profileData),
          email,
          phone,
        })
          .then((r) => r.json())
          .then((r) => {
            console.log(r);
            setEmail(r?.email);
            setPhone(r?.phone);
            dataUpdated();
          })
      : currUser?.roleProfile === "teacher" || teacher
      ? putTeacherById(currUser?.id, {
          ...(currUser ?? profileData),
          email,
          phone,
        })
          .then((r) => r.json())
          .then((r) => {
            console.log(r);
            setEmail(r?.email);
            setPhone(r?.phone);
            dataUpdated();
          })
      : putHeadTeacherById(currUser?.id, {
          ...(currUser ?? profileData),
          email,
          phone,
        })
          .then((r) => r.json())
          .then((r) => {
            console.log(r);
            setEmail(r?.email);
            setPhone(r?.phone);
            dataUpdated();
          });
  };

  const saveCard = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      // dataUpdated();
      getData();
    }, 300);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return teacher && currUser?.roleProfile === "student" ? (
    <></>
  ) : (
    <Card style={{ width: "100%" }}>
      <Spin spinning={loading}>
        <div
          style={{
            width: "100%",
            alignItems: "start",
            display: "flex",
            flexDirection: window.width <= 1200 ? "column" : "row",
            gap: "16px",
          }}
          size="large"
        >
          <Space
            direction="vertical"
            style={{ width: "100%", alignItems: "start" }}
          >
            <span style={{ fontWeight: "bold", fontSize: "24px" }}>
              Информация для связи
            </span>
            <span style={{ color: "#818199", fontSize: "16px" }}>
              {!view && "Введите актуальную информацию"}
            </span>
          </Space>
          <div
            style={{
              width: "100%",
              alignItems: "start",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Input
              // enableSearch={false}
              // disableDropdown
              // searchPlaceholder="Введите страну..."
              // preferredCountries={["kz"]}
              // excludeCountries={["ru"]}
              placeholder="Номер телефона"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              readOnly={(student || teacher || view) && !headteacher}
              style={{ width: "100%" }}
            />
            <Input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              readOnly={(student || teacher || view) && !headteacher}
              placeholder="Почта"
              style={{ width: "100%" }}
            />
            {(headteacher || supervisor) && (
              <Button
                type="primary"
                size="large"
                onClick={saveCard}
                disabled={!validateEmail(email) && !phone}
              >
                Сохранить
              </Button>
            )}
          </div>
        </div>
      </Spin>
    </Card>
  );
};

export default ContactInfo;
