import {
  Button,
  Input,
  Col,
  Row,
  Space,
  Spin,
  Result,
  DatePicker,
  Modal,
  Radio,
  ConfigProvider,
} from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WithLayout from "../../../withLayout";
import CurrentCourse from "../course-bought-main-page/current-course";
import "moment/locale/ru";
import moment from "moment";
import ru_RU from "antd/lib/locale/ru_RU";
import context from "../../../context";
import ls from "localstorage-slim";
import {
  getCourses,
  getTimePackages,
  pushNotificationApi,
} from "../../../apis";
moment.locale("ru");

const ChangeSchedulePageTemp = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [choose, setChoose] = React.useState(false);
  const { window } = React.useContext(context);
  const { profileData } = React.useContext(context);

  const handleSendRequest = () => {
    setLoading(true);
    pushNotificationApi(
      "Получен запрос на смену расписания!",
      `Студент ${profileData?.name} ${profileData?.surname} (${profileData.phone})
      отправил запрос на смену расписания курса ${currentCourse?.title}. Новое время: ${date}, комментарии: ${comment}`,
      [],
      ["ROLE_HEAD_TEACHER"]
    ).then(() => {
      setSuccess(true);
      setLoading(false);
    });
  };

  const [courses, setCourses] = React.useState([]);
  const [currentCourse, setCurrentCourse] = React.useState();
  const [date, setDate] = React.useState();
  const [comment, setComment] = useState();

  // React.useEffect(() => {
  //   getCourses()
  //     .then((r) => r.json())
  //     .then((r) => {
  //       // const rtemp = r
  //       // .filter((el) => {
  //       //   return el?.studentList?.find((y) => y.id === profileData?.id);
  //       // })
  //       // .map((el) => ({
  //       //   ...el,
  //       //   teacher: el.teacherList?.[0],
  //       // }));

  //       // setCourses(rtemp ?? []);
  //       // setLoading(false);

  //       //   console.log(r);
  //       // getTeachers()
  //       //   .then((rteach) => rteach.json())
  //       //.then((rteach) => {
  //       // const rtemp = r
  //       //   ?.filter((element) => {
  //       //     if (
  //       //       element.teacherList?.find(
  //       //         (el) => el?.userId === parseInt(id)
  //       //       )
  //       //     )
  //       //       return true;
  //       //     if (
  //       //       element.studentList?.find(
  //       //         (el) => el?.userId === parseInt(id)
  //       //       )
  //       //     )
  //       //       return true;
  //       //   })
  //       const rtemp = r
  //         .filter((el) => {
  //           return el?.studentList?.find((y) => y.id === profileData?.id);
  //         })
  //         ?.map((el) => {
  //           return {
  //             ...el,
  //             teacher: el.teacherList?.[0],
  //           };
  //         });
  //       getTimePackages(currUser?.id)
  //         .then((rr) => rr.json())
  //         .then((rrr) => {
  //           const tt = (rtemp ?? []).map((el) => {
  //             const temp = rrr.find((el2) => el2.courseId === el.id);
  //             return {
  //               ...temp,
  //               ...el,
  //             };
  //           });
  //           console.log(tt);
  //           setCourses(tt);
  //         });
  //       //});
  //     });
  // }, [currUser]);

  // React.useEffect(() => {
  //   if (
  //     ls.get("profile")?.role !== "student" ||
  //     profileData?.role !== "student"
  //   )
  //     navigate("/teacher-main-page");

  //   getCourses()
  //     .then((r) => r.json())
  //     .then((r) => {
  //       const rtemp = r
  //         .filter((el) => {
  //           return el?.studentList?.find((y) => y.id === profileData?.id);
  //         })
  //         .map((el) => ({
  //           ...el,
  //           teacher: el.teacherList?.[0],
  //         }));

  //       setCourses(rtemp ?? []);
  //       setLoading(false);
  //     });
  // }, [profileData]);
  React.useEffect(() => {
    if (
      ls.get("profile")?.role !== "student" &&
      profileData?.role !== "student"
    )
      navigate("/teacher-main-page");

    getCourses()
      .then((r) => r.json())
      .then((r) => {
        getTimePackages(profileData?.id ?? ls.get("profile")?.id)
          .then((rr) => rr.json())
          .then((rrr) => {
            const rtemp = r
              .filter((el) => {
                return el?.studentList?.find((y) => y.id === profileData?.id);
              })
              .map((el) => {
                const temp = rrr.find((el2) => el2.courseId === el.id);
                return { teacher: el.teacherList?.[0], ...temp, ...el };
              });
            setCourses(rtemp ?? []);
          });
      });
  }, [profileData]);

  const ref = React.useRef();
  const res = (
    <Result
      status="success"
      title={<span ref={ref}>Заявка отправлена успешно!</span>}
      subTitle="Наш менеджер скоро с вами свяжется"
      extra={[
        <Button type="primary" key="back" onClick={() => navigate("/")}>
          Назад
        </Button>,
      ]}
    />
  );
  React.useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [success]);
  const cond = !courses?.length;
  const cond2 = !date && !comment;

  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        gap: "32px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {success && (
        <div
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "25px",
            backgroundColor: "white",
          }}
        >
          {res}
        </div>
      )}
      <div
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "25px",
          backgroundColor: "white",
        }}
      >
        <Spin spinning={loading} style={{ height: "100%", width: "100%" }}>
          <Row gutter={[16, 32]} style={{ padding: "5%" }}>
            <Col
              span={12}
              xs={24}
              md={24}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <span
                style={{
                  fontSize: "28px",
                  lineHeight: "32px",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Изменить расписание
              </span>
              <span style={{ textAlign: "left" }}>
                Введите все данные, чтобы изменить время следующего урока
              </span>
            </Col>
            <Col
              span={12}
              xs={24}
              md={24}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col
                  span={18}
                  xs={24}
                  md={24}
                  lg={18}
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignContent: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "22px",
                      lineHeight: "26px",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    Перенос следующего урока
                  </span>
                </Col>
                <Col span={6} xs={24} md={24} lg={6}>
                  <Button
                    style={{
                      width: "100%",
                    }}
                    size="large"
                    onClick={() => setChoose(true)}
                    disabled={cond}
                  >
                    Выбрать
                  </Button>
                </Col>
              </Row>
              {currentCourse && (
                <CurrentCourse
                  {...currentCourse}
                  navigate={navigate}
                  name={currentCourse?.title}
                  // teacher={`${currentCourse?.teacher.name} ${currentCourse?.teacher.surname}`}
                  topics={currentCourse?.courseSubjects}
                  window={window}
                  mins={currentCourse?.mins}
                  total={
                    +(
                      currentCourse?.initialAmount
                        ? currentCourse?.initialAmount / 60
                        : 0
                    ).toFixed(0)
                  }
                  remaining={
                    +(
                      currentCourse?.amount ? currentCourse?.amount / 60 : 0
                    ).toFixed(0)
                  }
                  remainingHoursCount={
                    +(
                      currentCourse?.amount ? currentCourse?.amount / 60 : 0
                    ).toFixed(0)
                  }
                  totalHoursCount={
                    +(
                      currentCourse?.initialAmount
                        ? currentCourse?.initialAmount / 60
                        : 0
                    ).toFixed(0)
                  }
                  teacher={
                    currentCourse?.teacher && {
                      name: `${currentCourse.teacher.name} ${currentCourse.teacher.surname}`,
                      id: currentCourse.teacher.id,
                    }
                  }
                />
              )}
              <ConfigProvider locale={ru_RU}>
                <DatePicker
                  showTime={{
                    showMinute: false,
                  }}
                  locale={ru_RU}
                  disabled={cond}
                  onChange={(e) => setDate(e?.format("DD/MM/YYYY, HH:mm"))}
                />
              </ConfigProvider>
              <Input.TextArea
                disabled={cond}
                rows={6}
                placeholder="Ваш комментарии"
                onChange={(e) => setComment(e?.target?.value)}
              />
              <Button
                size="large"
                type="primary"
                shape="round"
                disabled={!(currentCourse && date && comment)}
                onClick={() => handleSendRequest()}
              >
                Отправить заявку
              </Button>
            </Col>
          </Row>
        </Spin>
      </div>
      <Modal
        open={choose}
        centered
        width="50%"
        onCancel={() => setChoose(false)}
        closeIcon={false}
        footer={null}
      >
        <Space
          direction="vertical"
          size={8}
          style={{
            padding: "15px",
          }}
        >
          <Radio.Group name="radiogroup" defaultValue={1}>
            {courses.map((x) => {
              return (
                <Space direction="horizontal">
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrentCourse({
                        ...x,
                      });
                      setChoose(false);
                    }}
                  >
                    Выбрать
                  </Button>
                  <CurrentCourse
                    {...x}
                    navigate={navigate}
                    name={x?.title}
                    total={
                      +(x?.initialAmount ? x?.initialAmount / 60 : 0).toFixed(0)
                    }
                    remaining={+(x?.amount ? x?.amount / 60 : 0).toFixed(0)}
                    remainingHoursCount={
                      +(x?.amount ? x?.amount / 60 : 0).toFixed(0)
                    }
                    totalHoursCount={
                      +(x?.initialAmount ? x?.initialAmount / 60 : 0).toFixed(0)
                    }
                    teacher={
                      x?.teacher && {
                        name: `${x.teacher.name} ${x.teacher.surname}`,
                        id: x.teacher.id,
                      }
                    }
                    topics={x?.courseSubjects}
                    window={window}
                  />
                </Space>
              );
            })}
          </Radio.Group>
        </Space>
      </Modal>
    </div>
  );
};

const ChangeSchedulePage = WithLayout(ChangeSchedulePageTemp);
export default ChangeSchedulePage;
