import React from "react";

const Footer = () => {
  return (
    <span
      style={{
        textAlign: "left",
      }}
    >
      © DEUTSCHKZ, 2024
    </span>
  );
};

export default Footer;
