import {Button, Card} from "antd";
import WithLayout from "../../../withLayout";
import {CertificateSvg} from "./certificate-svg";
import {GlassesSvg} from "./glasses-svg";
import NewsCard from "../../course-bought-page/course-bought-main-page/news-card";

import "./style.css"
import {useNavigate} from "react-router";

const MainPageTemp = () => {

    const navigate = useNavigate()

    return (
        <div className="main__body">
            <div className="main__title" style={{ display: "flex", justifyContent: "left"}}>
                Здравствуйте, Анна👋
            </div>
            <div style={{fontSize: "16px", display: "flex", justifyContent: "left"}}>
                 Готовы вносить порядок между учениками и преподавателями?
            </div>
            <div className="main__cards">
                <Card className="main__card">
                    <div style={{display: "flex", justifyContent: "left"}}>
                        <span>Ученики</span>
                    </div>
                    <div style={{display: "flex", justifyContent: "right"}}>
                        <CertificateSvg />
                    </div>
                    <div style={{display: "flex", justifyContent: "left"}}>
                        <Button style={{backgroundColor: 'black', color: 'white', borderRadius: "16px"}} onClick={() => navigate('/moderator-students')} >Смотреть список</Button>
                    </div>
                </Card>
                <Card  className="main__card">
                    <div style={{display: "flex", justifyContent: "left"}}>
                        <span>Мои преподаватели</span>
                    </div>
                    <div style={{display: "flex", justifyContent: "right"}}>
                        <GlassesSvg />
                    </div>
                    <div style={{display: "flex", justifyContent: "left"}}>
                        <Button style={{backgroundColor: 'black', color: 'white', borderRadius: "16px"}} onClick={() => navigate('/moderator-teachers')}>Смотреть список</Button>
                    </div>
                </Card>
            </div>
            <div style={{marginBottom: "10%"}}>
                <NewsCard />
            </div>
        </div>
    )
};

const MainPage = WithLayout(MainPageTemp);
export default MainPage;
