import {useState} from "react";
import {Button, Drawer, Input, Select} from "antd";

const InviteDrawer = () => {
    const [isVisible, setIsVisible] = useState(false)

    const onOpen = () => {
        setIsVisible(true)
    }

    const onClose = () => {
        setIsVisible(false)
    }

    return (
        <div style={{width: "100%"}}>
            <Button className="students__invite-btn" onClick={onOpen}>+ Добавить учителя</Button>
            <Drawer open={isVisible} title="Пригласить учителя" onClose={onClose} width={window.innerWidth < 750 ? "100%" : "30%"}>
                <Input placeholder="Электронная почта"/>
                <div style={{fontWeight: "700", marginTop: "30px"}}>Оплата</div>
                <Select
                   options={[
                    { value: 'jack', label: '₸' },
                    ]}
                 style={{marginTop: '20px'}}
                 placeholder="Валюта зарплаты"
                />
                <div className="teacher__salaries">
                    <Input placeholder="Инд. занятия (60м)"/>
                    <Input placeholder="Парные занятия (60м)"/>
                </div>
                <div style={{marginTop: '20px'}}>
                    <Input placeholder="Групповые занятия (60м)"/>
                </div>
                <div className="teacher__salaries">
                    <Input placeholder="Инд. занятия (90м)"/>
                    <Input placeholder="Парные занятия (90м)"/>
                </div>
                <div style={{marginTop: '20px'}}>
                    <Input placeholder="Групповые занятия (90м)"/>
                </div>
                <Button className="students__invite-btn" style={{marginTop: '20px'}}> Пригласить</Button>
            </Drawer>
        </div>
    )
}

export default InviteDrawer;