import React from "react";
import {
  Button,
  Avatar,
  Spin,
  Input,
  Space,
  Card,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import context from "../../../../context";
import { changePassword } from "../../../../apis";

const PasswordChange = (props) => {
  const { dataUpdated } = props;
  const [passwordOld, setPasswordOld] = React.useState("");

  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [regButt, setRegButt] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const saveCard = () => {
    setLoading(true);
    changePassword(passwordOld, passwordConfirm).then(() => {
      setLoading(false);
    });
  };

  const { window } = React.useContext(context);

  React.useEffect(() => {
    const cond =
      password.length >= 8 &&
      passwordConfirm.length >= 8 &&
      password === passwordConfirm;
    if (cond && !regButt) {
      setRegButt(true);
    } else if (!cond && regButt) setRegButt(false);
  }, [passwordConfirm, password]);

  return (
    <Card style={{ width: "100%" }}>
      <Spin spinning={loading}>
        <div
          style={{
            width: "100%",
            alignItems: "start",
            display: "flex",
            flexDirection: window.width <= 1200 ? "column" : "row",
            gap: "16px",
          }}
          size="large"
        >
          <Space
            direction="vertical"
            style={{ width: "100%", alignItems: "start" }}
          >
            <span style={{ fontWeight: "bold", fontSize: "24px" }}>
              Сменить пароль
            </span>
            <span
              style={{
                color: "#818199",
                fontSize: "16px",
                lineHeight: "18px",
                textAlign: "left",
              }}
            >
              Введите новый пароль и подтвердите его
            </span>
          </Space>
          <div
            style={{
              width: "100%",
              alignItems: "start",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Input.Password
              onChange={(e) => setPasswordOld(e.target.value)}
              value={passwordOld}
              placeholder="Старый пароль"
              size="large"
              style={{ width: "100%" }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <Input.Password
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="Новый пароль"
              size="large"
              style={{ width: "100%" }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <Input.Password
              onChange={(e) => setPasswordConfirm(e.target.value)}
              value={passwordConfirm}
              placeholder="Повторите новый пароль"
              size="large"
              style={{ width: "100%" }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            <Button
              type="primary"
              size="large"
              onClick={saveCard}
              disabled={!regButt}
            >
              Сохранить
            </Button>
          </div>
        </div>
      </Spin>
    </Card>
  );
};

export default PasswordChange;
