import { Row, Col, Card, Avatar, Button, Space, Modal } from "antd";
import React, { useContext, useState } from "react";
import {
  DeleteOutlined,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import context from "../../../context";
import { useNavigate } from "react-router";
import { deleteUser } from "../../../apis";

const StudentComp = (props) => {
  const {
    id,
    userId,
    getStuds,
    name,
    surname,
    editor,
    but,
    handleChatCreate,
    but2,
    inCourses,
  } = props;
  const { window } = React.useContext(context);
  const navigate = useNavigate();
  const { openNotification } = useContext(context);

  const deleteUserTemp = () =>
    deleteUser(userId)
      .then(() => {
        getStuds();
        setModall(false);
      })
      .then(() => {
        openNotification(
          "success",
          "Пользователь был удален!",
          "Данный пользователь был успешно удален, список обновлен"
        );
      })
      .catch(() => {
        openNotification("error", "Произошла ошибка!");
      });
  const [modall, setModall] = useState(false);
  return (
    <Card
      hoverable
      onClick={() =>
        navigate(
          `/${but ? "teacher" : but2 ? "curator" : "student"}/${
            but2 ? id : userId
          }`
        )
      }
      bodyStyle={{ padding: "25px" }}
      style={{ marginTop: "5px", borderRadius: "15px" }}
    >
      <Row gutter={[12, 12]}>
        <Col span={18} xs={24} md={24} lg={18}>
          <Space
            direction="vertical"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: window?.width < 1000 ? "column" : "row",
                alignItems: window?.width < 1000 ? "flex-start" : "center",
                justifyContent: "left",
                gap: "12px",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  // padding: "5%",
                  // paddingBottom: '1%',
                  // paddingLeft: "7%",
                  justifyContent: "left",
                }}
              >
                <Avatar
                  size={36}
                  style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
                  icon={<UserOutlined style={{ fontSize: "18px" }} />}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    textAlign: "left",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                    onClick={() =>
                      navigate(
                        `/${
                          but ? "teacher" : but2 ? "curator" : "student"
                        }/${userId}`
                      )
                    }
                  >
                    {`${name}`}
                    {/* <Button
                    style={{ marginLeft: "15px" }}
                    icon={<MessageOutlined />}
                  >
                    Связаться
                  </Button> */}
                  </span>
                </div>
              </span>
              <Button
                shape="round"
                icon={<MessageOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChatCreate(userId, name, surname);
                }}
              >
                Связаться
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  color: "#818199",
                }}
              >
                {`${inCourses?.length > 0 ? "Состоит в группах" : "Групп нет"}`}
              </span>
              {inCourses?.length > 0 && (
                <span>
                  {inCourses?.map(
                    (el, i) =>
                      `${el?.title}${i === inCourses?.length - 1 ? "" : ", "}`
                  )}{" "}
                </span>
              )}
            </div>
            {/* <span style={{ color: "#818199", fontSize: "14px", float: "left" }}>
              {`Состоит в группах: `}
              <p
                style={{
                  fontWeight: "bold",
                  color: "black",
                  paddingLeft: "5px",
                }}
              >
                {" Группа 1, Группа 2"}
              </p>
            </span> */}
          </Space>
        </Col>
        {editor && (
          <Col
            span={6}
            xs={24}
            md={24}
            lg={6}
            //   style={{
            //     display: "flex",
            //     flexDirection: "column",
            //     justifyContent: "center",
            //   }}
          >
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setModall(true);
              }}
              danger
              shape="round"
              icon={<DeleteOutlined />}
              style={{
                float: window?.width < 1000 ? "left" : "right",
                border: "0px",
                padding: "0px",
                boxShadow: "none",
              }}
            >
              Удалить
            </Button>
          </Col>
        )}
      </Row>
      <Modal
        open={modall}
        onCancel={() => setModall(false)}
        footer={
          <div>
            <Button
              style={{ margin: "15px", marginRight: "5px" }}
              onClick={() => {
                // save();
                setModall(false);
              }}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              danger
              shape="round"
              style={{ margin: "15px", marginLeft: "5px" }}
              onClick={(e) => {
                e.stopPropagation();
                deleteUserTemp();
              }}
            >
              Удалить
            </Button>
          </div>
        }
      >
        <span
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            padding: "25px",
            paddingBottom: "0px",
          }}
        >
          Удалить пользователя?
        </span>
      </Modal>
    </Card>
  );
};

export default StudentComp;
