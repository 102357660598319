import { Row, Col, Card, Button, Space } from "antd";
import React from "react";
// import { ReactComponent as Logo } from "./lib-ad.svg";
import libAd from "../../../../media/icons/addiv-main.svg";

const AdDiv = (props) => {
  const { navigate } = props;
  return (
    <Space
      direction="vertical"
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundImage: `url(${libAd})`,
        backgroundColor: "#F5C09E",
        padding: "15px",
      }}
      size={24}
    >
      <span
        style={{ fontSize: "32px", lineHeight: "32px", textAlign: "center" }}
      >
        Ознакомьтесь с нашей богатой библиотекой
      </span>
      <Button shape="round" type="primary" onClick={() => navigate("/library")}>
        Перейти в библиотеку
      </Button>
    </Space>
  );
};

export default AdDiv;
