import { Row, Col, Card, Button, Space } from "antd";
import React from "react";
import { ReactComponent as Logo } from "./ad-second.svg";

const AdDivTeachers = (props) => {
  const { navigate } = props;
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "white",
      }}
    >
      <Row>
        <Col span={12} xs={24} md={24} lg={12}>
          <Card
            style={{ backgroundColor: "transparent", border: "0" }}
            bodyStyle={{ textAlign: "left" }}
          >
            <Space
              direction="vertical"
              style={{ width: "100%", padding: "5%" }}
              size={32}
            >
              <span style={{ fontSize: "34px", lineHeight: "34px" }}>
                Правильная история начинается с хорошего преподавателя
              </span>
              <span>
                Мы предоставляем всю точную информацию об учителях без прикрас,
                чтобы Вы точно нашли своего учителя.
              </span>
              <Button
                type="primary"
                shape="round"
                onClick={() => navigate("/leave-a-request")}
              >
                Записаться на первый урок
              </Button>
            </Space>
          </Card>
        </Col>
        <Col span={12} xs={24} md={24} lg={12}>
          <Logo style={{ height: "100%" }} />
        </Col>
      </Row>
    </div>
  );
};

export default AdDivTeachers;
