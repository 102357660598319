import { Button, Avatar, Dropdown, Divider, Space, Card } from "antd";
import {
  UserOutlined,
  MenuOutlined,
  ExportOutlined,
  CalendarOutlined,
  BookOutlined,
  ContainerOutlined,
  VideoCameraOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import React from "react";
import context from "../../../../../../context";
import ls from "localstorage-slim";

const getItem = (key) => {
  switch (key) {
    case "2":
      return <UserOutlined style={{ marginRight: "10px" }} />;
    case "3":
      return <ExportOutlined style={{ marginRight: "10px" }} />;
    case "4":
      return <CalendarOutlined style={{ marginRight: "10px" }} />;
    default:
      return null;
  }
};

const Profile = (props) => {
  const {
    // fullname = "Павел Павел Павел",
    // name = "Павел",
    // email = "pasha@gmail.com",
    windowDimensions,
  } = props;

  const { profileData, hasCourses } = React.useContext(context);
  const { name, surname, email = "Загрузка..." } = { ...profileData };
  const fullname = name && surname ? `${name} ${surname}` : "Загрузка...";

  const navigate = useNavigate();

  const itemSmall = [
    ...(ls.get("profile")?.role === "student" || profileData?.role === "student"
      ? hasCourses
        ? [
            {
              key: "11",
              label: "Мои курсы",
              icon: <ContainerOutlined style={{ marginRight: "10px" }} />,
              navigate: () => navigate("/my-courses"),
            },
          ]
        : []
      : [
          {
            key: "11",
            label: "Студенты",
            icon: <ContainerOutlined style={{ marginRight: "10px" }} />,
            navigate: () => navigate("/teacher-students-page"),
          },
        ]),
    ...((ls.get("profile")?.role === "student" ||
      profileData?.role === "student") &&
    !hasCourses
      ? [
          {
            key: "122",
            label: "Учителя",
            icon: <ContainerOutlined style={{ marginRight: "10px" }} />,
            navigate: () => navigate("/teachers"),
          },
        ]
      : []),
    ...(ls.get("profile")?.role === "supervisor" ||
    profileData?.role === "supervisor" ||
    ls.get("profile")?.role === "headteacher" ||
    profileData?.role === "headteacher"
      ? [
          {
            key: "122",
            label: "Учителя",
            icon: <ContainerOutlined style={{ marginRight: "10px" }} />,
            navigate: () => navigate("/teacher-teachers-page"),
          },
        ]
      : []),
    ...(ls.get("profile")?.role === "supervisor" ||
    profileData?.role === "supervisor"
      ? [
          {
            key: "1222",
            label: "Кураторы",
            icon: <ContainerOutlined style={{ marginRight: "10px" }} />,
            navigate: () => navigate("/teacher-curators-page"),
          },
        ]
      : []),
    ...(ls.get("profile")?.role === "teacher" || profileData?.role === "teacher"
      ? [
          {
            key: "1222",
            label: "Домашние задания",
            icon: <ContainerOutlined style={{ marginRight: "10px" }} />,
            navigate: () => navigate("/hws"),
          },
        ]
      : [
          {
            key: "12",
            label: "Библиотека",
            icon: <BookOutlined style={{ marginRight: "10px" }} />,
            navigate: () => navigate("/library"),
          },
        ]),
    ...(!hasCourses &&
    (ls.get("profile")?.role === "student" || profileData?.role === "student")
      ? [
          {
            key: "13",
            label: "Первый взгляд",
            icon: <VideoCameraOutlined style={{ marginRight: "10px" }} />,
            navigate: () => navigate("/first-look"),
          },
        ]
      : []),

    ...(ls.get("profile")?.role === "student" ||
    profileData?.role === "student" ||
    ls.get("profile")?.role === "headteacher" ||
    profileData?.role === "headteacher"
      ? [
          {
            key: "14",
            label: "Новости",
            icon: <NotificationOutlined style={{ marginRight: "10px" }} />,
            navigate: () => navigate("/news-list"),
          },
        ]
      : []),

    ...(ls.get("profile")?.role !== "student" || profileData?.role !== "student"
      ? [
          {
            key: "12222",
            label: "Нововведения",
            icon: <NotificationOutlined style={{ marginRight: "10px" }} />,
            navigate: () => navigate("/news-internal-list"),
          },
        ]
      : []),
  ];

  const items = [
    {
      key: "1",
      label: email,
    },
    ...(windowDimensions.width < 1000 ? itemSmall : []),
    {
      key: "2",
      label: "Мой профиль",
      icon: <UserOutlined style={{ marginRight: "10px" }} />,
      navigate: () => navigate("/profile"),
    },
    ...((ls.get("profile")?.role === "student" ||
      profileData?.role === "student") &&
    hasCourses
      ? [
          {
            key: "4",
            label: "Изменить расписание",
            icon: <CalendarOutlined style={{ marginRight: "10px" }} />,
            navigate: () => navigate("/change-schedule"),
          },
        ]
      : []),
    {
      key: "3",
      label: "Выйти",
      icon: <ExportOutlined style={{ marginRight: "10px" }} />,
      navigate: () => {
        ls.clear();
        navigate("/authorization", {
          state: {
            check: true,
          },
        });
      },
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomLeft"
      dropdownRender={(menu) => {
        console.log(menu);
        return (
          <div style={{ backgroundColor: "white" }}>
            {menu?.props?.items?.map((x) => {
              return (
                <div key={x.key}>
                  {x.key == 1 && (
                    <div style={{ paddingBottom: "5px" }}>
                      <Card
                        size="small"
                        bordered={false}
                        title={
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Avatar
                              style={{
                                marginRight: "5px",
                                backgroundColor: "#FFD600",
                              }}
                              icon={<UserOutlined />}
                            />
                            {fullname}
                          </span>
                        }
                      >
                        {email}
                      </Card>
                    </div>
                  )}
                  {x.key != 1 && (
                    <div>
                      <Button
                        size="large"
                        type="text"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyItems: "start",
                        }}
                        onClick={x.navigate}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {x.icon}
                          {x.label}
                        </span>
                      </Button>
                      <Divider
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      }}
    >
      <Button size="large">
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
            size="small"
            icon={<UserOutlined />}
          />
          {windowDimensions > 1000 && name}
          <MenuOutlined size="small" style={{ marginLeft: "15px" }} />
        </span>
      </Button>
    </Dropdown>
  );
};

export default Profile;
