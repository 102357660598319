import React from "react";
import WithLayout from "../../../withLayout";
import {
  Button,
  Card,
  Col,
  Drawer,
  Empty,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
} from "antd";
import StudentComp from "./students-comp";
import { useNavigate } from "react-router";
import {
  createChatRoom,
  getCurators,
  getRoomById,
  getStudents,
  getTeachers,
  inviteStudent,
  inviteTeacher,
  inviteCurator,
  getCourses,
} from "../../../apis";
import ls from "localstorage-slim";
import context from "../../../context";
import CurrentCourse from "../teacher-main-page/current-course";
const HomeworksTemp = (props) => {
  const navigate = useNavigate();
  const { profileData, window, openNotification, notificationApi } =
    React.useContext(context);

  const [loading, setLoading] = React.useState(false);
  const [students, setStudents] = React.useState();
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [currency, setCurrency] = React.useState(1);

  const [individualPrice, setIndividualPrice] = React.useState(1000);
  const [pairPrice, setPairPrice] = React.useState(1500);
  const [groupPrice, setGroupPrice] = React.useState(2000);

  const [timePackageType, setTimePackageType] = React.useState("group");
  const [timePackageAmount, setTimePackageAmount] = React.useState(0);
  const [courses, setCourses] = React.useState();

  // const { students = [] } = props;
  const getStuds = () => {
    setLoading(true);
    getCourses()
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        getTeachers()
          .then((rteach) => rteach.json())
          .then((rteach) => {
            const rtemp = r
              ?.map((el) => {
                return {
                  ...el,
                  teacher: rteach.find((element) => element?.id === el?.id),
                };
              })
              .filter((x) => {
                console.log(x, profileData);
                if (ls.get("profile")?.role !== "teacher") return x;
                return x?.teacherList?.find((y) => y.id === profileData?.id);
              });

            setCourses(rtemp ?? []);
            setLoading(false);
          });
      })
      .catch(() => {
        notificationApi && openNotification("error", "Произошла ошибка!");
      });
  };
  React.useEffect(() => {
    getStuds();
  }, [props?.teachers, props?.curators]);

  return (
    <Space
      direction="vertical"
      size={24}
      style={{
        width: "100%",
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <Row gutter={[4, 4]}>
        <Col span={24}>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "36px",
              lineHeight: "36px",
              textAlign: "left",
              float: "left",
            }}
          >
            Домашние задания
          </span>
        </Col>
      </Row>
      <Row style={{ width: "100%" }} gutter={[8, 8]}>
        <Col span={12} xs={24} md={18} lg={12}>
          <Input.Search
            placeholder="Поиск"
            size="large"
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </Col>
        {/* <Col span={12} style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
      }}>
        <Button type="primary" size="large">
        Пригласить ученика
        </Button>
      </Col> */}
      </Row>
      <Spin spinning={loading} style={{ minHeight: "200px" }}>
        <Space direction="vertical" style={{ width: "100%" }} size={10}>
          {courses?.length > 0 && !loading ? (
            courses.map((x) => {
              return (
                <Col span={24}>
                  <CurrentCourse
                    {...x}
                    navigate={navigate}
                    name={x?.title}
                    delete={
                      ls.get("profile")?.role !== "teacher"
                        ? () => {}
                        : () => {}
                    }
                    teacher={
                      x?.teacherList?.[0]
                        ? `${x?.teacherList?.[0]?.name} ${x?.teacherList?.[0]?.surname}`
                        : "Учитель отсутствует"
                    }
                    topics={x?.courseSubjects}
                    extra={[
                      <Button
                        type="primary"
                        size="large"
                        style={{
                          margin: "5px",
                        }}
                        onClick={() =>
                          navigate("/teacher-course-page/" + x?.id, {
                            state: {
                              id: x?.id,
                            },
                          })
                        }
                      >
                        Перейти к ДЗ
                      </Button>,
                    ]}
                    window={window}
                  />
                </Col>
              );
            })
          ) : loading ? (
            <>
              <Col span={24}>
                <Card>
                  <Skeleton active rows />
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <Skeleton active rows />
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <Skeleton active rows />
                </Card>
              </Col>
            </>
          ) : (
            <Col span={24}>
              <span
                style={{
                  fontSize: "18px",
                  lineHeight: "20px",
                  float: "left",
                }}
              >
                Нет курсов
              </span>
            </Col>
          )}
        </Space>
      </Spin>
    </Space>
  );
};

const Homeworks = WithLayout(HomeworksTemp);
export default Homeworks;
