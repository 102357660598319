import { Row, Col, Card } from "antd";
import React from "react";
import libAd from "../../../../media/icons/addiv-main.svg";

const AdDiv2 = (props) => (
  <div
    style={{
      height: "100%",
      width: "100%",
      borderRadius: "25px",
      backgroundColor: "#3C3AF5",
      backgroundImage: `url(${libAd})`,
    }}
  >
    <Row style={{ padding: "5%" }}>
      <Col span={24}>
        <span style={{ fontSize: "34px", lineHeight: "38px", color: "white" }}>
          Купите курс - получите 3 учебника бесплатно!
        </span>
      </Col>
    </Row>
  </div>
);

export default AdDiv2;
