import React from "react";
import "./index.css";
import dayjs from "dayjs";
const TextMessage = (props) => {
  const { content, sendAt, align = "right", wasBefore, ref2 } = props;

  const borderRight = {
    borderTopRightRadius: "0px",
  };
  const borderLeft = {
    borderTopLeftRadius: "0px",
  };
  const border = align === "right" ? borderRight : borderLeft;
  const borderSeq = !wasBefore ? {} : { borderBottomRightRadius: "0px" };

  const timeMessage = dayjs(sendAt, "YYYY-MM-DDTHH:mm:ss.sssZ")
    .subtract(5, "hour")
    .format("DD/MM/YYYY, HH:mm");
  console.log(timeMessage, sendAt);
  return (
    <div
      className="text"
      ref={ref2}
      style={{
        display: "flex",
        float: "right",
        alignSelf: align === "right" ? "flex-end" : "flex-start",
        flexDirection: "column",
        gap: "4px",
        padding: "2px",
        maxWidth: "80%",
        textAlign: align,
        flexDirection: "row",
      }}
    >
      {align === "right" && !wasBefore ? (
        <span
          style={{
            fontSize: "12px",
            whiteSpace: "initial",
            color: "grey",
            textAlign: "right",
            alignSelf: align === "right" ? "flex-end" : "flex-start",
          }}
        >
          {timeMessage}
        </span>
      ) : (
        <span
          style={{
            fontSize: "12px",
            whiteSpace: "initial",
            color: "grey",
            textAlign: "right",
            alignSelf: align === "right" ? "flex-end" : "flex-start",
          }}
        ></span>
      )}
      <div
        style={{
          backgroundColor: align === "right" ? "#B40A1B" : "#FFE1E1",
          color: align === "right" ? "white" : "black",
          padding: "10px",
          paddingTop: "5px",
          borderRadius: "15px",
          ...border,
          ...borderSeq,
        }}
      >
        <span
          style={{
            fontSize: "16px",
            whiteSpace: "initial",
            wordBreak: "break-all",
            wordWrap: "break-word",
          }}
        >
          {content}
        </span>
      </div>
      {align === "left" && !wasBefore ? (
        <span
          style={{
            fontSize: "12px",
            whiteSpace: "initial",
            color: "grey",
            textAlign: "right",
            alignSelf: "flex-end",
          }}
        >
          {dayjs(sendAt).format("DD/MM/YYYY, HH:mm")}
        </span>
      ) : (
        <span
          style={{
            fontSize: "12px",
            whiteSpace: "initial",
            color: "grey",
            textAlign: "right",
            alignSelf: align === "right" ? "flex-end" : "flex-start",
          }}
        ></span>
      )}
    </div>
  );
};

export default TextMessage;
