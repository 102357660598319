import React from "react";
import { Layout, Space, LocaleProvider, version, notification } from "antd";
import { layoutStyles } from "./media/styles";
import {
  Header as HeaderLocal,
  Footer as FooterLocal,
  Content as ContentLocal,
} from "./components/layout";
import moment from "moment";

import "./App.css";
import context from "./context";

const { Header, Footer, Content } = Layout;

const Context = React.createContext({
  name: "Default",
});

const WithLayout =
  (Component) =>
  ({ ...props }) => {
    const { isHomePage = false, isProfilePage = false } = props;
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement, val) => {
      api.success({
        message: val,
        placement,
        closeIcon: false,
      });
    };
    moment.locale("ru");

    return (
      <Space
        direction="vertical"
        className={layoutStyles["space"]}
        size={[0, 48]}
      >
        <Layout className={layoutStyles["layoutStyle"]}>
          <Header className={layoutStyles["headerStyle"]}>
            <HeaderLocal isHomePage={isHomePage} />
          </Header>
          <Content className={layoutStyles["contentStyle"]}>
            {contextHolder}
            {/* <LocaleProvider locale={locales.ru}> */}
            <Component {...props} openNotification={openNotification} />
            {/* </LocaleProvider> */}
          </Content>
          <Footer className={layoutStyles["footerStyle"]}>
            <FooterLocal />
          </Footer>
        </Layout>
      </Space>
    );
  };

export default WithLayout;
