import { Avatar, Button, Empty, List, Modal, Space } from "antd";
import React, { useState } from "react";
import styles from "./index.module.css";
import Profile from "./profile";
import { useNavigate } from "react-router";
import {
  BellOutlined,
  DeleteOutlined,
  MessageOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import context from "../../../../../context";
import ls from "localstorage-slim";
import {
  addAttendance,
  deleteNotifications,
  getNotifications,
  getStudentCourseNear,
} from "../../../../../apis";
import dayjs from "dayjs";

const AuthRibbon = (props) => {
  const { windowDimensions } = props;
  const navigate = useNavigate();
  const {
    window: window2,
    profileData,
    hasCourses,
  } = React.useContext(context);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [joinUrl, setJoinUrl] = useState();
  const [newNotif, setNewNotif] = useState(false);

  React.useEffect(() => {
    getStudentCourseNear()
      .then((r) => r.json())
      .then((r) => {
        setJoinUrl(r);
      });
    updNotif();
  }, []);

  const updNotif = () => {
    getNotifications()
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        const notifs = r?.length
          ? r.map((el) => ({
              title: el?.title,
              description: el?.content,
              itemId: el?.id,
              time: el?.sentAt,
              sender: el?.sender,
            }))
          : [];
        setData([
          ...notifs.sort((a, b) => new Date(b.time) - new Date(a.time)),
        ]);
        const ls = localStorage.getItem("notificationLastId");
        console.log(ls);
        if (
          typeof ls === "undefined" ||
          (r?.length && r[r.length - 1]?.id > ls)
        ) {
          setNewNotif(true);
        }
        localStorage.setItem(
          "notificationLastId",
          notifs[notifs.length - 1]?.itemId ?? 0
        );
      });
  };
  const cond =
    ls.get("profile")?.role !== "student" && profileData?.role !== "student";
  return (
    <Space
      direction="horizontal"
      className={styles["wrapper-ribbon"]}
      size={windowDimensions.width < 1000 ? 8 : 16}
    >
      {(hasCourses || cond) && (
        <Space direction="horizontal" size={windowDimensions.width ? 4 : 16}>
          <Button
            onClick={() => navigate("/chat")}
            style={{ padding: "0px" }}
            shape="circle"
            icon={<MessageOutlined />}
          />
          <Button
            onClick={() => {
              setOpen(true);
              setNewNotif(false);
            }}
            style={{ padding: "0px" }}
            className={newNotif && styles["animate-button"]}
            shape="circle"
            icon={<BellOutlined />}
          />
          {/* {cond && (
            <Button
              onClick={() => {
                setOpen(true);
                setNewNotif(false);
              }}
              style={{ padding: "0px" }}
              className={newNotif && styles["animate-button"]}
              shape="circle"
              icon={<BellOutlined />}
            />
          )} */}
        </Space>
      )}
      {/* {windowDimensions > 1000 && (
        <Button type="primary" shape="round" size="large">
          Купить занятия
        </Button>
      )} */}
      {(ls.get("profile")?.role === "student" ||
        profileData?.role === "student") &&
        window2?.width > 1000 && (
          <Button
            type="primary"
            disabled={
              hasCourses
                ? !joinUrl?.lessonLink
                : ls.get("profile")?.role === "teacher" ||
                  profileData?.role === "teacher"
            }
            onClick={() => {
              // onClick={() => {
              //   addAttendance(element?.id).finally(() =>
              //     window.open(joinUrl?.lessonLink)
              //   );
              // }}
              hasCourses
                ? addAttendance(joinUrl?.id).finally(() =>
                    window.open(joinUrl?.lessonLink)
                  )
                : navigate("/leave-a-request");
            }}
            shape="round"
            size="large"
          >
            {`${
              hasCourses ||
              ls.get("profile")?.role === "teacher" ||
              profileData?.role === "teacher"
                ? "Присоединиться"
                : "Купить занятия"
            }`}
          </Button>
        )}
      {(ls.get("profile")?.role === "student" ||
        profileData?.role === "student") &&
        window2?.width <= 1000 && (
          <Button
            type="primary"
            disabled={!joinUrl?.lessonLink}
            onClick={() => {
              addAttendance(joinUrl?.id).finally(() =>
                window.open(joinUrl?.lessonLink)
              );
            }}
            shape="round"
            size="large"
            style={{
              position: "fixed",
              bottom: "35px",
              right: "35px",
            }}
            icon={<PlusCircleOutlined />}
          >
            Присоединиться
          </Button>
        )}
      <Profile windowDimensions={windowDimensions} />
      <Modal
        open={open}
        width={window2?.width > 1000 ? "50%" : "100%"}
        footer={() => null}
        onCancel={() => setOpen(false)}
        closeIcon={false}
      >
        <List
          itemLayout="horizontal"
          dataSource={data}
          style={{
            padding: "10px",
          }}
          locale={{
            emptyText: <Empty description="У вас нет уведомлений" />,
          }}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`}
                  />
                }
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                    }}
                  >
                    <span>
                      {`${
                        item.sender
                          ? `${item.title} от ${item.sender}`
                          : item.title
                      }`}{" "}
                      <text
                        style={{
                          fontSize: "12px",
                          lineHeight: "12px",
                          color: "gray",
                          display: "contents",
                        }}
                      >
                        {dayjs(item.time, "YYYY-MM-DDTHH:mm:ss.ssssss").format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </text>
                    </span>
                    <Button
                      type="text"
                      onClick={() => {
                        deleteNotifications(item?.itemId).then(() =>
                          updNotif()
                        );
                      }}
                      danger
                      size="small"
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                }
                description={item.description}
              />
            </List.Item>
          )}
        />
        {/* <div
          style={{
            padding: "10px",
          }}
        >
          {[
            {
              title: "success",
              message: "Ура!",
              description: "Поздравляем с регистрацией!",
            },
            {
              title: "error",
              message: "Внимание!",
              description: "Сайт в тестовой версии, аккуратно!",
            },
          ].map((element) => {
            return (
              <Alert
                type={element.title}
                showIcon
                description={element.description}
                message={element.message}
                style={{
                  margin: "15px",
                }}
              />
            );
          })}
        </div> */}
      </Modal>
    </Space>
  );
};

export default AuthRibbon;
