import React, { useState } from "react";
import WithLayout from "../../../withLayout";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Input,
  Result,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Table,
} from "antd";
import TopicCard from "./topic-card";
import MainAdDiv from "./main-ad-div";
import TeacherDiv from "./teacher-div";
import NextLessonDiv from "./next-lesson-div";
import RemainingTimeDiv from "./remaining-time-div";
import GroupLessonsDiv from "./group-lessons-div";
import SuccessMeter from "./success-meter";
import AttendanceStatus from "./attendance-status";
import {
  CaretRightOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import context from "../../../context";
import {
  getCourses,
  getStudents,
  getTeachers,
  getTimePackages,
  postCourse,
  postCourse2,
  postHomework,
} from "../../../apis";
import { useLocation, useNavigate, useParams } from "react-router";
import ls from "localstorage-slim";
import { saveByteArray } from "../../../utils/files";
import moment from "moment";
import dayjs from "dayjs";

export function fileToByteArray(file) {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      let fileByteArray = [];
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState == FileReader.DONE) {
          let arrayBuffer = evt.target.result,
            array = new Uint8Array(arrayBuffer);
          for (let byte of array) {
            fileByteArray.push(byte);
          }
        }
        resolve(fileByteArray);
      };
    } catch (e) {
      reject(e);
    }
  });
}

const getCol = (x, del) => {
  return (
    <Col span={8} xs={24} md={24} lg={8}>
      <Button
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#F6F6FA",
        }}
        danger={del}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            // backgroundColor: "#F6F6FA",
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              paddingLeft: "4%",
              paddingTop: "6%",
              paddingBottom: "6%",
              display: "flex",
              gap: "4px",
              flexDirection: "column",
              justifyContent: "center",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <span>{del ? <DeleteOutlined /> : <FileTextOutlined />}</span>
            <span>{x.name}</span>
          </div>
        </div>
      </Button>
    </Col>
  );
};

const CoursePageTemp = (props) => {
  const [files, setFiles] = React.useState([{ name: "Сочинение.docx" }]);
  const navigate = useNavigate();
  const {
    topics = [
      {
        key: "1",
        label: "Fälle und Geschlecht",
        files: files,
      },
    ],
  } = props;

  const [openDrawer, setOpenDrawer] = React.useState({
    open: false,
  });
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const [date, setDate] = React.useState();

  const [success, setSuccess] = React.useState(false);
  const [students, setStudents] = React.useState([]);

  const [buttons, setButtons] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { window, profileData } = React.useContext(context);
  const [course, setCourse] = React.useState();
  const [selectedTeacher, setSelectedTeacher] = React.useState();
  const [title, setTitle] = React.useState("");
  const [type, setType] = React.useState();
  const [teachers, setTeachers] = React.useState([]);
  const [link, setLink] = React.useState("");

  const { id } = useParams();

  const upd = () => {
    setLoading(true);
    getCourses(undefined, false)
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        getTeachers()
          .then((rteach) => rteach.json())
          .then((rteach) => {
            getStudents(ls.get("token"))
              .then((r) => r.json())
              .then((r) => setStudents(r));
            const rtemp = r?.map((el) => {
              return {
                ...el,
                teacher: rteach.find((element) => element?.id === el?.id),
              };
            });
            console.log(rtemp);
            const currCour = rtemp?.find((el) => {
              return el?.id.toString() === id;
            });
            setSelectedTeacher(currCour?.teacherList?.[0]?.id);
            setTitle(currCour?.title);
            setLink(currCour?.lessonLink);
            setType(currCour?.type);
            setCourse(currCour);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      });
  };

  React.useEffect(() => {
    if (ls.get("profile")?.role === "student") navigate("/");

    upd();
  }, []);

  const [file, setFile] = React.useState("");

  const [subjTitle, setSubjTitle] = React.useState("");
  const [subjDesc, setSubjDesc] = React.useState("");

  const addFile = () => {
    ref2?.current?.click();
  };

  const deleteSubject = (elDel) => {
    const newCourse = course?.courseSubjects?.filter(
      (el) => el?.id !== elDel?.id
    );
    postCourse(course?.id, {
      ...course,
      courseSubjects: newCourse,
    }).then(() => upd());
  };

  const handleUpload = (id, body) => {
    setLoading(true);
    postHomework(id, body)
      .then(() => {
        getCourses(undefined, false)
          .then((r) => r.json())
          .then((r) => {
            console.log(r);
            getTeachers()
              .then((rteach) => rteach.json())
              .then((rteach) => {
                const rtemp = r?.map((el) => {
                  return {
                    ...el,
                    teacher: rteach.find((element) => element?.id === el?.id),
                  };
                });
                console.log(rtemp);
                setCourse(
                  rtemp?.find((el) => {
                    return el?.id.toString() === id;
                  })
                );
                setLoading(false);
                setSuccess(true);
              })
              .catch(() => {
                setLoading(false);
              });
          });
      })
      .catch(() => setLoading(false));
  };

  const [titleDrawer, setTitleDrawer] = React.useState(false);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    fileToByteArray(fileUploaded).then((r) => {
      console.log(r.join("."));
      setFile({
        file: fileUploaded,
        byte: r.join("."),
      });
    });
  };
  const ref2 = React.useRef();
  console.log(profileData);
  const addHw = () => {};
  const [selectedUser, setSelectedUser] = React.useState();
  const mainadlistnode = (
    <Space style={{ width: "100%" }} direction="vertical" size={16}>
      <Row gutter={[4, 4]}>
        <Col span={12} xs={24} md={24} lg={24}>
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            onClick={() => setOpen2(true)}
          >
            Добавить тему
          </Button>
        </Col>
        {(ls.get("profile")?.role === "headteacher" ||
          ls.get("profile")?.role === "supervisor") && (
          <Col span={12} xs={24} md={24} lg={24}>
            <Button
              size="large"
              style={{ width: "100%" }}
              onClick={() => {
                setOpen3(true);
                const temp = students?.map(async (el) => {
                  const res = await getTimePackages(el?.id, true).then((res) =>
                    res.json()
                  );
                  return {
                    ...(res ?? []).find(
                      (element) => element.type === course.type
                    ),
                    ...el,
                  };
                });

                Promise.all(temp).then((r) =>
                  setActualUsers([...r].filter((el) => el?.amount > 0))
                );
              }}
            >
              Добавить студента
            </Button>
          </Col>
        )}
      </Row>

      {course?.courseSubjects
        ?.sort((el, el2) => dayjs(el2.startAt) - dayjs(el.startAt))
        ?.map((element) => {
          return (
            // <TopicCard
            //   // topics={[x]}
            //   files={files}
            //   setFile={setFile}
            //   onClick={setOpenDrawer}
            //   open={openDrawer}
            //   window={window}
            //   course={element}
            // />
            <Collapse
              bordered={false}
              ecoursepandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                backgroundColor: "white",
                borderRadius: "25pcourse",
              }}
              items={[
                {
                  label: (
                    <Space style={{ width: "100%" }} direction="vertical">
                      <span
                        style={{
                          tecoursetAlign: "left",
                          color: "#818199",
                        }}
                      >
                        {`Тема: ${element?.title}`}
                        <span
                          style={{
                            color: "#5ED973",
                            marginLeft: "5pcourse",
                          }}
                        >
                          {course?.homework?.completed && `Пройден`}
                        </span>
                      </span>
                      <span style={{ tecoursetAlign: "left" }}>
                        {element?.description}
                      </span>
                    </Space>
                  ),
                  children: (
                    <MainAdDiv
                      onClick={setOpenDrawer}
                      lessonLink={course?.lessonLink}
                      open={openDrawer}
                      window={window}
                      upd={upd}
                      loading={loading}
                      setFile={setFile}
                      setLoading={setLoading}
                      addHw={addHw}
                      course={course}
                      addFile={addFile}
                      element={element}
                      material={element?.files}
                    />
                  ),
                  style: {
                    borderRadius: "25pcourse",
                    border: "none",
                  },
                  extra: (profileData?.role === "headteacher" ||
                    profileData?.role === "supervisor") && (
                    <Button danger onClick={() => deleteSubject(element)}>
                      <DeleteOutlined />
                    </Button>
                  ),
                },
              ]}
            />
          );
        })}
      {/* {t.map((x) => {
return (
<TopicCard
topics={[x]}
files={files}
onClick={(open) => setOpenDrawer(open)}
open={openDrawer}
window={window}
course={course}
/>
);
})} */}
    </Space>
  );
  const mainaddivnode = course?.courseSubjects?.sort(
    (el, el2) => el2.id - el.id
  )[0] ? (
    <MainAdDiv
      main
      onClick={setOpenDrawer}
      lessonLink={course?.lessonLink}
      open={openDrawer}
      window={window}
      upd={upd}
      loading={loading}
      setFile={setFile}
      setLoading={setLoading}
      addHw={addHw}
      course={course}
      element={course?.courseSubjects?.sort((el, el2) => el2.id - el.id)[0]}
      addFile={addFile}
      material={
        course?.courseSubjects?.sort((el, el2) => el2.id - el.id)[0]?.files
      }
    />
  ) : null;

  const [actualUsers, setActualUsers] = useState([]);
  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        gap: window.width < 1000 ? "24px" : "96px",
        flexDirection: "column",
        // alignContent: "flex-start",
        // justifyContent: "center",
      }}
    >
      {!course?.title ? (
        <Spin
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <Spin spinning={loading}>
          <div
            style={{
              display: "flex",
              gap: window.width < 1000 ? "24px" : "96px",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Row>
              <Col span={12}>
                <span
                  style={
                    window.width < 1000
                      ? {
                          fontSize: "36px",
                          fontWeight: "bold",
                          textAlign: "left",
                          lineHeight: "44px",
                          float: "left",
                        }
                      : {
                          fontSize: "48px",
                          fontWeight: "bold",
                          textAlign: "left",
                          lineHeight: "76px",
                          float: "left",
                        }
                  }
                >
                  {course?.title}
                </span>
              </Col>
              <Col span={12}>
                <Space
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    style={{ float: "inline-end" }}
                    size="large"
                    onClick={() => {
                      getTeachers(ls.get("token"))
                        .then((r) => r.json())
                        .then((r) => {
                          setTeachers(r);
                          setTitleDrawer(true);
                        });
                    }}
                  >
                    Изменить
                  </Button>
                  <Button
                    style={{ float: "inline-end" }}
                    size="large"
                    onClick={() => navigate(-1)}
                  >
                    Назад
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={24} xs={24} md={24} lg={24}>
                {mainadlistnode}
                {/* {window.width < 1000 ? mainaddivnode : mainadlistnode} */}
              </Col>
            </Row>
            <Drawer
              open={open2}
              onClose={() => setOpen2(() => false)}
              width={window.width < 1000 ? "100%" : "50%"}
            >
              <Spin spinning={loading}>
                <Form.Item
                  label={"Название темы"}
                  labelCol={{
                    span: 24,
                  }}
                >
                  <Input
                    value={subjTitle}
                    onChange={(e) => setSubjTitle(e?.target?.value)}
                    placeholder="Введите название темы"
                  />
                </Form.Item>
                <Form.Item
                  label={"Описание темы"}
                  labelCol={{
                    span: 24,
                  }}
                >
                  <Input
                    value={subjDesc}
                    onChange={(e) => setSubjDesc(e?.target?.value)}
                    placeholder="Введите описание темы"
                  />
                </Form.Item>
                <Form.Item
                  label={"Время занятия"}
                  labelCol={{
                    span: 24,
                  }}
                >
                  <DatePicker
                    format="DD/MM/YYYY HH:mm"
                    showTime={{
                      format: "HH:mm",
                    }}
                    style={{ width: "100%" }}
                    value={date}
                    onChange={(e) => {
                      console.log(e);
                      setDate(e);
                    }}
                    placeholder="Выберите время урока"
                  />
                </Form.Item>
                <Button
                  type="primary"
                  shape="large"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    setLoading(true);
                    console.log(date);
                    postCourse(course?.id, {
                      ...course,
                      courseSubjects: [
                        ...course?.courseSubjects,
                        {
                          title: subjTitle,
                          description: subjDesc,
                          duration: 60,
                          startAt: date,
                        },
                      ],
                    })
                      .then((r) => {
                        console.log(r);
                        if (r?.status !== 200) {
                          throw new Error(r?.response);
                        }
                        return r.json();
                      })
                      .then((r) => {
                        console.log(r);
                        setOpen2(false);
                        setLoading(false);
                        upd();
                      })
                      .catch(() => {
                        setLoading(false);
                      })
                      .finally(() => {
                        setSubjDesc("");
                        setSubjTitle("");
                        setDate();
                      });
                  }}
                >
                  Создать
                </Button>
              </Spin>
            </Drawer>
            <Drawer
              open={openDrawer?.open}
              onClose={() =>
                setOpenDrawer({
                  ...openDrawer,
                  open: false,
                })
              }
              title={openDrawer?.course?.title}
              width={window.width < 1000 ? "100%" : "50%"}
            >
              <Space direction="vertical" style={{ width: "100%" }} size={24}>
                <Spin spinning={loading}>
                  {!success && (
                    <Space
                      direction="vertical"
                      style={{ width: "100%" }}
                      size={24}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <span style={{ fontSize: "14px", color: "#818199" }}>
                          Описание
                        </span>
                        <span>{openDrawer?.course?.description}</span>
                      </div>
                      {/* <Row gutter={[16, 8]}>{files.map((x) => getCol(x))}</Row> */}
                      {/* <Input.TextArea rows={6} placeholder="Ваш комментарии" /> */}
                      <input
                        type="file"
                        onChange={handleChange}
                        ref={ref2}
                        style={{ display: "none" }} // Make the file input element invisible
                      />
                      {file && (
                        <Row gutter={[16, 8]}>
                          <Col span={8} xs={24} md={24} lg={8}>
                            <Button
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#F6F6FA",
                              }}
                              onClick={() => {
                                console.log(file);
                                // var sampleArr = base64ToArrayBuffer(el.split("."));
                                const d = file.byte?.split("|");
                                saveByteArray(
                                  d[0] ?? "Материал №",
                                  d[2]?.split("."),
                                  d[1]
                                );
                                // saveByteArray(
                                //   "Материал №",
                                //   file.byte.split(".")
                                // );
                                // download(el.split("."));
                              }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "10px",
                                  // backgroundColor: "#F6F6FA",
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "8px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: "4%",
                                    paddingTop: "6%",
                                    paddingBottom: "6%",
                                    display: "flex",
                                    gap: "4px",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  <span>
                                    <FileTextOutlined />
                                  </span>
                                  <span>
                                    {file.byte?.split("|")[0] ??
                                      file?.file?.name}
                                  </span>
                                </div>
                              </div>
                            </Button>
                          </Col>
                        </Row>
                      )}
                      <Row gutter={[16, 8]}>
                        {buttons.map(() => {
                          return getCol(files[0], true);
                        })}
                      </Row>
                      <Button
                        onClick={() => {
                          setLoading(true);
                          handleUpload(openDrawer?.course?.homework?.id, {
                            ...openDrawer?.course?.homework,
                            file: file?.byte,
                          });
                          // setTimeout(() => {
                          //   setSuccess(true);
                          //   setLoading(false);
                          // }, 500);
                        }}
                        type="primary"
                        size="large"
                        style={{ width: "100%" }}
                      >
                        Принять
                      </Button>
                    </Space>
                  )}
                  {success && (
                    <Result
                      status="success"
                      title="Ваше задание было отправлено!"
                      extra={[
                        <Button
                          type="primary"
                          key="console"
                          block
                          size="large"
                          onClick={() => {
                            setOpenDrawer({
                              ...openDrawer,
                              open: false,
                            });
                            setSuccess(false);
                          }}
                        >
                          Закрыть
                        </Button>,
                      ]}
                    />
                  )}
                </Spin>
              </Space>
            </Drawer>
            <Drawer
              open={titleDrawer}
              onClose={() => setTitleDrawer(false)}
              title={"Изменить курс"}
              width={window.width < 1000 ? "100%" : "50%"}
            >
              <Spin spinning={loading}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Form.Item label="Название курса" labelCol={{ span: 24 }}>
                    <Input
                      placeholder="Введите название курса"
                      value={title}
                      onChange={(e) => setTitle(e?.target?.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Ссылка на урок" labelCol={{ span: 24 }}>
                    <Input
                      placeholder="Введите ссылку курса"
                      value={link}
                      onChange={(e) => setLink(e?.target?.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Тип курса" labelCol={{ span: 24 }}>
                    <Select
                      placeholder="Выберите тип курса"
                      disabled={
                        !(
                          profileData?.role === "headteacher" ||
                          profileData?.role === "supervisor"
                        )
                      }
                      onSelect={(e) => {
                        console.log(e);
                        setType(e);
                      }}
                      value={type}
                      notFoundContent={<Empty description="Нет данных" />}
                      options={[
                        {
                          value: "individual",
                          label: "Индивидульное занятие",
                        },
                        {
                          value: "group",
                          label: "Групповое занятие",
                        },
                        {
                          value: "pair",
                          label: "Парное занятие",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item label="Учитель" labelCol={{ span: 24 }}>
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      disabled={
                        profileData?.role !== "headteacher" &&
                        profileData?.role !== "supervisor"
                      }
                      value={selectedTeacher}
                      // onSearch={() => {}}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      optionFilterProp="children"
                      placeholder="Выберите учителя"
                      onSelect={(e) => setSelectedTeacher(e)}
                      notFoundContent={<Empty description="Нет данных" />}
                      options={teachers?.map((e) => ({
                        value: e?.id,
                        label: `${e?.name} ${e?.surname}`,
                      }))}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    disabled={!(selectedTeacher && title && type)}
                    style={{ width: "100%" }}
                    onClick={() => {
                      setLoading(true);
                      postCourse(course?.id, {
                        ...course,
                        title: title,
                        type: type,
                        lessonLink: link,
                        teacherList: [
                          {
                            id: selectedTeacher,
                          },
                        ],
                      }).then((e) => {
                        setTitleDrawer(false);
                        upd();
                      });
                    }}
                  >
                    Изменить
                  </Button>
                </Space>
              </Spin>
            </Drawer>
            <Drawer
              open={open3}
              onClose={() => setOpen3(false)}
              title={"Добавить студента"}
              width={window.width < 1000 ? "100%" : "50%"}
            >
              <Spin spinning={loading}>
                <Table
                  locale={{ emptyText: <Empty description="Нет данных" /> }}
                  columns={[
                    {
                      title: "Студент",
                      dataIndex: "student",
                      key: "student",
                    },
                    // {
                    //   title: "Почта",
                    //   dataIndex: "email",
                    //   key: "email",
                    // },
                    {
                      title: "Действие",
                      dataIndex: "action",
                      key: "action",
                      render: (_, el) => (
                        <Button
                          danger
                          onClick={() => {
                            setLoading(true);
                            postCourse(course?.id, {
                              ...course,
                              studentList: course?.studentList?.filter(
                                (el2) => el2.email !== el.email
                              ),
                            }).then(() => {
                              upd();
                            });
                          }}
                        >
                          Удалить
                        </Button>
                      ),
                    },
                  ]}
                  dataSource={[
                    ...course?.studentList?.map((el) => {
                      return {
                        student: `${el?.name} ${el?.surname}`,
                        email: el?.email,
                      };
                    }),
                  ]}
                />
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    placeholder="Выбрать студента"
                    style={{ width: "100%" }}
                    showSearch
                    value={selectedUser}
                    notFoundContent={<Empty description="Нет данных" />}
                    // onSearch={() => {}}
                    onSelect={(e) => setSelectedUser(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={(actualUsers || [])
                      .filter(
                        (el) =>
                          !course?.studentList?.find((el2) => el2.id === el?.id)
                      )
                      .map((d) => ({
                        value: d.id,
                        label: `${d?.name} ${d.surname}`,
                      }))}
                    disabled={
                      course?.type === "individual"
                        ? course?.studentList?.length === 1
                        : course?.type === "pair"
                        ? course?.studentList?.length === 2
                        : false
                    }
                  />
                  <Button
                    disabled={!selectedUser}
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setLoading(true);
                      postCourse(course?.id, {
                        ...course,
                        studentList: [
                          ...course?.studentList,
                          {
                            id: selectedUser,
                          },
                        ],
                      }).then(() => {
                        setSelectedUser();
                        upd();
                      });
                    }}
                  >
                    Добавить студента
                  </Button>
                </Space>
              </Spin>
            </Drawer>
          </div>
        </Spin>
      )}
    </div>
  );
};

const CoursePageTeacher = WithLayout(CoursePageTemp);
export default CoursePageTeacher;
