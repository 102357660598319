import { Row, Col, Card } from "antd";
import React from "react";
import { ReactComponent as Logo } from "../../../../media/icons/books.svg";

const AdDiv = (props) => (
  <div
    style={{
      height: "100%",
      width: "100%",
      borderRadius: "25px",
      backgroundColor: "#F5C09E",
    }}
  >
    <Row style={{ padding: "5%" }}>
      <Col span={12} xs={24} lg={12} xxl={12}>
        <Card
          title={
            <span
              style={{
                fontSize: "24px",
                lineHeight: "28px",
                whiteSpace: "normal",
                textAlign: props.window.width < 1000 ? "center" : "left",
              }}
            >
              Откройте еще больше возможностей
            </span>
          }
          style={{
            backgroundColor: "transparent",
            border: "0",
          }}
          bodyStyle={{ textAlign: "left" }}
          headStyle={{ fontSize: "34px" }}
        >
          <span
            style={{
              textAlign: props.window.width < 1000 ? "center" : "left",
            }}
          >
            Бесплатная онлайн-библиотека учебных материалов Школы Немецкого
            Языка DeutschKZ
          </span>
        </Card>
      </Col>
      <Col span={12} xs={24} md={24} lg={12} xxl={12}>
        <Logo style={{ width: "100%", height: "100%" }} />
      </Col>
    </Row>
  </div>
);

export default AdDiv;
