import { Row, Col } from "antd";
import React from "react";

const NextLessonDiv = (props) => {
  const { time } = props;

  return (
    <Row
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#FFFFFF",
        display: "flex",
        padding: "15px",
      }}
      gutter={[8, 8]}
    >
      <Col
        span={24}
        style={{
          padding: "15px",
          paddingTop: "15px",
          paddingLeft: "5px",
          paddingBottom: "5px",
          display: "flex",
          gap: "32px",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          Следующее занятие
        </span>
      </Col>
      <Col
        span={24}
        style={{
          padding: "15px",
          paddingTop: "5px",
          paddingLeft: "5px",
          paddingBottom: "15px",
          color: "#292929",
          fontSize: "16px",
          textAlign: "left",
        }}
      >
        {time}
      </Col>
    </Row>
  );
};

export default NextLessonDiv;
