import React, { useMemo } from "react";
import WithLayout from "../../../withLayout";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Input,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import MainAdDiv from "./main-ad-div";
import NextLessonDiv from "./next-lesson-div";
import AttendanceStatus from "./attendance-status";
import {
  CaretRightOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import context from "../../../context";
import {
  getCourses,
  getStudents,
  getSubject,
  getTeachers,
  postCourse,
  postHomework,
} from "../../../apis";
import { useNavigate, useParams } from "react-router";
import ls from "localstorage-slim";
import { saveByteArray } from "../../../utils/files";
import moment from "moment";
import dayjs from "dayjs";
import StudentList from "./student-list";

export function fileToByteArray(file) {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      let fileByteArray = [];
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState == FileReader.DONE) {
          let arrayBuffer = evt.target.result,
            array = new Uint8Array(arrayBuffer);
          for (let byte of array) {
            fileByteArray.push(byte);
          }
        }
        resolve(fileByteArray);
      };
    } catch (e) {
      reject(e);
    }
  });
}

const getCol = (x, del) => {
  return (
    <Col span={8} xs={24} md={24} lg={8}>
      <Button
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#F6F6FA",
        }}
        danger={del}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            // backgroundColor: "#F6F6FA",
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              paddingLeft: "4%",
              paddingTop: "6%",
              paddingBottom: "6%",
              display: "flex",
              gap: "4px",
              flexDirection: "column",
              justifyContent: "center",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <span>{del ? <DeleteOutlined /> : <FileTextOutlined />}</span>
            <span>{x.name}</span>
          </div>
        </div>
      </Button>
    </Col>
  );
};

const CoursePageTemp = () => {
  const [files, setFiles] = React.useState([{ name: "Сочинение.docx" }]);
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = React.useState({
    open: false,
  });
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [attendance, setAttendance] = React.useState({
    open: false,
  });

  const [date, setDate] = React.useState();

  const [success, setSuccess] = React.useState(false);
  const [students, setStudents] = React.useState([]);

  const [buttons, setButtons] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { window } = React.useContext(context);
  const [course, setCourse] = React.useState();
  const { id } = useParams();

  const upd = () => {
    setLoading(true);
    getCourses(undefined, false)
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        getTeachers()
          .then((rteach) => rteach.json())
          .then((rteach) => {
            const rtemp = r?.map((el) => {
              return {
                ...el,
                teacher: rteach.find((element) => element?.id === el?.id),
              };
            });
            console.log(rtemp);
            setCourse(
              rtemp?.find((el) => {
                return el?.id.toString() === id;
              })
            );
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      });
  };

  React.useEffect(() => {
    if (attendance?.open) {
      setAttendance({
        ...attendance,
        course: course?.courseSubjects?.find((el) => el.id === attendance.id),
      });
    }
  }, [course]);

  React.useEffect(() => {
    if (ls.get("profile")?.role === "student") navigate("/");

    upd();
  }, []);

  const [file, setFile] = React.useState("");
  const submitHw = (byteArray) => {};

  const [subjTitle, setSubjTitle] = React.useState("");
  const [subjDesc, setSubjDesc] = React.useState("");

  const addFile = () => {
    ref2?.current?.click();
  };

  const handleUpload = (id, body) => {
    setLoading(true);
    postHomework(id, body)
      .then(() => {
        getCourses(undefined, false)
          .then((r) => r.json())
          .then((r) => {
            console.log(r);
            getTeachers()
              .then((rteach) => rteach.json())
              .then((rteach) => {
                const rtemp = r?.map((el) => {
                  return {
                    ...el,
                    teacher: rteach.find((element) => element?.id === el?.id),
                  };
                });
                console.log(rtemp);
                setCourse(
                  rtemp?.find((el) => {
                    return el?.id.toString() === id;
                  })
                );
                setLoading(false);
                setSuccess(true);
              })
              .catch(() => {
                setLoading(false);
              });
          });
      })
      .catch(() => setLoading(false));
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    fileToByteArray(fileUploaded).then((r) => {
      console.log(r.join("."));
      setFile({
        file: fileUploaded,
        byte: r.join("."),
      });
    });
  };
  const ref2 = React.useRef();

  const addHw = () => {};
  const [selectedUser, setSelectedUser] = React.useState();

  const coursesFuture = useMemo(
    () =>
      course?.courseSubjects
        ?.filter((el) => dayjs(el?.startAt) >= dayjs())
        ?.sort((el, el2) => dayjs(el.startAt) - dayjs(el2.startAt)),
    [course]
  );

  const coursesPrev = useMemo(
    () =>
      course?.courseSubjects
        ?.filter((el) => dayjs(el?.startAt) < dayjs())
        ?.sort((el, el2) => dayjs(el.startAt) - dayjs(el2.startAt)),
    [course]
  );
  const mainadlistnode = (
    <Space style={{ width: "100%" }} direction="vertical" size={16}>
      <Row gutter={[4, 4]}>
        {/* <Col span={12} xs={24} md={24} lg={24}>
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            onClick={() => setOpen2(true)}
          >
            Добавить тему
          </Button>
        </Col> */}
        {/* {ls.get("profile")?.role === "headteacher" && (
          <Col span={24} xs={24} md={24} lg={24}>
            <Button
              size="large"
              type="primary"
              style={{ width: "100%" }}
              onClick={() => {
                getStudents(ls.get("token"))
                  .then((r) => r.json())
                  .then((r) => {
                    setStudents(r);
                    setOpen3(true);
                  });
              }}
            >
              Добавить студента
            </Button>
          </Col>
        )} */}
        <Col span={12} xs={24} md={24} lg={24}>
          <Button
            size="large"
            style={{ width: "100%" }}
            onClick={() => navigate("edit")}
          >
            Редактировать
          </Button>
        </Col>
      </Row>

      <Collapse
        defaultActiveKey={["1"]}
        className="mainers"
        style={{
          backgroundColor: "white",
          border: "none",
        }}
        items={[
          {
            key: "1",
            label: (
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                Будущие уроки
              </span>
            ),
            children: (
              <>
                {coursesFuture?.length >= 1 ? (
                  coursesFuture?.map((element) => {
                    return (
                      <Collapse
                        bordered={false}
                        ecoursepandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "0px",
                          borderTop: "1px solid #d9d9d9",
                        }}
                        items={[
                          {
                            label: (
                              <Space
                                style={{ width: "100%" }}
                                direction="vertical"
                              >
                                <span
                                  style={{
                                    tecoursetAlign: "left",
                                    color: "#818199",
                                  }}
                                >
                                  {`Тема: ${element?.title}`}
                                  <span
                                    style={{
                                      color: "#5ED973",
                                      marginLeft: "5pcourse",
                                    }}
                                  >
                                    {course?.homework?.completed && `Пройден`}
                                  </span>
                                </span>
                                <span style={{ tecoursetAlign: "left" }}>
                                  {element?.description}
                                </span>
                              </Space>
                            ),
                            children: (
                              <MainAdDiv
                                onClick={setOpenDrawer}
                                lessonLink={course?.lessonLink}
                                open={openDrawer}
                                window={window}
                                upd={upd}
                                loading={loading}
                                setFile={setFile}
                                setLoading={setLoading}
                                addHw={addHw}
                                course={course}
                                addFile={addFile}
                                element={element}
                                material={element?.files}
                              />
                            ),
                            style: {
                              borderRadius: "25pcourse",
                              border: "none",
                            },
                          },
                        ]}
                      />
                    );
                  })
                ) : (
                  <span>Уроков нет</span>
                )}
              </>
            ),
          },
        ]}
      />
      <Collapse
        style={{
          backgroundColor: "white",
          border: "none",
        }}
        className="mainers"
        items={[
          {
            key: "2",
            label: (
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                Прошедшие уроки
              </span>
            ),
            children: (
              <>
                {coursesPrev?.length >= 1 ? (
                  coursesPrev.map((element) => {
                    return (
                      <Collapse
                        bordered={false}
                        ecoursepandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        onChange={() => {
                          getSubject(element.id)
                            .then((r) => r.json())
                            .then((r) => {
                              console.log(r);
                            });
                        }}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "0px",
                          borderTop: "1px solid #d9d9d9",
                        }}
                        items={[
                          {
                            label: (
                              <Space
                                style={{ width: "100%" }}
                                direction="vertical"
                              >
                                <span
                                  style={{
                                    tecoursetAlign: "left",
                                    color: "#818199",
                                  }}
                                >
                                  {`Тема: ${element?.title}`}
                                  <span
                                    style={{
                                      color: "#5ED973",
                                      marginLeft: "5pcourse",
                                    }}
                                  >
                                    {course?.homework?.completed && `Пройден`}
                                  </span>
                                </span>
                                <span style={{ tecoursetAlign: "left" }}>
                                  {element?.description}
                                </span>
                              </Space>
                            ),
                            children: (
                              <MainAdDiv
                                onClick={setOpenDrawer}
                                lessonLink={course?.lessonLink}
                                open={openDrawer}
                                window={window}
                                upd={upd}
                                loading={loading}
                                setFile={setFile}
                                setLoading={setLoading}
                                addHw={addHw}
                                course={course}
                                addFile={addFile}
                                element={element}
                                material={element?.files}
                              />
                            ),
                            style: {
                              borderRadius: "25pcourse",
                              border: "none",
                            },
                          },
                        ]}
                      />
                    );
                  })
                ) : (
                  <span>Уроков нет</span>
                )}
              </>
            ),
          },
        ]}
      />
      {/* {t.map((x) => {
return (
<TopicCard
topics={[x]}
files={files}
onClick={(open) => setOpenDrawer(open)}
open={openDrawer}
window={window}
course={course}
/>
);
})} */}
    </Space>
  );
  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        gap: window.width < 1000 ? "24px" : "96px",
        flexDirection: "column",
        // alignContent: "flex-start",
        // justifyContent: "center",
      }}
    >
      {!course?.title ? (
        <Spin
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <Spin spinning={loading}>
          <div
            style={{
              display: "flex",
              gap: window.width < 1000 ? "24px" : "96px",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={
                window.width < 1000
                  ? {
                      fontSize: "36px",
                      fontWeight: "bold",
                      textAlign: "left",
                      lineHeight: "44px",
                    }
                  : {
                      fontSize: "48px",
                      fontWeight: "bold",
                      textAlign: "left",
                      lineHeight: "76px",
                    }
              }
            >
              {course?.title}
            </span>
            <Row gutter={[16, 8]}>
              <Col span={16} xs={24} md={24} lg={16}>
                {mainadlistnode}
                {/* {window.width < 1000 ? mainaddivnode : mainadlistnode} */}
              </Col>
              <Col span={8} xs={24} md={24} lg={8}>
                <Row gutter={[8, 8]}>
                  <Col span={24} xs={12} md={12} lg={24}>
                    <NextLessonDiv
                      time={
                        course?.courseSubjects
                          ?.filter((el) => !el.completed)
                          .sort((el, el2) => el2.id - el.id)[0]?.startAt
                          ? moment(
                              course?.courseSubjects
                                ?.filter((el) => !el.completed)
                                .sort((el, el2) => el2.id - el.id)[0]?.startAt
                            ).format("DD/MM/YYYY, HH:mm")
                          : "Нет урока"
                      }
                    />
                  </Col>
                  <Col span={24} xs={12} md={12} lg={24}>
                    <AttendanceStatus
                      openAttendance={() => {
                        getStudents(ls.get("token"))
                          .then((r) => r.json())
                          .then((r) => {
                            setStudents(r);
                            setAttendance({
                              open: true,
                            });
                          });
                      }}
                    />
                  </Col>
                  <Col span={24} xs={24} md={24} lg={24}>
                    <StudentList students={course?.studentList} />
                  </Col>
                </Row>
                {/* {window.width < 1000 ? mainaddivnode : mainadlistnode} */}
              </Col>
              {/* <Col span={8} xs={24} md={24} lg={8}>
                <Space style={{ width: "100%" }} direction="vertical" size={16}>
                  <TeacherDiv
                    name={`${course?.teacher?.name} ${course?.teacher?.surname}`}
                  />
                  <NextLessonDiv time={course?.duration} />
                  <RemainingTimeDiv />
                  <GroupLessonsDiv />
                  <SuccessMeter />
                  <AttendanceStatus />
                </Space>
              </Col> */}
              {/* <Col span={8} xs={24} md={24} lg={8}>
                {mainadlistnode} */}
              {/* {window.width < 1000 ? mainadlistnode : mainaddivnode} */}
              {/* </Col> */}
            </Row>
            <Drawer
              open={open2}
              onClose={() => setOpen2(() => false)}
              width={window.width < 1000 ? "100%" : "50%"}
            >
              <Spin spinning={loading}>
                <Form.Item
                  label={"Название темы"}
                  labelCol={{
                    span: 24,
                  }}
                >
                  <Input
                    value={subjTitle}
                    onChange={(e) => setSubjTitle(e?.target?.value)}
                    placeholder="Введите название темы"
                  />
                </Form.Item>
                <Form.Item
                  label={"Описание темы"}
                  labelCol={{
                    span: 24,
                  }}
                >
                  <Input
                    value={subjDesc}
                    onChange={(e) => setSubjDesc(e?.target?.value)}
                    placeholder="Введите описание темы"
                  />
                </Form.Item>
                <Form.Item
                  label={"Время занятия"}
                  labelCol={{
                    span: 24,
                  }}
                >
                  <DatePicker
                    format="DD/MM/YYYY HH:mm"
                    showTime={{
                      format: "HH:mm",
                    }}
                    style={{ width: "100%" }}
                    value={date}
                    onChange={(e) => {
                      console.log(e);
                      setDate(e);
                    }}
                    placeholder="Выберите время урока"
                  />
                </Form.Item>
                <Button
                  type="primary"
                  shape="large"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    setLoading(true);
                    console.log(date);
                    postCourse(course?.id, {
                      ...course,
                      courseSubjects: [
                        ...course?.courseSubjects,
                        {
                          title: subjTitle,
                          description: subjDesc,
                          duration: 60,
                          startAt: date,
                        },
                      ],
                    })
                      .then((r) => {
                        console.log(r);
                        if (r?.status !== 200) {
                          throw new Error(r?.response);
                        }
                        return r.json();
                      })
                      .then((r) => {
                        console.log(r);
                        setOpen2(false);
                        setLoading(false);
                        upd();
                      })
                      .catch(() => {
                        setLoading(false);
                      })
                      .finally(() => {
                        setSubjDesc("");
                        setSubjTitle("");
                        setDate();
                      });
                  }}
                >
                  Создать
                </Button>
              </Spin>
            </Drawer>
            <Drawer
              open={openDrawer?.open}
              onClose={() =>
                setOpenDrawer({
                  ...openDrawer,
                  open: false,
                })
              }
              title={openDrawer?.course?.title}
              width={window.width < 1000 ? "100%" : "50%"}
            >
              <Space direction="vertical" style={{ width: "100%" }} size={24}>
                <Spin spinning={loading}>
                  {!success && (
                    <Space
                      direction="vertical"
                      style={{ width: "100%" }}
                      size={24}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <span style={{ fontSize: "14px", color: "#818199" }}>
                          Описание
                        </span>
                        <span>{openDrawer?.course?.description}</span>
                      </div>
                      {/* <Row gutter={[16, 8]}>{files.map((x) => getCol(x))}</Row> */}
                      {/* <Input.TextArea rows={6} placeholder="Ваш комментарии" /> */}
                      <input
                        type="file"
                        onChange={handleChange}
                        ref={ref2}
                        style={{ display: "none" }} // Make the file input element invisible
                      />
                      {file && (
                        <Row gutter={[16, 8]}>
                          <Col span={8} xs={24} md={24} lg={8}>
                            <Button
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#F6F6FA",
                              }}
                              onClick={() => {
                                console.log(file);
                                // var sampleArr = base64ToArrayBuffer(el.split("."));
                                const d = file.byte?.split("|");
                                saveByteArray(
                                  d[0] ?? "Материал №",
                                  d[2]?.split("."),
                                  d[1]
                                );
                                // saveByteArray(
                                //   "Материал №",
                                //   file.byte.split(".")
                                // );
                                // download(el.split("."));
                              }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "10px",
                                  // backgroundColor: "#F6F6FA",
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "8px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: "4%",
                                    paddingTop: "6%",
                                    paddingBottom: "6%",
                                    display: "flex",
                                    gap: "4px",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  <span>
                                    <FileTextOutlined />
                                  </span>
                                  <span>
                                    {file.byte?.split("|")[0] ??
                                      file?.file?.name}
                                  </span>
                                </div>
                              </div>
                            </Button>
                          </Col>
                        </Row>
                      )}
                      <Row gutter={[16, 8]}>
                        {buttons.map(() => {
                          return getCol(files[0], true);
                        })}
                      </Row>
                      <Button
                        onClick={() => {
                          setLoading(true);
                          handleUpload(openDrawer?.course?.homework?.id, {
                            ...openDrawer?.course?.homework,
                            file: file?.byte,
                          });
                          // setTimeout(() => {
                          //   setSuccess(true);
                          //   setLoading(false);
                          // }, 500);
                        }}
                        type="primary"
                        size="large"
                        style={{ width: "100%" }}
                      >
                        Принять
                      </Button>
                    </Space>
                  )}
                  {success && (
                    <Result
                      status="success"
                      title="Ваше задание было отправлено!"
                      extra={[
                        <Button
                          type="primary"
                          key="console"
                          block
                          size="large"
                          onClick={() => {
                            setOpenDrawer({
                              ...openDrawer,
                              open: false,
                            });
                            setSuccess(false);
                          }}
                        >
                          Закрыть
                        </Button>,
                      ]}
                    />
                  )}
                </Spin>
              </Space>
            </Drawer>
            <Drawer
              open={attendance?.open}
              onClose={() =>
                setAttendance({
                  open: false,
                })
              }
              title={"Посещаемость"}
              width={window.width < 1000 ? "100%" : "80%"}
            >
              <Spin spinning={loading}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Space.Compact
                    direction="horizontal"
                    style={{ width: "100%" }}
                  >
                    <Select
                      placeholder="Выбрать урок"
                      style={{ width: "100%" }}
                      showSearch
                      value={attendance?.course?.id}
                      // onSearch={() => {}}
                      onSelect={(e) => {
                        console.log(e, students);
                        setAttendance({
                          ...attendance,
                          course: course?.courseSubjects?.find(
                            (el) => el.id === e
                          ),
                        });
                      }}
                      notFoundContent={<Empty description="Нет данных" />}
                      options={(course?.courseSubjects || []).map((d) => ({
                        value: d.id,
                        label: d.title,
                      }))}
                    />
                    <Button
                      onClick={() =>
                        setAttendance({
                          ...attendance,
                          course: undefined,
                        })
                      }
                    >
                      Показать всех
                    </Button>
                  </Space.Compact>
                  <Table
                    columns={[
                      {
                        title: "Студент",
                        dataIndex: "student",
                        key: "student",
                      },
                      {
                        title: "Почта",
                        dataIndex: "email",
                        key: "email",
                      },
                      {
                        title: "ID",
                        dataIndex: "id",
                        key: "id",
                      },
                      ...(attendance?.course
                        ? [
                            {
                              title: "Посещение",
                              dataIndex: "present",
                              key: "present",
                              render: (_, { present, id }) => {
                                return (
                                  <Select
                                    onSelect={(val) => {
                                      setLoading(true);
                                      const tempSubj =
                                        course?.courseSubjects?.map((el) => {
                                          if (
                                            el.id === attendance?.course?.id
                                          ) {
                                            return {
                                              ...el,
                                              studentList: val
                                                ? [
                                                    ...el?.studentList,
                                                    {
                                                      id,
                                                    },
                                                  ]
                                                : [
                                                    ...el?.studentList?.filter(
                                                      (element) =>
                                                        element?.id !== id
                                                    ),
                                                  ],
                                            };
                                          }
                                          return el;
                                        });
                                      postCourse(course?.id, {
                                        ...course,
                                        courseSubjects: [...tempSubj],
                                      }).then(() => {
                                        upd();
                                      });
                                    }}
                                    defaultValue={present}
                                    options={[
                                      {
                                        label: "Был",
                                        value: true,
                                      },
                                      {
                                        label: "Не был",
                                        value: false,
                                      },
                                    ]}
                                  />
                                );
                              },
                            },
                          ]
                        : [
                            ...course?.courseSubjects?.map((el, i) => ({
                              title: `Урок ${i + 1}`,
                              dataIndex: `${el.id}${i}`,
                              key: `${el.id}${i}`,
                            })),
                          ]),
                    ]}
                    scroll={{
                      x: true,
                    }}
                    locale={{
                      emptyText: <Empty description="Нет данных" />,
                    }}
                    dataSource={
                      attendance?.course?.id
                        ? [
                            ...course?.studentList?.map((el) => {
                              const studsPresent =
                                attendance?.course?.studentList?.map((el) => {
                                  return {
                                    student: `${el?.name} ${el?.surname}`,
                                    email: el?.email,
                                  };
                                }) ?? [];
                              if (
                                studsPresent.find(
                                  (el2) => el2.email === el.email
                                )
                              )
                                return {
                                  student: `${el?.name} ${el?.surname}`,
                                  email: el?.email,
                                  present: true,
                                  id: el?.id,
                                };
                              return {
                                student: `${el?.name} ${el?.surname}`,
                                email: el?.email,
                                present: false,
                                id: el?.id,
                              };
                            }),
                          ]
                        : [
                            ...course?.studentList?.map((el) => {
                              const attrs = {};
                              course?.courseSubjects?.forEach((el3, i) => {
                                attrs[`${el3.id}${i}`] = el3.studentList.find(
                                  (el2) => el2.email === el.email
                                )
                                  ? "Был"
                                  : "Не был";
                              });
                              return {
                                student: `${el?.name} ${el?.surname}`,
                                email: el?.email,
                                id: el?.id,
                                ...attrs,
                              };
                            }),
                          ]
                    }
                  />
                  {/* <Select
                    placeholder="Выбрать студента"
                    style={{ width: "100%" }}
                    showSearch
                    value={selectedUser}
                    // onSearch={() => {}}
                    onSelect={(e) => {
                      console.log(e);
                      setSelectedUser(e);
                    }}
                    notFoundContent={<Empty description="Нет данных" />}
                    options={(students || [])
                      .filter(
                        (el) =>
                          !attendance?.course?.studentList?.find(
                            (el2) => el2.id === el?.id
                          )
                      )
                      .map((d) => ({
                        value: d.id,
                        label: `${d?.name} ${d.surname}`,
                      }))}
                  ></Select>
                  <Button
                    disabled={!(selectedUser && attendance?.course?.id)}
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setLoading(true);
                      const tempSubj = course?.courseSubjects?.map((el) => {
                        if (el.id === attendance?.course?.id) {
                          return {
                            ...el,
                            studentList: [
                              ...el?.studentList,
                              {
                                id: selectedUser,
                              },
                            ],
                          };
                        }
                        return el;
                      });
                      console.log(tempSubj, selectedUser);
                      postCourse(course?.id, {
                        ...course,
                        courseSubjects: [...tempSubj],
                      }).then(() => {
                        setSelectedUser();
                        upd();
                      });
                    }}
                  >
                    Добавить студента
                  </Button> */}
                </Space>
              </Spin>
            </Drawer>
            <Drawer
              open={open3}
              onClose={() => setOpen3(false)}
              title={"Добавить студента"}
              width={window.width < 1000 ? "100%" : "50%"}
            >
              <Spin spinning={loading}>
                <Table
                  columns={[
                    {
                      title: "Студент",
                      dataIndex: "student",
                      key: "student",
                    },
                    // {
                    //   title: "Почта",
                    //   dataIndex: "email",
                    //   key: "email",
                    // },
                    {
                      title: "Действие",
                      dataIndex: "action",
                      key: "action",
                      render: (_, el) => (
                        <Button
                          danger
                          onClick={() => {
                            setLoading(true);
                            postCourse(course?.id, {
                              ...course,
                              studentList: course?.studentList?.filter(
                                (el2) => el2.email !== el.email
                              ),
                            }).then(() => {
                              upd();
                            });
                          }}
                        >
                          Удалить
                        </Button>
                      ),
                    },
                  ]}
                  dataSource={[
                    ...course?.studentList?.map((el) => {
                      return {
                        student: `${el?.name} ${el?.surname}`,
                        email: el?.email,
                      };
                    }),
                  ]}
                />
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    placeholder="Выбрать студента"
                    style={{ width: "100%" }}
                    showSearch
                    value={selectedUser}
                    // onSearch={() => {}}
                    onSelect={(e) => {
                      console.log(e);
                      setSelectedUser(e);
                    }}
                    notFoundContent={<Empty description="Нет данных" />}
                    options={(students || [])
                      .filter(
                        (el) =>
                          !attendance?.course?.studentList?.find(
                            (el2) => el2.id === el?.id
                          )
                      )
                      .map((d) => ({
                        value: d.id,
                        label: `${d?.name} ${d.surname}`,
                      }))}
                  ></Select>
                  <Button
                    disabled={!selectedUser}
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setLoading(true);
                      postCourse(course?.id, {
                        ...course,
                        studentList: [
                          ...course?.studentList,
                          {
                            id: selectedUser,
                          },
                        ],
                      }).then(() => {
                        setSelectedUser();
                        upd();
                      });
                    }}
                  >
                    Добавить студента
                  </Button>
                </Space>
              </Spin>
            </Drawer>
          </div>
        </Spin>
      )}
    </div>
  );
};

const CoursePageTeacher = WithLayout(CoursePageTemp);
export default CoursePageTeacher;
