import { Button, Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import WithLayout from "../../../withLayout";
import UpperAd from "./upper-ad";
import AdDiv from "./ad-div";
import VideosRows from "../first-look-page/videos-rows";
import AdDivSecond from "./ad-div-second";

const HeadPageTemp = (props) => {
  const { isAuth = true } = props;
  const navigate = useNavigate();

  return (
    <div
      direction="vertical"
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        gap: "32px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <UpperAd navigate={navigate} />
      <AdDiv navigate={navigate} />
      <span style={{ fontWeight: "bold", fontSize: "24px", textAlign: "left" }}>
        Первый взгляд на уроки
      </span>
      <VideosRows small={true} navigate={navigate} />
      <AdDivSecond navigate={navigate} />
    </div>
  );
};

const HeadPage = WithLayout(HeadPageTemp);
export default HeadPage;
