import React from "react";
import WithLayout from "../../../withLayout";
import { Button, Card, Col, Divider, Image, Input, Row, Space } from "antd";
import TeacherDiv from "./teacher-div";
import TextMessage from "./text-message";

const ModChatPageTemp = (props) => {
  const [messages, setMessages] = React.useState([]);
  const [input, setInput] = React.useState("");
  const [chosen, setChosen] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      messages.length === 0 &&
        setMessages((prev) => [
          ...prev,
          {
            text: "Entschuldigung, ich habe keine Zeit morgen. Vielleicht am Freitag?",
            time: `${new Date().getHours()}:${
              new Date().getMinutes() < 10
                ? `0${new Date().getMinutes()}`
                : new Date().getMinutes()
            }`,
            align: "left",
          },
        ]);
    }, 1000);
  }, []);

  return (
    <div
      style={{
        paddingLeft: "10%",
        paddingRight: "10%",
        paddingBottom: "5%",
        width: "100%",
        height: "100%",
      }}
    >
      <Row gutter={[16, 8]}>
        <Col xs={24} md={24} lg={12}>
          <Space
            direction="vertical"
            size={16}
            style={{
              width: "100%",
            }}
          >
            <Input.Search placeholder="Поиск" style={{ border: "none" }} />
            <div
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "25px",
              }}
            >
              <Card
                hoverable
                style={{
                  border: "1px solid grey",
                }}
              >
                <TeacherDiv swap />
              </Card>
            </div>
            <div
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "25px",
              }}
            >
              <Card hoverable>
                <TeacherDiv swap name="Даурен Карасаев" />
              </Card>
            </div>
          </Space>
        </Col>
        <Col xs={24} md={24} lg={18}>
          <div
            style={{
              borderRadius: "25px",
              height: "100%",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            {/* <TeacherDiv swap /> */}
            <Space
              direction="vertical"
              style={{
                width: "100%",
                padding: "15px",
              }}
              size={16}
            >
              <div
                style={{
                  width: "100%",
                  height: "500px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                {messages?.map((element) => {
                  return <TextMessage {...element} />;
                })}
              </div>
              <Row>
                <Col span={20}>
                  <Input.TextArea
                    value={input}
                    onChange={(event) => {
                      console.log(event.target.value);
                      setInput(event.target.value);
                    }}
                    rows={3}
                    placeholder="Введите сообщение"
                  />
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    style={{
                      height: "100%",
                    }}
                    onClick={() => {
                      setMessages((prev) => {
                        const tempInp = input;
                        setInput("");
                        return [
                          ...prev,
                          {
                            text: tempInp,
                            time: `${new Date().getHours()}:${
                              new Date().getMinutes() < 10
                                ? `0${new Date().getMinutes()}`
                                : new Date().getMinutes()
                            }`,
                          },
                        ];
                      });
                    }}
                  >
                    Отправить
                  </Button>
                </Col>
              </Row>
            </Space>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const ModChatPage = WithLayout(ModChatPageTemp);
export default ModChatPage;
