import React, { useState } from "react";
import WithLayout from "../../../withLayout";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import StudentComp from "./students-comp";
import { useNavigate } from "react-router";
import {
  createChatRoom,
  getCurators,
  getRoomById,
  getStudents,
  getTeachers,
  inviteStudent,
  inviteTeacher,
  inviteCurator,
  getCourses,
} from "../../../apis";
import ls from "localstorage-slim";
import context from "../../../context";
import dayjs from "dayjs";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/ru/world.json";
import "antd-country-phone-input/dist/index.css";
const TeacherStudentsPageTemp = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [students, setStudents] = React.useState();
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [birthday, setBirthday] = React.useState();
  const [credential, setCredential] = React.useState();

  const [currency, setCurrency] = React.useState(1);
  const [neuvazh, setNeuvazh] = useState(false);
  const [individualPrice, setIndividualPrice] = React.useState(1000);
  const [pairPrice, setPairPrice] = React.useState(1500);
  const [groupPrice, setGroupPrice] = React.useState(2000);

  const [timePackageType, setTimePackageType] = React.useState();
  const [timePackageAmount, setTimePackageAmount] = React.useState(0);

  const getStuds = (ids = []) => {
    setLoading(true);
    props?.teachers
      ? getTeachers(ls.get("token"))
          .then((r) => r.json())
          .then((r) => {
            setStudents(
              r.map((el) => ({ ...el, name: `${el?.name} ${el?.surname}` }))
            );
          })
          .finally(() => setLoading(false))
      : props?.curators
      ? getCurators(ls.get("token"))
          .then((r) => r.json())
          .then((r) => {
            setStudents(
              r.map((el) => ({ ...el, name: `${el?.name} ${el?.surname}` }))
            );
          })
          .finally(() => setLoading(false))
      : getStudents(ls.get("token"))
          .then((r) => r.json())
          .then((r) => {
            const stud =
              profileData?.role === "teacher" ||
              ls.get("profile")?.role === "teacher"
                ? r.filter((el) =>
                    el?.inCourses?.find((elIn) =>
                      ids.find((elInIn) => elInIn === elIn.id)
                    )
                  )
                : r;
            setStudents(
              (stud?.length ? stud : [])?.map((el) => ({
                ...el,
                name: `${el?.name} ${el?.surname}`,
              }))
            );
          })
          .finally(() => setLoading(false));
  };
  React.useEffect(() => {
    profileData?.role === "teacher" || ls.get("profile")?.role === "teacher"
      ? getCourses()
          .then((r) => r.json())
          .then((r) => {
            const ids = r
              .filter(
                (el) =>
                  el?.teacherList?.[0]?.id ===
                  (profileData.id ?? ls.get("profile")?.id)
              )
              .map((el) => el.id);
            getStuds(ids ?? []);
          })
      : getStuds();
  }, [props?.teachers, props?.curators]);

  const { profileData, window, openNotification } = React.useContext(context);
  const ops2 = [
    {
      value: "individual",
      label: "Индвидуальные занятия",
    },
    {
      value: "group",
      label: "Групповые занятия",
    },
    {
      value: "pair",
      label: "Парные занятия",
    },
  ];
  const ops = [
    {
      value: 1,
      label: "KZT",
    },
    {
      value: 2,
      label: "RUB",
    },
    {
      value: 3,
      label: "USD",
    },
  ];
  const handleChatCreate = (id2, name, surname) => {
    getRoomById(ls.get("profile")?.userId ?? profileData?.userId)
      .then((res) => res.json())
      .then((res2) => {
        const elll = res2?.find(
          (el) => el?.title?.includes(name) || el?.title?.includes(surname)
        ); // && res2?.find((el) => el?.title?.includes(id2));
        console.log(elll);
        if (elll) {
          navigate("/chat/" + elll?.id);
        } else
          createChatRoom(
            ls.get("profile")?.userId ?? profileData?.userId,
            id2,
            `${ls.get("profile")?.userId ?? profileData?.userId}${id2}`
          ).then((res) => {
            console.log(res.json());
            getRoomById(ls.get("profile")?.userId ?? profileData?.userId)
              .then((res) => res.json())
              .then((res2) => {
                const elll = res2?.find(
                  (el) =>
                    el?.title?.includes(name) || el?.title?.includes(surname)
                ); // && res2?.find((el) => el?.title?.includes(id2));
                if (elll) {
                  navigate("/chat/" + elll?.id);
                }
              });
            // navigate("/chat/" + (res2[res2.length - 1]?.id + 1));
          });
      });
  };

  const node = (
    <div>
      <Form.Item labelCol={{ span: 24 }} label="Реквизиты">
        <Input
          placeholder="Введите реквизиты"
          value={credential}
          onChange={(e) => setCredential(e.target.value)}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item labelCol={{ span: 24 }} label="Валюта">
        <Select
          placeholder="Выберите валюту"
          onSelect={(e) => {
            console.log(e);
            setCurrency(e);
          }}
          value={currency}
          notFoundContent={<Empty description="Нет данных" />}
          options={ops}
        />
      </Form.Item>

      <span>Оплата за 60 минут</span>
      <Row gutter={[8, 8]}>
        <Col span={8} xs={24} md={24} lg={8}>
          <Form.Item labelCol={{ span: 24 }} label="Индивидуальное занятие">
            <InputNumber
              placeholder="Введите сумму"
              value={individualPrice}
              onChange={(e) => setIndividualPrice(e)}
            />
          </Form.Item>
        </Col>
        <Col span={8} xs={24} md={24} lg={8}>
          <Form.Item labelCol={{ span: 24 }} label="Парное занятие">
            <InputNumber
              placeholder="Введите сумму"
              value={pairPrice}
              onChange={(e) => setPairPrice(e)}
            />
          </Form.Item>
        </Col>
        <Col span={8} xs={24} md={24} lg={8}>
          <Form.Item labelCol={{ span: 24 }} label="Групповое занятие">
            <InputNumber
              placeholder="Введите сумму"
              value={groupPrice}
              onChange={(e) => setGroupPrice(e)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Оплата при неуважительной причине"
          >
            <Radio.Group
              value={neuvazh}
              onChange={(e) => setNeuvazh(e.target.checked)}
              options={[
                {
                  label: "Да",
                  value: true,
                },
                {
                  label: "Нет",
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
  return (
    <Space
      direction="vertical"
      size={24}
      style={{
        width: "100%",
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <Row gutter={[4, 4]}>
        <Col span={24}>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "36px",
              lineHeight: "36px",
              textAlign: "left",
              float: "left",
            }}
          >
            {props?.teachers
              ? "Преподаватели"
              : props?.curators
              ? "Кураторы"
              : "Ученики"}
          </span>
        </Col>
      </Row>
      <Row style={{ width: "100%" }} gutter={[8, 8]}>
        <Col span={12} xs={24} md={18} lg={12}>
          <Input.Search
            placeholder="Поиск"
            size="large"
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </Col>
        {profileData?.role !== "teacher" &&
          ls.get("profile")?.role !== "teacher" && (
            <Col flex="auto">
              <Button
                type="primary"
                size="large"
                style={{
                  float: window?.width > 1000 ? "right" : "left",
                }}
                onClick={() => setOpen(true)}
              >
                Пригласить
                {/* {`Пригласить ${
                props?.teachers
                  ? "преподавателя"
                  : props?.curators
                  ? "куратора"
                  : "ученика"
              }`} */}
              </Button>
            </Col>
          )}
        {/* <Col span={12} style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
      }}>
        <Button type="primary" size="large">
        Пригласить ученика
        </Button>
      </Col> */}
      </Row>
      <Spin spinning={loading} style={{ minHeight: "200px" }}>
        <Space direction="vertical" style={{ width: "100%" }} size={10}>
          {students?.length > 0 &&
            students
              .filter((el) => {
                console.log(el);
                return (
                  el?.surname !== "int-user" &&
                  el?.surname !== "Руководитель" &&
                  el.name.toLowerCase().includes(search.toLowerCase())
                );
              })
              .map((element) => {
                return (
                  <StudentComp
                    {...element}
                    getStuds={getStuds}
                    but={props?.teachers}
                    editor={
                      profileData?.role !== "teacher" &&
                      ls.get("profile")?.role !== "teacher"
                    }
                    but2={props?.curators}
                    handleChatCreate={handleChatCreate}
                    userName={ls.get("profile")?.userId ?? profileData?.userId}
                    // onClick={() => navigate("/teacher-course-page")}
                  />
                );
              })}
        </Space>
      </Spin>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        width={window.width < 1000 ? "100%" : "50%"}
      >
        <Form.Item labelCol={{ span: 24 }} label="Имя">
          <Input
            placeholder="Введите имя"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Input>
        </Form.Item>
        <Form.Item labelCol={{ span: 24 }} label="Фамилия">
          <Input
            placeholder="Введите фамилию"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          ></Input>
        </Form.Item>
        <ConfigProvider locale={en}>
          <Form.Item labelCol={{ span: 24 }} label="Телефон">
            <CountryPhoneInput
              inline
              placeholder="Введите номер телефона и код страны"
              onChange={(e) => {
                console.log(e, "val");
                if (e?.phone?.match(/[^$,.\d]/)) {
                  return;
                }
                setPhone(e);
              }}
              value={phone}
            />
            {/* <Input
            placeholder="Введите телефон"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          ></Input> */}
          </Form.Item>
        </ConfigProvider>

        <Form.Item labelCol={{ span: 24 }} label="Почта">
          <Input
            placeholder="Введите почту"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Input>
        </Form.Item>
        {props?.teachers
          ? node
          : !props?.curators && (
              <div>
                <Form.Item labelCol={{ span: 24 }} label="День рождения">
                  <DatePicker
                    placeholder="Введите день рождения"
                    value={birthday}
                    onChange={(e) => setBirthday(e)}
                    style={{ width: "100%" }}
                    format={"DD/MM/YYYY"}
                  />
                </Form.Item>
                <Form.Item labelCol={{ span: 24 }} label="Тип занятий">
                  <Select
                    placeholder="Выберите тип"
                    onSelect={(e) => {
                      console.log(e);
                      setTimePackageType(e);
                    }}
                    value={timePackageType}
                    notFoundContent={<Empty description="Нет данных" />}
                    options={ops2}
                  />
                </Form.Item>
                <Form.Item labelCol={{ span: 24 }} label="Часы">
                  <InputNumber
                    placeholder="Введите количество"
                    value={timePackageAmount}
                    onChange={(e) => setTimePackageAmount(e)}
                  />
                </Form.Item>
              </div>
            )}
        <Button
          style={{ width: "100%" }}
          size="large"
          type="primary"
          disabled={!(phone.code && phone.phone && email && name && surname)}
          onClick={() => {
            setLoading(true);
            props?.teachers
              ? inviteTeacher(
                  email,
                  `+${phone.code}${phone.phone}`, // phone,
                  name,
                  surname,
                  ops.find((el) => el.value === currency)?.label,
                  individualPrice,
                  // isPayableForCanceledLesson: neuvazh,
                  pairPrice,
                  groupPrice,
                  neuvazh,
                  credential
                )
                  .then(() => {
                    getStuds();
                  })
                  .then(() => {
                    openNotification(
                      "success",
                      "Пользователь был приглашен!",
                      "Данный пользователь был успешно приглашен, список обновлен"
                    );
                  })
                  .catch(() => {
                    openNotification("error", "Произошла ошибка!");
                  })
              : props?.curators
              ? inviteCurator(
                  email,
                  `+${phone.code}${phone.phone}`,
                  name,
                  surname
                )
                  .then(() => {
                    getStuds();
                  })
                  .then(() => {
                    openNotification(
                      "success",
                      "Пользователь был приглашен!",
                      "Данный пользователь был успешно приглашен, список обновлен"
                    );
                  })
                  .catch(() => {
                    openNotification("error", "Произошла ошибка!");
                  })
              : inviteStudent(
                  email,
                  `+${phone.code}${phone.phone}`, // phone,
                  name,
                  surname,
                  dayjs(birthday).format("YYYY-MM-DD"),
                  timePackageType,
                  timePackageAmount * 60
                )
                  .then(() => {
                    getStuds();
                  })
                  .then(() => {
                    openNotification(
                      "success",
                      "Пользователь был приглашен!",
                      "Данный пользователь был успешно приглашен, список обновлен"
                    );
                  })
                  .catch(() => {
                    openNotification("error", "Произошла ошибка!");
                  });
            setOpen(false);
          }}
        >
          Пригласить
        </Button>
      </Drawer>
    </Space>
  );
};

const TeacherStudentsPage = WithLayout(TeacherStudentsPageTemp);
export default TeacherStudentsPage;
