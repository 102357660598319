import { Row, Col, Card } from "antd";
import React from "react";
import { ReactComponent as Logo } from "../../../../media/icons/books.svg";

const AdDiv = (props) => {
  const { time } = props;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#E8FE9D",
        padding: "10px",
      }}
    >
      <Row style={{ width: "100%", height: "100%" }}>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              padding: "15px",
              display: "flex",
              gap: "8px",
              flexDirection: "column",
              textAlign: "left",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {time ? "Следующий урок" : "Уроков пока нет"}
            </span>
            <span>{time}</span>
          </div>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Logo
            style={{
              height: "80%",
              width: "80%",
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdDiv;
