import React from "react";
import WithLayout from "../../../withLayout";
import { Image } from "antd";
import { useParams } from "react-router";
import { getNewsById } from "../../../apis";

const NewsPageTemp = (props) => {
  const { img = require("./photo_2023-10-08_18-05-17.jpg") } = props;
  const { id } = useParams();
  const [article, setArticle] = React.useState({});
  React.useEffect(() => {
    id &&
      getNewsById(id)
        .then((r) => r.json())
        .then((r2) => {
          console.log(r2);
          setArticle(r2);
        });
  }, []);
  return (
    <div
      style={{
        padding: "0%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "24px",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <span
        style={{
          padding: "5%",
          paddingTop: "0%",
          paddingBottom: "25px",
          fontSize: "48px",
          fontWeight: "bold",
          lineHeight: "48px",
          textAlign: "left",
        }}
      >
        {article.title}
      </span>
      <div>
        <Image
          width={"100%"}
          style={{ objectFit: "cover" }}
          height={"50vh"}
          src={article.picture}
        />
      </div>
      <span
        style={{
          // paddingLeft: "20%",
          // paddingRight: "20%",
          padding: "10%",
          paddingTop: "25px",
          paddingBottom: "0%",
          fontSize: "24px",
          lineHeight: "28px",
          textAlign: "left",
        }}
      >
        {article?.shortDescription}
      </span>
      <span
        style={{
          // paddingLeft: "20%",
          // paddingRight: "20%",
          padding: "5%",
          paddingTop: "0%",
          paddingBottom: "0%",
          fontSize: "24px",
          lineHeight: "28px",
          textAlign: "left",
        }}
      >
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: article?.description }}
        ></div>

        {/* Белл покинет компанию с 26 марта. В заявлении компании отмечалось, что
        отставка финансового директора «не была вызвана какими-либо
        разногласиями с компанией». Business Insider сообщил со ссылкой на
        источники, что отставка была недобровольной: на решение повлиял член
        совета директоров GameStop, предприниматель Райан Коэн, который в 2020
        году купил более 9 миллионов акций компании. В январе он присоединился к
        совету директоров компании.
        <br /> <br />В конце января пользователи Reddit подняли стоимость акций
        «умирающей» сети магазинов GameStop на 700%. Флешмоб стал
        интернет-феноменом, который повлиял на прибыль хедж-фондов и вызывал
        негативную реакцию со стороны Уолл-стрит: биржи запрещали покупку акций,
        а потерявшие миллиарды долларов инвесторы жаловались на «манипуляции». */}
      </span>
    </div>
  );
};

const NewsPage = WithLayout(NewsPageTemp);
export default NewsPage;
