import React from "react";
import WithLayout from "../../../withLayout";
import CurrentCourse from "../course-bought-main-page/current-course";
import { Space, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import context from "../../../context";
import { getCourses, getTeachers, getTimePackages } from "../../../apis";
import ls from "localstorage-slim";
import dayjs from "dayjs";
const MyCoursesPageTemp = (props) => {
  // const {
  //   courses = [
  //     { teacher: "Павел Козлов", delayed: false, isGroup: false },
  //     {
  //       teacher: "Даурен Карасаев",
  //       timeout: "01:29",
  //       name: "Gruppenunterricht Deutsch A1 15:00 – 15:40",
  //       delayed: true,
  //       isGroup: true,
  //     },
  //   ],
  // } = props;

  const navigate = useNavigate();
  const { window, profileData } = React.useContext(context);
  const [courses, setCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (
      ls.get("profile")?.role !== "student" ||
      profileData?.role !== "student"
    )
      navigate("/teacher-main-page");
    setLoading(true);
    getCourses()
      .then((r) => r.json())
      .then((r) => {
        getTimePackages(profileData?.id ?? ls.get("profile")?.id)
          .then((rr) => rr.json())
          .then((rrr) => {
            const rtemp = r
              .filter((el) => {
                return el?.studentList?.find((y) => y.id === profileData?.id);
              })
              .map((el) => {
                const temp = rrr.find((el2) => el2.courseId === el.id);
                return { teacher: el.teacherList?.[0], ...temp, ...el };
              });
            console.log(rtemp);
            const t = rtemp?.sort(
              (el, el2) =>
                dayjs(el?.durationList?.[el?.durationList?.length - 1]) -
                dayjs(el2?.durationList?.[el2?.durationList?.length - 1])
            );
            console.log(t);
            // setTime(t?.[0]?.durationList?.[0]);
            setCourses(rtemp ?? []);
            setLoading(false);
          });
        // console.log(r);
        // const rtemp = r
        //   .filter((el) => {
        //     return el?.studentList?.find((y) => y.id === profileData?.id);
        //   })
        //   .map((el) => ({
        //     ...el,
        //     teacher: el.teacherList?.[0],
        //   }));

        // setCourses(rtemp ?? []);
        // setLoading(false);
        // getTeachers()
        //   .then((rteach) => rteach.json())
        //   .then((rteach) => {
        //     // let rtemp = r?.map((el) => {
        //     //   return {
        //     //     ...el,
        //     //     teacher: rteach.find((element) => element?.id === el?.id),
        //     //   };
        //     // });
        //     // rtemp = rtemp.filter((x) => {
        //     //   console.log(x, profileData);
        //     //   if (
        //     //     ls.get("profile")?.role === "teacher" ||
        //     //     profileData?.role === "teacher"
        //     //   )
        //     //     return x?.teacherList?.find((y) => y.id === profileData?.id);
        //     //   return x?.studentList?.find((y) => y.id === profileData?.id);
        //     // });
        //     const rtemp = r.filter((el) => {
        //       return el?.studentList?.find((y) => y.id === profileData?.id);
        //     })
        //     const rtemp
        //     setCourses(rtemp ?? []);
        //     setLoading(false);
        //   });
      });
  }, []);

  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "96px",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ fontSize: "48px", fontWeight: "bold", textAlign: "left" }}>
        Мои курсы
      </span>
      <Spin spinning={loading} style={{ height: "100vh" }}>
        <Space style={{ width: "100%" }} direction="vertical" size={16}>
          {courses.map((x) => {
            return (
              <CurrentCourse
                {...x}
                navigate={navigate}
                name={x?.title}
                teacher={
                  x?.teacherList?.[0] && {
                    name: x.teacherList[0]
                      ? `${x.teacherList[0].name} ${x.teacherList[0].surname}`
                      : "Учитель отсутствует",
                    id: x.teacherList[0].userId,
                  }
                }
                topics={x?.courseSubjects}
                window={window}
                type={x?.type}
                remainingHours={+(x?.amount ? x?.amount / 60 : 0).toFixed(0)}
                totalHours={
                  +(x?.initialAmount ? x?.initialAmount / 60 : 0).toFixed(0)
                }
                remainingHoursCount={
                  +(x?.amount ? x?.amount / 60 : 0).toFixed(0)
                }
                totalHoursCount={
                  +(x?.initialAmount ? x?.initialAmount / 60 : 0).toFixed(0)
                }
              />
            );
          })}
        </Space>
      </Spin>
    </div>
  );
};

const MyCoursesPage = WithLayout(MyCoursesPageTemp);
export default MyCoursesPage;
