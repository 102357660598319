import React from "react";
import { Col, Form, Card, Row, Input, Button, Space } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const RestoreCard = (props) => {
  const { setLogin, setLoading } = props;

  const [email, setEmail] = React.useState("");
  const [validate, setValidate] = React.useState("");

  const [loginButt, setLoginButt] = React.useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  React.useEffect(() => {
    const cond = validateEmail(email);
    if (cond && !loginButt) {
      setLoginButt(true);
    } else if (!cond && loginButt) setLoginButt(false);
  }, [email]);

  return (
    <Card
      headStyle={{ flex: 0 }}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
      }}
      title="Восстановление пароля"
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row>
          <Col span={24}>
            <Form.Item
              validateStatus={validate}
              help={validate === "error" ? "Пользователь не найден!" : ""}
            >
              <Input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Электронная почта"
                size="large"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: "5%" }}>
          <Col span={24}>
            <Button
              type="primary"
              size="large"
              disabled={!loginButt}
              onClick={() => {
                setLoading(true);

                setTimeout(() => {
                  setValidate("error");
                  setLoading(false);
                }, 500);
              }}
              block
            >
              Восстановить
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <span>
              Нет аккаунта?
              <Button
                type="link"
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    setLogin({
                      isLogin: false,
                      isRegister: true,
                      isRestore: false,
                      isRegEmployee: false,

                    });
                    setLoading(false);
                  }, 500);
                }}
              >
                Зарегистрироваться
              </Button>
            </span>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

export default RestoreCard;
