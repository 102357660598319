import { Button, Input, Col, Row, Spin, Result, Form } from "antd";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import WithLayout from "../../../withLayout";
import { sendNewCourseRequest, pushNotificationApi } from "../../../apis";

const FormRequestPageTemp = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [form] = Form.useForm();

  const handleSendRequest = (fields) => {
    setLoading(true);
    const { name, email, goal, level, phone } = fields;
    sendNewCourseRequest(name, phone, email, level, goal)
      .then(() => {
        pushNotificationApi(
          "Получена заявка на приобритение курса!",
          `Имя: ${name}, почта: ${email}, телефон: ${phone}, уровень знания немецкого: ${level}, цель обучения: ${goal}`,
          [],
          ["ROLE_HEAD_TEACHER"]
        ).then(() => setSuccess(true));
      })
      .catch(() => {
        setSuccess(true);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const successProps = useMemo(
    () => ({
      status: "success",
      title: "Заявка отправлена успешно!",
      subTitle: "Наш менеджер скоро с вами свяжется",
    }),
    [error]
  );

  const errorProps = useMemo(
    () => ({
      status: "error",
      title: "Произошла ошибка при отправке",
      subTitle: "Попробуйте попозже",
    }),
    [error]
  );

  return (
    <div
      style={{
        padding: "10%",
        paddingTop: "0%",
        width: "100%",
        height: "100%",
        gap: "32px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {success && (
        <div
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "25px",
            backgroundColor: "white",
          }}
        >
          <Result
            {...(error ? errorProps : successProps)}
            extra={[
              <Button type="primary" key="back" onClick={() => navigate("/")}>
                Назад
              </Button>,
            ]}
          />
        </div>
      )}
      {!success && (
        <div
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "25px",
            backgroundColor: "white",
          }}
        >
          <Spin spinning={loading} style={{ height: "100%", width: "100%" }}>
            <Row gutter={[16, 8]} style={{ padding: "5%" }}>
              <Col
                span={12}
                xs={24}
                md={24}
                lg={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <span
                  style={{
                    fontSize: "28px",
                    lineHeight: "32px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Оставить заявку
                </span>
                <span style={{ textAlign: "left" }}>
                  Оставьте заявку на покупку занятий и мы с вами свяжемся
                </span>
              </Col>
              <Col
                span={12}
                xs={24}
                md={24}
                lg={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Form form={form} onFinish={handleSendRequest}>
                  <Form.Item name="name">
                    <Input placeholder="Имя" />
                  </Form.Item>
                  <Form.Item name="phone">
                    <Input placeholder="Телефон" />
                  </Form.Item>
                  <Form.Item name="email">
                    <Input placeholder="Почта" />
                  </Form.Item>
                  <Form.Item name="level">
                    <Input placeholder="Ваш уровень" />
                  </Form.Item>
                  <Form.Item name="goal">
                    <Input.TextArea rows={6} placeholder="Цель обучения" />
                  </Form.Item>
                </Form>

                <Button
                  size="large"
                  type="primary"
                  shape="round"
                  // onClick={() => handleSendRequest()}
                  onClick={() => form.submit()}
                >
                  Отправить заявку
                </Button>
              </Col>
            </Row>
          </Spin>
        </div>
      )}
    </div>
  );
};

const FormRequestPage = WithLayout(FormRequestPageTemp);
export default FormRequestPage;
