export const CertificateSvg =  () =>{
        return(
            <svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.82701 85.7373L5.53513 87.2448C3.92496 83.4111 3.12462 80.499 3.93287 80.1016C3.93445 80.1016 5.72335 82.4309 7.82701 85.7373Z" fill="#D6E7FA"/>
                    <path d="M14.4826 101.758C14.4842 101.85 14.4858 101.941 14.4842 102.032C11.8665 102.526 2.90771 88.9746 2.90771 88.9746L5.5365 87.2438L7.82839 85.7363C10.7941 90.3998 14.3909 97.0062 14.4826 101.758Z" fill="url(#paint0_linear_63_23690)"/>
                    <path d="M122.607 42.7059C122.607 42.7059 77.1651 65.2695 69.6046 70.0502C62.0425 74.8308 18.4777 101.103 18.4777 101.103C18.4777 101.103 18.4271 100.544 18.2831 99.586C17.6742 95.5749 15.3918 84.5679 8.11437 78.2781C7.8613 78.0564 7.60032 77.8411 7.33301 77.6336C7.33301 77.6336 33.2571 65.5308 50.4644 57.1413C56.8561 54.0265 62.0441 51.4232 64.2553 50.1422C66.2119 49.0084 69.8624 46.9134 74.2785 44.3813C88.2861 36.3513 110.014 23.9396 110.014 23.9396C110.014 23.9396 121.029 23.6784 122.607 42.7059Z" fill="url(#paint1_linear_63_23690)"/>
                    <path d="M18.2832 99.5852L15.2558 101.557L14.8335 101.831L14.8303 101.832L14.5567 102.011L14.4839 102.059C14.4839 102.049 14.4839 102.038 14.4839 102.03C14.4855 101.94 14.4839 101.848 14.4823 101.756C14.3906 97.0041 10.7954 90.3976 7.82811 85.7357C5.72445 82.4293 3.93555 80.1 3.93555 80.1C3.94978 80.0936 3.96402 80.0873 3.97825 80.081L8.11598 78.2773C15.3918 84.5671 17.6742 95.5741 18.2832 99.5852Z" fill="url(#paint2_linear_63_23690)"/>
                    <path opacity="0.5" d="M77.1554 63.3543C76.0213 66.1634 73.6219 68.4231 70.4157 69.3115C64.8149 70.8602 58.863 67.6424 57.12 62.1207C56.269 59.4271 56.579 56.6861 57.7669 54.401C59.0148 51.9988 61.2356 50.1097 64.1048 49.3132C69.7087 47.7629 75.6623 50.9806 77.4037 56.5024C78.1487 58.8602 78.0048 61.2593 77.1554 63.3543Z" fill="#9C9BAE"/>
                    <path d="M70.1534 61.9736L69.7517 61.8438L61.4588 88.971C58.6339 88.8254 54.5737 80.7605 54.5737 80.7605L46.0752 86.4105C49.1769 82.2173 50.2651 61.3592 55.2285 54.8019C56.5809 53.0156 63.1244 50.5723 63.1244 50.5723L70.1534 61.9736Z" fill="url(#paint3_linear_63_23690)"/>
                    <path d="M90.1305 60.5801C90.8233 64.8524 95.9005 70.3029 95.9005 70.3029L70.1536 61.9736L63.1245 50.5738L68.5861 47.6491C68.5861 47.6491 80.9392 46.8415 84.9425 49.1708C88.9458 51.4986 99.9212 56.2159 99.9212 56.2159C99.0402 58.0196 90.1305 60.5801 90.1305 60.5801Z" fill="url(#paint4_linear_63_23690)"/>
                    <path d="M74.28 44.3809L71.9549 49.5352L55.7472 57.2026L50.4644 57.1409C56.856 54.0261 62.044 51.4228 64.2552 50.1417C66.2134 49.0095 69.8639 46.9145 74.28 44.3809Z" fill="url(#paint5_linear_63_23690)"/>
                    <path d="M76.4757 61.2195C75.3416 64.0287 72.9422 66.2883 69.7361 67.1767C64.1353 68.7254 58.1833 65.5077 56.4403 59.9859C55.5893 57.2924 55.8993 54.5513 57.0872 52.2663C58.3352 49.8641 60.5559 47.9749 63.4251 47.1784C69.029 45.6282 74.9826 48.8459 76.724 54.3676C77.4674 56.727 77.3251 59.1245 76.4757 61.2195Z" fill="#142533"/>
                    <path d="M76.4753 61.2191C75.2241 63.6102 73.0066 65.4962 70.1437 66.288C64.5429 67.8398 58.591 64.6237 56.8479 59.0972C56.1061 56.7441 56.2469 54.3546 57.0868 52.2659C57.6799 51.1242 58.4929 50.0981 59.491 49.2509C60.5918 48.3134 61.9189 47.5945 63.4262 47.1765C69.0302 45.6262 74.9822 48.8439 76.7236 54.3656C76.7679 54.5097 76.8106 54.6538 76.8501 54.7964C77.448 57.0165 77.2725 59.2524 76.4753 61.2191Z" fill="url(#paint6_linear_63_23690)"/>
                    <path d="M73.9973 59.8318C73.0577 61.6275 71.3938 63.0432 69.2442 63.637C65.0401 64.8025 60.5702 62.386 59.2621 58.2388C58.7054 56.4732 58.8113 54.6774 59.4424 53.1098C60.3804 51.3061 62.0459 49.8873 64.2002 49.2903C68.4075 48.1264 72.8774 50.5429 74.1839 54.6885C74.7406 56.4589 74.6347 58.2594 73.9973 59.8318Z" fill="url(#paint7_linear_63_23690)"/>
                    <path d="M76.8504 54.7983C76.2683 55.0042 75.6689 55.1815 75.0504 55.3304C68.1131 56.9915 61.4335 54.2948 59.4912 49.2528C60.5921 48.3154 61.9191 47.5965 63.4265 47.1784C69.0305 45.6282 74.9824 48.8459 76.7238 54.3676C76.7681 54.5101 76.8108 54.6542 76.8504 54.7983Z" fill="url(#paint8_linear_63_23690)"/>
                    <path d="M67.8032 60.1258L63.2226 58.6357C62.9696 58.5534 62.7956 58.3174 62.8051 58.0704L62.8351 57.3689C62.8478 57.0522 63.1578 56.8479 63.4884 56.9398L66.0207 57.6413C66.3354 57.7284 66.6344 57.5479 66.6707 57.2486L67.3066 51.8979C67.3398 51.6112 67.6182 51.4307 67.9219 51.4956L69.0227 51.7316C69.3232 51.7965 69.5352 52.0768 69.4972 52.3587L68.4802 59.7505C68.4359 60.0546 68.1212 60.2288 67.8032 60.1258Z" fill="white"/>
                    <defs>
                            <linearGradient id="paint0_linear_63_23690" x1="23.1903" y1="118.808" x2="1.01733" y2="79.6242" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C2CCED"/>
                                    <stop offset="0.0512" stop-color="#C4CEED"/>
                                    <stop offset="1" stop-color="#E4EBF0"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_63_23690" x1="73.7483" y1="74.5625" x2="57.509" y2="45.2607" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C2CCED"/>
                                    <stop offset="0.2728" stop-color="#DFE4F6"/>
                                    <stop offset="0.6118" stop-color="white"/>
                                    <stop offset="1" stop-color="#E4EBF0"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_63_23690" x1="-0.0316597" y1="49.1682" x2="17.4756" y2="111.943" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C2CCED"/>
                                    <stop offset="0.0512" stop-color="#C4CEED"/>
                                    <stop offset="1" stop-color="#E4EBF0"/>
                            </linearGradient>
                            <linearGradient id="paint3_linear_63_23690" x1="68.4042" y1="86.6003" x2="52.4335" y2="65.6973" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#D6E7FA"/>
                                    <stop offset="1" stop-color="#749DED"/>
                            </linearGradient>
                            <linearGradient id="paint4_linear_63_23690" x1="92.1665" y1="68.4468" x2="76.1958" y2="47.5437" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#D6E7FA"/>
                                    <stop offset="1" stop-color="#749DED"/>
                            </linearGradient>
                            <linearGradient id="paint5_linear_63_23690" x1="81.857" y1="76.3221" x2="65.8863" y2="55.419" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#D6E7FA"/>
                                    <stop offset="1" stop-color="#749DED"/>
                            </linearGradient>
                            <linearGradient id="paint6_linear_63_23690" x1="54.4178" y1="43.5988" x2="86.417" y2="77.5019" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#475B80"/>
                                    <stop offset="0.6559" stop-color="#2C3D51"/>
                                    <stop offset="1" stop-color="#21313E"/>
                            </linearGradient>
                            <linearGradient id="paint7_linear_63_23690" x1="59.2442" y1="58.2591" x2="74.2025" y2="54.6859" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#475B80"/>
                                    <stop offset="0.6559" stop-color="#2C3D51"/>
                                    <stop offset="1" stop-color="#21313E"/>
                            </linearGradient>
                            <linearGradient id="paint8_linear_63_23690" x1="69.1612" y1="32.6006" x2="67.8971" y2="57.3515" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="white" stop-opacity="0.3"/>
                                    <stop offset="1" stop-color="white" stop-opacity="0"/>
                            </linearGradient>
                    </defs>
            </svg>

        )
        }