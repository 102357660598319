import React, { useContext } from "react";
import WithLayout from "../../../withLayout";
import { Button, Card, Col, Input, Row, Select, Space } from "antd";
import TeacherDiv from "./teacher-div";
import TextMessage from "./text-message";
import context from "../../../context";
import {
  createChatRoom,
  getChatRoomMsgs,
  getCurators,
  getRoomById,
  getUserRoles,
} from "../../../apis";
import ls from "localstorage-slim";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { Outlet, useNavigate, useParams } from "react-router";
import moment from "moment";
import { ArrowRightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const rolesDict = {
  ["Студент"]: 1,
  ["Учитель"]: 2,
  ["Куратор"]: 3,
  ["Все"]: 4,
};

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const ChatPageTemp = (props) => {
  const [input, setInput] = React.useState("");
  const [chosen, setChosen] = React.useState();
  const { profileData } = React.useContext(context);

  const [rooms, setRooms] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
  const [stomp, setStomp] = React.useState();
  const [reff, setReff] = React.useState();

  const ref = React.createRef();

  React.useEffect(() => {
    if (chosen) {
      getChatRoomMsgs(chosen)
        .then((res) => res.json())
        .then((res2) => {
          console.log(res2);
          setMessages(res2);
        });
    }
  }, [chosen]);

  React.useEffect(() => {
    stomp && stomp.activate();
  }, [stomp]);

  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log(ref);
    ref &&
      ref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
  }, [messages]);

  React.useEffect(() => {
    if (!id || typeof id === "undefined") return;
    var stomp = new Client({
      // https://ultima.deutschkz.online/api
      brokerURL: "wss://ultima.deutschkz.online/api/chat",
      debug: function (str) {
        console.log(str);
      },
    });
    console.log(typeof WebSocket);
    if (typeof WebSocket !== "function") {
      stomp.webSocketFactory = function () {
        return new SockJS("https://ultima.deutschkz.online/api/chat");
      };
    }
    stomp.onConnect = function (frame) {
      stomp.subscribe(`/api/chat/room/${id}/queue/messages`, (message) => {
        console.log(message);
        getChatRoomMsgs(id)
          .then((res) => res.json())
          .then((res2) => {
            console.log(res2);
            setMessages(res2);
          });
      });
    };

    stomp.onStompError = function (frame) {
      console.log("Broker reported error: " + frame.headers["message"]);
      console.log("Additional details: " + frame.body);
    };
    setStomp(stomp);
    getChatRoomMsgs(id)
      .then((res) => res.json())
      .then((res2) => {
        console.log(res2);
        setMessages(res2);
      });
  }, [id]);

  const { window } = useContext(context);
  return (
    <Col
      span={24}
      xs={24}
      md={24}
      lg={24}
      xl={24}
      style={{
        // height: `${window.height - 200}px`,
        height: `70vh`,
        // height: "100%",
        padding: "0px",
        overflow: "visible",

        paddingBottom: "0px",
        // paddingLeft: "15px",
        // paddingRight: "15px",
      }}
    >
      <div
        style={{
          borderRadius: "25px",
          height: "100%",
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "20px",
          justifyContent: "flex-end",
          overflow: "visible",
          padding: "0px",
          borderBottomRightRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
      >
        <Row
          gutter={[8, 8]}
          style={{
            // paddingTop: "15px",
            flexGrow: "1",
            flexBasis: "10%",
          }}
        >
          {/* {window?.width < 1000 && (
            <Col>
              <Button danger onClick={() => navigate("/chat")}>
                <ArrowLeftOutlined />
              </Button>
            </Col>
          )} */}
          {/* <Col>
            <span>Имя</span>
          </Col> */}
        </Row>
        <div
          style={
            {
              // overflowY: "scroll",
              // maxHeight: "600px",
            }
          }
        >
          <div
            // className="scrollchat"
            direction="vertical"
            style={{
              width: "100%",
              padding: "15px",
              overflowY: "scroll",
              maxHeight: `${
                document?.getElementById("col-right")?.getBoundingClientRect()
                  .height -
                document?.getElementById("col-right2")?.getBoundingClientRect()
                  .height
                //    -
                // (window.width <= 1000
                //   ? document
                //       ?.getElementById("col-right3")
                //       ?.getBoundingClientRect().height
                //   : 0)
              }px`,
              // display: "flex",
              // flexDirection: "column",
              // gap: "8px",
              // maxHeight: `${window?.height - 600}px`,
            }}
            size={16}
          >
            {/* <div
            style={{
              // height: "500px",
              overflowY: "scroll",
              display: "flex",
            }}
          > */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                overflowY: "scroll",
              }}
            >
              {messages?.length > 0 &&
                messages?.map((element, i) => {
                  console.log(
                    moment
                      .duration(moment(element.sendAt).diff(moment(new Date())))
                      .asMinutes()
                  );
                  console.log(i === messages.length - 1 ? ref : null);
                  return (
                    <TextMessage
                      {...element}
                      ref2={i === messages.length - 1 ? ref : null}
                      key={element?.sendAt}
                      align={
                        element?.senderId ===
                          (ls.get("profile")?.userId ?? profileData?.userId) ??
                        profileData?.userId
                          ? "right"
                          : "left"
                      }
                    />
                  );
                })}
            </div>
            {/* </div> */}
          </div>
          <Row
            gutter={[16, 8]}
            style={{
              margin: "0px",
            }}
            // style={{
            //   paddingLeft: "16px",
            // }}
            // style={{ padding: "16px", paddingRight: "0px", paddingTop: "0px" }}
          >
            <Col
              span={24}
              xs={24}
              md={24}
              lg={24}
              xl={24}
              style={{
                paddingTop: "10px",
              }}
              id="col-right2"
            >
              <Row
                style={{
                  padding: "5px",
                }}
              >
                <Col
                  span={20}
                  // xs={24}
                  // md={24}
                  // lg={24}
                  // xl={22}
                >
                  <Input.TextArea
                    value={input}
                    onChange={(event) => {
                      console.log(event.target.value);
                      setInput(event.target.value);
                    }}
                    rows={2}
                    onKeyDown={(e) => {
                      if (e.key !== "Enter" || !input || input === "") return;
                      e.preventDefault();
                      var sendAt = dayjs(new Date()).tz("Asia/Almaty");
                      sendAt = sendAt.toISOString(true);
                      console.log(sendAt);
                      setInput();
                      stomp.publish({
                        destination: "/api/chat/app/send",
                        body: `{ "chatId": ${id}, "senderId": ${
                          ls.get("profile")?.userId ?? profileData?.userId
                        }, "senderName": "${
                          ls.get("profile")?.name ?? profileData?.name
                        }", "content": "${input}", "sendAt": "${sendAt}"}`,
                      });
                    }}
                    autoSize={{
                      minRows: 2,
                      maxRows: 2,
                    }}
                    style={{
                      border: "0px",
                      width: "100%",
                      boxShadow: "none",
                    }}
                    // enterButton={<ArrowRightOutlined />}
                    placeholder="Введите сообщение"
                  />
                </Col>
                <Col
                  span={4}
                  style={{
                    // marginTop: "auto",
                    // marginBottom: "auto",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    type="primary"
                    disabled={!input || input === ""}
                    style={{
                      height: "100%",
                      // position: "absolute",
                      // bottom: "0px",
                      // right: "0px",
                      float: "right",
                    }}
                    onClick={() => {
                      var sendAt = dayjs(new Date()).tz("Asia/Almaty");
                      console.log(sendAt.toISOString());
                      sendAt = sendAt.toISOString();
                      stomp.publish({
                        destination: "/api/chat/app/send",
                        body: `{ "chatId": ${id}, "senderId": ${
                          ls.get("profile")?.userId ?? profileData?.userId
                        }, "senderName": "${
                          ls.get("profile")?.name ?? profileData?.name
                        }", "content": "${input}", "sendAt": "${sendAt}"}`,
                      });
                      setInput();
                    }}
                  >
                    <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
            {/* <Col
              span={4}
              xs={24}
              md={24}
              lg={24}
              xl={4}
              style={{
                paddingRight: "0px",
              }}
            >
             
            </Col> */}
          </Row>
        </div>
      </div>
    </Col>
    // </Modal>
  );
};

const InnerChat = () => {
  const navigate = useNavigate();
  const { profileData, window } = React.useContext(context);
  const [rooms, setRooms] = React.useState();
  const handleChoose = () => {};
  const { id } = useParams();
  React.useEffect(() => {
    getRoomById(ls.get("profile")?.userId ?? profileData?.userId)
      .then((res) => res.json())
      .then((res2) => {
        console.log(res2);
        const arr = res2?.map((el) => {
          if (
            el?.receiverId === profileData?.userId ||
            el?.receiverId === ls.get("profile")?.userId
          )
            return el?.senderId;
          return el?.receiverId;
        });
        console.log(arr);
        getUserRoles({
          ids: arr,
        })
          .then((r) => r.json())
          .then((r) => {
            const tempRes = res2.map((el) => {
              const roleTemp = r.find(
                (el2) => el2?.id === el?.senderId || el2?.id === el?.receiverId
              )?.role;
              return {
                ...el,
                role:
                  roleTemp === "ROLE_TEACHER"
                    ? "Учитель"
                    : roleTemp === "ROLE_HEAD_TEACHER"
                    ? "Куратор"
                    : roleTemp === "ROLE_SUPERVISOR"
                    ? "Руководитель"
                    : "Студент",
              };
            });
            setRooms(tempRes);
          });

        // !id && res2[0]?.id && navigate("/chat/" + res2[0]?.id);
      });
  }, [id]);

  const [curators, setCurators] = React.useState([]);

  React.useEffect(() => {
    // if (profileData?.role === "student") {
    rooms &&
      getCurators()
        .then((r) => r.json())
        .then((rInner) => {
          setCurators(
            rInner.filter(
              (el) =>
                el?.name !== "int-user" &&
                el?.name !== "Амирлан" &&
                !rooms.find(
                  (el2) =>
                    el2?.senderId === el.userId || el2?.receiverId === el.userId
                )
            )
          );
        });
    // }
  }, [rooms]);

  const [search, setSearch] = React.useState("");
  const [searchRole, setSearchRole] = React.useState(4);
  const curatorSearch = curators?.filter((e) =>
    `${e?.name} ${e?.surname}`?.includes(search)
  );

  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "75vh",
        // marginTop: "10%",
        paddingLeft: "10px",
        paddingRight: "10px",
        overflow: "hidden",
      }}
    >
      <Row
        gutter={[16, 8]}
        style={{
          margin: "0px",
          width: "100%",
          // height: `${window.height - 200}px`,
          // overflowY: "scroll",
        }}
      >
        {window.width > 1000 ? (
          <Col
            span={24}
            xs={24}
            md={24}
            lg={8}
            xl={8}
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "scroll",
            }}
          >
            <Space
              direction={"vertical"}
              size={16}
              style={{
                width: "100%",
                overflowY: "scroll",
                overflowX: "scroll",
              }}
            >
              <Row gutter={[4, 4]}>
                <Col span={12}>
                  <Input
                    placeholder="Поиск"
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value)}
                    size="large"
                  />
                </Col>
                <Col span={12}>
                  <Select
                    placeholder="Роль"
                    value={searchRole}
                    style={{
                      width: "100%",
                    }}
                    onSelect={(e) => setSearchRole(e)}
                    size="large"
                    options={[
                      {
                        label: "Студент",
                        value: 1,
                      },
                      {
                        label: "Учитель",
                        value: 2,
                      },
                      {
                        label: "Куратор",
                        value: 3,
                      },
                      {
                        label: "Все",
                        value: 4,
                      },
                    ]}
                  />
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  maxHeight: "60vh",
                  overflowY: "scroll",
                  padding: "10px",
                }}
              >
                {curatorSearch?.length &&
                (searchRole ? searchRole === 3 : true) ? (
                  <span>Начните общаться!</span>
                ) : null}
                {curatorSearch?.map((element, key) => {
                  console.log(element?.id === id, element, id);
                  return (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        minWidth: window.width <= 1200 ? "250px" : "200px",
                      }}
                      key={element?.id}
                      // onClick={() => handleChoose(element?.id)}
                    >
                      <Card
                        hoverable
                        style={{
                          border: element?.id == id ? "1px solid grey" : "none",
                          borderRadius: "20px",
                        }}
                        bodyStyle={{
                          padding: "14px",
                        }}
                        onClick={() => {
                          console.log(rooms, element, "click");
                          const chat = rooms.find(
                            (ell) =>
                              ell.receiverId === element.userId ||
                              ell.senderId === element.userId
                          );
                          console.log(chat);
                          if (chat) {
                            navigate("/chat/" + chat?.id);
                          } else {
                            createChatRoom(
                              ls.get("profile")?.userId ?? profileData?.userId,
                              element?.userId,
                              `${
                                ls.get("profile")?.userId ?? profileData?.userId
                              }${element?.userId}${key}`
                            ).then((res) => {
                              console.log(res.json());
                              getRoomById(
                                ls.get("profile")?.userId ?? profileData?.userId
                              )
                                .then((res) => res.json())
                                .then((res2) => {
                                  console.log(res2);
                                  setRooms(res2);
                                  // !id && navigate("/chat/" + res2[0]?.id);
                                  const go = res2.find(
                                    (el) =>
                                      el?.senderId === element?.userId ||
                                      el?.receiverId === element?.userId
                                  )?.id;
                                  go && navigate("/chat/" + go);
                                });
                            });
                          }
                        }}
                      >
                        <TeacherDiv
                          chat
                          swap
                          name={`${element?.name} ${element?.surname}`}
                          pos={true}
                          role={"Куратор"}
                          onClick={() => {}}
                        />
                      </Card>
                    </div>
                  );
                })}
                {curatorSearch?.length ? <span>Продолжить чат</span> : <></>}

                {rooms
                  ?.filter(
                    (e) =>
                      e?.title?.includes(search) &&
                      (searchRole
                        ? searchRole === rolesDict?.[e?.role] ||
                          searchRole === 4
                        : true)
                  )
                  .map((element) => {
                    console.log(element);
                    return (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          minWidth: window.width <= 1200 ? "250px" : "200px",
                        }}
                        key={element?.id}
                        onClick={() => handleChoose(element?.id)}
                      >
                        <Card
                          hoverable
                          style={{
                            border:
                              element?.id == id ? "1px solid grey" : "none",
                            borderRadius: "20px",
                          }}
                          bodyStyle={{
                            padding: "14px",
                          }}
                          onClick={() => navigate("/chat/" + element?.id)}
                        >
                          <TeacherDiv
                            chat
                            swap
                            name={element?.title}
                            pos={true}
                            role={element?.role}
                          />
                        </Card>
                      </div>
                    );
                  })}
              </div>
            </Space>
          </Col>
        ) : (
          !id && (
            <Col
              span={24}
              xs={24}
              md={24}
              lg={8}
              xl={8}
              style={{
                width: "100%",
                overflowY: "scroll",
                overflowX: "scroll",
              }}
            >
              <Space
                direction={"vertical"}
                size={16}
                style={{
                  width: "100%",
                  overflowY: "scroll",
                  overflowX: "scroll",
                }}
              >
                <Row gutter={[4, 4]}>
                  <Col span={12}>
                    <Input
                      placeholder="Поиск"
                      value={search}
                      onChange={(e) => setSearch(e?.target?.value)}
                      size="large"
                    />
                  </Col>
                  <Col span={12}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Роль"
                      value={searchRole}
                      onSelect={(e) => setSearchRole(e)}
                      size="large"
                      options={[
                        {
                          label: "Студент",
                          value: 1,
                        },
                        {
                          label: "Учитель",
                          value: 2,
                        },
                        {
                          label: "Куратор",
                          value: 3,
                        },
                        {
                          label: "Все",
                          value: 4,
                        },
                      ]}
                    />
                  </Col>
                </Row>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    maxHeight: "60vh",
                    overflowY: "scroll",
                    padding: "10px",
                  }}
                >
                  {curatorSearch?.length ? (
                    <span>Начните общаться!</span>
                  ) : null}
                  {curatorSearch
                    .filter((el) => el.userId !== profileData?.userId)
                    .map((element, key) => {
                      console.log(element?.id === id, element, id);
                      return (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            minWidth: window.width <= 1200 ? "250px" : "200px",
                          }}
                          key={element?.id}
                          // onClick={() => handleChoose(element?.id)}
                        >
                          <Card
                            hoverable
                            style={{
                              border:
                                element?.id == id ? "1px solid grey" : "none",
                              borderRadius: "20px",
                            }}
                            bodyStyle={{
                              padding: "14px",
                            }}
                            onClick={() => {
                              console.log(rooms, element, "click");
                              const chat = rooms.find(
                                (ell) =>
                                  ell.receiverId === element.userId ||
                                  ell.senderId === element.userId
                              );
                              console.log(chat);
                              if (chat) {
                                navigate("/chat/" + chat?.id);
                              } else {
                                createChatRoom(
                                  ls.get("profile")?.userId ??
                                    profileData?.userId,
                                  element?.userId,
                                  `${
                                    ls.get("profile")?.userId ??
                                    profileData?.userId
                                  }${element?.userId}${key}`
                                ).then((res) => {
                                  console.log(res.json());
                                  getRoomById(
                                    ls.get("profile")?.userId ??
                                      profileData?.userId
                                  )
                                    .then((res) => res.json())
                                    .then((res2) => {
                                      console.log(res2);
                                      setRooms(res2);
                                      // !id && navigate("/chat/" + res2[0]?.id);
                                      const go = res2.find(
                                        (el) =>
                                          el?.senderId === element?.userId ||
                                          el?.receiverId === element?.userId
                                      )?.id;
                                      go && navigate("/chat/" + go);
                                    });
                                });
                              }
                            }}
                          >
                            <TeacherDiv
                              chat
                              swap
                              name={`${element?.name} ${element?.surname}`}
                              pos={true}
                              role={"Куратор"}
                              onClick={() => {}}
                            />
                          </Card>
                        </div>
                      );
                    })}
                  {curatorSearch?.length ? <span>Продолжить чат</span> : <></>}

                  {rooms?.length &&
                    Object.entries(
                      // Object.groupBy(
                      //   rooms?.filter(
                      //     (e) =>
                      //       e?.title?.includes(search) &&
                      //       (searchRole
                      //         ? searchRole === rolesDict?.[e?.role] ||
                      //           searchRole === 4
                      //         : true)
                      //   ),
                      //   ({ role }) => role
                      // )
                      groupBy(
                        rooms?.filter(
                          (e) =>
                            e?.title?.includes(search) &&
                            (searchRole
                              ? searchRole === rolesDict?.[e?.role] ||
                                searchRole === 4
                              : true)
                        ),
                        "role"
                      )
                    ).map(([role, element2]) => {
                      return element2.map((element) => {
                        return (
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              minWidth:
                                window.width <= 1200 ? "250px" : "200px",
                            }}
                            key={element?.id}
                            onClick={() => handleChoose(element?.id)}
                          >
                            <Card
                              hoverable
                              style={{
                                border:
                                  element?.id == id ? "1px solid grey" : "none",
                                borderRadius: "20px",
                              }}
                              bodyStyle={{
                                padding: "14px",
                              }}
                              onClick={() => navigate("/chat/" + element?.id)}
                            >
                              <TeacherDiv
                                chat
                                swap
                                name={element?.title}
                                pos={true}
                                role={role}
                              />
                            </Card>
                          </div>
                        );
                      });
                    })}
                </div>
              </Space>
            </Col>
          )
        )}
        {id && window.width <= 1000 && (
          <Button
            type="text"
            danger
            style={{ float: "left" }}
            onClick={() => navigate("/chat")}
          >
            Назад к чатам
          </Button>
        )}
        {id && (
          <Col
            span={24}
            xs={24}
            md={24}
            lg={16}
            xl={16}
            id="col-right"
            style={{
              width: "100%",
              overflowY: "scroll",
              overflowX: "scroll",
            }}
          >
            <Outlet />
          </Col>
        )}
      </Row>
    </div>
  );
};

const ChatPage = WithLayout(InnerChat);
export default ChatPage;
