import { Avatar, Button, Card, Col, Progress, Row, Space } from "antd";
import dayjs from "dayjs";
import React, { useContext, useMemo } from "react";
import context from "../../../../context";
import { ArrowRightOutlined, UserOutlined } from "@ant-design/icons";

const CurrentCourse = (props) => {
  const {
    small = false,
    name,
    teacher,
    id,
    delayed = false,
    navigate,
    remainingHours,
    totalHours,
    topics = [],
    type,
    window,
    extra = [],
    remainingHoursCount,
    totalHoursCount,
  } = props;
  const smallWindow = window.width < 1000;
  const { profileData } = useContext(context);

  const filteredTopics = useMemo(
    () =>
      topics
        ?.filter((a) => a.completed === false || a.completed === null)
        ?.sort((a, b) => new Date(a.startAt) - new Date(b.startAt)),
    [topics]
  );

  const topicLastTitle = filteredTopics?.[filteredTopics?.length - 1];
  return (
    <Card
      extra={
        remainingHoursCount > 0 || profileData?.role !== "student"
          ? [
              ...extra,
              <Button
                type="primary"
                size="large"
                style={{ marginTop: "15px" }}
                // style={{ position: "absolute", right: "24px", bottom: "24px" }}
                onClick={() => navigate("/course/" + id)}
                icon={<ArrowRightOutlined />}
                iconPosition="end"
              >
                Перейти к курсу
              </Button>,
            ]
          : []
      }
      // title={name}
      title={
        <Space
          style={{ width: "100%", textAlign: "left" }}
          direction="vertical"
        >
          {!small && (
            <span
              style={{
                fontSize: "14px",
                lineHeight: "16px",
                color: "#757575",
              }}
            >
              Курс
            </span>
          )}
          <span
            style={{
              fontSize: small ? "16px" : "24px",
              lineHeight: small ? "18px" : "26px",
              fontWeight: "bold",
            }}
          >
            {name}
          </span>
        </Space>
      }
      style={{
        width: "100%",
      }}
      bodyStyle={{
        paddingTop: "4px",
        paddingBottom: "16px",
      }}
    >
      <Row gutter={[16, 8]}>
        {/* <Col span={smallWindow ? 24 : 16}> */}
        <Col span={12}>
          <Space
            style={{ width: "100%", textAlign: "left" }}
            direction="vertical"
          >
            <span
              style={{
                fontSize: "16px",
                lineHeight: "18px",
                color: "#757575",
                width: "100%",
              }}
            >
              {type === "individual"
                ? "Индивидуальный"
                : type === "group"
                ? "Групповой"
                : "Парный"}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                // backgroundColor: "#F6F6FA",
                padding: "15px",
                justifyContent: "start",
                borderRadius: "10px",
                alignItems: "start",
              }}
            >
              {!small && (
                <span
                  style={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "#757575",
                  }}
                >
                  Следующая тема
                </span>
              )}
              <span
                style={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: "bold",
                }}
              >
                {topicLastTitle?.title ?? "Уроков нет"}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                backgroundColor: "#F6F6FA",
                padding: "15px",
                justifyContent: "start",
                borderRadius: "10px",
                alignItems: "start",
              }}
            >
              {!small && remainingHoursCount > 0 && totalHoursCount ? (
                // <span
                //   // style={{ width: smallWindow ? "100%" : "50%" }}
                //   className="current-course"
                // >
                <>
                  <span>
                    {!smallWindow && (
                      <>
                        <span
                          style={{
                            fontSize: "14px",
                            // color: "#757575",
                          }}
                        >
                          {`Осталось часов по вашему тарифу: ${remainingHoursCount}/${totalHoursCount}`}
                        </span>
                        {/* <span
                          style={{ fontSize: "14px", fontWeight: "normal" }}
                        >
                          {` ${remainingHoursCount}/${totalHoursCount}`}
                        </span> */}
                      </>
                    )}
                  </span>
                  <Progress
                    percent={(remainingHoursCount / totalHoursCount) * 100}
                    style={{
                      margin: "0px",
                    }}
                    // format={(f) => (
                    //   <span style={{ fontSize: "14px", fontWeight: "normal" }}>
                    //     {`${remainingHoursCount}/${totalHoursCount}`}
                    //     {/* {!smallWindow && (
                    //     <span
                    //       style={{
                    //         fontSize: "14px",
                    //         color: "#757575",
                    //         paddingLeft: "5px",
                    //       }}
                    //     >
                    //       Осталось часов по вашему тарифу
                    //     </span>
                    //   )} */}
                    //   </span>
                    // )}
                    showInfo={false}
                  />
                </>
              ) : (
                // </span>
                profileData?.role === "student" && (
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#757575",
                      paddingLeft: "5px",
                    }}
                  >
                    У вас нет часов по тарифу
                  </span>
                )
              )}
            </div>
          </Space>
        </Col>
        {/* <Col
          span={window.width > 1000 ? 8 : 24}
          style={{ textAlign: window.width > 1000 ? "right" : "left" }}
        > */}
        <Col
          span={12}
          style={{
            textAlign: window.width > 1000 ? "right" : "left",
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              gap: "4px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                backgroundColor: "#F6F6FA",
                padding: "15px",
                justifyContent: "end",
                borderRadius: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    color: "#757575",
                    textAlign: "end",
                  }}
                >
                  Учитель
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/teacher/${teacher.id}`);
                  }}
                >
                  {teacher?.name}
                </span>
              </div>
              <Avatar
                size={38}
                style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
                icon={<UserOutlined style={{ fontSize: "19px" }} />}
              />
            </div>
            {!small && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  backgroundColor: "#F6F6FA",
                  padding: "15px",
                  justifyContent: "end",
                  borderRadius: "10px",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "14px", color: "#757575" }}>
                  {delayed && (
                    <span style={{ color: "red" }}>Урок был перенесен! </span>
                  )}
                  Следующий урок
                </span>
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {topicLastTitle?.startAt
                    ? dayjs(topicLastTitle?.startAt)
                        .tz("Asia/Almaty")
                        .format("DD/MM/YYYY HH:mm")
                    : "Уроков нет"}
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CurrentCourse;
