import React, {
  useEffect,
  useInsertionEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import WithLayout from "../../withLayout";
import {
  Button,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  Result,
  Row,
  Space,
  Spin,
  Switch,
  Upload,
} from "antd";
import { useNavigate } from "react-router";
// import NewsCard from "./news-card";
import { createNews, getLocalNews, getNews, updateNews } from "../../apis";
import context from "../../context";
import ls from "localstorage-slim";
import NewsCard from "../teacher-page/teacher-main-page/news-card";
import { PlusOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getFileFunc = (dataUrlString) => {
  if (!dataUrlString) return [];
  console.log(dataUrlString);
  let encodedString = dataUrlString.replace("data:image/jpeg;base64,", "");
  let data = atob(encodedString);
  let blob = new Blob([data], { type: "optional mime type here" });
  //if you need a literal File object
  let file = new File([blob], "filename.pdf", { type: "image/jpeg" });
  return [file];
};
const NewsListPageTemp = (props) => {
  const { internal = false } = props;
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [edit, setEdit] = React.useState({});
  const [add, setAdd] = React.useState(false);

  const [news, setNews] = useState([]);

  const [title, setTitle] = useState("");
  const [id, setId] = useState();

  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");

  const [photo, setPhoto] = useState();

  const { window, profileData } = React.useContext(context);

  const setNewsTemp = (data) => {
    const newData = data.sort((a, b) => b.id - a.id);
    if (
      ls.get("profile")?.role === "student" ||
      ls.get("profile")?.role === "teacher"
    ) {
      setNews(newData.filter((el) => el.show));
      return;
    }
    setNews(newData);
  };
  const getNewsTemp = internal ? getLocalNews : getNews;
  useInsertionEffect(() => {
    if (ls.get("profile")?.role === "student" && internal)
      navigate("/news-list");
  }, []);

  useEffect(() => {
    getNewsTemp()
      .then((r) => r.json())
      .then((data) => {
        console.log(data);
        setNewsTemp(data);
      });
  }, [internal]);

  //   React.useEffect(() => {
  //     setDrawer(true);
  //   }, [edit]);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
    </button>
  );
  console.log(photo);
  const uploadNode = useMemo(
    () => (
      <Upload
        listType="picture-card"
        fileList={[...(getFileFunc(photo) ?? [])]}
        onPreview={handlePreview}
        accept=".jpeg"
        onChange={handleChange}
        customRequest={({ onSuccess, onError, file }) => {
          getBase64(file).then((r) => setPhoto(r));
          onSuccess();
        }}
      >
        {uploadButton}
      </Upload>
    ),
    []
  );
  return (
    <Space
      direction="vertical"
      size={24}
      style={{
        width: "100%",
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={18} xs={24} md={18} lg={18}>
          <span
            style={{
              fontSize: "36px",
              lineHeight: "40px",
              fontWeight: "bold",
              float: "left",
            }}
          >
            {internal ? "Нововведения" : "Новости"}
          </span>
        </Col>
        {(ls.get("profile")?.role === "supervisor" ||
          profileData?.role === "supervisor" ||
          ls.get("profile")?.role === "headteacher" ||
          profileData?.role === "headteacher") && (
          <Col span={6} xs={24} md={6} lg={6}>
            <Button
              onClick={() => {
                setAdd(true);
              }}
              style={{
                float: "left",
              }}
              type="primary"
              size="large"
            >
              {`Добавить ${internal ? "нововведение" : "новость"}`}
            </Button>
          </Col>
        )}
      </Row>
      {news.map((element) => {
        return (
          <NewsCard
            onClick={() => navigate("/news/" + element.id)}
            singlePhoto
            edit={
              ls.get("profile")?.role === "supervisor" ||
              ls.get("profile")?.role === "headteacher"
            }
            editAction={(e) => {
              e.stopPropagation();
              console.log(element);
              setTitle(element.title);
              setDescription(element.description);
              setShortDescription(element.shortDescription);
              setPhoto(element.picture);
              setId(element.id);
              setShow(element.show);
              setEdit({
                open: true,
                id: element?.id,
              });
            }}
            title={element.title}
            description={element.shortDescription}
            photo={element.picture}
            drawer={() => setEdit(true)}
            click={() => navigate("/news")}
          />
        );
      })}
      <Drawer
        width={window.width < 1000 ? "100%" : "50%"}
        open={edit?.open}
        onClose={() => {
          setTitle("");
          setDescription("");
          setShortDescription("");
          setPhoto("");
          setId();
          setShow(false);
          setEdit({
            open: false,
          });
        }}
      >
        {!success && (
          <Spin spinning={loading}>
            <Space direction="vertical" style={{ width: "100%" }} size={24}>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Заголовок</span>}
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="Заголовок"
                  value={title}
                  onChange={(e) => setTitle(e?.target?.value)}
                />
              </Form.Item>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Фото (jpeg)</span>}
                labelCol={{ span: 24 }}
              >
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Image src={photo} />
                  {uploadNode}
                </Space>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontWeight: "bold" }}>Краткое описание</span>
                }
                labelCol={{ span: 24 }}
              >
                <Input.TextArea
                  rows={5}
                  placeholder="Краткое описание"
                  value={shortDescription}
                  onChange={(e) => setShortDescription(e?.target?.value)}
                />
              </Form.Item>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Описание</span>}
                labelCol={{ span: 24 }}
              >
                <ReactQuill
                  theme="snow"
                  rows={10}
                  value={description}
                  onChange={(e) => setDescription(e)}
                />
              </Form.Item>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Показывать</span>}
                labelCol={{ span: 24 }}
              >
                <Switch checked={show} onChange={(e) => setShow(e)} />
                <span
                  style={{
                    fontSize: window.width > 1000 ? "16px" : "14px",
                    paddingLeft: "8px",
                  }}
                >{`${show ? "Показывать" : "Не показывать"}`}</span>
              </Form.Item>
              <Button
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    updateNews({
                      id,
                      title,
                      description,
                      shortDescription,
                      show,
                      picture: photo,
                      internal: internal,
                    }).then(() => {
                      setSuccess(true);
                      setLoading(false);
                    });
                  }, 300);
                  // setTimeout(() => {
                  //   setSuccess(true);
                  //   setLoading(false);
                  // }, 300);
                }}
                type="primary"
                disabled={!(title && description && shortDescription)} // && photo)}
                size="large"
                style={{ width: "100%" }}
              >
                Обновить статью
              </Button>
            </Space>
          </Spin>
        )}
        {success && (
          <Result
            status="success"
            title="Статья была обновлена!"
            extra={[
              <Button
                type="primary"
                key="console"
                block
                size="large"
                onClick={() => {
                  setEdit({
                    open: false,
                  });
                  getNewsTemp()
                    .then((r) => r.json())
                    .then((data) => setNewsTemp(data));
                  setSuccess(false);
                }}
              >
                Закрыть
              </Button>,
            ]}
          />
        )}
      </Drawer>
      <Drawer
        width={window.width < 1000 ? "100%" : "50%"}
        open={add}
        onClose={() => {
          setTitle("");
          setDescription("");
          setShortDescription("");
          setPhoto("");
          setId();
          setShow(false);
          setAdd(false);
        }}
      >
        {!success && (
          <Spin spinning={loading}>
            <Space direction="vertical" style={{ width: "100%" }} size={24}>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Заголовок</span>}
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="Заголовок"
                  value={title}
                  onChange={(e) => setTitle(e?.target?.value)}
                />
              </Form.Item>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Фото (jpeg)</span>}
                labelCol={{ span: 24 }}
              >
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Image src={photo} />
                  </Col>
                  <Col span={24}>{uploadNode}</Col>
                </Row>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontWeight: "bold" }}>Краткое описание</span>
                }
                labelCol={{ span: 24 }}
              >
                <Input.TextArea
                  rows={5}
                  placeholder="Краткое описание"
                  value={shortDescription}
                  onChange={(e) => setShortDescription(e?.target?.value)}
                />
              </Form.Item>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Описание</span>}
                labelCol={{ span: 24 }}
              >
                <ReactQuill
                  theme="snow"
                  rows={10}
                  value={description}
                  onChange={(e) => setDescription(e)}
                />
              </Form.Item>
              <Button
                onClick={() => {
                  setLoading(true);
                  createNews({
                    title,
                    description,
                    shortDescription,
                    show: true,
                    picture: photo,
                    internal: internal,
                  }).then(() => {
                    setSuccess(true);
                    setLoading(false);
                  });
                  // setTimeout(() => {
                  //   setSuccess(true);
                  //   setLoading(false);
                  // }, 300);
                }}
                type="primary"
                disabled={!(title && description && shortDescription)} // && photo)}
                size="large"
                style={{ width: "100%" }}
              >
                Добавить статью
              </Button>
            </Space>
          </Spin>
        )}
        {success && (
          <Result
            status="success"
            title="Статья была добавлена!"
            extra={[
              <Button
                type="primary"
                key="console"
                block
                size="large"
                onClick={() => {
                  setAdd(false);
                  getNewsTemp()
                    .then((r) => r.json())
                    .then((data) => setNewsTemp(data));
                  setSuccess(false);
                }}
              >
                Закрыть
              </Button>,
            ]}
          />
        )}
      </Drawer>
    </Space>
  );
};

const NewsListPage = WithLayout(NewsListPageTemp);
export default NewsListPage;
