import {ReactComponent as Calendar2 } from '../../../../media/icons/calendar.svg';

import "./styles.css"
import {Avatar, Button, Progress} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {EditDrawer} from "./edit-drawer";
export const GroupSchedulePage = () => {
    return (
        <div className="group-schedule__body">
            <div style={{width: "100%"}}>
                <div style={{backgroundColor: "#EBEBF0", borderRadius: '10px', padding: '20px'}}>
                    <div style={{fontSize: '30px'}}>+</div>
                    <div style={{fontWeight: '700',}}>Добавить группу</div>
                </div>

                <div className="group-schedule__card">
                    <div className="group-schedule__card__title">
                        <div style={{width: '50%'}}>
                            <div style={{display: 'flex', justifyContent: 'left'}}>Курс</div>
                            <div style={{fontSize: '24px', fontWeight: "600"}}>Gruppenunterricht Deutsch A1 13:00 – 13:40</div>
                        </div>
                        <Button className="group-schedule__card__delete-btn" >Удалить из группы <DeleteOutlined /></Button>
                    </div>
                    <div className="group-schedule__card__teacher">
                        <Avatar size={20}/>
                        <div style={{color: '#818199'}}>Учитель</div>
                        <div style={{fontSize: '14px'}}>Айжан Нуржанкызы</div>
                    </div>
                    <div style={{display: 'flex', gap: '20px', marginTop: '10px'}}>
                        <div className="group-schedule__card__teacher">
                            13:00 – 13:40 (Пн, Вт, Пт)
                        </div>
                        <div className="group-schedule__card__teacher">
                            Индивидуальные занятия
                        </div>
                    </div>
                    <div className="group-schedule__card__footer">
                        <div style={{display: 'flex', gap: '10px'}}>
                            <div className="group-schedule__card__teacher">
                                Посещаемость 68%
                            </div>
                            <div style={{color: '#B40A1B', padding: '5px', textDecoration: 'underline'}}>
                                Смотреть посещаемость
                            </div>
                        </div>
                        <div>
                           <EditDrawer />
                        </div>
                    </div>
                </div>

                <div className="group-schedule__card">
                    <div className="group-schedule__card__title">
                        <div style={{width: '50%'}}>
                            <div style={{display: 'flex', justifyContent: 'left'}}>Курс</div>
                            <div style={{fontSize: '24px', fontWeight: "600"}}>Gruppenunterricht Deutsch A1 13:00 – 13:40</div>
                        </div>
                        <Button className="group-schedule__card__delete-btn" >Удалить из группы <DeleteOutlined /></Button>
                    </div>
                    <div className="group-schedule__card__teacher">
                        <Avatar size={20}/>
                        <div style={{color: '#818199'}}>Учитель</div>
                        <div style={{fontSize: '14px'}}>Айжан Нуржанкызы</div>
                    </div>
                    <div style={{display: 'flex', gap: '20px', marginTop: '10px'}}>
                        <div className="group-schedule__card__teacher">
                            13:00 – 13:40 (Пн, Вт, Пт)
                        </div>
                        <div className="group-schedule__card__teacher">
                            Индивидуальные занятия
                        </div>
                    </div>
                    <div className="group-schedule__card__footer">
                        <div style={{display: 'flex', gap: '10px'}}>
                            <div className="group-schedule__card__teacher">
                                Посещаемость 68%
                            </div>
                            <div style={{color: '#B40A1B', padding: '5px', textDecoration: 'underline'}}>
                                Смотреть посещаемость
                            </div>
                        </div>
                        <div>
                            <EditDrawer />
                        </div>
                    </div>
                </div>


                <div className="group-schedule__card">
                    <div className="group-schedule__card__title">
                        <div style={{width: '50%'}}>
                            <div style={{display: 'flex', justifyContent: 'left'}}>Курс</div>
                            <div style={{fontSize: '24px', fontWeight: "600"}}>Gruppenunterricht Deutsch A1 13:00 – 13:40</div>
                        </div>
                        <Button className="group-schedule__card__delete-btn" >Удалить из группы <DeleteOutlined /></Button>
                    </div>
                    <div className="group-schedule__card__teacher">
                        <Avatar size={20}/>
                        <div style={{color: '#818199'}}>Учитель</div>
                        <div style={{fontSize: '14px'}}>Айжан Нуржанкызы</div>
                    </div>
                    <div style={{display: 'flex', gap: '20px', marginTop: '10px'}}>
                        <div className="group-schedule__card__teacher">
                            13:00 – 13:40 (Пн, Вт, Пт)
                        </div>
                        <div className="group-schedule__card__teacher">
                            Индивидуальные занятия
                        </div>
                    </div>
                    <div className="group-schedule__card__footer">
                        <div style={{display: 'flex', gap: '10px'}}>
                            <div className="group-schedule__card__teacher">
                                Посещаемость 68%
                            </div>
                            <div style={{color: '#B40A1B', padding: '5px', textDecoration: 'underline'}}>
                                Смотреть посещаемость
                            </div>
                        </div>
                        <div>
                            <EditDrawer />
                        </div>
                    </div>
                </div>

            </div>
            <div>
              <div style={{display: "flex", flexWrap: 'wrap'}}>
                  <Calendar2 />
              </div>
              <div className="group-schedule__progress">
                  <div style={{display: 'flex' , gap: '20px'}}>
                      <Avatar />
                      <div style={{marginTop: '5px', fontWeight: '700'}}>Индивидуальные занятия</div>
                  </div>
                  <div style={{backgroundColor: '#F6F6FA', padding: '5px', borderRadius: '10px', marginTop: '10px'}}>
                      <Progress percent={80} status="active" showInfo={false}/>
                  </div>
                  <div style={{display: 'flex' , gap: '20px', marginTop: '20px'}}>
                      <Avatar />
                      <div style={{marginTop: '5px', fontWeight: '700'}}>Групповые занятия</div>
                  </div>
                  <div style={{backgroundColor: '#F6F6FA', padding: '5px', borderRadius: '10px', marginTop: '10px'}}>
                      <Progress percent={100} status="active" showInfo={false}/>
                  </div>
                  <div style={{marginTop: '20px'}}>
                      <Button style={{backgroundColor: '#2B2A2A', width: '100%', color: 'white'}}>Добавить тариф</Button>
                  </div>
                 </div>
            </div>
        </div>
    )
}