import dayjs from "dayjs";
import ls from "localstorage-slim";
export const url = "https://ultima.deutschkz.online/api";

export const login = (username, password) => {
  return fetch(`${url}/user/login`, {
    method: "POST",
    headers: new Headers({ "content-type": "application/json" }),
    body: JSON.stringify({
      username,
      password,
    }),
  });
};

export const inviteStudent = (
  email,
  phone,
  name,
  surname,
  birthday,
  timePackageType,
  timePackageAmount
) => {
  return fetch(`${url}/student/invite`, {
    method: "POST",
    headers: {
      // ...new Headers({ "content-type": "application/json" }),
      "Content-Type": "application/json",
      Authorization: "Bearer " + ls.get("token"),
    },
    body: JSON.stringify({
      email,
      phone,
      name,
      surname,
      birthday,
      timePackageType,
      timePackageAmount,
    }),
  });
};

export const inviteCurator = (email, phone, name, surname) => {
  return fetch(`${url}/head/teacher/invite`, {
    method: "POST",
    headers: {
      // ...new Headers({ "content-type": "application/json" }),
      "Content-Type": "application/json",
      Authorization: "Bearer " + ls.get("token"),
    },
    body: JSON.stringify({
      email,
      phone,
      name,
      surname,
    }),
  });
};

export const inviteTeacher = (
  email,
  phone,
  name,
  surname,
  currency,
  individualPrice,
  pairPrice,
  groupPrice,
  isPayableForCanceledLesson,
  bankDetails
) => {
  return fetch(`${url}/teacher/invite`, {
    method: "POST",
    headers: {
      // ...new Headers({ "content-type": "application/json" }),
      "Content-Type": "application/json",
      Authorization: "Bearer " + ls.get("token"),
    },
    body: JSON.stringify({
      email,
      phone,
      name,
      surname,
      currency,
      individualPrice,
      pairPrice,
      groupPrice,
      isPayableForCanceledLesson,
      bankDetails,
    }),
  });
};

export const getStudents = (token) => {
  return fetch(`${url}/student`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getStudentCourses = () => {
  return fetch(`${url}/course/student`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const getTimePackages = (studentId, showAll) => {
  // /api/time/package/student/{studentId}
  return fetch(
    `${url}/time/package/${showAll ? "" : "course/"}student/${studentId}`,
    {
      headers: {
        Authorization: "Bearer " + ls.get("token"),
      },
    }
  );
};

export const getHeadTeachers = (token) => {
  return fetch(`${url}/head/teacher`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

// {
//   "id": 1,
//   "title": "1",
//   "description": "sdfgsdgdfg",
//   "picture": "testsdfsdgsdfsdf"
// }
export const createNews = (body) => {
  return fetch(`${url}/news`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const updateNews = (body) => {
  return fetch(`${url}/news`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const getNewsById = (id) => {
  // /api/time/package/student/{studentId}
  return fetch(`${url}/news/${id}`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const getNews = () => {
  // /api/time/package/student/{studentId}
  return fetch(`${url}/news`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const getLocalNews = () => {
  // /api/time/package/student/{studentId}
  return fetch(`${url}/news?only-internal=true`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

// /api/course/subject/{courseSubjectId}/update/status?canceled={false|true}
export const getSubject = (subjId) => {
  return fetch(`${url}/course/subject/${subjId}`, {
    method: "GET",
    headers: new Headers({
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

// /api/course/subject/{courseSubjectId}/update/status?canceled={false|true}
export const updateSubjectCanceled = (subjId, canc) => {
  return fetch(
    `${url}/course/subject/${subjId}/update/status?canceled=${canc}`,
    {
      method: "PATCH",
      headers: new Headers({
        authorization: "Bearer " + ls.get("token"),
      }),
      // {
      //   Authorization: "Bearer " + ls.get("token"),
      // },
    }
  );
};

// payment/rate/teacher/report/month/{monthId},
export const getPaymentForMonth = (id) => {
  // /api/time/package/student/{studentId}
  return fetch(
    `${url}/payment/rate/teacher/report/month/${id}?currentTimestamp=${dayjs().format(
      "YYYY-MM-DDTHH:mm:ss"
    )}`,
    {
      headers: {
        Authorization: "Bearer " + ls.get("token"),
      },
    }
  );
};

export const addAttendance = (id) => {
  return fetch(`${url}/course/subject/${id}/student`, {
    method: "POST",
    body: JSON.stringify({}),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const putStudentById = (id, body) => {
  return fetch(`${url}/student/${id}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

// payment/rate/teacher/report/month/{monthId}

export const getTeacherPaymentsById = (id) => {
  return fetch(`${url}/payment/rate/teacher/${id}`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const getTeacherPayment = (monthId) => {
  return fetch(
    `${url}/payment/rate/teacher/report/month/${monthId}?currentTimestamp=${dayjs().format(
      "YYYY-MM-DDTHH:mm:ss"
    )}`,
    {
      headers: {
        Authorization: "Bearer " + ls.get("token"),
      },
    }
  );
};

export const getTeacherPaymentById = (monthId, teacherId) => {
  return fetch(
    `${url}/payment/rate/teacher/${teacherId}/report/month/${monthId}?currentTimestamp=${dayjs().format(
      "YYYY-MM-DDTHH:mm:ss"
    )}`,
    {
      headers: {
        Authorization: "Bearer " + ls.get("token"),
      },
    }
  );
};

export const getStudentById = (id) => {
  return fetch(`${url}/student/user/${id}`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const getStudentCourseNear = () => {
  return fetch(`${url}/course/subject/student/recent`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

// /student/send/application

//  /api/payment/rate/teacher

export const updateTeacherRate = (body, id) => {
  return fetch(`${url}/payment/rate/teacher`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const sendNewCourseRequest = (name, phone, email, level, studyGoal) => {
  return fetch(`${url}/student/send/application`, {
    method: "POST",
    body: JSON.stringify({ name, phone, email, level, studyGoal }),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const postHomeWork = (id, id2, body) => {
  return fetch(`${url}/homework/course/subject/${id}/student/${id2}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const putHomeWork = (body) => {
  return fetch(`${url}/homework`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

// export const postNotification = ({ title, content, userIdList, roleGroup }) => {
//   return fetch(`${url}/notification`, {
//     method: "POST",
//     body: JSON.stringify({ title, content, userIdList, roleGroup }),
//     headers: new Headers({
//       "content-type": "application/json",
//       authorization: "Bearer " + ls.get("token"),
//     }),
//     // {
//     //   Authorization: "Bearer " + ls.get("token"),
//     // },
//   });
// };

export const putTeacherById = (id, body) => {
  return fetch(`${url}/teacher/${id}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const putHeadTeacherById = (id, body) => {
  return fetch(`${url}/head/teacher/${id}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const getTeacherPayments = () => {
  return fetch(`${url}/payment/rate/teacher`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const getTeacherById = (id) => {
  return fetch(`${url}/teacher/user/${id}`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const getHeadTeacherById = (id) => {
  return fetch(`${url}/head/teacher/${id}`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const deleteUser = (id) => {
  return fetch(`${url}/user/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const getUser = (id, token) => {
  return fetch(`${url}/user/${id}`, {
    headers: {
      Authorization: `Bearer ${token ? token : ls.get("token")}`,
    },
  });
};

export const getCurators = (token) => {
  return fetch(`${url}/head/teacher`, {
    headers: {
      Authorization: `Bearer ${token ? token : ls.get("token")}`,
    },
  });
};

export const booksList = (token) => {
  return fetch(`${url}/books`, {
    headers: {
      Authorization: `Bearer ${token ? token : ls.get("token")}`,
    },
  });
};

export const getTeachers = (token) => {
  return fetch(`${url}/teacher`, {
    headers: {
      Authorization: `Bearer ${token ? token : ls.get("token")}`,
    },
  });
};

export const deleteCourse = (id) => {
  return fetch(`${url}/course/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${ls.get("token")}`,
    },
  });
};

export const getCourses = (token, files = true) => {
  return fetch(`${url}/course${files ? `?exclude-files=${files}` : ""}`, {
    headers: {
      Authorization: `Bearer ${token ? token : ls.get("token")}`,
    },
  });
};

export const changePassword = (curr, newpass) => {
  return fetch(`${url}/user/password`, {
    method: "PATCH",
    body: JSON.stringify({
      currentPassword: curr,
      newPassword: newpass,
    }),
    headers: {
      authorization: "Bearer " + ls.get("token"),
      "content-type": "application/json",
    },
  });
};

export const putCourse = () => {
  return fetch(`${url}/course`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};
// /api/notification/{notification_id}/user

export const deleteNotifications = (id) => {
  return fetch(`${url}/notification/${id}/user`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const getNotifications = () => {
  return fetch(`${url}/notification/user`, {
    headers: {
      Authorization: "Bearer " + ls.get("token"),
    },
  });
};

export const pushNotificationApi = (title, content, userIdList, roleGroup) => {
  return fetch(`${url}/notification`, {
    method: "POST",
    body: JSON.stringify({
      title,
      content,
      userIdList,
      roleGroup,
    }),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const getUserRoles = ({ ids }) => {
  return fetch(`${url}/user/roles`, {
    method: "POST",
    body: JSON.stringify({
      userIdList: ids,
    }),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const getRoomById = (id) => {
  return fetch(`${url}/room/user/${id}`, {
    headers: new Headers({
      authorization: "Bearer " + ls.get("token"),
    }),
  });
};

//http://localhost:80/api/room/1/messages

export const getChatRoomMsgs = (id) => {
  return fetch(`${url}/room/${id}/messages`, {
    headers: new Headers({
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const createChatRoom = (id, id2, title) => {
  return fetch(`${url}/room`, {
    method: "POST",
    body: JSON.stringify({
      senderId: id,
      receiverId: id2,
      title: title,
    }),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const postCourse2 = (body) => {
  return fetch(`${url}/course`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const postCourse = (id, body) => {
  return fetch(`${url}/course/` + id, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const postHomework = (id, body, type = "PUT") => {
  return fetch(`${url}/homework`, {
    method: type,
    body: JSON.stringify(body),
    headers: new Headers({
      "content-type": "application/json",
      authorization: "Bearer " + ls.get("token"),
    }),
    // {
    //   Authorization: "Bearer " + ls.get("token"),
    // },
  });
};

export const registerStudent = (
  username,
  password,
  name,
  surname,
  email,
  phone
) => {
  return fetch(`${url}/student`, {
    method: "POST",
    headers: new Headers({ "content-type": "application/json" }),
    body: JSON.stringify({
      username,
      password,
      name,
      surname,
      email,
      phone,
    }),
  });
};

export const registerCurator = (
  username,
  password,
  name,
  surname,
  email,
  phone
) => {
  return fetch(`${url}/head/teacher`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      password,
      name,
      surname,
      email,
      phone,
    }),
  });
};

export const fetchNewsList = () => {
  return fetch("http://195.49.215.141/api/news").then((response) =>
    response.json()
  );
};

export const fetchVideoClassList = () => {
  return fetch("http://195.49.215.141/api/video/class").then((response) =>
    response.json()
  );
};
