import { UserOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Row,
  Space,
  Button,
  Avatar,
  Divider,
  Modal,
  Carousel,
  Image,
} from "antd";
import React, { useContext } from "react";
import ReactPlayer from "react-player";
import context from "../../../../context";

const contentStyle = {
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const TeacherCard = (props) => {
  const { teacher } = props;
  const { name, level, experience, location, about, photo, url } = {
    ...teacher,
  };

  const [open, setOpen] = React.useState(false);
  const [openBio, setOpenBio] = React.useState(false);
  const { window } = useContext(context);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "white",
      }}
    >
      <Row>
        <Col span={18} xs={24} md={24} lg={18}>
          <Card
            style={{ backgroundColor: "transparent", border: "0" }}
            bodyStyle={{ textAlign: "left" }}
          >
            {window.width > 1000 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  alignContent: "center",
                  width: "100%",
                  height: "100%",
                  padding: "15px",
                }}
              >
                <Avatar
                  size={160}
                  style={{
                    marginRight: "5px",
                    backgroundColor: "#FFD600",
                    marginTop: "15px",
                    minWidth: "160px",
                    minHeight: "160px",
                  }}
                  src={photo}
                  icon={<UserOutlined style={{ fontSize: "80px" }} />}
                />
                <div
                  style={{
                    paddingTop: "15px",
                    gap: "16px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <span style={{ fontSize: "24px", lineHeight: "26px" }}>
                      {name}
                    </span>
                    <span
                      style={{ fontSize: "12px", color: "rgb(170, 170, 170)" }}
                    >
                      Преподаватель немецкого языка
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <span
                      style={{ fontSize: "12px", color: "rgb(170, 170, 170)" }}
                    >
                      Уровень языка:
                    </span>
                    <span style={{ fontSize: "18px" }}>{level}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <span
                      style={{ fontSize: "12px", color: "rgb(170, 170, 170)" }}
                    >
                      Опыт преподавания:
                    </span>
                    <span style={{ fontSize: "18px" }}>{experience}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  alignContent: "center",
                  width: "100%",
                  height: "100%",
                  padding: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    size={120}
                    style={{
                      marginRight: "5px",
                      backgroundColor: "#FFD600",
                      marginTop: "15px",
                      minWidth: "120px",
                      minHeight: "120px",
                    }}
                    src={photo}
                    icon={<UserOutlined style={{ fontSize: "60px" }} />}
                  />
                  <div
                    style={{
                      paddingTop: "15px",
                      gap: "16px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <span style={{ fontSize: "24px", lineHeight: "26px" }}>
                        {name}
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "rgb(170, 170, 170)",
                        }}
                      >
                        Преподаватель немецкого языка
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <span
                      style={{ fontSize: "12px", color: "rgb(170, 170, 170)" }}
                    >
                      Уровень языка:
                    </span>
                    <span style={{ fontSize: "18px" }}>{level}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <span
                      style={{ fontSize: "12px", color: "rgb(170, 170, 170)" }}
                    >
                      Опыт преподавания:
                    </span>
                    <span style={{ fontSize: "18px" }}>{experience}</span>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </Col>
        <Col
          span={1}
          xs={24}
          md={24}
          lg={1}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Divider
            type={window?.width > 1000 ? "vertical" : "horizontal"}
            style={{
              borderInlineStart: "2px solid rgba(5, 5, 5, 0.06)",
              height: window?.width > 1000 ? "100%" : "auto",
              margin: "0px",
            }}
          />
        </Col>
        <Col span={5} xs={24} md={24} lg={5}>
          <Space
            direction={window?.width > 1000 ? "vertical" : "horizontal"}
            size={8}
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              paddingBottom: "20px",
            }}
          >
            {/* <ReactPlayer controls width={"100%"} height={"480px"} url={url} /> */}

            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={() => setOpenBio(true)}
            >
              Подробнее
            </Button>
            {url && (
              <Button size="large" shape="round" onClick={() => setOpen(true)}>
                Видео
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <Modal
        closeIcon={false}
        open={open}
        width={"50%"}
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
        }}
        onCancel={() => setOpen(false)}
        footer={[]}
      >
        <ReactPlayer controls width={"100%"} height={"480px"} url={url} />
      </Modal>
      <Modal
        closeIcon={false}
        open={openBio}
        width={"70%"}
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
        }}
        onCancel={() => setOpenBio(false)}
        footer={[]}
      >
        <div
          style={{
            width: "100%",
            padding: "5%",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            gap: "16px",
          }}
        >
          <span style={{ fontSize: "22px", fontWeight: "bold" }}>
            С Вами будут заниматься из:
          </span>
          <span>{location}</span>
          <span style={{ fontSize: "22px", fontWeight: "bold" }}>
            О преподавателе:
          </span>
          {about}
          <div>
            {/* <Image.PreviewGroup

            >
              <Image
                width={200}
                src='https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp'
              />
              <Image
                width={200}
                src='https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp'
              />
            </Image.PreviewGroup> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TeacherCard;
