import React, { useEffect, useState } from "react";
import {
  Button,
  Spin,
  Space,
  Card,
  Form,
  InputNumber,
  Drawer,
  Table,
  Empty,
  Select,
  Row,
  Col,
  Input,
} from "antd";
import context from "../../../../context";
import {
  putHeadTeacherById,
  putStudentById,
  putTeacherById,
  getTeacherPayment,
  getTeacherPayments,
  getTeacherPaymentsById,
  updateTeacherRate,
  getTeacherPaymentById,
  getCourses,
} from "../../../../apis";
import dayjs from "dayjs";

const opts = [
  {
    label: "KZT",
    value: "KZT",
  },
  {
    label: "RUB",
    value: "RUB",
  },
  {
    label: "USD",
    value: "USD",
  },
];
const PaymentInfo = (props) => {
  const { dataUpdated, view, currUser, supervisor, teacher, headteacher } =
    props;

  const { window, profileData } = React.useContext(context);
  console.log(profileData);
  const [individ, setIndivid] = React.useState(0);
  const [group, setGroup] = React.useState(0);
  const [pair, setPair] = React.useState(0);

  const [currencyIndivid, setCurrencyIndivid] = React.useState();
  const [currencyGroup, setCurrencyGroup] = React.useState();
  const [currencyPair, setCurrencyPair] = React.useState();

  const [loading, setLoading] = React.useState(false);
  const [showReport, setShowReport] = React.useState(false);
  const [totalSum, setTotalSum] = React.useState({});

  const [courses, setCourses] = React.useState([]);
  const [coursesTemp, setCoursesTemp] = React.useState([]);

  const [currentCourse, setCurrentCourse] = React.useState({});
  const [currentMonth, setCurrentMonth] = React.useState(
    new Date().getMonth() + 1
  );

  const [penaly, setPenalty] = useState(currUser?.isPayableForCanceledLesson);
  const [creds, setCreds] = useState(currUser?.bankDetails);

  React.useEffect(() => {
    console.log(currUser);
    if (
      currUser?.id &&
      (currUser?.roleProfile === "teacher" || currUser?.role === "teacher")
    ) {
      setPenalty(currUser?.isPayableForCanceledLesson);
      setCreds(currUser?.bankDetails);
      getTeacherPaymentsById(currUser?.id)
        .then((r) => r.json())
        .then((r) => {
          console.log(r);
          const individ = r?.find((el) => el.type === "individual");
          const group = r?.find((el) => el.type === "group");
          const pair = r?.find((el) => el.type === "pair");

          setIndivid(individ?.amount);
          setGroup(group?.amount);
          setPair(pair?.amount);
          setCurrencyIndivid(individ?.currencyCode);
          setCurrencyGroup(group?.currencyCode);
          setCurrencyPair(pair?.currencyCode);
          return r;
        });
      getCourses()
        .then((r) => r.json())
        .then((r) => {
          console.log(r);
          const temp =
            r?.filter &&
            r?.filter((el) =>
              el?.teacherList?.find((elF) => elF?.id == currUser?.id)
            );
          console.log(temp);
          setCourses(temp);
        });
    }
  }, [currUser]);

  const getData = () => {
    putTeacherById(currUser?.id, {
      ...currUser,
      isPayableForCanceledLesson: penaly,
      bankDetails: creds,
    })
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        dataUpdated();
      });
  };

  const [currId, setCurrId] = useState();

  const getTotalSum = (month) => {
    getTeacherPaymentById(month ?? currentMonth, currUser?.id)
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        let sum = 0;
        for (let i = 0; i < r?.paymentReportResponses?.length; i++) {
          if (r?.paymentReportResponses?.[i].total !== null)
            sum += r?.paymentReportResponses?.[i].total;
        }
        setTotalSum({
          ...r,
          totalSum: sum,
        });
        month && setShowReport(true);
      });
  };
  const getTable = (val) => {
    setCurrId(val);
    console.log(val, currentMonth, courses);
    let temp = { ...courses?.find((el) => el?.id == val) };
    if (temp) {
      temp.courseSubjects = temp?.courseSubjects
        ?.filter((el) => {
          console.log(dayjs(el.startAt).month() + 1, currentMonth);
          return dayjs(el.startAt).month() + 1 == currentMonth;
        })
        .map((el, i) => {
          const date = dayjs(el?.startAt).format("DD/MM/YYYY, HH:mm");
          return {
            ...el,
            dataIndex: date,
            key: i,
            title: date,
          };
        });
      temp.studentListReport = temp?.studentList?.map((el) => {
        const obj = temp?.courseSubjects?.map((el2) => ({
          [el2?.dataIndex]: el2?.completed
            ? el2?.studentList?.find((el3) => el3?.id == el?.id)
              ? "Был"
              : "Не был"
            : "Не пройден",
        }));
        let objFin = {};
        obj.forEach((element) => {
          objFin = {
            ...objFin,
            ...element,
          };
        });
        return {
          student: `${el?.name} ${el?.surname}`,
          ...objFin,
        };
      });
    }
    setCurrentCourse({ ...temp });
  };

  useEffect(() => {
    currentMonth && currId && getTable(currId);
    currentMonth && getTotalSum();
  }, [currentMonth, currId]);

  return !(individ && pair && group) ? null : (
    <Card style={{ width: "100%" }}>
      <Spin spinning={loading}>
        <div
          style={{
            width: "100%",
            alignItems: "start",
            display: "flex",
            flexDirection: window.width <= 1200 ? "column" : "row",
            gap: "16px",
          }}
          size="large"
        >
          <Space
            direction="vertical"
            style={{ width: "100%", alignItems: "start" }}
          >
            <span style={{ fontWeight: "bold", fontSize: "24px" }}>
              Информация об оплате
            </span>
            <span style={{ color: "#818199", fontSize: "16px" }}>
              {!view && "Введите актуальную информацию"}
            </span>
          </Space>
          <div
            style={{
              width: "100%",
              alignItems: "start",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Row gutter={[4, 4]} style={{ width: "100%" }}>
              <Col span={20}>
                <Form.Item
                  label="Реквизиты"
                  labelCol={{
                    span: 24,
                  }}
                  style={{ width: "100%" }}
                >
                  <Input
                    value={creds}
                    onChange={(e) => setCreds(e?.target?.value)}
                    readOnly={teacher}
                    placeholder="Реквизиты"
                    style={{ width: "100%" }}
                    addonAfter={
                      <Select
                        placeholder="Валюта"
                        onSelect={(e) => {
                          console.log(e);
                          setCurrencyIndivid(e);
                        }}
                        value={currencyIndivid}
                        options={opts}
                      />
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="Компенсация"
                  labelCol={{
                    span: 24,
                  }}
                  style={{ width: "100%" }}
                >
                  <Select
                    value={penaly}
                    onSelect={(e) => setPenalty(e)}
                    options={[
                      {
                        label: "Да",
                        value: true,
                      },
                      {
                        label: "Нет",
                        value: false,
                      },
                    ]}
                    disabled={teacher}
                    placeholder="Компенсация"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Индивидуальное занятие"
              labelCol={{
                span: 24,
              }}
              style={{ width: "100%" }}
            >
              <InputNumber
                onChange={(e) => setIndivid(e)}
                value={individ}
                suffix={currencyIndivid}
                readOnly={teacher}
                placeholder="Индивидуальное занятие"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label="Групповое занятие"
              labelCol={{
                span: 24,
              }}
              style={{ width: "100%" }}
            >
              <InputNumber
                onChange={(e) => setGroup(e)}
                value={group}
                readOnly={teacher}
                suffix={currencyIndivid}
                placeholder="Групповое занятие"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label="Парное занятие"
              labelCol={{
                span: 24,
              }}
              style={{ width: "100%" }}
            >
              <InputNumber
                onChange={(e) => setPair(e)}
                value={pair}
                readOnly={teacher}
                suffix={currencyIndivid}
                placeholder="Парное занятие"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Space direction="horizontal">
              <Button
                size="large"
                onClick={() => {
                  getTotalSum(new Date().getMonth() + 1);
                }}
              >
                Показать отчет
              </Button>
              {(headteacher || supervisor) && (
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    setLoading(true);
                    setTimeout(() => {
                      getData();
                      updateTeacherRate(
                        {
                          teacherId: currUser?.id,
                          currency: currencyIndivid,
                          ratesToUpdate: [
                            {
                              type: "individual",
                              amount: individ,
                            },
                            {
                              type: "group",
                              amount: group,
                            },
                            {
                              type: "pair",
                              amount: pair,
                            },
                          ],
                        },
                        currUser?.id
                      ).finally(() => setLoading(false));
                    }, 500);
                  }}
                  disabled={!individ && !group && !pair}
                >
                  Сохранить
                </Button>
              )}
            </Space>
          </div>
        </div>
      </Spin>
      <Drawer
        open={showReport}
        onClose={() => setShowReport(false)}
        width={window.width < 1000 ? "100%" : "50%"}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row gutter={[4, 4]}>
            <Col span={12}>
              <Select
                style={{ width: "100%" }}
                placeholder="Выберите урок"
                onSelect={(val) => getTable(val)}
                options={courses?.map((el) => ({
                  label: el?.title,
                  value: el?.id,
                }))}
              />
            </Col>
            <Col span={12}>
              <Select
                style={{ width: "100%" }}
                value={currentMonth}
                onSelect={(e) => {
                  setCurrentMonth(e);
                }}
                options={[
                  {
                    label: "Январь",
                    value: 1,
                  },
                  {
                    label: "Февраль",
                    value: 2,
                  },
                  {
                    label: "Март",
                    value: 3,
                  },
                  {
                    label: "Апрель",
                    value: 4,
                  },
                  {
                    label: "Май",
                    value: 5,
                  },
                  {
                    label: "Июнь",
                    value: 6,
                  },
                  {
                    label: "Июль",
                    value: 7,
                  },
                  {
                    label: "Август",
                    value: 8,
                  },
                  {
                    label: "Сентябрь",
                    value: 9,
                  },
                  {
                    label: "Октябрь",
                    value: 10,
                  },
                  {
                    label: "Ноябрь",
                    value: 11,
                  },
                  {
                    label: "Декабрь",
                    value: 12,
                  },
                ]}
                placeholder="Выберите месяц"
              />
            </Col>
          </Row>
          {currentCourse?.id && (
            <Table
              scroll={{
                x: true,
              }}
              columns={[
                {
                  dataIndex: "student",
                  title: "Студент",
                  key: "student",
                },
                ...currentCourse?.courseSubjects,
              ]}
              pagination={false}
              dataSource={currentCourse?.studentListReport}
              locale={{
                emptyText: <Empty description="Нет данных" />,
              }}
            />
          )}
          <Table
            columns={[
              {
                dataIndex: "type",
                title: "Тип урока",
                key: "type",
              },
              {
                dataIndex: "amount",
                title: "Количество уроков",
                key: "amount",
              },
              {
                dataIndex: "sum",
                title: "Оплата",
                key: "sum",
              },
            ]}
            pagination={false}
            dataSource={totalSum?.paymentReportResponses?.map((el) => {
              return {
                sum: el?.total === null ? 0 : el?.total,
                type:
                  el?.type === "individual"
                    ? "Индивид."
                    : el?.type === "group"
                    ? "Групповой"
                    : "Парный",
                amount: el?.subjectsCount,
              };
            })}
            locale={{
              emptyText: <Empty description="Нет данных" />,
            }}
          />
          <Form.Item label="Общая сумма">
            <InputNumber value={totalSum?.totalSum} readOnly />
          </Form.Item>
        </Space>
      </Drawer>
    </Card>
  );
};

export default PaymentInfo;
