import { Row, Col, Button } from "antd";
import React, { useContext } from "react";
import logo from "../../../../media/icons/red-back.svg";
import context from "../../../../context";
import ls from "localstorage-slim";
const HomeworkStatus = (props) => {
  const { courses } = props;

  const { profileData } = useContext(context);

  const getSkippedHw = () => {
    console.log(courses);
    let number = 0;
    for (let i = 0; i < courses?.length; i++) {
      courses[i]?.courseSubjects.filter((el) => {
        const foundHwUser = el?.homeworkList?.find(
          (el) => el.studentId === (profileData?.id ?? ls.get("profile")?.id)
        );
        if (
          el.homeworkTitle &&
          ((foundHwUser && foundHwUser.completed) || !foundHwUser)
        ) {
          number++;
        }
      });
    }
    return number;
  };

  return (
    <Row
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "25px",
        backgroundColor: "#FA3F48",
        backgroundImage: `url(${logo})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        paddingLeft: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        display: "flex",
      }}
    >
      <Col
        span={24}
        style={{
          paddingLeft: "25px",
          paddingTop: "25px",
          paddingBottom: "25px",
          display: "flex",
          gap: "32px",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <span
          style={{
            // paddingLeft: "25px",
            // paddingTop: "25px",
            // paddingBottom: "25px",
            fontSize: "20px",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {`У вас осталось ${getSkippedHw()} задании для выполнения`}
        </span>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          padding: "15px",
          paddingTop: "20px",
          justifyContent: "left",
        }}
      >
        <Button
          type="primary"
          style={{ backgroundColor: "black", color: "white" }}
          size="large"
        >
          Смотреть задание
        </Button>
      </Col>
    </Row>
  );
};

export default HomeworkStatus;
