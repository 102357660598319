import {ReactComponent as Calendar2 } from '../../../../media/icons/calendar.svg';

import "./styles.css"
import {Avatar, Button, Progress} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {EditDrawer} from "./edit-drawer";
export const GroupSchedulePage = () => {
    return (
        <div className="group-schedule__body">
            <div style={{width: "100%"}}>
                <div style={{backgroundColor: "#EBEBF0", borderRadius: '10px', padding: '20px'}}>
                    <div style={{fontSize: '30px'}}>+</div>
                    <div style={{fontWeight: '700',}}>Создать новую группу</div>
                </div>

                <div className="group-schedule__card">
                    <div className="group-schedule__card__title">
                        <div style={{width: '50%'}}>
                            <div style={{display: 'flex', justifyContent: 'left'}}>Курс</div>
                            <div style={{fontSize: '24px', fontWeight: "600"}}>Gruppenunterricht Deutsch A1 13:00 – 13:40</div>
                        </div>
                        <Button className="group-schedule__card__delete-btn" >Удалить из группы <DeleteOutlined /></Button>
                    </div>
                    <div className="group-schedule__card__footer">
                        <div style={{display: 'flex', gap: '10px'}}>
                            <div className="group-schedule__card__teacher">
                                13:00 – 13:40 (Пн, Вт, Пт)
                            </div>
                            <div className="group-schedule__card__teacher">
                                Индивидуальные занятия
                            </div>
                        </div>
                        <div>
                           <EditDrawer />
                        </div>
                    </div>
                </div>

                <div className="group-schedule__card">
                    <div className="group-schedule__card__title">
                        <div style={{width: '50%'}}>
                            <div style={{display: 'flex', justifyContent: 'left'}}>Курс</div>
                            <div style={{fontSize: '24px', fontWeight: "600"}}>Gruppenunterricht Deutsch A1 13:00 – 13:40</div>
                        </div>
                        <Button className="group-schedule__card__delete-btn" >Удалить из группы <DeleteOutlined /></Button>
                    </div>
                    <div className="group-schedule__card__footer">
                        <div style={{display: 'flex', gap: '10px'}}>
                            <div className="group-schedule__card__teacher">
                                13:00 – 13:40 (Пн, Вт, Пт)
                            </div>
                            <div className="group-schedule__card__teacher">
                                Индивидуальные занятия
                            </div>
                        </div>
                        <div>
                            <EditDrawer />
                        </div>
                    </div>
                </div>

            </div>
            <div>
              <div style={{display: "flex", flexWrap: 'wrap'}}>
                  <Calendar2 />
              </div>
            </div>
        </div>
    )
}