import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Drawer, Empty, Row, Space, Table } from "antd";
import React, { useContext, useMemo, useState } from "react";
import context from "../../../context";
import { useNavigate } from "react-router";

const CurrentCourse = (props) => {
  const {
    small = false,
    name,
    teacher,
    topics = [],
    type,
    extra,
    totalHoursCount,
    courseSubjects,
    currUser,
    remainingHoursCount,
  } = props;

  const [showAttendance, setShowAttendance] = useState(false);

  const { profileData, window } = useContext(context);
  const filteredTopics = useMemo(
    () =>
      topics
        ?.filter((a) => a.completed === false || a.completed === null)
        ?.sort((a, b) => new Date(a.startAt) - new Date(b.startAt)),
    [topics]
  );

  const topicLastTitle = filteredTopics?.[filteredTopics?.length - 1];

  const navigate = useNavigate();

  const attendance = useMemo(() => {
    let rate = courseSubjects?.length;
    courseSubjects.forEach((element) => {
      if (
        element.completed &&
        element.studentList?.find?.((el) => el.userId === currUser.userId) ===
          -1
      ) {
        rate += 1;
      }
    });
    return Math.ceil(rate / (courseSubjects?.length / 100));
  }, [courseSubjects]);
  return (
    <>
      <Card
        extra={
          remainingHoursCount > 0 || profileData?.role !== "student"
            ? [
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  size="large"
                  onClick={() =>
                    navigate(`/teacher-course-page/${props.id}/edit`)
                  }
                />,
                ...extra,
              ]
            : []
        }
        title={
          <Space
            style={{ width: "100%", textAlign: "left" }}
            direction="vertical"
          >
            {!small && (
              <span
                style={{
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#757575",
                }}
              >
                Курс
              </span>
            )}
            <span
              style={{
                fontSize: small ? "16px" : "24px",
                lineHeight: small ? "18px" : "26px",
                fontWeight: "bold",
              }}
            >
              {name}
            </span>
          </Space>
        }
        className="currentcourse"
        bodyStyle={{
          paddingTop: "4px",
          paddingBottom: "16px",
        }}
      >
        <Row gutter={[16, 8]}>
          {/* <Col span={16}> */}
          <Space direction="vertical" style={{ textAlign: "left" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                backgroundColor: "#F6F6FA",
                padding: "15px",
                justifyContent: "start",
                borderRadius: "10px",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "4px",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    color: "#757575",
                    textAlign: "end",
                  }}
                >
                  Учитель
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/teacher/${teacher.id}`);
                  }}
                >
                  {teacher?.name ?? "Не найден"}
                </span>
              </div>
              <Avatar
                size={24}
                style={{ marginRight: "5px", backgroundColor: "#FFD600" }}
                icon={<UserOutlined style={{ fontSize: "12px" }} />}
              />
            </div>
            <Space style={{ width: "100%" }}>
              <span
                style={{
                  fontSize: "16px",
                  lineHeight: "18px",
                  color: "#757575",
                  backgroundColor: "#F6F6FA",
                  padding: "15px",
                  borderRadius: "10px",
                }}
              >
                {type === "group"
                  ? "Групповой курс"
                  : type !== "pair"
                  ? "Индвидуальный курс"
                  : "Парный курс"}
              </span>

              {!small && (
                <span
                  style={{
                    backgroundColor: "#F6F6FA",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      lineHeight: "18px",
                      lineHeight: "16px",
                      color: "#757575",
                    }}
                  >
                    Следующая тема
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      lineHeight: "18px",
                      fontWeight: "bold",
                      paddingLeft: "5px",
                    }}
                  >
                    {topicLastTitle?.title ?? "Уроков нет"}
                  </span>
                </span>
              )}
            </Space>
            <Space>
              {totalHoursCount && remainingHoursCount > 0 ? (
                <span
                  style={{
                    width: "100%",
                    minWidth: "100px",
                    backgroundColor: "#F6F6FA",
                    padding: "15px",
                    borderRadius: "10px",
                    width: "fit-content",
                    color: "#757575",
                  }}
                  className="current-course"
                >
                  {`${remainingHoursCount}/${totalHoursCount} часов`}
                </span>
              ) : (
                profileData?.role === "student" && (
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                    }}
                  >
                    У вас нет часов по тарифу
                  </span>
                )
              )}
              <span
                style={{
                  fontSize: "16px",
                  lineHeight: "18px",
                  color: "#757575",
                  backgroundColor: "#F6F6FA",
                  padding: "15px",
                  borderRadius: "10px",
                }}
              >
                {`Посещаемость: ${attendance}%`}
              </span>
              <Button type="link" onClick={() => setShowAttendance(true)}>
                Смотреть посещаемость
              </Button>
            </Space>
          </Space>
        </Row>
      </Card>
      <Drawer
        open={showAttendance}
        onClose={() => setShowAttendance(false)}
        title="Посещаемость"
        width={window.width < 1000 ? "100%" : "80%"}
      >
        {/* <Spin spinning={loading}> */}
        <Space direction="vertical" style={{ width: "100%" }}>
          <Table
            columns={[
              {
                title: "Урок",
                dataIndex: "lesson",
                key: 1,
              },
              {
                title: "Посещамость",
                dataIndex: "attendance",
                key: 2,
              },
            ]}
            scroll={{
              x: true,
            }}
            locale={{
              emptyText: <Empty description="Нет данных" />,
            }}
            dataSource={courseSubjects?.map((el) => {
              return {
                lesson: el.title,
                attendance: el.studentList.find(
                  (el) => el.userId === currUser.userId
                )
                  ? "Был"
                  : "Не был",
              };
            })}
          />
        </Space>
        {/* </Spin> */}
      </Drawer>
    </>
  );
};

export default CurrentCourse;
