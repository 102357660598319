export function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function saveByteArray(reportName, byte, type) {
  if (!(byte && type)) return false;
  //   var link = document.createElement("a");
  //   link.href = window.URL.createObjectURL(blob);
  const anchorElement = document.createElement("a");
  document.body.appendChild(anchorElement);
  anchorElement.style.display = "none";

  var bytes = new Uint8Array(byte.length);
  for (var i = 0; i < byte.length; i++) {
    // var ascii = binaryString.charCodeAt(i);
    bytes[i] = byte[i];
  }
  var blob = new Blob([bytes], { type: type ?? "application/pdf" });

  // var url = window.URL.createObjectURL(blob);

  const url = window.URL.createObjectURL(blob);

  anchorElement.href = url;
  anchorElement.download = reportName;
  anchorElement.click();

  window.URL.revokeObjectURL(url);

  // window.open(url);
  //   var fileName = reportName;
  //   link.download = fileName;
  //   link.click();
}

export function download(arrayBuffer) {
  var blob = new Blob([arrayBuffer], {
    type: "application/pdf",
  });
  console.log(blob);
  var url = window.URL.createObjectURL(blob);
  window.open(url);
}
