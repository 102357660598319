import {
  Row,
  Col,
  Button,
  Drawer,
  Space,
  Input,
  Form,
  Result,
  Spin,
  Modal,
  DatePicker,
  Typography,
  Badge,
} from "antd";
import React, { useContext, useEffect } from "react";
import {
  DeleteOutlined,
  DownloadOutlined,
  FileTextOutlined,
  MailOutlined,
  PlusOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { addAttendance, postCourse, postHomeWork } from "../../../../../apis";
import { saveByteArray } from "../../../../../utils/files";
import moment from "moment";
import context from "../../../../../context";
import ls from "localstorage-slim";
import { fileToByteArray } from "../../../../teacher-page/course-page";

const MainAdDiv = (props) => {
  const {
    element,
    course,
    loading,
    lessonLink,
    setLoading,
    upd,
    material,
    hwSubmitted,
    current,
  } = props;

  const ref3 = React.useRef();
  const ref4 = React.useRef();

  const { window: window2, profileData } = useContext(context);

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [classUrl, setClassUrl] = React.useState(lessonLink);

  const [openModal, setOpenModal] = React.useState(false);

  const [success, setSuccess] = React.useState(false);
  const [submitHw, setSubmitHw] = React.useState(false);

  React.useEffect(() => {
    setClassUrl(lessonLink);
  }, [lessonLink]);

  useEffect(() => {
    if (hwSubmitted) {
      setHwFile({
        name: hwSubmitted.file.split("|")[0],
        id: hwSubmitted.file,
      });
      setHwText(hwSubmitted.description);
    }
  }, [hwSubmitted]);

  const handleChange = (event) => {
    setLoading(true);
    const fileUploaded = event.target.files[0];
    fileToByteArray(fileUploaded).then((r) => {
      const tempSubj = course?.courseSubjects?.map((el) => {
        if (
          el?.id ===
          (element
            ? element?.id
            : course?.courseSubjects[course?.courseSubjects?.length - 1]?.id)
        ) {
          return {
            ...el,
            files: [
              ...el?.files,
              `${fileUploaded?.name}|${fileUploaded?.type}|${r.join(".")}`,
            ],
          };
        }
        return el;
      });
      postCourse(course?.id, {
        ...course,
        courseSubjects: tempSubj,
      }).then(() => {
        upd();
      });
    });
  };

  const [modalFile, setModalFile] = React.useState();
  const [modalFile2, setModalFile2] = React.useState();

  const [hwDrawer, setHwDrawer] = React.useState(false);

  const handleChange2 = (event) => {
    setLoading(true);
    const fileUploaded = event.target.files[0];
    fileToByteArray(fileUploaded)
      .then((r) => {
        setFiles([
          ...files,
          `${fileUploaded?.name}|${fileUploaded?.type}|${r.join(".")}`,
        ]);
      })
      .finally(() => setLoading(false));
  };
  const [hwText, setHwText] = React.useState("");
  const [updateHwObject, setUpdateHwObject] = React.useState();
  const [hwFile, setHwFile] = React.useState();

  const downloadModal = () => {
    setModalFile2(hwFile);
  };

  const handleChangeHwsApiCall = () => {
    setLoading(false);
    postHomeWork(
      element?.id,
      ls.get("profile")?.userId ?? profileData?.userId,
      {
        ...updateHwObject,
        description: hwText,
      }
    ).then(() => {
      setModalFile2();
      setHwDrawer();
      setSubmitHw(false);
      setHwText("");
      upd();
    });
  };

  const handleChangeHws = (event) => {
    // if (!event) {
    //   downloadModal(event);
    //   return;
    // }
    setLoading(true);
    const fileUploaded = event.target.files[0];
    fileToByteArray(fileUploaded).then((r) => {
      setLoading(false);
      setUpdateHwObject({
        file: `${fileUploaded?.name}|${fileUploaded?.type}|${r.join(".")}`,
        description: hwText,
        completed: false,
        progress: true,
      });
      setHwFile({
        name: fileUploaded?.name,
        type: fileUploaded?.type,
        id: `${fileUploaded?.name}|${fileUploaded?.type}|${r.join(".")}`,
      });
    });
  };

  const handleSuccess = () => {
    setLoading(true);
    postCourse(course?.id, {
      ...course,
      courseSubjects: course?.courseSubjects?.map((el) => {
        if (
          el.id ===
          (element
            ? element.id
            : course?.courseSubjects[course?.courseSubjects?.length - 1]?.id)
        ) {
          return {
            ...el,
            homeworkDescription: description,
            homeworkTitle: name,
            homeworkFiles: files,
          };
        }
        return el;
      }),
    })
      .then((r) => {
        if (r?.status !== 200) {
          throw new Error(r?.response);
        }
        return r.json();
      })
      .then((r) => {
        setLoading(false);
        setSuccess(true);
        upd();
      })
      .catch(() => {
        setOpen(false);
        setLoading(false);
      });
  };

  const save = () => {
    if (modalFile) {
      const d = modalFile.split("|");
      saveByteArray(d[0] ?? "Материал №", d[2]?.split("."), d[1]);
      setModalFile(false);
    }
  };

  const save2 = () => {
    if (modalFile2) {
      console.log(modalFile2);
      const d = modalFile2.split("|");
      saveByteArray(d[0] ?? "Материал №", d[2]?.split("."), d[1]);
      setModalFile2(false);
    }
  };

  const handlePostHw = () => {
    setLoading(false);
    setSuccess(false);
    setOpen(false);
  };

  React.useEffect(() => {
    const lastTemp = element
      ? element
      : course?.courseSubjects[course?.courseSubjects?.length - 1];
    setName(lastTemp?.homeworkTitle);
    setDescription(lastTemp?.homeworkDescription);
    setFiles(lastTemp?.homeworkFiles ?? []);
  }, [course]);

  const ref2 = React.useRef();
  const last = element
    ? element
    : course?.courseSubjects[course?.courseSubjects?.length - 1];
  return (
    <div>
      <Row gutter={[16, 8]} style={{ paddingBottom: "10px" }}>
        {material?.map((el, i) => (
          <Col span={8} xs={12} md={12} lg={12}>
            <Button
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#F6F6FA",
                maxWidth: "100%",
                overflow: "hidden",
                borderRadius: "15px",
              }}
              onClick={() => {
                const d = el.split("|");
                saveByteArray(d[0] ?? "Материал №", d[2]?.split("."), d[1]);
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "10px",
                  // backgroundColor: "#F6F6FA",
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  justifyContent: "center",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    display: "flex",
                    gap: "4px",
                    flexDirection: "column",
                    justifyContent: "center",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    maxWidth: "100%",
                    wordBreak: "break-all",
                    whiteSpace: "normal",
                  }}
                >
                  <FileTextOutlined />
                  <span style={{ overflow: "hidden" }}>
                    {el?.split("|")[0] ?? "Материал №" + (i + 1)}
                  </span>
                </div>
              </div>
            </Button>
          </Col>
        ))}
      </Row>
      <div
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "15px",
          backgroundColor: "#E8FE9D",
        }}
      >
        <div
          style={{
            padding: !current && "25px",
            display: "flex",
            gap: "16px",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col
                  span={12}
                  xs={24}
                  md={24}
                  lg={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <span
                    style={{
                      tecoursetAlign: "left",
                      color: "#818199",
                    }}
                  >
                    Домашнее задание
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#292929",
                      fontWeight: "bold",
                    }}
                  >
                    {(element ?? last)?.homeworkTitle ??
                      "Домашнего задания пока нет"}
                  </span>
                </Col>
                <Col
                  span={12}
                  xs={24}
                  md={24}
                  lg={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    size="large"
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={() => setHwDrawer(true)}
                    disabled={
                      !(element ?? last)?.homeworkTitle || hwFile?.completed
                    }
                  >
                    Открыть задание
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Drawer
          open={hwDrawer}
          onClose={() => setHwDrawer(false)}
          width={window2.width < 1000 ? "100%" : "50%"}
          title={
            <div
              style={{
                display: "flex",
                gap: "4px",
              }}
            >
              <h3
                style={{
                  fontWeight: "bold",
                  margin: "0px",
                  color:
                    !hwSubmitted?.inProgress && !hwSubmitted?.completed
                      ? "black"
                      : hwSubmitted?.completed
                      ? "green"
                      : "red",
                }}
              >
                {`${(element ?? last)?.homeworkTitle?.trim()}:`}
              </h3>
              <h3
                style={{
                  fontWeight: "bold",
                  margin: "0px",
                  color:
                    !hwSubmitted?.inProgress && !hwSubmitted?.completed
                      ? "black"
                      : hwSubmitted?.completed
                      ? "green"
                      : "red",
                }}
              >
                {!hwSubmitted
                  ? "Не сдан"
                  : hwSubmitted?.inProgress
                  ? "На проверке"
                  : hwSubmitted?.completed
                  ? "Сдано"
                  : "Нужно пересдать"}
              </h3>
            </div>
          }
        >
          <Form.Item
            label={<p style={{ fontWeight: "bold" }}>Описание задания:</p>}
            labelCol={{ span: 24 }}
          >
            <Typography.Paragraph>
              {(element ?? last)?.homeworkDescription ?? "Описания задания нет"}
            </Typography.Paragraph>
          </Form.Item>
          <Form.Item
            label={<p style={{ fontWeight: "bold" }}>Файлы задания:</p>}
            labelCol={{ span: 24 }}
          >
            {element?.homeworkFiles?.length > 0 ? (
              <>
                {element.homeworkFiles.map((el, i) => (
                  <Col span={8} xs={12} md={12} lg={12}>
                    <Button
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#F6F6FA",
                        maxWidth: "100%",
                        overflow: "hidden",
                        borderRadius: "15px",
                      }}
                      onClick={() => {
                        const d = el.split("|");
                        saveByteArray(
                          d[0] ?? "Материал №",
                          d[2]?.split("."),
                          d[1]
                        );
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          justifyContent: "center",
                          maxWidth: "100%",
                        }}
                      >
                        <div
                          style={{
                            paddingLeft: "10px",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                            display: "flex",
                            gap: "4px",
                            flexDirection: "column",
                            justifyContent: "center",
                            flexWrap: "nowrap",
                            alignItems: "center",
                            maxWidth: "100%",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          <FileTextOutlined />
                          <span>
                            {el?.split("|")[0] ?? "Материал №" + (i + 1)}
                          </span>
                        </div>
                      </div>
                    </Button>
                  </Col>
                ))}
              </>
            ) : (
              <Typography.Paragraph>Файлов нет</Typography.Paragraph>
            )}
          </Form.Item>
          {hwSubmitted?.feedback && (
            <Form.Item
              label={<p style={{ fontWeight: "bold" }}>Ответ учителя:</p>}
              labelCol={{ span: 24 }}
            >
              <Typography.Paragraph>
                {hwSubmitted.feedback}
              </Typography.Paragraph>
            </Form.Item>
          )}
          <Form.Item
            label={<p style={{ fontWeight: "bold" }}>Ваш ответ:</p>}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea
              rows={6}
              placeholder="Введите текст"
              value={hwText}
              onChange={(e) => setHwText(e?.target?.value)}
            />
          </Form.Item>
          <Space direction="vertical" style={{ width: "100%" }}>
            {hwFile && (
              <Space.Compact style={{ width: "100%" }}>
                <Button
                  size="large"
                  type="default"
                  danger
                  style={{ width: "100%" }}
                  onClick={() => setHwFile()}
                  icon={<DeleteOutlined />}
                >
                  Удалить
                </Button>
                <Button
                  size="large"
                  type="default"
                  style={{ width: "100%" }}
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    const d = hwFile.id.split("|");
                    console.log(d);
                    saveByteArray(d[0] ?? "ДЗ №", d[2]?.split("."), d[1]);
                  }}
                >
                  {hwFile.name}
                </Button>
              </Space.Compact>
            )}
            <Button
              size="large"
              type="default"
              style={{ width: "100%" }}
              onClick={() => ref4?.current?.click()}
              icon={<PlusOutlined />}
              disabled={!element?.homeworkTitle}
            >
              {hwFile ? "Заменить файл" : "Добавить файл"}
            </Button>
            <Button
              size="large"
              type={!hwSubmitted ? "primary" : "default"}
              style={{ width: "100%" }}
              onClick={() => handleChangeHwsApiCall()}
              icon={<MailOutlined />}
              disabled={!updateHwObject || hwText?.length <= 0}
            >
              Сдать
            </Button>
          </Space>
          <input
            type="file"
            onChange={handleChangeHws}
            ref={ref4}
            style={{ display: "none" }}
          />
        </Drawer>
        <Drawer
          open={submitHw}
          onClose={() => setSubmitHw(false)}
          width={window2.width < 1000 ? "100%" : "50%"}
        >
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item label="Описание" labelCol={{ span: 24 }}>
                <Input.TextArea
                  rows={6}
                  placeholder="Введите текст"
                  onChange={(e) => setHwText(e?.target?.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                size="large"
                type={!hwSubmitted ? "primary" : "default"}
                style={{ width: "100%" }}
                onClick={() =>
                  hwFile ? handleChangeHws() : ref4?.current?.click()
                }
                disabled={!element?.homeworkTitle}
              >
                Сдать
              </Button>
            </Col>
          </Row>
        </Drawer>
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          width={window2.width < 1000 ? "100%" : "50%"}
        >
          {success ? (
            <Result
              status="success"
              title={`Ваше задание было ${
                !last?.homeworkTitle ? "добавлено" : "обновлено"
              }!`}
              extra={[
                <Button
                  type="primary"
                  key="console"
                  block
                  size="large"
                  onClick={handlePostHw}
                >
                  Закрыть
                </Button>,
              ]}
            />
          ) : (
            <Spin
              spinning={loading}
              style={{
                width: "100%",
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }} size={8}>
                <Form.Item label="Название задания" labelCol={{ span: 24 }}>
                  <Input
                    placeholder="Введите название задания"
                    value={name}
                    onChange={(e) => setName(e?.target?.value)}
                  ></Input>
                </Form.Item>
                <Form.Item label="Описание задания" labelCol={{ span: 24 }}>
                  <Input
                    placeholder="Введите название задания"
                    value={description}
                    onChange={(e) => setDescription(e?.target?.value)}
                  ></Input>
                </Form.Item>
                <Row gutter={[8, 8]}>
                  {files ? (
                    files.map((el, i) => (
                      <Col span={12} xs={24} md={24} lg={12}>
                        <Row gutter={[4, 4]}>
                          <Col span={20}>
                            <Button
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#F6F6FA",
                              }}
                              onClick={() => {
                                // var sampleArr = base64ToArrayBuffer(el.split("."));
                                const d = el.split("|");
                                saveByteArray(
                                  d[0] ?? "ДЗ №",
                                  d[2]?.split("."),
                                  d[1]
                                );
                                // saveByteArray("Домашнее задание №", el.split("."));
                                // download(el.split("."));
                              }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "10px",
                                  // backgroundColor: "#F6F6FA",
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "8px",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: "10px",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                    display: "flex",
                                    gap: "4px",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                  }}
                                >
                                  <FileTextOutlined />
                                  <span style={{ overflow: "hidden" }}>
                                    {el?.split("|")[0] ?? "ДЗ №" + (i + 1)}
                                  </span>
                                </div>
                              </div>
                            </Button>
                          </Col>
                          <Col span={4}>
                            <Button
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#F6F6FA",
                              }}
                              danger
                              onClick={() => {
                                // var sampleArr = base64ToArrayBuffer(el.split("."));
                                // saveByteArray("Домашнее задание №", el.split("."));
                                // download(el.split("."));
                                console.log(files);
                                const tempFiles = files?.find(
                                  (ele) => ele.id === el.id
                                );
                                setFiles([
                                  ...files.filter(
                                    (ele) => ele.id !== tempFiles?.id
                                  ),
                                ]);
                              }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  borderRadius: "10px",
                                  // backgroundColor: "#F6F6FA",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexWrap: "nowrap",
                                    alignItems: "center",
                                  }}
                                >
                                  <DeleteOutlined />
                                </div>
                              </div>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    ))
                  ) : (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      Файлы не вложены
                    </span>
                  )}
                  {/* {files &&
                  files?.map((el, i) => (
                    <Col span={12} xs={24} md={24} lg={12}>
                      <Button
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#F6F6FA",
                        }}
                        danger
                        onClick={() => {
                          // var sampleArr = base64ToArrayBuffer(el.split("."));
                          // saveByteArray("Домашнее задание №", el.split("."));
                          // download(el.split("."));
                          console.log(files);
                          const tempFiles = files?.find(
                            (ele) => ele.id === el.id
                          );
                          setFiles([
                            ...files.filter((ele) => ele.id !== tempFiles?.id),
                          ]);
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "10px",
                            // backgroundColor: "#F6F6FA",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              paddingTop: "15px",
                              paddingBottom: "15px",
                              display: "flex",
                              gap: "4px",
                              flexDirection: "column",
                              justifyContent: "center",
                              flexWrap: "nowrap",
                              alignItems: "center",
                            }}
                          >
                            <DeleteOutlined />
                            <span>{"Удалить №" + (i + 1)}</span>
                          </div>
                        </div>
                      </Button>
                    </Col>
                  ))} */}
                </Row>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  onClick={() => ref3?.current?.click()}
                >
                  Добавить материал
                </Button>
                <Button
                  type="primary"
                  size="large"
                  style={{ width: "100%" }}
                  onClick={handleSuccess}
                >
                  Обновить домашнее задание
                </Button>
                <input
                  type="file"
                  onChange={handleChange2}
                  ref={ref3}
                  style={{ display: "none" }} // Make the file input element invisible
                />
              </Space>
            </Spin>
          )}
        </Drawer>
        <Modal
          open={openModal}
          onCancel={() => setOpenModal(false)}
          footer={
            <Button
              disabled={loading}
              type="primary"
              style={{ margin: "15px" }}
              onClick={() => {
                setLoading(true);
                postCourse(course?.id, {
                  ...course,
                  lessonLink: classUrl,
                })
                  // postHomework("", {
                  //     ...last
                  // })
                  .then((r) => {
                    console.log(r);
                    if (r?.status !== 200) {
                      throw new Error(r?.response);
                    }
                    return r.json();
                  })
                  .then((r) => {
                    console.log(r);

                    setLoading(false);
                    upd();
                  })
                  .catch(() => {
                    setLoading(false);
                  });
              }}
            >
              Сохранить
            </Button>
          }
        >
          <Spin spinning={loading}>
            <Form.Item
              label="Ссылка на Zoom"
              labelCol={{ span: 24 }}
              style={{
                padding: "25px",
                paddingBottom: "10px",
              }}
            >
              <Input
                placeholder="Введите ссылку Zoom"
                value={classUrl}
                onChange={(e) => setClassUrl(e?.target?.value)}
              ></Input>
            </Form.Item>
          </Spin>
        </Modal>
        <Modal
          open={modalFile}
          onCancel={() => setModalFile(false)}
          footer={
            <div>
              <Button
                type="primary"
                danger
                style={{ margin: "15px", marginRight: "5px" }}
                // onClick={() => {
                //   ref4?.current?.click();
                // }}
              >
                Удалить
              </Button>
              ,
              <Button
                type="primary"
                style={{ margin: "15px", marginLeft: "5px" }}
                onClick={() => {
                  save();
                }}
              >
                Сохранить
              </Button>
            </div>
          }
        >
          <span style={{ padding: "25px", paddingBottom: "0px" }}>
            Выберите действие с файлом:
          </span>
        </Modal>
        <Modal
          open={modalFile2}
          onCancel={() => setModalFile2(false)}
          footer={
            <div>
              <Button
                style={{ margin: "15px", marginRight: "5px" }}
                onClick={() => {
                  ref4?.current?.click();
                }}
                disabled={loading}
              >
                Заменить
              </Button>
              <Button
                type="primary"
                style={{ margin: "15px", marginLeft: "5px" }}
                onClick={() => {
                  save2();
                }}
                disabled={loading}
              >
                Сохранить
              </Button>
            </div>
          }
        >
          <span style={{ padding: "25px", paddingBottom: "0px" }}>
            Выберите действие с файлом:
          </span>
        </Modal>
      </div>
      <Row gutter={[8, 8]} style={{ paddingTop: "10px" }}>
        {/* <Col span={24}>
          <span style={{ fontSize: "14px" }}>Ссылка на урок</span>
        </Col> */}
        <input
          type="file"
          onChange={handleChange}
          ref={ref2}
          style={{ display: "none" }} // Make the file input element invisible
        />
        <Col span={8}>
          <DatePicker
            allowClear={false}
            inputReadOnly={true}
            open={false}
            format="DD/MM HH:mm"
            showTime={{
              format: "HH:mm",
            }}
            value={moment(element?.startAt, "YYYY-MM-DDTHH:mm:ss.SSS+06:00")}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={8}>
          <Button
            type="primary"
            style={{ width: "100%" }}
            disabled={!classUrl || element?.completed}
            onClick={() => {
              addAttendance(element?.id).finally(() =>
                window.open(classUrl, "_blank")
              );
            }}
          >
            Присоединиться
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MainAdDiv;
